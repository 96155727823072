import { useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import classNames from 'classnames';

import { PitchTypes } from 'components/Pitches/data';
import { AudioInclusionTypes } from 'components/Popups/DesktopSearch/data';
import { ISystemPitch } from 'components/Popups/SystemPitchForm';
import { Button, PlayButton } from 'components/Reusable';
import { ButtonVariant } from 'components/Reusable/Button';
import { ISongData } from 'components/Songs/data';
import { NewTextField as TextField, Tooltip } from 'components/UI';

import { useIsMounted } from 'hooks';
import Api from 'services/Api';
import { debouncePromise, DEFAULT_ERROR_CONFIG, getSongVersion, validateRequired } from 'utils';

import { ReactComponent as ApproveIcon } from 'assets/approve-2.svg';
import { ReactComponent as PlusCircleIcon } from 'assets/plus-circle.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import styles from './ManualPitchForm.module.scss';

import { IManualPitch, ISong, SongRow } from '.';

export interface IAddSongProps {
  addedSongs?: ISong[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  methods: UseFormReturn<IManualPitch | ISystemPitch, object>;
  pitchType?: PitchTypes;
}

const isAdded = (data: ISongData, songs: ISong[]): boolean =>
  Boolean(songs.find((v) => v.mediaId === data.mediaId && v.id === data.id));

const SearchResultItem = ({
  data,
  added,
  onAddClick,
  songs,
}: {
  added: boolean;
  data: ISongData;
  onAddClick: (data: ISongData) => void;
  songs: ISongData[];
}) => {
  const writers = data.writers.join(' / ');

  return (
    <div className={styles.row}>
      <div className={styles.playCell}>
        <PlayButton
          extension={data.mediaPlaybackPath}
          getTracks={() =>
            songs.map((item) => ({
              mediaId: item.mediaId || '',
              id: item.id,
              title: item.title || '',
              writers: item.writers || [],
              path: item.mediaPath || '',
              playbackPath: item.mediaPlaybackPath || '',
            }))
          }
          mediaId={data.mediaId}
        />
      </div>
      <div className={styles.titleCell}>
        <div>
          <Tooltip text={data.title}>
            <div className={styles.title}>{data.title}</div>
          </Tooltip>
        </div>
        {writers && (
          <div>
            <Tooltip text={writers}>
              <div className={styles.description}>{writers}</div>
            </Tooltip>
          </div>
        )}
      </div>
      <div className={styles.versionCell}>{getSongVersion(data?.version)}</div>
      <div className={styles.actionsCell}>
        {data.mediaId ? (
          added ? (
            <ApproveIcon />
          ) : (
            <Button className={styles.addSongButton} onClick={() => onAddClick(data)} variant={ButtonVariant.Text}>
              <PlusCircleIcon />
            </Button>
          )
        ) : null}
      </div>
    </div>
  );
};

const AddSongs = ({ addedSongs, methods, pitchType }: IAddSongProps) => {
  const [search, setSearch] = useState<{ loading?: boolean; results?: ISongData[]; text?: string }>({});

  const isMounted = useIsMounted();

  const onSearchChange = (v: string | number | null) => {
    setSearch({ loading: !!v, results: [], text: v ? v?.toString() || '' : undefined });

    if (!v) return;

    debouncePromise(async () => {
      const results = (await Api.getSongs(
        {
          searchQuery: v?.toString() || '',
          pagination: { skip: 0, take: 30 },
          audioInclusionType: AudioInclusionTypes.withAudio,
        },
        { errorPopupConfig: DEFAULT_ERROR_CONFIG }
      )) as { songs: ISongData[] } | undefined;
      results &&
        isMounted() &&
        setSearch((prev) => ({ ...prev, loading: false, results: prev.text ? results.songs : [] }));
    }, 'manual-pitch-songs');
  };

  const songs = methods.watch('songs');

  const allAddedSongs = useMemo(
    () =>
      [...(addedSongs || []), ...(songs || [])].reduce((arr: ISong[], song) => {
        const existed = arr.find((s) => s.id === song.id && s.mediaId === song.mediaId);
        !existed && arr.push(song);
        return arr;
      }, []),
    [addedSongs, songs]
  );

  return (
    <Controller
      control={methods.control}
      name="songs"
      render={({ field: { onChange, value } }) => (
        <div className={styles.addSongs}>
          <TextField
            className={styles.searchbox}
            Icon={<SearchIcon height="13" width="13" />}
            onChange={onSearchChange}
            placeholder="Search for Songs or Writers"
            value={search.text}
          />
          <div className={classNames(styles.overflowY, styles.searchSongsContainer)}>
            {search.loading ? (
              <div className={styles.message}>Loading...</div>
            ) : (
              <>
                {search.results?.length === 0 && typeof search.text === 'string' && (
                  <div className={styles.message}>Nothing found</div>
                )}
                {search.results?.map((data, i, arr) => (
                  <SearchResultItem
                    added={isAdded(data, allAddedSongs)}
                    data={data}
                    songs={arr}
                    key={i}
                    onAddClick={(d) =>
                      onChange([
                        ...(value || []),
                        {
                          id: d.id,
                          mediaId: d.mediaId,
                          title: d.title,
                          version: d.version || undefined,
                          writers: d.writers,
                          path: d.mediaPath,
                          playbackPath: d.mediaPlaybackPath,
                        },
                      ])
                    }
                  />
                ))}
              </>
            )}
          </div>
          {value && value.length > 0 && (
            <>
              <h3 className={styles.heading}>Added Songs</h3>
              <div className={classNames(styles.overflowY, styles.addedSongsContainer)}>
                {value.map((s, i, arr) => (
                  <SongRow
                    editable
                    key={i}
                    onChange={() => onChange(value?.slice())}
                    onDelete={(song) => onChange((value || []).filter((d) => d !== song))}
                    song={s}
                    songs={arr}
                    showResultColumn={pitchType !== PitchTypes.system}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
      rules={{ validate: validateRequired }}
    />
  );
};

export default AddSongs;
