import { Control, Path } from 'react-hook-form';

import { InputAutocomplete } from 'components/UI';

interface IAutocompletesListProps<T, R> {
  control: Control<T>;
  data: R[];
  disabledFields?: Array<Path<T>>;
}

export interface IAutocompleteNestedData {
  id: number | string;
  title: string;
}

export interface IAutocompleteData<T> {
  id: number;
  title: string;
  data: IAutocompleteNestedData[];
  value: Path<T>;
  onlyFirstCharCapitalized?: boolean;
}

export interface IAutocompleteClassnames {
  formRootClass?: string;
  formLabelClass?: string;
  autocompleteRootClass?: string;
  autocompleteInputRootClass?: string;
  autocompleteInputRootReplaceClass?: string;
  autocompleteInputClass?: string;
  autocompleteEndAdornmentClass?: string;
  autocompletePaperClass?: string;
  autocompletePopperClass?: string;
  autocompleteListboxClass?: string;
  disabledClass?: string;
  multiple?: boolean;
  disableClearable?: boolean;
}

const AutocompletesList = <T, R extends IAutocompleteData<T>>({
  control,
  data,
  formLabelClass,
  formRootClass,
  autocompleteRootClass,
  autocompleteInputRootClass,
  autocompleteInputClass,
  autocompleteEndAdornmentClass,
  autocompletePaperClass,
  autocompletePopperClass,
  autocompleteListboxClass,
  disabledClass,
  disabledFields,
  multiple,
  disableClearable = true,
}: IAutocompletesListProps<T, R> & IAutocompleteClassnames): JSX.Element => {
  return (
    <>
      {data.map(({ id, title, data, value, onlyFirstCharCapitalized }) => (
        <InputAutocomplete
          key={id}
          name={value}
          label={title}
          data={data}
          control={control}
          disabled={disabledFields?.includes(value)}
          labelPlacement="start"
          formLabelClass={formLabelClass}
          formRootClass={formRootClass}
          autocompleteRootClass={autocompleteRootClass}
          autocompleteInputRootClass={autocompleteInputRootClass}
          autocompleteInputClass={autocompleteInputClass}
          autocompleteEndAdornmentClass={autocompleteEndAdornmentClass}
          autocompletePaperClass={autocompletePaperClass}
          autocompletePopperClass={autocompletePopperClass}
          autocompleteListboxClass={autocompleteListboxClass}
          disabledClass={disabledClass}
          multiple={multiple}
          disableClearable={disableClearable}
          onlyFirstCharCapitalized={onlyFirstCharCapitalized}
        />
      ))}
    </>
  );
};

export default AutocompletesList;
