import { NotificationPreferencesKeys, notificationsList } from 'components/NotificationPreferences/data';
import { switchButtons } from 'components/Popups/DesktopSearch/data';
import { playlistMoreButtons } from 'components/UI/PlaylistMoreMenu/data';
import { songMoreButtons } from 'components/UI/SongMoreMenu/data';
import { filterByPlaylist } from 'containers/Sidebar/data';

import { selectedItemsActions, songsActionButtons, UserRoleCodes } from './data-constants';

export type UnknownObject = {
  [key: string]: unknown;
};

type PermissionsType = {
  [key in UserRoleCodes]: UnknownObject;
};

const SuperAdminPermissions = {
  searchSongs: true,
  editSongDetails: true,
  viewSongsTabs: true,
  songsModuleFilters: true,
  viewSongDetailsHeader: true,
  viewSongDetailsTags: true,
  viewSongDetailsVersions: true,
  viewSongDetailsStatuses: true,
  songDetailsActions: true,
  playbarPitch: true,
  playbarDownload: true,
  playbarArchive: true,
  playbarAddMarker: true,
  playbarLabelView: true,
  playlistFilters: filterByPlaylist,
  myAccountPage: true,
  adminControlsPage: true,
  reportsPage: true,
  contactsPage: true,
  changeMyTeams: true,
  pitchesPage: true,
  teamPitches: true,
  pitchActions: true,
  addContactFromPitchForm: true,
  reportActions: true,
  deleteContact: true,
  contactActions: true,
  playlistActions: playlistMoreButtons,
  songsActions: songMoreButtons,
  songsSelectedItemsActions: selectedItemsActions,
  viewMergeSongsButton: true,
  songsActionButtons: songsActionButtons.slice(0, 7),
  editFeaturedPlaylist: true,
  searchSwitchButtons: switchButtons,
  searchAudioToggle: true,
  shareSongOrPlaylistToInternalUsers: true,
  createSearchAlertForInternalUsers: true,
  notificationPreferencesList: notificationsList,
  showHoldRequestContact: true,
  showAiTagIcon: true,
};

const permissions: PermissionsType = {
  [UserRoleCodes.Label]: {
    allowedSongsTableColumns: ['', 'empty', 'selection', 'actions', 'filters', 'title', 'version', 'writers', 'team'],
    songsModuleFiltersTitle: true,
    songsModuleFiltersTeam: true,
    songsModuleFiltersWriters: true,
    myAccountPage: true,
    viewAllSongs: true,
    viewSongDetailsBpm: true,
    viewSongDetailsStatus: true,
    viewSongDetailsTags: true,
    viewSongDetailsVersions: true,
    viewSongDetailsStatuses: true,
    playlistFilters: filterByPlaylist.slice(1),
    playlistActions: [playlistMoreButtons[0], playlistMoreButtons[1], playlistMoreButtons[4], playlistMoreButtons[6]],
    songsActions: songMoreButtons.filter((btn) => btn.isForLabelUsers),
    songsSelectedItemsActions: [selectedItemsActions[0]],
    songsActionButtons: [songsActionButtons[0], songsActionButtons[1], songsActionButtons[3]],
    songsPageHeaderMarginBottom: 10,
    searchSwitchButtons: [switchButtons[0], switchButtons[2]],
    notificationPreferencesList: notificationsList.filter(
      (el) => el.value !== NotificationPreferencesKeys.pitchActivity
    ),
  },
  // TODO: open this comment when will integrate Partner and Songwriter roles
  // [UserRoleCodes.Partner]: {
  //   editSongDetails: true,
  //   viewTeamUploadsSongs: true,
  //   viewMyUploadsSongs: true,
  //   songDetailsActions: true,
  //   playlistFilters: filterByPlaylist.slice(1),
  //   pitchesPage: true,
  //   teamPitches: true,
  //   pitchActions: true,
  //   addContactFromPitchForm: true,
  //   reportsPage: true,
  //   reportActions: true,
  //   contactsPage: true,
  //   deleteContact: true,
  //   contactActions: true,
  // },
  // [UserRoleCodes.Songwriter]: {
  //   editSongDetails: true,
  //   viewTeamUploadsSongs: true,
  //   songDetailsActions: true,
  //   playlistFilters: [filterByPlaylist[1], filterByPlaylist[3], filterByPlaylist[4], filterByPlaylist[5]],
  //   pitchesPage: true,
  //   addContactFromPitchForm: true,
  //   contactsPage: true,
  //   deleteContact: true,
  // },
  [UserRoleCodes.ArrowReadOnly]: {
    searchSongs: true,
    playlistFilters: [filterByPlaylist[0]],
    myAccountPage: true,
    pitchesPage: true,
    teamPitches: true,
    reportsPage: true,
    contactsPage: true,
    playlistActions: [playlistMoreButtons[0]],
    songsActions: songMoreButtons.filter((btn) => btn.isForReadOnlyUsers),
    songsSelectedItemsActions: [selectedItemsActions[0]],
    songsActionButtons: [],
    notificationPreferencesList: notificationsList,
  },
  [UserRoleCodes.ArrowTeamMember]: { ...SuperAdminPermissions },
  [UserRoleCodes.SuperAdmin]: { ...SuperAdminPermissions },
  // TODO: remove this when will integrate Partner and Songwriter roles
  [UserRoleCodes.Partner]: { ...SuperAdminPermissions },
  [UserRoleCodes.Songwriter]: { ...SuperAdminPermissions },
};

export default permissions;
