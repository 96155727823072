import { Control, Controller } from 'react-hook-form';

import { SongsModuleFilterDataType, SongsModuleFilterKeys, SongsModuleFilterTitles } from 'components/Songs/data';
import { InputAsyncAutocomplete } from 'components/UI';
import { IInputAutocompleteOption } from 'components/UI/InputAsyncAutocomplete';

import Api from 'services/Api';

import styles from './SongsModuleFiltersPopup.module.scss';

import { IWriterItem } from 'types';

const WritersAutocompleteFilterField = ({ control }: { control: Control<SongsModuleFilterDataType> }) => (
  <Controller
    control={control}
    name={SongsModuleFilterKeys.writers}
    render={({ field: { onChange, value } }) => (
      <InputAsyncAutocomplete
        multiple
        label={SongsModuleFilterTitles.writers}
        labelPlacement="start"
        placeholder="filter by writer name"
        value={
          Array.isArray(value)
            ? (value as IWriterItem[]).map((item) => ({ id: item.id || '', title: item.name || '' }))
            : []
        }
        onChange={(_, value) => {
          onChange((value as IInputAutocompleteOption[])?.map((item) => ({ id: item.id, name: item.title })));
        }}
        inputBaseClassName={styles.writersInputBaseClassName}
        autocompleteClassName={styles.writersAutocomplete}
        className={styles.writersAutocompleteLabel}
        onResolveSuggestions={(text) => Api.getWriters(text).catch(() => [])}
        disableClearable
        disableCloseOnSelect
      />
    )}
  />
);

export default WritersAutocompleteFilterField;
