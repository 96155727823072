import { useForm } from 'react-hook-form';

import { MultiStepsFormDialog } from 'components/Reusable';
import { IStep } from 'components/Reusable/MultiStepsFormDialog';

import { useDidUpdate } from 'hooks';
import Api from 'services/Api';
import { DEFAULT_ERROR_CONFIG, sendAnalyticsEvent } from 'utils';

import styles from './ManualPitchForm.module.scss';

import { AddSongs, Confirm, CreatePitch } from '.';
import { ISong } from './SongRow';
import { AnalyticsEventTypes, IAutocompleteOption } from 'types';

export interface IContactAutocompleteOption extends IAutocompleteOption {
  email?: string;
  isGroup?: boolean;
  team?: string;
  teamName?: string;
}

export interface IManualPitch {
  artist?: string;
  contact?: IContactAutocompleteOption;
  id?: string;
  label?: string;
  sentDate?: Date;
  songs?: ISong[];
  title?: string;
}

export interface IManualPitchFormProps {
  addedSongs?: ISong[];
  item?: IManualPitch;
  onClose: () => void;
  onSubmit: () => void;
}

const ManualPitchForm = ({ addedSongs, item, onClose, onSubmit }: IManualPitchFormProps) => {
  const methods = useForm<IManualPitch>({ defaultValues: item, mode: 'all' });

  const onSubmitClick = async (values: IManualPitch) => {
    const result = values.id
      ? await Api.addMediasToManualPitch(
          values.id,
          values.songs?.map((item) => ({ mediaId: item.mediaId || '', result: (item?.result?.id || null) as string })),
          { errorPopupConfig: DEFAULT_ERROR_CONFIG }
        )
      : await Api.addManualPitch(values, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    sendAnalyticsEvent(AnalyticsEventTypes.pitchSaved);
    result && onSubmit();
  };

  useDidUpdate(() => methods.reset(item), [item]);

  const creationSteps: IStep[] = [
    {
      Component: (
        <div className={styles.manualPitchFormContent}>
          <CreatePitch methods={methods} />
        </div>
      ),
      title: 'Create Manual Pitch',
    },
    {
      Component: (
        <div className={styles.manualPitchFormContent}>
          <AddSongs addedSongs={addedSongs} methods={methods} />
        </div>
      ),
      title: 'Add Songs to Manual Pitch',
    },
    {
      Component: (
        <div className={styles.manualPitchFormContent}>
          <Confirm methods={methods} />
        </div>
      ),
      title: 'Confirm Pitch',
    },
  ];

  const editSteps: IStep[] = [
    {
      Component: (
        <div className={styles.manualPitchFormContent}>
          <AddSongs addedSongs={addedSongs} methods={methods} />
        </div>
      ),
      title: 'Add Songs to Manual Pitch',
    },
  ];

  return (
    <MultiStepsFormDialog
      contentMaxHeight="436px"
      isCurrentStepValid={methods.formState.isValid}
      isSubmitting={methods.formState.isSubmitting}
      onCancel={onClose}
      onStepChange={methods.trigger} // Revalidate form
      onSubmit={methods.handleSubmit(onSubmitClick)}
      open
      playbarVisible
      steps={item?.id ? editSteps : creationSteps}
    />
  );
};

export default ManualPitchForm;
