import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { GridApi } from 'ag-grid-community';

import songsStyles from 'components/Songs/Songs.module.scss';
import DataGrid, { DefaultColDefConfigs } from 'components/UI/DataGrid';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getFeaturedPlaylistHash, setCurrentPlaylistId } from 'store';
import { TABLE_PAGE_SIZE } from 'utils';

import { featuredPlaylistColumns } from './data';

const FeaturedPlaylistTable = () => {
  const dispatch = useDispatch();

  const featuredPlaylistHash = useMemoSelector(getFeaturedPlaylistHash);

  const gridRef = useRef<GridApi | null>(null);

  const getRowsHandler = useCallback(async (params) => {
    const { startRow } = params;

    const res = await Api.getFeaturedPlaylistsForAdmin({
      pagination: {
        skip: startRow,
        take: TABLE_PAGE_SIZE,
      },
    });

    return [
      ...(Array.isArray(res?.pinedPlaylists) ? res.pinedPlaylists : []),
      ...(Array.isArray(res?.playlists) ? res.playlists : []),
    ];
  }, []);

  return (
    <div className={songsStyles.dataGridContainer} style={{ height: 500 }}>
      <DataGrid
        ref={gridRef}
        reloadHash={`${featuredPlaylistHash}`}
        columns={featuredPlaylistColumns}
        datasource={{
          type: 'infinite',
          pageSize: TABLE_PAGE_SIZE,
          getRows: getRowsHandler,
        }}
        className={songsStyles.dataGrid}
        defaultColDef={DefaultColDefConfigs}
        disableColumnsConfig={true}
        onRowClicked={(e) => e.data.id && dispatch(setCurrentPlaylistId(e.data.id))}
      />
    </div>
  );
};

export default FeaturedPlaylistTable;
