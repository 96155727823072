import React, { useState } from 'react';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import classNames from 'classnames';

import { DoubleIconBtnGroup } from 'components/Reusable';

import { EDIT_TOOLTIP_CONTAINER_ID } from 'utils';

import { ReactComponent as EditIcon } from 'assets/edit.svg';
import styles from './EditTooltip.module.scss';

interface IEditTooltip {
  Component: JSX.Element;
  children: JSX.Element;
  onSubmit?: () => void;
  onCancel?: () => void;
  onOpen?: () => void;
  className?: string;
  tooltipClassName?: string;
  editIconClassName?: string;
  disableSpacing?: boolean;
  disableEditing?: boolean;
  disableClosing?: boolean;
  disableSubmit?: boolean;
}

const EditTooltip = ({
  Component,
  children,
  onCancel,
  onOpen,
  onSubmit,
  className,
  tooltipClassName,
  editIconClassName,
  disableSpacing,
  disableEditing,
  disableClosing,
  disableSubmit,
}: IEditTooltip): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = (
    e: React.MouseEvent<Document, MouseEvent> | React.ChangeEvent<Record<string, unknown>>
  ) => {
    if (!open) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const path = (e as any).path || ((e as any).composedPath && (e as any).composedPath());

    if (path?.find((item: Element) => item.id === EDIT_TOOLTIP_CONTAINER_ID) || disableClosing) return;

    onCancel && onCancel();
    setOpen(false);
  };

  const handleTooltipSubmit = () => {
    onSubmit && onSubmit();
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    onOpen && onOpen();
    setOpen(true);
  };

  return disableEditing ? (
    <div className={classNames(styles.childrenContainer, className)}>{children}</div>
  ) : (
    <ClickAwayListener onClickAway={handleTooltipClose} touchEvent="onTouchEnd" disableReactTree>
      <Tooltip
        arrow
        classes={{
          popper: styles.editTooltipPopper,
          tooltip: classNames(styles.editTooltip, tooltipClassName),
          arrow: styles.editTooltipArrow,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <div id={EDIT_TOOLTIP_CONTAINER_ID} className={styles.editTooltipContainer}>
            {Component}
            <DoubleIconBtnGroup
              className={styles.doubleIconBtnGroup}
              onClick1={handleTooltipSubmit}
              onClick2={handleTooltipClose as unknown as React.MouseEventHandler}
              disableSubmit={disableSubmit}
            />
          </div>
        }
      >
        <div className={classNames(styles.childrenContainer, className)}>
          {children}
          {!disableSpacing && <div className={styles.spacing} />}
          <EditIcon
            data-testid="edit-icon"
            className={classNames(styles.editIcon, editIconClassName, {
              [styles.active]: open,
            })}
            onClick={handleTooltipOpen}
          />
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default EditTooltip;
