import { useEffect, useRef, useState } from 'react';

const useContainerWidth = <T extends HTMLElement>(): [React.RefObject<T>, number] => {
  const containerRef = useRef<T>(null);
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    setWidth(containerRef.current?.offsetWidth);
    const observer = new ResizeObserver(() => setWidth(containerRef.current?.offsetWidth));
    containerRef.current && observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [containerRef]);
  return [containerRef, width || 0];
};

export default useContainerWidth;
