/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { CSSProperties, useCallback, useEffect, useRef } from 'react';

import { fitToContainer, RoundingStyle, roundRect } from './canvas';
import { WaveFormProps } from './IWaveForm';
import { resample } from './utils';

// eslint-disable-next-line react/display-name
export const WaveForm = React.forwardRef((props: WaveFormProps, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Rendering options with defaults
  const barWidth = props.barWidth || 5;
  const spaceWidth = props.spaceWidth || 2;
  const baseFillStyle = props.baseFillStyle || '#dedede';
  const playedFillStyle = props.playedFillStyle || '#d9a81b';
  const caretFillStyle = props.caretFillStyle || '#aaa';
  const loadingFillStyle = props.loadingFillStyle || '#9c9c9c';
  const showProgressCaret = typeof props.showProgressCaret !== 'undefined' ? props.showProgressCaret : true;
  const rounding = props.rounding || 'none';
  const progress = props.progress || 0;

  useEffect(() => {
    if (!ref) return;

    typeof ref === 'function' ? ref(containerRef) : (ref.current = containerRef.current);
  }, [ref]);

  // Wave form rendering logic
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderCanvas = useCallback((): void => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d') as CanvasRenderingContext2D;

      const isLoading = props.peaks.length === 0;
      const loadingMaxPeak = 1;
      const loadingPeak = 0.4;

      fitToContainer(canvasRef.current);
      const { height, width } = canvasRef.current;
      ctx.clearRect(0, 0, width, height);

      // Bottom left coords
      ctx.translate(0, height);
      ctx.scale(1, -1);

      const peaks = resample(!isLoading ? props.peaks.map(Math.abs) : [loadingPeak], barWidth, spaceWidth, width);
      const maxPeak = !isLoading ? props.maxPeak || Math.max(...peaks.map(Math.abs)) : loadingMaxPeak;
      const ratio = height / maxPeak; // (height * 0.9) / maxPeak;
      const minHeight = 0.05 * height;

      // const gap = (width - (peaks.length * (barWidth + spaceWidth) - spaceWidth)) / 2;

      const progressX = (progress / 100) * width;
      let bias = 0;

      // Fill with a 2-colored theme
      const renderPeak = (peak: number) => {
        let barHeight = Math.round(peak * ratio);
        if (barHeight < minHeight) barHeight = minHeight;
        if (rounding === 'none') {
          ctx.rect(bias, 0, barWidth, barHeight);
        } else {
          const radius: Partial<RoundingStyle> = { bl: barWidth / 2, br: barWidth / 2 }; // bottom radius ends up on top
          if (rounding === 'both') {
            radius.tl = barWidth / 2;
            radius.tr = barWidth / 2;
          }
          roundRect(ctx, bias, 0, barWidth, barHeight, radius);
        }
        bias += barWidth + spaceWidth;
      };
      const playedItems = progressX / (barWidth + spaceWidth);
      if (rounding === 'none') ctx.beginPath();
      ctx.fillStyle = playedFillStyle;
      peaks.slice(0, playedItems + 1).forEach(renderPeak);
      if (rounding === 'none') ctx.closePath();
      ctx.fill();
      if (rounding === 'none') ctx.beginPath();
      ctx.fillStyle = !isLoading ? baseFillStyle : loadingFillStyle;
      peaks.slice(playedItems + 1).forEach(renderPeak);
      if (rounding === 'none') ctx.closePath();
      ctx.fill();

      // Progress current progress caret
      if (showProgressCaret) {
        ctx.beginPath();
        ctx.fillStyle = caretFillStyle;
        ctx.rect(progressX - 1, 0, 2, height);
        ctx.fill();
      }
    }
  }, [
    barWidth,
    baseFillStyle,
    caretFillStyle,
    playedFillStyle,
    loadingFillStyle,
    progress,
    props.peaks,
    props.maxPeak,
    rounding,
    showProgressCaret,
    spaceWidth,
  ]);

  // Render canvas diagram on mount and rerender on props update
  useEffect(() => {
    renderCanvas();

    // Register resize observer
    const observer = new ResizeObserver(renderCanvas);
    observer.observe(containerRef.current as HTMLDivElement);
    return () => observer.disconnect();
  }, [renderCanvas]);

  // On seek (rewind) handler
  const seekHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!props.onSeek) return;

      const offsetLeft = (e.target as HTMLDivElement).getBoundingClientRect().left;
      const clientWidth = (e.currentTarget as HTMLDivElement).clientWidth;
      const rewindTo = ((e.clientX - offsetLeft) / clientWidth) * 100;

      props.onSeek(rewindTo);
    },
    [props]
  );

  // Styling the component with inline style on purpose
  // to provide greater compatibility while copying the reusable component

  return (
    <div
      className={props.className}
      ref={containerRef}
      onClick={seekHandler}
      style={{
        cursor: props.onSeek ? 'pointer' : 'default', // adds pointer when onSeek is defined
        position: 'relative',
        // Removed fleshing highlight on tap (mobile devices)
        ...({ WebkitTapHighlightColor: 'transparent' } as CSSProperties),
      }}
    >
      <canvas ref={canvasRef} />
      {/* Overlay to prevent interaction with canvas as image */}
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
        }}
      />
    </div>
  );
});

export default WaveForm;
