import { forwardRef, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';

import { useDidUpdate } from 'hooks';
import { isEmpty } from 'utils';

import { ReactComponent as ApproveIcon } from 'assets/approve-2.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import styles from './TextField.module.scss';

export interface ITextFieldProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  highlightValid?: boolean;
  Icon?: JSX.Element;
  iconRender?: string | JSX.Element;
  iconPlaceWidth?: number;
  label?: string | JSX.Element;
  labelClassName?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  /**
   * Works with number type only
   */
  max?: number;
  maxLength?: number;
  /**
   * Works with number type only
   */
  min?: number;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (value: string | number | null) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  required?: boolean;
  type?: string;
  value?: string | number | null;
}

const convertToNumber = (value: string | undefined | null): number | null => {
  const v = value ? parseFloat(value) : null;
  return typeof v === 'number' && isNaN(v) ? null : v;
};

const converToString = (value: string | number | undefined | null): string => {
  return typeof value === 'number' || typeof value === 'string' ? value.toString() : '';
};

const TextField = forwardRef(
  (
    {
      className,
      disabled,
      error,
      highlightValid,
      Icon,
      label,
      labelClassName,
      labelPlacement = 'top',
      max,
      maxLength = 80,
      min,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      required,
      type,
      value,
      iconRender,
      iconPlaceWidth,
    }: ITextFieldProps,
    ref: React.LegacyRef<HTMLInputElement>
  ) => {
    const [focused, setFocused] = useState<boolean>();
    const [inputValue, setInputValue] = useState(() => converToString(value));
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const empty = isEmpty(value, true);
    const valid = highlightValid && !error && !empty;
    const hasIcon = Boolean(error || required || valid || Icon);

    const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      onBlur && onBlur(e);
    };
    const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      onFocus && onFocus(e);
    };
    const onInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      let inputV: string | number | null = target.value;
      let numericV: number | null = null;
      if (typeof maxLength === 'number' && inputV) inputV = inputV.substring(0, maxLength);
      if (type === 'number') {
        inputV = inputV ? inputV.toString().replace(/[^0-9.]/g, '') : '';
        numericV = convertToNumber(inputV);
        if (typeof numericV === 'number') {
          if (typeof min === 'number' && numericV < min) {
            numericV = min;
            inputV = numericV.toString();
          }
          if (typeof max === 'number' && numericV > max) {
            numericV = max;
            inputV = numericV.toString();
          }
        }
      }
      if (type === 'tel') {
        inputV = inputV
          ?.toString()
          .replace(/[^0-9]/g, '')
          .substring(0, 20);
      }
      setInputValue(inputV);
      onChange && onChange(type === 'number' ? numericV : inputV);
    };

    useDidUpdate(() => {
      if (type === 'number') {
        const numericInputValue = convertToNumber(inputValue);
        if (numericInputValue !== value) setInputValue(converToString(value));
      } else {
        setInputValue(value?.toString() || '');
      }
    }, [value]);

    return (
      <FormControlLabel
        classes={{
          label: classNames(styles.label, labelClassName),
          labelPlacementStart: styles.labelPlacementStart,
          labelPlacementTop: styles.labelPlacementTop,
        }}
        className={className}
        control={
          <div
            className={classNames(styles.control, {
              [styles.disabled]: disabled,
              [styles.errorBorder]: error,
              [styles.focused]: focused,
              [styles.validBorder]: valid,
            })}
          >
            <input
              className={classNames(styles.input, {
                [styles.inputWithIcon]: hasIcon,
                [styles.textFieldWithIcon]: iconRender,
              })}
              disabled={disabled}
              name={name}
              onBlur={onInputBlur}
              onChange={onInputChange}
              onFocus={onInputFocus}
              placeholder={placeholder}
              ref={ref}
              type={type}
              value={inputValue}
              style={{ paddingRight: iconPlaceWidth ? `${iconPlaceWidth}px` : undefined }}
            />
            {iconRender && <div className={classNames(styles.textFieldIcon, error && styles.error)}>{iconRender}</div>}
            {hasIcon && (
              <div className={styles.iconContainer}>
                {(error || required) && !valid && (
                  <Tooltip
                    arrow
                    classes={{ arrow: styles.tooltipArrow, popper: styles.tooltipPopper, tooltip: styles.tooltip }}
                    onClose={() => setTooltipOpen(false)}
                    onOpen={() => setTooltipOpen(true)}
                    open={tooltipOpen && Boolean(error)}
                    placement="top-end"
                    title={error || ''}
                  >
                    <div className={styles.tooltipIcon}>
                      {required ? (
                        <div className={classNames(styles.requiredIcon, { [styles.errorColor]: Boolean(error) })} />
                      ) : (
                        <WarningIcon className={classNames(styles.errorColor)} />
                      )}
                    </div>
                  </Tooltip>
                )}
                {valid && <ApproveIcon className={classNames(styles.validColor)} />}
                {Icon}
              </div>
            )}
          </div>
        }
        label={label}
        labelPlacement={labelPlacement}
      />
    );
  }
);

export default TextField;
