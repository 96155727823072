import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import { Checkbox } from 'components/UI';
import { DataGridColDef } from 'components/UI/DataGrid';

import { useClickOutside, useMemoSelector } from 'hooks';
import { getIsOpenShowHideColumns, setIsOpenShowHideColumns } from 'store';

import styles from './ShowHideColumns.module.scss';

interface IShowHideColumnsProps<T> {
  columns: DataGridColDef<T>[];
  setColumns: Dispatch<SetStateAction<DataGridColDef<T>[]>>;
  hideFromList?: string[];
}

const ShowHideColumns = <T,>({ columns, setColumns, hideFromList = [] }: IShowHideColumnsProps<T>) => {
  const dispatch = useDispatch();

  const isOpen = useMemoSelector(getIsOpenShowHideColumns);

  const popupContainer = useClickOutside((e) => {
    isOpen &&
      !(e.target as unknown as SVGSVGElement).className?.baseVal?.includes('moreHeaderIcon') &&
      dispatch(setIsOpenShowHideColumns(false));
  });

  const changeVisibilityColumn = (fieldName: string) => {
    setColumns(
      columns.map((col) => (col.field === fieldName && col.field !== 'filters' ? { ...col, hide: !col.hide } : col))
    );
  };

  return isOpen ? (
    <div ref={popupContainer} className={styles.columnConfigContainer}>
      <div className={styles.columnConfigTitle}>SHOW/HIDE COLUMNS</div>
      <div className={styles.columnConfigItems}>
        {columns.map(
          (col) =>
            col.headerName &&
            !hideFromList?.includes(col.headerName) && (
              <Checkbox
                key={col.headerName}
                label={col.headerName}
                checked={!col.hide}
                fromControlRootClassName={styles.formControl}
                onChange={() => changeVisibilityColumn(col.field)}
              />
            )
        )}
      </div>
    </div>
  ) : null;
};

export default ShowHideColumns;
