import styles from './MusicPlayer.module.scss';

import { markerData } from './data';
import { ActionTypes, IActionMarker, IPlayerPopupsProps, PlayerPopups } from './IMusicPlayer';

interface IMarkersPopupProps extends IPlayerPopupsProps {
  actionMarker: (props: IActionMarker) => void;
  changeOpenPopup: (type: PlayerPopups | '') => void;
}

const MarkersPopup = ({ isShow, popupContainerRef, actionMarker, changeOpenPopup }: IMarkersPopupProps) => {
  return isShow ? (
    <div className={styles.markerContainer} ref={popupContainerRef}>
      {markerData.map((item, index) => (
        <div
          className={styles.markerOption}
          key={index}
          onClick={() => actionMarker({ actionType: ActionTypes.add, value: item })}
        >
          {item}
        </div>
      ))}
      <div className={styles.exitBtn} onClick={() => changeOpenPopup('')}>
        Exit
      </div>
    </div>
  ) : null;
};

export default MarkersPopup;
