import './styles/index.scss';

import React, { lazy, Suspense, useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';

import CookiesConsent from 'components/OneTrust';

const Home = lazy(() => import(/* webpackChunkName: "HomeComponent" */ 'components/Home'));
const Registration = lazy(() => import(/* webpackChunkName: "HomeComponent" */ 'components/Registration'));
const PrivacyPolicyPage = lazy(
  () => import(/* webpackChunkName: "PrivacyPolicyPageComponent" */ 'components/PrivacyPolicyPage')
);
const TermsOfUsePage = lazy(
  () => import(/* webpackChunkName: "TermsOfUsePageComponent" */ 'components/TermsOfUsePage')
);
const SearchAlertTurnedOff = lazy(
  () => import(/* webpackChunkName: "SearchAlertTurnedOffComponent" */ 'components/SearchAlertTurnedOff')
);
const PitchExternalPage = lazy(
  () => import(/* webpackChunkName: "PitchExternalPageComponent" */ 'components/PitchExternalPage')
);
const ShareExternalPage = lazy(
  () => import(/* webpackChunkName: "ShareExternalPageComponent" */ 'components/ShareExternalPage')
);
const Layout = lazy(() => import(/* webpackChunkName: "LayoutComponent" */ 'containers/Layout'));
const Sidebar = lazy(() => import(/* webpackChunkName: "SidebarComponent" */ 'containers/Sidebar'));
const TopMenu = lazy(() => import(/* webpackChunkName: "TopMenuComponent" */ 'containers/TopMenu'));

import AdobeLaunch from 'components/AdobeLaunch';
import LoginCallbackCustom from 'components/LoginCallbackCustom';
import {
  DownloadPopup,
  ErrorPopup,
  HoldRequestActionPopup,
  HoldRequestFormPopup,
  HoldRequestRejectPopup,
  ShareSongPopup,
} from 'components/Popups';

import oktaAuth from 'services/Okta';
import { Paths } from 'utils';

const App = () => {
  const history = useHistory();

  const restoreOriginalUri = useCallback(
    async (_, url: string) => history.replace(url.replace(window.location.origin, '')),
    [history]
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Suspense fallback={<div />}>
        <div className="app">
          <div className="app-container">
            <Switch>
              <Route exact path={[Paths.empty, Paths.home]} component={Home} />
              <Route path={Paths.registration} component={Registration} />
              <Route path={Paths.privacyPolicy} component={PrivacyPolicyPage} />
              <Route path={Paths.termsOfUse} component={TermsOfUsePage} />
              <Route path={Paths.turnedOff} component={SearchAlertTurnedOff} />
              <Route path={Paths.publicPitchesId} component={PitchExternalPage} />
              <Route path={Paths.publicSongsId} component={ShareExternalPage} />
              <Route path={Paths.publicAlertId} component={ShareExternalPage} />
              <Route path={Paths.oktaLogin} component={LoginCallbackCustom} />
              <Layout />
            </Switch>
          </div>
          <Sidebar />
          <TopMenu />
          <ErrorPopup />
          <DownloadPopup />
          <ShareSongPopup />
          <HoldRequestFormPopup />
          <HoldRequestActionPopup />
          <HoldRequestRejectPopup />
          <CookiesConsent />
          <AdobeLaunch />
        </div>
      </Suspense>
    </Security>
  );
};

export default App;
