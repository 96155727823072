import { useMemo, useState } from 'react';

import { NotificationPopup } from 'components/Popups';
import { Button, PageHeader, Tab, Tabs } from 'components/Reusable';
import MobileWrapper from 'containers/MobileWrapper';

import { useMemoSelector } from 'hooks';
import { getUserId, getUserPermissions } from 'store';

import { ReactComponent as PlusCircleIcon } from 'assets/plus-circle.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Contacts.module.scss';

import {
  AddToGroupForm,
  ContactsForm,
  ContactsGrid,
  GroupForm,
  GroupGrid,
  IAddToGroupFormItem,
  IContact,
  IContactGroup,
} from '.';

const Contacts = (): JSX.Element => {
  const { userId, permissions } = useMemoSelector((state) => ({
    userId: getUserId(state),
    permissions: getUserPermissions(state),
  }));

  const [addToGroupFormItem, setAddToGroupFormItem] = useState<IAddToGroupFormItem | undefined>();
  const [contactsFormItem, setContactsFormItem] = useState<IContact | undefined>();
  const [groupForm, setGroupForm] = useState<
    { hideContacts?: boolean; item: IContactGroup; pendingSave?: boolean } | undefined
  >();
  const [notification, setNotification] = useState<string | undefined>();
  const [reloadHash, setReloadHash] = useState<string | undefined>();

  const onAddContactsToGroupFormSubmit = (groupWasCreated: boolean) => {
    setAddToGroupFormItem(undefined);
    setNotification(groupWasCreated ? 'Group Created and Contacts Added' : 'Contacts Added');
    setReloadHash(new Date().toISOString());
  };

  const onAddToGroupClick = (items: IContact[]) =>
    setAddToGroupFormItem({ contacts: items.map((i) => ({ id: i.id || '', title: i.name || '' })) });

  const onContactsFormSubmit = (values: IContact) => {
    setContactsFormItem(undefined);
    setNotification(contactsFormItem?.id ? 'Contact Updated' : 'Contact Created');
    groupForm &&
      setGroupForm((prev) => {
        const contacts = prev?.item.contacts?.slice() || [];
        values?.id && contacts.push({ id: values.id, title: values.name || '' });
        return prev ? { ...prev, item: { ...prev.item, contacts } } : prev;
      });
    setReloadHash(new Date().toISOString());
  };

  const onGroupFormSubmit = (values: IContactGroup) => {
    setGroupForm(undefined);
    if (addToGroupFormItem) {
      setAddToGroupFormItem((prev) =>
        prev ? { ...prev, group: { id: values.id || '', title: values.title || '' } } : prev
      );
    } else {
      setReloadHash(new Date().toISOString());
    }
  };

  const createMenuItems = useMemo(
    () => [
      { onClick: () => setContactsFormItem({}), text: 'Create Contact' },
      { onClick: () => setGroupForm({ item: {} }), text: 'Create Group' },
    ],
    []
  );

  return (
    <MobileWrapper>
      <div className={globalStyles.wrapper}>
        <div className={styles.contactsHeader}>
          <PageHeader className={styles.pageHeader} smallMargin />
          {permissions.contactActions && <Button Icon={<PlusCircleIcon />} menuItems={createMenuItems} text="Create" />}
        </div>
        <Tabs className={styles.tabs} contentClassName={styles.tabContent}>
          <Tab label="My Contacts" value="my">
            <ContactsGrid
              onAddToGroupClick={onAddToGroupClick}
              onEditContactClick={setContactsFormItem}
              reloadHash={reloadHash}
              userId={userId}
            />
          </Tab>
          <Tab label="Team Contacts" value="team">
            <ContactsGrid
              onAddToGroupClick={onAddToGroupClick}
              onEditContactClick={setContactsFormItem}
              reloadHash={reloadHash}
            />
          </Tab>
          <Tab label="Team Groups" value="team-groups">
            <GroupGrid reloadHash={reloadHash} />
          </Tab>
        </Tabs>
        <NotificationPopup
          isOpen={Boolean(notification)}
          setIsOpen={() => setNotification(undefined)}
          text={notification || ''}
        />
        {contactsFormItem && (
          <ContactsForm
            item={contactsFormItem}
            onClose={() => setContactsFormItem(undefined)}
            onSubmit={onContactsFormSubmit}
          />
        )}
        {groupForm && (
          <GroupForm
            hideContacts={groupForm.hideContacts}
            item={groupForm.item}
            onClose={() => setGroupForm(undefined)}
            onNewContactClick={() => setContactsFormItem({})}
            onSubmit={onGroupFormSubmit}
            pendingSave={groupForm.pendingSave}
          />
        )}
        {addToGroupFormItem && (
          <AddToGroupForm
            item={addToGroupFormItem}
            onClose={() => setAddToGroupFormItem(undefined)}
            onNewGroupClick={() => setGroupForm({ hideContacts: true, item: {}, pendingSave: true })}
            onSubmit={onAddContactsToGroupFormSubmit}
          />
        )}
      </div>
    </MobileWrapper>
  );
};

export default Contacts;
