import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { ISongData } from 'components/Songs/data';
import PlayIconCell from 'components/Songs/PlayIconCell';
import PlayIconHeaderCell from 'components/Songs/PlayIconHeaderCell';
import songsStyles from 'components/Songs/Songs.module.scss';
import SongTitleCell from 'components/Songs/SongTitleCell';
import WritersCell from 'components/Songs/WritersCell';
import { StatusCard } from 'components/UI';
import { DataGridColDef } from 'components/UI/DataGrid';

import globalStyles from 'styles/modules/Global.module.scss';

import ActionsCell from './ActionsCell';
import { HoldRequestTypes, HoldStatuses, IHoldRequestData } from 'types';

const holdStatuses = {
  [HoldStatuses.AWAITING_APPROVAL]: 'Pending',
  [HoldStatuses.CANCELED]: 'Cancelled',
  [HoldStatuses.REJECTED]: 'Denied',
  [HoldStatuses.APPROVED]: 'Approved',
};

enum ColumnFields {
  empty = 'empty',
  songTitle = 'title',
  writers = 'writers',
  artist = 'artist',
  requesterName = 'requesterName',
  label = 'label',
  // teamName = 'teamName',
  status = 'status',
  actions = 'actions',
}

enum ColumnHeaderNames {
  empty = 'empty',
  songTitle = 'Song Title',
  writers = 'Writer(s)',
  artist = 'Artist',
  requesterName = 'Requester',
  label = 'Label',
  // teamName = 'Team',
  status = 'Status',
  actions = 'Actions',
}

export const getColumns: (type: HoldRequestTypes) => DataGridColDef<IHoldRequestData>[] = (type) => [
  {
    field: ColumnFields.empty,
    headerName: ColumnHeaderNames.empty,
    headerClass: songsStyles.playIconHeaderCell,
    headerComponentFramework: ({ api }: { api: GridApi }) => <PlayIconHeaderCell api={api} />,
    maxWidth: 50,
    cellClass: songsStyles.cellContainer,
    cellRendererFramework: ({ data, api }) => <PlayIconCell data={data as unknown as ISongData} api={api} />,
    suppressColumnsToolPanel: true,
  },
  {
    field: ColumnFields.songTitle,
    headerName: ColumnHeaderNames.songTitle,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: songsStyles.cellContainer,
    minWidth: 250,
    cellRendererFramework: ({ data, api }) => (
      <SongTitleCell hideFavoriteIcon title={data?.title} mediaId={data?.mediaId} api={api} />
    ),
    suppressColumnsToolPanel: true,
  },
  {
    field: ColumnFields.writers,
    headerName: ColumnHeaderNames.writers,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: songsStyles.cellContainer,
    cellRendererFramework: ({ data }) => <WritersCell writers={data?.writers} />,
    minWidth: 180,
  },
  {
    field: ColumnFields.artist,
    headerName: ColumnHeaderNames.artist,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: songsStyles.cellContainer,
  },
  {
    field: ColumnFields.requesterName,
    headerName: ColumnHeaderNames.requesterName,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: songsStyles.cellContainer,
    minWidth: 130,
  },
  {
    field: ColumnFields.label,
    headerName: ColumnHeaderNames.label,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: songsStyles.cellContainer,
  },
  // {
  //   field: ColumnFields.teamName,
  //   headerName: ColumnHeaderNames.teamName,
  //   headerClass: globalStyles.f13h16SuisseSB_grayDarker,
  //   cellClass: songsStyles.cellContainer,
  // },
  {
    field: ColumnFields.status,
    headerName: ColumnHeaderNames.status,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: songsStyles.cellContainer,
    cellRendererFramework: ({ data }) => (data?.status ? <StatusCard value={holdStatuses[data.status]} /> : ''),
  },
  {
    field: ColumnFields.actions,
    headerName: ColumnHeaderNames.actions,
    pinned: 'right',
    suppressColumnsToolPanel: true,
    cellClass: classNames('actionsCell', songsStyles.cellContainer),
    cellRendererFramework: ({ data, node, api }) => (
      <ActionsCell holdId={data?.id || ''} status={data?.status} type={type} node={node} api={api} />
    ),
  },
  {
    field: 'filters',
    hide: true,
    suppressColumnsToolPanel: true,
  },
];
