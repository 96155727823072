import { Dispatch, SetStateAction } from 'react';
import { Chip } from '@material-ui/core';
import classNames from 'classnames';

import { IContactAutocompleteOption } from 'components/Popups/ManualPitchForm';
import { IShareToContacts } from 'components/Popups/ShareSongPopup/data';
import { InputAsyncAutocomplete } from 'components/UI';
import { IInputAutocompleteOption } from 'components/UI/InputAsyncAutocomplete';

import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './ContactsAsyncAutocomplete.module.scss';

export interface IContactsAsyncAutocomplete {
  label: string;
  shareToItems: IShareToContacts[];
  setShareToItems: Dispatch<SetStateAction<IShareToContacts[]>>;
  placeHolder?: string;
  required?: boolean;
  onResolveSuggestions: (text: string) => Promise<IContactAutocompleteOption[]>;
}

const ContactsAsyncAutocomplete = ({
  label,
  shareToItems,
  setShareToItems,
  placeHolder,
  required,
  onResolveSuggestions,
}: IContactsAsyncAutocomplete) => (
  <InputAsyncAutocomplete
    multiple
    labelRootClassName={styles.labelRoot}
    labelClassName={styles.label}
    disableClearable
    label={
      <>
        {label}
        {required && <span className={styles.required}>*</span>}
      </>
    }
    placeholder={placeHolder}
    value={shareToItems.length ? (shareToItems as unknown as IInputAutocompleteOption[]) : null}
    onChange={(_, value) => setShareToItems((value as unknown as IShareToContacts[]) || [])}
    onResolveSuggestions={onResolveSuggestions}
    renderOption={(option: IInputAutocompleteOption, state) => {
      const optionsCasted = option as unknown as IShareToContacts;
      const splittedName = optionsCasted.name?.split(' ');
      const initials = splittedName?.reduce((total, item) => total + item[0], '') || '';

      const isTeamOption = typeof optionsCasted.team !== 'string' && optionsCasted.team;

      return (
        <div
          className={classNames(styles.optionContainer, {
            [styles.selectedOption]: state.selected,
          })}
        >
          <div
            className={classNames(styles.optionInitials, globalStyles.f11h13SuisseSB_grayDarker, {
              [styles.optionTeamInitials]: isTeamOption,
            })}
          >
            {initials}
          </div>
          <div>
            <span className={globalStyles.f13h16SuisseREG_grayDarker}>{optionsCasted.name}</span>
            <span className={globalStyles.f11h13SuisseREG_grayDarker}>
              ({isTeamOption ? 'Entire Team' : optionsCasted.teamName})
            </span>
          </div>
        </div>
      );
    }}
    renderTags={(value, getTagProps) => {
      return (value as unknown as IShareToContacts[]).map((item, index) => {
        const isTeamOption = typeof item.team !== 'string' && item.team;

        return (
          <Chip
            classes={{
              root: classNames(styles.chipRoot, {
                [styles.chipGray]: !isTeamOption,
              }),
            }}
            deleteIcon={<DeleteIcon />}
            {...getTagProps({ index })}
            key={item?.id}
            label={`${item.name} (${isTeamOption ? 'Entire Team' : item.teamName})`}
          />
        );
      });
    }}
  />
);

export default ContactsAsyncAutocomplete;
