import classNames from 'classnames';

import { Tooltip } from 'components/UI';

import globalStyles from 'styles/modules/Global.module.scss';

const PitchedByCell = ({ pitchedBy }: { pitchedBy?: string }): JSX.Element => {
  return (
    <Tooltip text={pitchedBy || ''}>
      <span className={classNames(globalStyles.textEllipsis, globalStyles.f13h16SuisseREG_grayDarker)}>
        {pitchedBy || '-'}
      </span>
    </Tooltip>
  );
};

export default PitchedByCell;
