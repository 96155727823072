import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { DoubleBtnGroup } from 'components/Reusable';

import styles from './DesktopSearch.module.scss';

import { SearchDataKeys, SearchDataType } from './data';

interface ISearchHeaderProps {
  register: UseFormRegister<SearchDataType>;
  setValue: UseFormSetValue<SearchDataType>;
  maxLength?: number;
}

const SearchHeader = ({ register, setValue, maxLength = 80 }: ISearchHeaderProps): JSX.Element => {
  return (
    <div className={styles.searchHeader}>
      <div className={styles.searchInputBorder}>
        <input
          className={styles.searchQuery}
          type="text"
          autoFocus
          placeholder="Search for title or writer"
          autoComplete="off"
          maxLength={maxLength}
          {...register(SearchDataKeys.searchQuery)}
          onBlur={(e) => !e.target.value?.trim() && setValue(SearchDataKeys.searchQuery, '')}
        />
      </div>
      <DoubleBtnGroup
        name1="Reset"
        name2="Search"
        type1="reset"
        type2="submit"
        spacing={13}
        className={styles.searchDoubleBtn}
      />
    </div>
  );
};

export default SearchHeader;
