import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { IFilter, IFilterParams } from 'ag-grid-community/dist/lib/interfaces/iFilter';

/**
 * CustomFilter implements an AdHoc JSON filter which can be used as an example for developing any sort of filters,
 * it also can be handy for a headless (externally provided) filters
 */

// eslint-disable-next-line react/display-name
export default forwardRef((props: IFilterParams, ref) => {
  const [filterText, setFilterText] = useState('');

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return new (class implements IFilter {
      doesFilterPass() {
        return true; // Only for use in server-side filters
      }

      isFilterActive() {
        return filterText !== null && filterText !== '' && filterText !== '{}';
      }

      getModel() {
        if (!this.isFilterActive()) return undefined; // empty model for not active filter

        let jsonFilterModel = undefined;
        try {
          jsonFilterModel = JSON.parse(filterText);
        } catch (ex) {
          /**/
        }
        return jsonFilterModel;
      }

      setModel(model?: unknown) {
        let jsonFilterText = '';
        try {
          jsonFilterText = JSON.stringify(model, null, 2);
        } catch (ex) {
          /**/
        }
        setFilterText(jsonFilterText);
      }
    })();
  });

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setFilterText(event.target.value);
  };

  useEffect(() => {
    props.filterChangedCallback();
  }, [filterText, props]);

  return (
    <div style={{ padding: 10, width: 200 }}>
      <div style={{ fontWeight: 'bold', marginBottom: 5 }}>Custom filter:</div>
      <textarea
        style={{ margin: '4 0 4 0', width: '100%', minHeight: 80, border: '1px solid #ccc' }}
        value={filterText}
        onChange={onChange}
        placeholder="Valid JSON object as text"
      />
    </div>
  );
});
