import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TextEditor } from 'components/UI';

import Api from 'services/Api';
import { DEFAULT_ERROR_CONFIG, isEmptyHtmlString } from 'utils';

import styles from './SongDetails.module.scss';

import { IAddPopupProps } from 'types';

const AddNotes = ({ setBtnDisabled }: IAddPopupProps, ref: Ref<unknown> | undefined) => {
  const { id } = useParams<{ id: string }>();

  const [note, setNote] = useState<string>('');

  useEffect(() => {
    setBtnDisabled(isEmptyHtmlString(note));
  }, [note, setBtnDisabled]);

  const onHandleSubmit = async () => {
    try {
      await Api.addSongNote(id, note, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to add note:', error);
    }
  };

  useImperativeHandle(ref, () => ({ onHandleSubmit }));

  return (
    <div className={styles.addNotes}>
      <div className={styles.addNotesTitle}>Message</div>
      <TextEditor value={note} onChange={(content) => setNote(content)} />
    </div>
  );
};

export default forwardRef(AddNotes);
