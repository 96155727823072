import { useEffect } from 'react';
import lottie from 'lottie-web';

import { useMemoSelector } from 'hooks';
import { getLoading } from 'store';

import loadingDataAnimation from 'assets/animations/loading-data.json';
import styles from './Loading.module.scss';

const Loading = () => {
  const isLoading = useMemoSelector(getLoading);

  useEffect(() => {
    if (!isLoading) return;

    const container = document.querySelector('#loading-data-animation');

    container &&
      lottie.loadAnimation({
        animationData: loadingDataAnimation,
        container,
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
          viewBoxOnly: true,
        },
      });
  }, [isLoading]);

  return isLoading ? (
    <div id="loading-data-animation" className={styles.loadingDataAnimation}>
      <div />
    </div>
  ) : null;
};

export default Loading;
