import classNames from 'classnames';

import { getFormattedDate, Paths } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './PitchDetails.module.scss';

import { IPitchContact } from 'types';

interface IPitchEmailProps {
  expireDate: string;
  publicCode: string;
  message: string;
  contacts: IPitchContact[];
  title: string;
}

const PitchEmail = ({ publicCode, expireDate, message, contacts, title }: IPitchEmailProps): JSX.Element => {
  return (
    <div className={styles.pitchEmail}>
      <span className={classNames(globalStyles.f13h16SuisseSB_grayDarker, globalStyles.textEllipsis)}>{title}</span>
      {contacts.length ? (
        <span className={globalStyles.f11h13SuisseREG_grayDark}>
          to {contacts[0]?.contact} ({contacts[0]?.email})
        </span>
      ) : null}
      <span className={globalStyles.f11h13SuisseREG_grayDark}>Expiry Date: {getFormattedDate(expireDate || '')}</span>
      <span
        className={classNames(globalStyles.f13h16SuisseREG_grayDarker, styles.requestedTitle)}
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <span className={classNames(globalStyles.f13h16SuisseREG_grayDarker, styles.linkInfo)}>
        Also check this out:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={`${Paths.publicPitches}/${publicCode}`}
        >{`${window.location.origin}${Paths.publicPitches}/${publicCode}`}</a>
      </span>
      <span className={globalStyles.f13h16SuisseREG_grayDarker}>Thanks!</span>
    </div>
  );
};

export default PitchEmail;
