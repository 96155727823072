import { useCallback, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import classNames from 'classnames';

import MusicPlayer, { TrackInfo } from 'components/MusicPlayer';

import { useDebounce, useMemoSelector } from 'hooks';
import Api from 'services/Api';
import {
  dispatch,
  getActiveTrackIndex,
  getActiveTrackMediaId,
  getCurrentTracks,
  getPlaylistHash,
  setLoading,
} from 'store';
import { convertBackendMarkersToState } from 'utils';

import styles from './PlayBar.module.scss';

import { ICurrentTrack, ISong, ISongMedias, SongStatusTypes } from 'types';

interface IPlaybarProps {
  isDark?: boolean;
  isExternal?: boolean;
  addPlayActivity?: () => void;
}
interface IDebouncedGetSongDetailsProps {
  pathId?: string;
  mediaId?: string;
  songCode?: string;
  closeLoading?: boolean;
  mediaPreSignedUrl?: string;
  presignForPlayback?: string;
}

const PlayBar = ({ isDark, isExternal, addPlayActivity }: IPlaybarProps): JSX.Element => {
  const { currentTracks, activeTrackMediaId, activeTrackIndex, playlistHash } = useMemoSelector((state) => ({
    currentTracks: getCurrentTracks(state),
    activeTrackMediaId: getActiveTrackMediaId(state),
    activeTrackIndex: getActiveTrackIndex(state),
    playlistHash: getPlaylistHash(state),
  }));

  const {
    authState: { isAuthenticated },
  } = useOktaAuth();

  const [tracks, setTracks] = useState<TrackInfo[]>([]);
  const [prevPlaylistHash, setPrevPlaylistHash] = useState(playlistHash);
  const [currentLoadedSong, setCurrentLoadedSong] = useState<TrackInfo | null>(null);
  const [openMobileMarkersView, setOpenMobileMarkersView] = useState<boolean>(false);

  const debouncedGetSongDetails = useDebounce<IDebouncedGetSongDetailsProps>(
    async ({
      songCode,
      mediaPreSignedUrl,
      presignForPlayback,
      mediaId,
      pathId,
      closeLoading,
    }: IDebouncedGetSongDetailsProps) => {
      const song: ISong | undefined = songCode
        ? await Api.playPublicPitch(songCode)
        : pathId
        ? await Api.getSongDetails({ pathId })
        : undefined;

      if (!song) {
        const media = mediaId ? currentTracks.find((t) => t.mediaId === mediaId) : undefined;
        media &&
          setCurrentLoadedSong({
            id: media.id || '',
            title: media.title,
            path: media.path,
            playbackPath: media.playbackPath,
            peeks: media.waveForm || [],
            writers: media.writers,
            markers: convertBackendMarkersToState(media.markers, media.duration),
            version: media.version || '',
            status: media.status || SongStatusTypes.ACTIVE,
            duration: Number(media.duration) || 0,
          });

        closeLoading && dispatch(setLoading(false));
        return;
      }

      const media = (songCode ? song : song.medias.find((el) => el.id === mediaId)) as ISongMedias;

      if (!media) {
        closeLoading && dispatch(setLoading(false));
        return;
      }

      if (
        !!isAuthenticated &&
        activeTrackMediaId !== media.id &&
        ![SongStatusTypes.ARCHIVED, ''].includes(song.status as SongStatusTypes)
      ) {
        Api.addRecentPlayedMedia(media.id);
      }

      setCurrentLoadedSong({
        id: media.id,
        songId: songCode ? media.workId : song.id,
        title: song.title || '',
        path: media.path || media.fileName || '',
        playbackPath: media.playbackPath || '',
        peeks: JSON.parse(media.waveform || media.waveForm || '[]'),
        writers: song.writers?.map((item) => item.name || '') || media.creators,
        markers: convertBackendMarkersToState(media.markers || [], media.duration),
        version:
          media.version && typeof media.version === 'object' ? media.version.id?.toString() : media.version || '',
        status: song.status || media.status || SongStatusTypes.ACTIVE,
        type: song.type,
        duration: Number(media.duration) || 0,
        labelView: song?.labelView,
        teamId: song?.teamId,
        featured: song?.featured,
        preSignedUrl: mediaPreSignedUrl || '',
        presignForPlayback: presignForPlayback || '',
      });

      closeLoading && dispatch(setLoading(false));
    },
    200
  );

  const updateTracks = useCallback(
    async (newTrack: ICurrentTrack) => {
      setTracks([
        {
          ...newTrack,
          peeks: newTrack.waveForm || [],
          markers: convertBackendMarkersToState(newTrack.markers || [], newTrack.duration),
          songId: newTrack.id,
          id: newTrack.mediaId,
          version: newTrack.version || '',
          status: newTrack.status || '',
          duration: Number(newTrack.duration) || 0,
        },
      ]);

      debouncedGetSongDetails({
        pathId: newTrack.id,
        mediaId: newTrack.mediaId,
        songCode: newTrack.songCode,
        mediaPreSignedUrl: newTrack.preSignedUrl,
        presignForPlayback: newTrack.presignForPlayback,
      });
    },
    [debouncedGetSongDetails]
  );

  useEffect(() => {
    if (playlistHash === prevPlaylistHash || !currentLoadedSong?.songId || !currentLoadedSong?.id) return;

    setPrevPlaylistHash(playlistHash);
    debouncedGetSongDetails({ pathId: currentLoadedSong.songId, mediaId: currentLoadedSong.id, closeLoading: true });
  }, [playlistHash, debouncedGetSongDetails, currentLoadedSong, prevPlaylistHash]);

  useEffect(() => {
    if (currentLoadedSong?.id === activeTrackMediaId) {
      setTracks([currentLoadedSong]);
    }
  }, [currentLoadedSong, activeTrackMediaId]);

  useEffect(() => {
    if (!currentTracks.length) return;

    const newTrack =
      currentTracks[activeTrackIndex >= 0 && activeTrackIndex < currentTracks.length ? activeTrackIndex : 0];

    if (newTrack.mediaId === tracks[0]?.id) {
      return;
    }

    updateTracks(newTrack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTracks]);

  return (
    <div
      className={classNames(styles.playBar, {
        [styles.darkPlaybar]: isDark,
        [styles.playbarOpenedMobileMarkers]: openMobileMarkersView,
      })}
    >
      <MusicPlayer
        isExternal={isExternal}
        isDark={isDark}
        tracks={tracks}
        setTracks={setTracks}
        updateTracks={updateTracks}
        addPlayActivity={addPlayActivity}
        openMobileMarkersView={openMobileMarkersView}
        setOpenMobileMarkersView={setOpenMobileMarkersView}
      />
    </div>
  );
};

export default PlayBar;
