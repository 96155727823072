import React, { memo, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useForm } from 'react-hook-form';
import { batch, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Dialog, Fade } from '@material-ui/core';

import { Slide } from 'components/UI';

import { useDebounce, useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getIsOpenSearch, getSearchData, getSearchItemsNumState, setSearchData, toggleIsOpenSearch } from 'store';
import { Paths } from 'utils';

import styles from './DesktopSearch.module.scss';

import { CloseBtn, SearchAutocompletesList, SearchHeader, SwitchButtonsList, TitlesWritersList } from './';
import {
  AudioInclusionTypes,
  defaultSearchData,
  IBackendQuickSearchData,
  SearchDataKeys,
  SearchDataType,
  SongsSearchTypes,
} from './data';

const DesktopSearch = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isOpenSearch, searchData, searchItemsNum } = useMemoSelector((state) => ({
    isOpenSearch: getIsOpenSearch(state),
    searchData: getSearchData(state),
    searchItemsNum: getSearchItemsNumState(state),
  }));

  const { control, handleSubmit, register, reset, watch, setValue } = useForm<SearchDataType>({
    defaultValues: defaultSearchData,
  });
  const allFields = watch();

  const [prevFields, setPrevFields] = useState<SearchDataType | null>(allFields);
  const [searchResult, setSearchResult] = useState<IBackendQuickSearchData | null>(null);

  const isReset = useRef<boolean>(false);

  const getQuickSearch = useDebounce(async () => {
    // Note: keep in mind this
    setSearchResult(await Api.getQuickSearchResults({ ...defaultSearchData, ...allFields }));
  }, 700);

  const onSubmit = (data: SearchDataType) => {
    location.pathname !== Paths.search && history.push(Paths.search);

    batch(() => {
      dispatch(setSearchData(data));
      dispatch(toggleIsOpenSearch());
    });
  };

  const onCancel = (): void => {
    reset(searchData, { keepValues: true });
    dispatch(toggleIsOpenSearch());
  };

  const onReset = () => {
    isReset.current = true;
    reset(defaultSearchData);
    setSearchResult(null);
  };

  useEffect(() => {
    if (!isOpenSearch) return;

    reset(searchData);
  }, [isOpenSearch, reset, searchData]);

  useEffect(() => {
    if (!isOpenSearch) return;

    if (isReset.current) {
      isReset.current = false;
      return;
    }
    if (isEqual(allFields, defaultSearchData)) {
      setSearchResult(null);
      setPrevFields(null);
      return;
    }
    if (isEqual(prevFields, allFields)) {
      return;
    }

    setPrevFields(allFields);
    getQuickSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFields, getQuickSearch, prevFields]);

  return (
    <Dialog
      className={styles.dialog}
      PaperProps={{ className: styles.dialogPaper }}
      BackdropProps={{ className: styles.dialogBackdrop }}
      fullScreen
      open={isOpenSearch}
      onClose={onCancel}
      TransitionComponent={Slide}
    >
      <Fade in={isOpenSearch} timeout={{ enter: 600, exit: 80 }}>
        <form className={styles.search} onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
          <CloseBtn onClick={onCancel} />
          <SearchHeader register={register} setValue={setValue} />
          <div className={styles.filtersContainer}>
            <SearchAutocompletesList
              control={control}
              setValue={setValue}
              disabledBpm={allFields.audioInclusionType === AudioInclusionTypes.withoutAudio}
              type="search"
            />
            <SwitchButtonsList control={control} switchBtnRootClassName={styles.switchBtnRoot} />
            <TitlesWritersList
              className={styles.songTitlesContainer}
              label="Song Titles"
              btnText="View All Song Title Results"
              data={searchResult?.songTitles?.slice(0, searchItemsNum) || []}
              dataKey="songTitle"
              onClick={(e: React.MouseEvent) => {
                setValue(SearchDataKeys.searchType, SongsSearchTypes.viewAllSongs);
                handleSubmit(onSubmit)(e);
              }}
            />
            <TitlesWritersList
              className={styles.writersContainer}
              label="Writers"
              btnText="View All Writer Results"
              data={searchResult?.songWriter?.slice(0, searchItemsNum) || []}
              onClick={(e: React.MouseEvent) => {
                setValue(SearchDataKeys.searchType, SongsSearchTypes.viewAllSongsWriters);
                handleSubmit(onSubmit)(e);
              }}
            />
          </div>
        </form>
      </Fade>
    </Dialog>
  );
};

export default memo(DesktopSearch);
