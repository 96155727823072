// typeof window !== 'undefined' && window['OptanonWrapper'] = optanon('https://www.wminewmedia.com/cookies-policy');
export const optanon = (policyUrl: string) => {
  if (!policyUrl) {
    return () => {
      /**/
    };
  }
  return () => {
    const allLinks: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
      `a[href*="${policyUrl}"]:not([href*="?ot="])`
    );
    const otId = document.getElementById('onetrustcdn')?.getAttribute('data-domain-script');
    allLinks.forEach((link) => {
      const href = `${link.href}?ot=${otId}&url=${window.location.hostname}`;
      link.setAttribute('href', href);
      link.setAttribute('target', '_blank');
    });
    const eOT = new Event('OneTrustGroupsUpdated');
    document.dispatchEvent(eOT);
  };
};
