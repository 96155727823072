import { LoginCallback, useOktaAuth } from '@okta/okta-react';

import RequestAccessPage from 'components/RequestAccessPage';

const LoginCallbackCustom = () => {
  const { authState } = useOktaAuth();

  return (
    <>
      {/* Note: accessToken is undefined when directly going to /callback route */}
      {authState.accessToken === undefined && <RequestAccessPage />}
      <LoginCallback errorComponent={RequestAccessPage} />
    </>
  );
};

export default LoginCallbackCustom;
