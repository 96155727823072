import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ColumnState, GridApi, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import classNames from 'classnames';

import { SongsModuleFiltersPopup } from 'components/Popups';
import { NoDataAvailable, TableFilters } from 'components/Reusable';
import DataGrid, {
  CellRendererProps,
  DataGridColDef,
  DefaultColDefConfigs,
  FilteringProps,
  GetRowsHandler,
} from 'components/UI/DataGrid';
import { autoSizeActionsColumn } from 'components/UI/DataGrid/utils';

import { useDebounce, useMemoSelector, useMount, useUnMount, useWindowSize } from 'hooks';
import Api, { getQuickSearchBody } from 'services/Api';
import {
  getIsOpenSidebar,
  getRecentEditedSongs,
  getSearchData,
  getSelectedRowIndex,
  getSongsModuleFiltersData,
  getSongsModuleIsOpenFiltersPopup,
  getSongsModuleMyFiltersData,
  getSongsModuleSongsCount,
  getUserPermissions,
  getUserTerritory,
  setRecentEditedSongs,
  setSelectedRowIndex,
  setSongsModule,
} from 'store';
import { UsageKeys } from 'store/reducers/general/types';
import {
  findRecentEditedSongById,
  getDataGridItems,
  getStartEndDateFromString,
  IColumnConfig,
  Paths,
  SCREEN_BREAKPOINTS,
  TABLE_PAGE_SIZE,
  UserPreferenceTypes,
} from 'utils';
import { addToCache, getCache, invalidateCache } from 'utils/cache';

import styles from './Songs.module.scss';

import { ExpandedRow, SelectedPanel } from './';
import {
  ColumnFields,
  ColumnHeaderNames,
  defaultSongsModuleFilterData,
  getFilteredColumnsData,
  hiddenSongsColumnsDesktopSmall,
  hiddenSongsColumnsMobile,
  hiddenSongsColumnsTablet,
  ISongData,
  ISongsModuleNestedData,
  SongsModuleFilterKeys,
  SongsModuleFilterTitles,
  SongsTableFilterTypes,
} from './data';
import { IWriterItem, SongStatusTypes } from 'types';

interface ISongsTableProps {
  height?: number | string;
  isSongsPage?: boolean;
  reloadHash?: string;
  setReloadHash?: Dispatch<SetStateAction<string | undefined>>;
  ignoreLoadingOnFiltersChange?: boolean;
  title?: string;
}

const SongsTable = ({
  height,
  isSongsPage,
  reloadHash,
  setReloadHash,
  ignoreLoadingOnFiltersChange,
  title,
}: ISongsTableProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowSize();
  const { desktopBig, desktopSmall, tablet } = SCREEN_BREAKPOINTS;
  const songsCacheKey = history.location.pathname;
  const sortCacheKey = `${history.location.pathname} [sort]`;

  const clearAllCache = () => {
    invalidateCache(sortCacheKey);
    invalidateCache(songsCacheKey);
  };

  const {
    selectedRowIndex,
    userTerritory,
    isOpenSidebar,
    filtersData,
    myFilter,
    isOpenFiltersPopup,
    searchData,
    recentEditedSongs,
    permissions,
    songsCount,
  } = useMemoSelector((state) => ({
    selectedRowIndex: getSelectedRowIndex(state),
    userTerritory: getUserTerritory(state),
    filtersData: getSongsModuleFiltersData(state),
    searchData: getSearchData(state),
    myFilter: getSongsModuleMyFiltersData(state),
    isOpenSidebar: getIsOpenSidebar(state),
    isOpenFiltersPopup: getSongsModuleIsOpenFiltersPopup(state),
    recentEditedSongs: getRecentEditedSongs(state),
    permissions: getUserPermissions(state),
    songsCount: getSongsModuleSongsCount(state),
  }));

  const filterInitializationCounter = useRef(0);
  const sortRestoredFromCache = useRef(false);

  const [columns, setColumns] = useState(
    getFilteredColumnsData(
      myFilter.filterType as SongsTableFilterTypes,
      setReloadHash,
      permissions.allowedSongsTableColumns as ColumnFields[]
    )
  );

  const [selectedRows, setSelectedRows] = useState<ISongData[]>([]);
  const [columnsFromBackend, setColumnsFromBackend] = useState<DataGridColDef<ISongData>[]>([]);

  const gridRef = useRef<GridApi | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const pagePreferencesName = useMemo(
    () => (isSongsPage ? UserPreferenceTypes.songs : UserPreferenceTypes.songs_search),
    [isSongsPage]
  );

  const getRowsHandler: GetRowsHandler<ISongData> = useCallback(
    async (params) => {
      const { startRow } = params;
      const cache = getCache(songsCacheKey);

      const firstLoad = startRow === 0;

      const sortingParams = params.sortModel;
      const filtersParams = params.filterModel?.filters;

      const createdOn = filtersParams?.creationDate ? getStartEndDateFromString(filtersParams.creationDate) : null;
      const uploadedOn = filtersParams?.uploadedOn ? getStartEndDateFromString(filtersParams.uploadedOn) : null;
      const modifiedOn = filtersParams?.modifiedOn ? getStartEndDateFromString(filtersParams.modifiedOn) : null;

      let songs;

      if (firstLoad && !selectedRowIndex) {
        scrollToTop();
      }

      if (firstLoad && cache.length) {
        songs = cache;
      } else {
        const body = {
          pagination: {
            skip: startRow,
            take: TABLE_PAGE_SIZE,
            sorts: sortingParams,
          },
          // For Search
          ...(filtersParams && getQuickSearchBody(filtersParams, isSongsPage)),
          type: filtersParams?.searchType,
          territory: userTerritory || '',
          // My Filters
          ...(filtersParams.myFilter && { myFilter: filtersParams.myFilter }),
          // All filters
          ...(filtersParams && {
            filter: {
              type: filtersParams?.type?.code,
              title: filtersParams?.songTitle,
              workCode: filtersParams?.workId,
              writers: (filtersParams?.writers as IWriterItem[])?.map((el) => el.id) || [],
              workStatuses: filtersParams?.status?.map((item: ISongsModuleNestedData) => item.code),
              teams: filtersParams?.team?.map((item: ISongsModuleNestedData) => item.title),
              ...(filtersParams?.wcm && {
                wcm: filtersParams.wcm,
              }),
              uploadedBy: filtersParams?.uploadedBy,
              modifiedBy: filtersParams?.modifiedBy,
              ...(createdOn && { createdOn }),
              ...(uploadedOn && { uploadedOn }),
              ...(modifiedOn && { modifiedOn }),
            },
          }),
        };
        const { songs: data, totalCount } = (await Api.getSongs(body)) || {};
        songs = data;
        typeof totalCount === 'number' && dispatch(setSongsModule({ songsCount: totalCount }));

        addToCache(songs, songsCacheKey);
      }

      // Note: map songs and added key for DataGrid because backend sent us the same id for different versions of song
      return songs?.map((item: ISongData) => ({ ...item, key: `${item.id}${item.version}${item.mediaId}` })) || [];
    },
    [songsCacheKey, isSongsPage, userTerritory, selectedRowIndex, dispatch]
  );

  const onDragStartGridParent = (e: React.DragEvent) => {
    const t = e.target as HTMLElement;
    if (!t.getAttribute) return;
    if (t.getAttribute('role') !== 'row') return;

    const selectedSongData = gridRef.current?.getRowNode(t.getAttribute('row-id') as string)?.data;

    e.dataTransfer?.setData('text', JSON.stringify(selectedSongData));
  };

  const onMouseDownGridParent = (e: React.MouseEvent) => {
    const t = e.target as HTMLElement;
    const rowEl = t.closest('div.ag-row[role="row"]');
    if (rowEl === null) return;

    const selectedSongData = gridRef.current?.getRowNode(rowEl.getAttribute('row-id') as string)?.data;

    if (!selectedSongData?.mediaId || selectedSongData.status === SongStatusTypes.ARCHIVED) return;

    if (!rowEl.getAttribute || !rowEl.classList.contains('ag-row-level-0')) return;
    if (rowEl.getAttribute('draggable') === 'true') return;
    rowEl.setAttribute('draggable', 'true');
  };

  const deleteTag = (type: SongsModuleFilterKeys): void => {
    dispatch(
      setSongsModule({
        filtersData: {
          [type]: defaultSongsModuleFilterData[type],
        },
      })
    );
  };

  const changeColumns = useDebounce((disabledColumns: ColumnFields[]) => {
    const isMobile = windowWidth < tablet;

    setColumns((prevState) =>
      prevState.map((item) => {
        if (isMobile && item.field === ColumnFields.songTitle) {
          return {
            ...item,
            resizable: false,
          };
        }

        if (isMobile && item.field === ColumnFields.actions) {
          return {
            ...item,
            maxWidth: 50,
            pinned: '',
            headerName: '',
          };
        }

        return item.field === 'filters'
          ? { ...item }
          : {
              ...item,
              hide: disabledColumns.includes(item.field as ColumnFields),
              ...(item.field === ColumnFields.actions && {
                maxWidth: undefined,
                pinned: 'right',
                headerName: ColumnHeaderNames.actions,
              }),
              ...(item.field === ColumnFields.songTitle && {
                resizable: true,
              }),
            };
      })
    );

    setTimeout(() => autoSizeActionsColumn(gridRef.current), 100);
  }, 200);

  useEffect(() => {
    if (!isOpenSidebar) {
      if (windowWidth >= desktopBig) {
        setColumns((prevState) =>
          columnsFromBackend.length
            ? columnsFromBackend
            : prevState.map((item) => ({ ...item, hide: item.field === 'filters' }))
        );
      }
      if (windowWidth >= desktopSmall && windowWidth < desktopBig) {
        columnsFromBackend.length ? setColumns(columnsFromBackend) : changeColumns(hiddenSongsColumnsDesktopSmall);
      }
      if (windowWidth >= tablet && windowWidth < desktopSmall) {
        changeColumns(hiddenSongsColumnsTablet);
      }
      if (windowWidth < tablet) {
        changeColumns(hiddenSongsColumnsMobile);
      }
    } else {
      if (windowWidth >= desktopBig) {
        columnsFromBackend.length ? setColumns(columnsFromBackend) : changeColumns(hiddenSongsColumnsDesktopSmall);
      }
      if (windowWidth >= tablet && windowWidth < desktopBig) {
        changeColumns(hiddenSongsColumnsTablet);
      }
      if (windowWidth < tablet) {
        changeColumns(hiddenSongsColumnsMobile);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSidebar, windowWidth, setColumns]);

  useEffect(() => {
    if (
      !gridRef.current ||
      !recentEditedSongs.length ||
      (gridRef?.current &&
        getDataGridItems<ISongData>(gridRef.current).every((el) => !findRecentEditedSongById(recentEditedSongs, el.id)))
    ) {
      dispatch(setRecentEditedSongs({ usages: [UsageKeys.songsTable] }));
      return;
    }

    setReloadHash && setReloadHash(new Date().toISOString());
    dispatch(setRecentEditedSongs({ usages: [UsageKeys.songsTable] }));
  }, [dispatch, recentEditedSongs, setReloadHash]);

  const scrollToTop = () => {
    try {
      gridRef.current?.ensureIndexVisible(0, 'top');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error scrolling to row:', error);
    }
  };

  const scrollToRow = () => {
    if (selectedRowIndex) {
      try {
        // Ensure the row is in the viewport (this will trigger data fetching if needed)
        setTimeout(() => {
          gridRef.current?.ensureIndexVisible(selectedRowIndex, 'middle');
        }, 50);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error scrolling to row:', error);
      } finally {
        dispatch(setSelectedRowIndex(0));
      }
    }
  };

  const handleSortChange = ({ columnApi }: SortChangedEvent) => {
    if (sortRestoredFromCache.current) {
      return (sortRestoredFromCache.current = false);
    }
    invalidateCache(songsCacheKey);
    const sortModel = columnApi.getColumnState().find((s) => s.sort != null);
    if (!sortModel) return;
    addToCache([sortModel], sortCacheKey);
  };

  const applySort = ({ columnApi }: GridReadyEvent) => {
    const sortCache = getCache(sortCacheKey) as ColumnState[];
    if (!sortCache.length) return;
    sortRestoredFromCache.current = true;
    const currentSortModel = columnApi.getColumnState();
    const mergeSortModel = currentSortModel.map((o1) => sortCache.find((o2) => o2.colId === o1.colId) || o1);

    columnApi.applyColumnState({
      state: mergeSortModel,
      defaultState: { sort: null },
    });
  };

  useUnMount(() => {
    if (history.location.pathname.includes(`${Paths.songs}/`)) return;
    clearAllCache();
    dispatch(setSongsModule({ filtersData: defaultSongsModuleFilterData }));
  });

  useMount(async () => {
    if (!sessionStorage.getItem('fromSongsPage')) {
      clearAllCache();
      dispatch(setSelectedRowIndex(0));
    }
    sessionStorage.removeItem('fromSongsPage');

    if (windowWidth < desktopSmall) {
      return;
    }

    try {
      const preferences = await Api.getUserPreferencesByPreferenceName({ pathId: pagePreferencesName });
      const preference = preferences ? JSON.parse(preferences?.preference) : null;

      if (!Array.isArray(preference?.columns)) return;

      const newColumns = columns.map((item) => {
        const isExistingFieldVisible = preference?.columns.some(
          (elem: IColumnConfig) => elem.field === item.field && elem.hide
        );

        return { ...item, hide: item.field === 'filters' ? true : isExistingFieldVisible };
      });

      setColumns(newColumns);
      setColumnsFromBackend(newColumns);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.debug(err);
    }
  });

  const onChangeColumnsConfig = (columns: DataGridColDef<ISongData>[]) => {
    Api.createUpdateUserPreferences(
      { columns: columns.map((item) => ({ field: item.field, hide: !!item.hide })) },
      { pathId: pagePreferencesName }
    );
    setColumnsFromBackend(columns);
  };

  return (
    <div
      ref={parentRef}
      className={classNames(styles.dataGridContainer, {
        [styles.dataGridBigHeight]: myFilter.filterType !== SongsTableFilterTypes.myUploads,
        [styles.dataGridOnlyAllTab]: permissions.viewAllSongs,
      })}
      style={{ height }}
    >
      <SongsModuleFiltersPopup parent={parentRef} />
      <TableFilters
        className={classNames(styles.hideInMobile, {
          [styles.myUploadTableFilters]: myFilter.filterType === SongsTableFilterTypes.myUploads,
        })}
        data={filtersData}
        dataTitles={SongsModuleFilterTitles}
        isOpenFiltersPopup={isOpenFiltersPopup}
        onClickFilterIcon={() => dispatch(setSongsModule({ isOpenFiltersPopup: true }))}
        onDeleteTag={deleteTag}
        title={title}
      />
      <SelectedPanel
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        gridRef={gridRef}
        setReloadHash={setReloadHash}
        isArchived={myFilter.filterType === SongsTableFilterTypes.archived}
        total={songsCount}
      />
      <div style={{ display: 'contents' }} onDragStart={onDragStartGridParent} onMouseDown={onMouseDownGridParent}>
        <DataGrid
          ref={gridRef}
          suppressMultiSort
          refreshHeaderOnAutoSizeActionsColumn
          className={styles.dataGrid}
          columns={columns}
          noRowsOverlayComponentFramework={() => <NoDataAvailable />}
          datasource={{
            type: 'infinite',
            pageSize: TABLE_PAGE_SIZE,
            getRows: getRowsHandler,
            rowKey: 'key',
          }}
          onFirstDataRendered={scrollToRow}
          onGridReady={applySort}
          defaultColDef={{
            ...DefaultColDefConfigs,
            ...FilteringProps,
          }}
          onSelectionChanged={(rows) => setSelectedRows(rows)}
          onSortChanged={handleSortChange}
          onFilterChanged={() => {
            // Omit the invalidation on initial load as the Grid triggers a filter change event on load
            if (filterInitializationCounter.current < 3) {
              return filterInitializationCounter.current++;
            }
            invalidateCache(songsCacheKey);
            setReloadHash && setReloadHash(new Date().toISOString());
          }}
          rowSelection="multiple"
          suppressRowClickSelection
          rowHeight={54}
          isFullWidthCell={(p) => p.level === 1}
          fullWidthCellRendererFramework={(p: CellRendererProps<DataGridColDef<ISongData>>) => <ExpandedRow p={p} />}
          reloadHash={reloadHash}
          rowClassRules={{
            [styles.expandedRow]: (p) => p.node.detail,
          }}
          onRowClicked={({ data: { id }, rowIndex }) => {
            if (!id || myFilter.filterType === SongsTableFilterTypes.archived) return;

            dispatch(setSelectedRowIndex(rowIndex || 0));
            history.push(`${Paths.songs}/${id}`);
          }}
          initialFilterModel={{ filters: { ...filtersData, ...searchData, myFilter } }}
          onChangeColumnsConfig={onChangeColumnsConfig}
          disableColumnsConfig={windowWidth < desktopSmall}
          ignoreLoadingOnFiltersChange={ignoreLoadingOnFiltersChange}
        />
      </div>
    </div>
  );
};

export default SongsTable;
