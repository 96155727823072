import { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';

import songsStyles from 'components/Songs/Songs.module.scss';
import { StatusCard } from 'components/UI';
import { DataGridColDef, SortableProps } from 'components/UI/DataGrid';
import { UserStatuses } from 'components/UI/StatusCard/data';

import Api, { IRequestParams, playPlaylistById } from 'services/Api';
import { IUserData } from 'store/reducers/general/types';
import { getFormattedDate, getUserRoleTitle } from 'utils';
import { UserRoleCodes } from 'utils/data-constants';

import { ReactComponent as PlayCircleIcon } from 'assets/play-circle.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './AdminControls.module.scss';

import { ActionsCell, FeaturedPlaylistActionsCell, MoreIconContainer, UsersCell } from '.';
import {
  AdminControlsFilterDataType,
  AdminControlsFilterKeys,
  HoldRequestTabKeys,
  HoldRequestTabValuesType,
  IAdminControlsNestedData,
  IAutocompleteOption,
  StatusTabKeys,
  StatusTabValuesType,
} from 'types';

export const defaultStatusTabValues: StatusTabValuesType = {
  [StatusTabKeys.AllUsers]: null,
  [StatusTabKeys.ACTIVE]: null,
  [StatusTabKeys.PENDING]: null,
  [StatusTabKeys.REJECTED]: null,
  [StatusTabKeys.ARCHIVED]: null,
};

export const defaultHoldRequestTabValues: HoldRequestTabValuesType = {
  [HoldRequestTabKeys.AllStatuses]: null,
  [HoldRequestTabKeys.AWAITING_APPROVAL]: null,
  [HoldRequestTabKeys.APPROVED]: null,
  [HoldRequestTabKeys.REJECTED]: null,
};

export interface ITabsData {
  id: number;
  title: string;
  type: string;
  dataKey: StatusTabKeys;
}

export interface IHoldRequestTabsData {
  id: number;
  title: string;
  dataKey: HoldRequestTabKeys;
}

export const tabsData: ITabsData[] = [
  {
    id: 1,
    title: 'All Users',
    type: 'All',
    dataKey: StatusTabKeys.AllUsers,
  },
  {
    id: 2,
    title: 'Active',
    type: 'ACTIVE',
    dataKey: StatusTabKeys.ACTIVE,
  },
  {
    id: 3,
    title: 'Pending',
    type: 'PENDING',
    dataKey: StatusTabKeys.PENDING,
  },
  {
    id: 4,
    title: 'Denied',
    type: 'REJECTED',
    dataKey: StatusTabKeys.REJECTED,
  },
  {
    id: 5,
    title: 'Archived',
    type: 'ARCHIVED',
    dataKey: StatusTabKeys.ARCHIVED,
  },
];

export const holdRequestTabsData: IHoldRequestTabsData[] = [
  {
    id: 1,
    title: 'All',
    dataKey: HoldRequestTabKeys.AllStatuses,
  },
  {
    id: 2,
    title: 'Pending',
    dataKey: HoldRequestTabKeys.AWAITING_APPROVAL,
  },
  {
    id: 3,
    title: 'On Hold',
    dataKey: HoldRequestTabKeys.APPROVED,
  },
  {
    id: 4,
    title: 'Denied',
    dataKey: HoldRequestTabKeys.REJECTED,
  },
];

export enum UserRoles {
  ARROW_READ = 'WCM Read-Only',
  ARROW_TEAM_MEMBER = 'WCM Team Member',
  SUPER_ADMIN = 'WCM Super Admin',
  LABEL = 'Label',
  PARTNER = 'Partner',
  SONGWRITER = 'Songwriter',
}

export enum ColumnFields {
  index = 'index',
  user = 'firstName',
  team = 'defaultTeam',
  isTeamAdmin = 'isTeamAdmin',
  territory = 'defaultTerritory',
  role = 'role',
  status = 'status',
  actions = 'actions',
  filters = 'filters',
}

export enum ColumnHeaderNames {
  empty = '',
  actions = 'Actions',
  user = 'User',
  defaultTeam = 'Default Team',
  territory = 'Territory',
  role = 'Role',
  status = 'Status',
  team = 'Team',
  teamAdmin = 'Team Admin',
  label = 'Primary Label',
  subLabel = 'Sub Label',
  company = 'Company',
}

export interface IAdminControlsData {
  id: number;
  title: string;
  data: IAdminControlsNestedData[];
  value: AdminControlsFilterKeys;
  required?: boolean;
  onlyFirstCharCapitalized?: boolean;
  code?: string;
}

type TApiMethod = (query: string, params?: IRequestParams) => Promise<never[] | IAutocompleteOption[]>;
type TApiMethodWithLabel = (
  query: string,
  label?: string,
  params?: IRequestParams
) => Promise<never[] | IAutocompleteOption[]>;

export interface IAdminControlsAdditionalData {
  id: number;
  title: string;
  value: AdditionalUserDataKeys;
  required?: boolean;
  apiMethod: TApiMethod | TApiMethodWithLabel;
}

export enum AdminControlsFilterTitles {
  firstName = 'First Name',
  lastName = 'Last Name',
  email = 'Email',
  status = 'Status',
  defaultTeam = 'Team',
  defaultTerritory = 'Territory',
  role = 'Role',
  isTeamAdmin = 'Team Admin',
}

export enum AdditionalUserDataKeys {
  label = 'label',
  subLabel = 'subLabel',
  company = 'company',
}

export const defaultAdminControlsFilterData: AdminControlsFilterDataType = {
  [AdminControlsFilterKeys.firstName]: '',
  [AdminControlsFilterKeys.lastName]: '',
  [AdminControlsFilterKeys.email]: '',
  [AdminControlsFilterKeys.status]: null,
  [AdminControlsFilterKeys.team]: null,
  [AdminControlsFilterKeys.territory]: null,
  [AdminControlsFilterKeys.role]: null,
  [AdminControlsFilterKeys.isTeamAdmin]: null,
};

export interface IColumnsState {
  columns: DataGridColDef<IUserData>[];
  setColumns: Dispatch<SetStateAction<DataGridColDef<IUserData>[]>>;
}

interface IAdminControlsFilterInputs {
  id: number;
  title: AdminControlsFilterKeys;
  label: string;
  placeholder: string;
}

export const adminControlsFilterInputs: IAdminControlsFilterInputs[] = [
  {
    id: 1,
    title: AdminControlsFilterKeys.firstName,
    label: 'First Name',
    placeholder: 'Filter by first name',
  },
  {
    id: 2,
    title: AdminControlsFilterKeys.lastName,
    label: 'Last Name',
    placeholder: 'Filter by last name',
  },
  {
    id: 3,
    title: AdminControlsFilterKeys.email,
    label: 'Email',
    placeholder: 'Filter by email',
  },
];

export const statusTitles: { [K in UserStatuses]: string } = {
  [UserStatuses.ACTIVE]: 'Active',
  [UserStatuses.PENDING]: 'Pending',
  [UserStatuses.REJECTED]: 'Denied',
  [UserStatuses.ARCHIVED]: 'Archived',
  [UserStatuses.ERROR]: '',
  [UserStatuses.APPROVED]: '',
  [UserStatuses.INACTIVE]: '',
};

export const STATUSES: IAdminControlsNestedData[] = [
  { id: 1, title: statusTitles.ACTIVE, code: UserStatuses.ACTIVE },
  { id: 2, title: statusTitles.REJECTED, code: UserStatuses.REJECTED },
  { id: 3, title: statusTitles.ARCHIVED, code: UserStatuses.ARCHIVED },
  { id: 4, title: statusTitles.PENDING, code: UserStatuses.PENDING },
];

export const ROLES: IAdminControlsNestedData[] = [
  { id: 1, title: UserRoles.ARROW_READ, code: UserRoleCodes.ArrowReadOnly },
  { id: 2, title: UserRoles.SUPER_ADMIN, code: UserRoleCodes.SuperAdmin },
  { id: 3, title: UserRoles.ARROW_TEAM_MEMBER, code: UserRoleCodes.ArrowTeamMember },
  { id: 4, title: UserRoles.LABEL, code: UserRoleCodes.Label },
  { id: 5, title: UserRoles.PARTNER, code: UserRoleCodes.Partner },
  { id: 6, title: UserRoles.SONGWRITER, code: UserRoleCodes.Songwriter },
];

export const TEAM_ADMIN_VALUES: IAdminControlsNestedData[] = [
  { id: 1, title: 'Yes' },
  { id: 2, title: 'No' },
];

export const TERRITORIES: IAdminControlsNestedData[] = [
  { id: 1, title: 'Afghanistan', code: 'AF' },
  { id: 2, title: 'Albania', code: 'AL' },
  { id: 3, title: 'Algeria', code: 'DZ' },
  { id: 4, title: 'Andorra', code: 'AD' },
  { id: 5, title: 'Angola', code: 'AO' },
  { id: 6, title: 'Anguilla', code: 'AI' },
  { id: 7, title: 'Antigua and Barbuda', code: 'AG' },
  { id: 8, title: 'Argentina', code: 'AR' },
  { id: 9, title: 'ARIPO', code: 'AP' },
  { id: 10, title: 'Armenia', code: 'AM' },
  { id: 11, title: 'Aruba', code: 'AW' },
  { id: 12, title: 'Australia', code: 'AU' },
  { id: 13, title: 'Austria', code: 'AT' },
  { id: 14, title: 'Azerbaijan', code: 'AZ' },
  { id: 15, title: 'Bahamas', code: 'BS' },
  { id: 16, title: 'Bahrain', code: 'BH' },
  { id: 17, title: 'Bangladesh', code: 'BD' },
  { id: 18, title: 'Barbados', code: 'BB' },
  { id: 19, title: 'BBM and BBDM', code: 'BX' },
  { id: 20, title: 'Belarus', code: 'BY' },
  { id: 21, title: 'Belgium', code: 'BE' },
  { id: 22, title: 'Belize', code: 'BZ' },
  { id: 23, title: 'Benin', code: 'BJ' },
  { id: 24, title: 'Bermuda', code: 'BM' },
  { id: 25, title: 'Bhutan', code: 'BT' },
  { id: 26, title: 'Bolivia', code: 'BO' },
  { id: 27, title: 'Bosnia and Herzegovina', code: 'BA' },
  { id: 28, title: 'Botswana', code: 'BW' },
  { id: 29, title: 'Bouvet Island', code: 'BV' },
  { id: 30, title: 'Brazil', code: 'BR' },
  { id: 31, title: 'British Virgin Islands', code: 'VG' },
  { id: 32, title: 'Burundi', code: 'BI' },
  { id: 33, title: 'Brunei Darussalam', code: 'BN' },
  { id: 34, title: 'Bulgaria', code: 'BG' },
  { id: 35, title: 'Burkina Faso', code: 'BF' },
  { id: 36, title: 'Burma', code: 'BU' },
  { id: 37, title: 'Cameroon', code: 'CM' },
  { id: 38, title: 'Canada', code: 'CA' },
  { id: 39, title: 'Cape Verde', code: 'CV' },
  { id: 40, title: 'Cayman Islands', code: 'KY' },
  { id: 41, title: 'Central African Republic', code: 'CF' },
  { id: 42, title: 'Chad', code: 'TD' },
  { id: 43, title: 'Chile', code: 'CL' },
  { id: 44, title: 'China', code: 'CN' },
  { id: 45, title: 'Columbia', code: 'CO' },
  { id: 46, title: 'Comoros', code: 'KM' },
  { id: 47, title: 'Congo', code: 'CG' },
  { id: 48, title: 'Cook Islands', code: 'CK' },
  { id: 49, title: 'Costa Rica', code: 'CR' },
  { id: 50, title: 'Cote d’Ivoire', code: 'CI' },
  { id: 51, title: 'Croatia', code: 'HR' },
  { id: 52, title: 'Cuba', code: 'CU' },
  { id: 53, title: 'Cyprus', code: 'CY' },
  { id: 54, title: 'Czech Republic', code: 'CZ' },
  { id: 55, title: 'Czechoslovakia', code: 'CS' },
  { id: 56, title: 'Denmark', code: 'DK' },
  { id: 57, title: 'Djibouti', code: 'DJ' },
  { id: 58, title: 'Dominica', code: 'DM' },
  { id: 59, title: 'Dominican Republic', code: 'DO' },
  { id: 60, title: 'EAPO', code: 'EA' },
  { id: 61, title: 'East Germany', code: 'DD' },
  { id: 62, title: 'East Timor', code: 'TP' },
  { id: 63, title: 'Ecuador', code: 'EC' },
  { id: 64, title: 'Egypt', code: 'EG' },
  { id: 65, title: 'El Salvador', code: 'SV' },
  { id: 66, title: 'EPO', code: 'EP' },
  { id: 67, title: 'Equatorial Guinea', code: 'GQ' },
  { id: 68, title: 'Estonia', code: 'EE' },
  { id: 69, title: 'Ethiopia', code: 'ET' },
  { id: 70, title: 'Falkland Island', code: 'FK' },
  { id: 71, title: 'Faroe Islands', code: 'FO' },
  { id: 72, title: 'Fiji', code: 'FJ' },
  { id: 73, title: 'Finland', code: 'FI' },
  { id: 74, title: 'France', code: 'FR' },
  { id: 75, title: 'Gabon', code: 'GA' },
  { id: 76, title: 'Gambia', code: 'GM' },
  { id: 77, title: 'Greenland', code: 'GL' },
  { id: 78, title: 'Georgia', code: 'GE' },
  { id: 79, title: 'Germany', code: 'DE' },
  { id: 80, title: 'Ghana', code: 'GH' },
  { id: 81, title: 'Gibraltar', code: 'GI' },
  { id: 82, title: 'Greece', code: 'GR' },
  { id: 83, title: 'Grenada', code: 'GD' },
  { id: 84, title: 'Guatemala', code: 'GT' },
  { id: 85, title: 'Guinea', code: 'GN' },
  { id: 86, title: 'Guinea-Bissau', code: 'GW' },
  { id: 87, title: 'Guyana', code: 'GY' },
  { id: 88, title: 'Haiti', code: 'HT' },
  { id: 89, title: 'Honduras', code: 'HN' },
  { id: 90, title: 'Hong Kong', code: 'HK' },
  { id: 91, title: 'Hungary', code: 'HU' },
  { id: 92, title: 'Iceland', code: 'IS' },
  { id: 93, title: 'IIB', code: 'IB' },
  { id: 94, title: 'India', code: 'IN' },
  { id: 95, title: 'Indonesia', code: 'ID' },
  { id: 96, title: 'Iran', code: 'IR' },
  { id: 97, title: 'Iraq', code: 'IQ' },
  { id: 98, title: 'Ireland', code: 'IE' },
  { id: 99, title: 'Israel', code: 'IL' },
  { id: 100, title: 'Italy', code: 'IT' },
  { id: 101, title: 'Jamaica', code: 'JM' },
  { id: 102, title: 'Japan', code: 'JP' },
  { id: 103, title: 'Jordan', code: 'JO' },
  { id: 104, title: 'Kampuchea', code: 'KH' },
  { id: 105, title: 'Kazakhstan', code: 'KZ' },
  { id: 106, title: 'Kenya', code: 'KE' },
  { id: 107, title: 'Kiribati', code: 'KI' },
  { id: 108, title: 'Kuwait', code: 'KW' },
  { id: 109, title: 'Kyrgyzstan', code: 'KG' },
  { id: 110, title: 'Laos', code: 'LA' },
  { id: 111, title: 'Lebanon', code: 'LB' },
  { id: 112, title: 'Lesotho', code: 'LS' },
  { id: 113, title: 'Liberia', code: 'LR' },
  { id: 114, title: 'Libya', code: 'LY' },
  { id: 115, title: 'Liechtenstein', code: 'LI' },
  { id: 116, title: 'Lithuania', code: 'LT' },
  { id: 117, title: 'Luxembourg', code: 'LU' },
  { id: 118, title: 'Macao', code: 'MO' },
  { id: 119, title: 'Macedonia', code: 'MK' },
  { id: 120, title: 'Madagascar', code: 'MG' },
  { id: 121, title: 'Malawi', code: 'MW' },
  { id: 122, title: 'Malaysia', code: 'MY' },
  { id: 123, title: 'Maldives', code: 'MV' },
  { id: 124, title: 'Mali', code: 'ML' },
  { id: 125, title: 'Malta', code: 'MT' },
  { id: 126, title: 'Marshall Islands', code: 'MH' },
  { id: 127, title: 'Mauritania', code: 'MR' },
  { id: 128, title: 'Mauritius', code: 'MU' },
  { id: 129, title: 'Mexico', code: 'MX' },
  { id: 130, title: 'Micronesia', code: 'FM' },
  { id: 131, title: 'Moldova', code: 'MD' },
  { id: 132, title: 'Monaco', code: 'MC' },
  { id: 133, title: 'Mongolia', code: 'MN' },
  { id: 134, title: 'Montserrat', code: 'MS' },
  { id: 135, title: 'Morocco', code: 'MA' },
  { id: 136, title: 'Mozambique', code: 'MZ' },
  { id: 137, title: 'Myanmar', code: 'MM' },
  { id: 138, title: 'Namibia', code: 'NA' },
  { id: 139, title: 'Nauru', code: 'NR' },
  { id: 140, title: 'Nepal', code: 'NP' },
  { id: 141, title: 'Netherlands(Holland)', code: 'NL' },
  { id: 142, title: 'Netherlands Antilles', code: 'AN' },
  { id: 143, title: 'New Zealand', code: 'NZ' },
  { id: 144, title: 'Nicaragua', code: 'NI' },
  { id: 145, title: 'Niger', code: 'NE' },
  { id: 146, title: 'Nigeria', code: 'NG' },
  { id: 147, title: 'North Korea', code: 'KP' },
  { id: 148, title: 'Norway', code: 'NO' },
  { id: 149, title: 'Northern Mariana Islands', code: 'MP' },
  { id: 150, title: 'OAPI', code: 'OA' },
  { id: 151, title: 'OHIM', code: 'EM' },
  { id: 152, title: 'Oman', code: 'OM' },
  { id: 153, title: 'Other Country', code: 'XX' },
  { id: 154, title: 'Other Country in Africa', code: 'X6' },
  { id: 155, title: 'Other Country in Asia', code: 'X1' },
  { id: 156, title: 'Other Country in Europe', code: 'X4' },
  { id: 157, title: 'Other Country in North America', code: 'X2' },
  { id: 158, title: 'Other Country In Oceania', code: 'X5' },
  { id: 159, title: 'Other Country in South America', code: 'X3' },
  { id: 160, title: 'Pakistan', code: 'PK' },
  { id: 161, title: 'Panama', code: 'PA' },
  { id: 162, title: 'Papua New Guinea', code: 'PG' },
  { id: 163, title: 'Paraguay', code: 'PY' },
  { id: 164, title: 'Peru', code: 'PE' },
  { id: 165, title: 'Philippines', code: 'PH' },
  { id: 166, title: 'Poland', code: 'PL' },
  { id: 167, title: 'Portugal', code: 'PT' },
  { id: 168, title: 'Qatar', code: 'QA' },
  { id: 169, title: 'Republic of Yemen', code: 'YD' },
  { id: 170, title: 'Rhodesia', code: 'RH' },
  { id: 171, title: 'Romania', code: 'RO' },
  { id: 172, title: 'Russian Federation', code: 'RU' },
  { id: 173, title: 'Rwanda', code: 'RW' },
  { id: 174, title: 'Saint Helena', code: 'SH' },
  { id: 175, title: 'Saint Kitts and Nevis', code: 'KN' },
  { id: 176, title: 'Saint Lucia', code: 'LC' },
  { id: 177, title: 'Saint Vincent and the Grenadines', code: 'VC' },
  { id: 178, title: 'Samoa', code: 'WS' },
  { id: 179, title: 'San Marino', code: 'SM' },
  { id: 180, title: 'Sao Tome and Principe', code: 'ST' },
  { id: 181, title: 'Saudi Arabia', code: 'SA' },
  { id: 182, title: 'Senegal', code: 'SN' },
  { id: 183, title: 'Seychelles', code: 'SC' },
  { id: 184, title: 'Sierra Leone', code: 'SL' },
  { id: 185, title: 'Singapore', code: 'SG' },
  { id: 186, title: 'Slovakia', code: 'SK' },
  { id: 187, title: 'Slovenia', code: 'SI' },
  { id: 188, title: 'Solomon Islands', code: 'SB' },
  { id: 189, title: 'Somalia', code: 'SO' },
  { id: 190, title: 'South Africa', code: 'ZA' },
  { id: 191, title: 'South Georgia and The South Sandwich Islands', code: 'GS' },
  { id: 192, title: 'South Korea', code: 'KR' },
  { id: 193, title: 'Spain', code: 'ES' },
  { id: 194, title: 'Sri Lanka', code: 'LK' },
  { id: 195, title: 'Sudan', code: 'SD' },
  { id: 196, title: 'Suriname', code: 'SR' },
  { id: 197, title: 'Swaziland', code: 'SZ' },
  { id: 198, title: 'Sweden', code: 'SE' },
  { id: 199, title: 'Switzerland', code: 'CH' },
  { id: 200, title: 'Syria', code: 'SY' },
  { id: 201, title: 'Taiwan', code: 'TW' },
  { id: 202, title: 'Tajikistan', code: 'TJ' },
  { id: 203, title: 'Tanzania', code: 'TZ' },
  { id: 204, title: 'Thailand', code: 'TH' },
  { id: 205, title: 'Togo', code: 'TG' },
  { id: 206, title: 'Tonga', code: 'TO' },
  { id: 207, title: 'Trinidad and Tobago', code: 'TT' },
  { id: 208, title: 'Tunisia', code: 'TN' },
  { id: 209, title: 'Turkey', code: 'TR' },
  { id: 210, title: 'Turkmenistan', code: 'TM' },
  { id: 211, title: 'Turks and Caicos Islands', code: 'TC' },
  { id: 212, title: 'Tuvalu', code: 'TV' },
  { id: 213, title: 'Uganda', code: 'UG' },
  { id: 214, title: 'Ukraine', code: 'UA' },
  { id: 215, title: 'United Arab Emirates', code: 'AE' },
  { id: 216, title: 'United Kingdom', code: 'GB' },
  { id: 217, title: 'United States of America', code: 'US' },
  { id: 218, title: 'Uruguay', code: 'UY' },
  { id: 219, title: 'Soviet Union', code: 'SU' },
  { id: 220, title: 'Uzbekistan', code: 'UZ' },
  { id: 221, title: 'Vanuatu', code: 'VU' },
  { id: 222, title: 'Vatican City', code: 'VA' },
  { id: 223, title: 'Venezuela', code: 'VE' },
  { id: 224, title: 'Vietnam', code: 'VN' },
  { id: 225, title: 'Volta', code: 'HV' },
  { id: 226, title: 'Western Sahara', code: 'EH' },
  { id: 227, title: 'WIPO', code: 'WO' },
  { id: 228, title: 'Yemen', code: 'YE' },
  { id: 229, title: 'Yugoslavia', code: 'YU' },
  { id: 230, title: 'Zaire', code: 'ZR' },
  { id: 231, title: 'Zambia', code: 'ZM' },
  { id: 232, title: 'Zimbabwe', code: 'ZW' },
];

export const adminControlsData: IAdminControlsData[] = [
  {
    id: 1,
    title: ColumnHeaderNames.status,
    value: AdminControlsFilterKeys.status,
    data: STATUSES,
    onlyFirstCharCapitalized: true,
  },
  {
    id: 2,
    title: ColumnHeaderNames.role,
    value: AdminControlsFilterKeys.role,
    data: ROLES,
    required: true,
  },
  {
    id: 3,
    title: ColumnHeaderNames.team,
    value: AdminControlsFilterKeys.team,
    data: [],
  },
  {
    id: 4,
    title: ColumnHeaderNames.teamAdmin,
    value: AdminControlsFilterKeys.isTeamAdmin,
    data: TEAM_ADMIN_VALUES,
  },
  {
    id: 5,
    title: ColumnHeaderNames.territory,
    value: AdminControlsFilterKeys.territory,
    data: TERRITORIES,
  },
];

export const adminControlsAdditionalData: IAdminControlsAdditionalData[] = [
  {
    id: 6,
    title: ColumnHeaderNames.label,
    value: AdditionalUserDataKeys.label,
    required: true,
    apiMethod: Api.getLabelsByQuery,
  },
  {
    id: 7,
    title: ColumnHeaderNames.subLabel,
    value: AdditionalUserDataKeys.subLabel,
    apiMethod: Api.getSubLabelsByQueryAndLabel as TApiMethodWithLabel,
  },
  {
    id: 8,
    title: ColumnHeaderNames.company,
    value: AdditionalUserDataKeys.company,
    apiMethod: Api.getCompaniesByQuery,
    required: true,
  },
];

export const columns: DataGridColDef<IUserData>[] = [
  {
    field: ColumnFields.index,
    headerName: ColumnHeaderNames.empty,
    cellClass: styles.indexCell,
    valueGetter: ({ node }) => (Number(node?.rowIndex) || 0) + 1,
    maxWidth: 30,
  },
  {
    field: ColumnFields.user,
    headerClass: styles.headerCell,
    headerName: ColumnHeaderNames.user,
    cellClass: styles.userCell,
    cellRendererFramework: ({ data }) => <UsersCell data={data} />,
    minWidth: 350,
    ...SortableProps,
  },
  {
    field: ColumnFields.team,
    headerName: ColumnHeaderNames.defaultTeam,
    headerClass: styles.headerCell,
    cellClass: styles.cellContainer,
    cellRendererFramework: ({ data }) => (data?.defaultTeam?.isInternal ? data?.defaultTeam?.name || '-' : '-'),
  },
  {
    field: ColumnFields.territory,
    headerName: ColumnHeaderNames.territory,
    headerClass: styles.headerCell,
    cellClass: styles.cellContainer,
    cellRendererFramework: ({ data }) => data?.defaultTerritory || '-',
  },
  {
    field: ColumnFields.role,
    headerName: ColumnHeaderNames.role,
    headerClass: styles.headerCell,
    cellClass: styles.cellContainer,
    cellRendererFramework: ({ data }) => getUserRoleTitle(data?.role),
    minWidth: 150,
  },
  {
    field: ColumnFields.isTeamAdmin,
    headerName: ColumnHeaderNames.teamAdmin,
    headerClass: styles.headerCell,
    cellClass: styles.cellContainer,
    cellRendererFramework: ({ data }) => (data?.isTeamAdmin ? 'Yes' : 'No'),
    minWidth: 130,
  },
  {
    field: ColumnFields.status,
    headerName: ColumnHeaderNames.status,
    headerClass: classNames(styles.headerCell, styles.statusesHeaderCell),
    cellClass: styles.statusesCellRenderer,
    cellRendererFramework: ({ data }) => (data?.status ? <StatusCard value={statusTitles[data.status]} /> : ''),
  },
  {
    field: ColumnFields.actions,
    headerComponentFramework: () => <MoreIconContainer />,
    headerName: ColumnHeaderNames.actions,
    headerClass: styles.actionsHeader,
    cellClass: styles.actionsCellContainer,
    cellRendererFramework: ({ data, node }) => <ActionsCell data={data} node={node} />,
    pinned: 'right',
  },
  {
    field: 'filters',
    hide: true,
  },
];

enum FeaturedPlaylistColumnFields {
  empty = 'empty',
  playlistTitle = 'title',
  status = 'status',
  expirationDate = 'expireOn',
  actions = 'actions',
}

enum FeaturedPlaylistColumnHeaderNames {
  empty = 'empty',
  playlistTitle = 'Playlist Title',
  status = 'Status',
  expirationDate = 'Expiration',
  actions = 'Actions',
}

export interface IFeaturedPlaylistData {
  id: string;
  title: string;
  status: string;
  expireOn: string;
  releaseOn: string;
  hasPin: boolean;
}

export const featuredPlaylistColumns: DataGridColDef<IFeaturedPlaylistData>[] = [
  {
    field: FeaturedPlaylistColumnFields.empty,
    headerName: FeaturedPlaylistColumnHeaderNames.empty,
    headerClass: songsStyles.playIconHeaderCell,
    headerComponentFramework: () => <PlayCircleIcon />,
    maxWidth: 50,
    cellClass: songsStyles.cellContainer,
    cellRendererFramework: ({ data }) => <PlayCircleIcon onClick={() => data?.id && playPlaylistById(data.id)} />,
    suppressColumnsToolPanel: true,
  },
  {
    field: FeaturedPlaylistColumnFields.playlistTitle,
    headerName: FeaturedPlaylistColumnHeaderNames.playlistTitle,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseSB_grayDarker,
    minWidth: 250,
  },
  {
    field: FeaturedPlaylistColumnFields.status,
    headerName: FeaturedPlaylistColumnHeaderNames.status,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
  },
  {
    field: FeaturedPlaylistColumnFields.expirationDate,
    headerName: FeaturedPlaylistColumnHeaderNames.expirationDate,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    valueGetter: ({ data }) => getFormattedDate(data.expireOn || ''),
  },
  {
    field: ColumnFields.actions,
    headerName: ColumnHeaderNames.actions,
    pinned: 'right',
    suppressColumnsToolPanel: true,
    cellClass: classNames('actionsCell', songsStyles.cellContainer),
    cellRendererFramework: ({ data }) => <FeaturedPlaylistActionsCell data={data} />,
  },
];

export enum FeaturedPlaylistsStatuses {
  active = 'Active',
  scheduled = 'Scheduled',
  expired = 'Expired',
  archived = 'Archived',
  unpublished = 'Unpublished',
}
