import classNames from 'classnames';

import { Tooltip } from 'components/UI';

import { useMemoSelector } from 'hooks';
import { getActiveTrack } from 'store';
import { getSongVersion } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './PitchDetails.module.scss';

interface ISongTitleCellProps {
  id?: string | null;
  title?: string;
  version?: string;
  className?: string;
  isExternal?: boolean;
  writers?: string[] | null;
}

const SongTitleCell = ({ id, title, version, className, isExternal, writers }: ISongTitleCellProps) => {
  const activeTrack = useMemoSelector(getActiveTrack);

  return (
    <Tooltip text={`${title || ''} ${(isExternal && version) || ''}`}>
      <div className={styles.songTitleContainerWithVersion}>
        <div className={styles.songTitleContainerWithWriter}>
          <span
            className={classNames(
              globalStyles.f13h16SuisseSB_grayDarker,
              globalStyles.textEllipsis,
              className,
              {
                [globalStyles.songPlayingTitle]: id && [activeTrack.mediaId, activeTrack.songId].includes(id),
              },
              styles.titleVersionWrapper
            )}
          >
            {title || '-'}
          </span>
          <span
            className={classNames(
              globalStyles.f13h16SuisseREG_grayDarker,
              globalStyles.textEllipsis,
              className,
              {
                [globalStyles.songPlayingTitle]: id && [activeTrack.mediaId, activeTrack.songId].includes(id),
              },
              styles.titleVersionWrapper,
              styles.showOnlyInMobile
            )}
          >
            {writers?.join(' / ')}
          </span>
        </div>
        {(isExternal || version) && (
          <span
            className={classNames(styles.versionText, globalStyles.f13h16SuisseSB_grayDarker, {
              [styles.showOnlyInMobile]: !isExternal,
            })}
          >
            {getSongVersion(version)}
          </span>
        )}
      </div>
    </Tooltip>
  );
};

export default SongTitleCell;
