import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GridApi } from 'ag-grid-community';

import { AddToPlaylistPopup } from 'components/Popups';
import { IAddToPlaylistRef } from 'components/Popups/AddToPlaylistPopup';
import { ISong } from 'components/Popups/SystemPitchForm';
import { SwitchBtn, Tooltip } from 'components/UI';
import { ActionButtonsTypes } from 'components/UI/SongMoreMenu/data';

import { useMemoSelector } from 'hooks';
import Api, { downloadSongs } from 'services/Api';
import {
  getUserPermissions,
  getUserTeamId,
  setError,
  setMainConfirmPopup,
  setMainNotification,
  setPitchesModule,
  setRecentEditedSongs,
} from 'store';
import { PitchContentType } from 'store/reducers/general/types';
import { DEFAULT_ERROR_CONFIG, DEFAULT_MAIN_CONFIRM_POPUP, ISelectedItemsActions } from 'utils';

import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Songs.module.scss';

import { ISongData } from './data';
import { SongStatusTypes, SongsWorkTypes } from 'types';

interface ISelectedPanel {
  selectedRows: ISongData[];
  setSelectedRows: Dispatch<SetStateAction<ISongData[]>>;
  gridRef: MutableRefObject<GridApi | null>;
  isArchived: boolean;
  setReloadHash?: Dispatch<SetStateAction<string | undefined>>;
  total?: number;
}

const SelectedPanel = (props: ISelectedPanel) => {
  const { selectedRows, setSelectedRows, gridRef, setReloadHash, isArchived, total } = props;
  const dispatch = useDispatch();

  const { permissions, userTeamId } = useMemoSelector((state) => ({
    permissions: getUserPermissions(state),
    userTeamId: getUserTeamId(state),
  }));

  const [detailsPanelAction, setDetailsPanelAction] = useState<ActionButtonsTypes | ''>('');
  const [labelView, setLabelView] = useState(false);

  const addToPlaylistRef = useRef<IAddToPlaylistRef | null>(null);

  const noAudioSelectedRows = useMemo(() => selectedRows.filter((el) => !el.mediaId), [selectedRows]);
  const isMultipleNoAudioSelected = useMemo(() => noAudioSelectedRows.length > 1, [noAudioSelectedRows]);
  const isMultipleSongsSelected = useMemo(() => selectedRows.length > 1, [selectedRows]);

  const songsThatCanBeChanged = useMemo(
    () => selectedRows.filter((el) => el.type === SongsWorkTypes.preliminaryWork && el.teamId === userTeamId),
    [selectedRows, userTeamId]
  );

  const songsThatCanNotBeChangedTitles = useMemo(
    () =>
      selectedRows.reduce(
        (total, item) => [
          ...total,
          ...(item.type === SongsWorkTypes.work || item.teamId !== userTeamId ? [item.title] : []),
        ],
        [] as string[]
      ),
    [selectedRows, userTeamId]
  );

  const changingSongIds = useMemo(
    () =>
      selectedRows.reduce(
        (total, item) => [
          ...total,
          ...(item.type === SongsWorkTypes.preliminaryWork && item.teamId === userTeamId ? [item.id] : []),
        ],
        [] as string[]
      ),
    [selectedRows, userTeamId]
  );

  const changingSongs = useMemo(() => {
    return selectedRows.filter((item) => changingSongIds.includes(item.id));
  }, [changingSongIds, selectedRows]);

  const notAbleToChangeSomeSongs = useMemo(() => !songsThatCanBeChanged.length, [songsThatCanBeChanged.length]);

  const mediaIds = useMemo(
    () => selectedRows.reduce((total, item) => (item.mediaId ? [...total, item.mediaId] : total), [] as string[]),
    [selectedRows]
  );

  const addToPlaylistMedias = useMemo(
    () =>
      selectedRows.reduce(
        (total, item) => (item.mediaId ? [...total, { title: item.title, id: item.mediaId }] : total),
        [] as { title: string; id: string }[]
      ),
    [selectedRows]
  );

  const isEverySongNotHaveAudio = useMemo(() => selectedRows.every((el) => !el.mediaId), [selectedRows]);
  const isSomeSongNotHaveAudio = useMemo(() => selectedRows.some((el) => !el.mediaId), [selectedRows]);

  useEffect(() => {
    if (detailsPanelAction !== ActionButtonsTypes.visibility) return;

    setLabelView(isEverySongNotHaveAudio ? false : selectedRows.every((el) => el.labelView || !el.mediaId));
  }, [detailsPanelAction, isEverySongNotHaveAudio, selectedRows]);

  useEffect(() => {
    if (!detailsPanelAction || selectedRows.length) return;
    setDetailsPanelAction('');
  }, [detailsPanelAction, selectedRows]);

  const closeMainConfirmPopup = () => dispatch(setMainConfirmPopup(DEFAULT_MAIN_CONFIRM_POPUP));
  const setNotification = (text: string) => dispatch(setMainNotification(text));
  const reloadTable = () => setReloadHash && setReloadHash(new Date().toISOString());

  const closeConfirmPopupSetNotificationReloadTable = (notificationText?: string) => {
    closeMainConfirmPopup();
    notificationText && setNotification(notificationText);
    reloadTable();
  };

  const progressAction = async (word: string, method: () => void) => {
    if (isEverySongNotHaveAudio) {
      dispatch(
        setError({
          isOpen: true,
          title: 'No audio',
          text: `There is no audio associated with this ${isMultipleSongsSelected ? 'songs' : 'song'} to ${word}.`,
        })
      );
      return;
    }

    if (isSomeSongNotHaveAudio) {
      dispatch(
        setMainConfirmPopup({
          isOpen: true,
          questionText: `Selected ${isMultipleNoAudioSelected ? 'songs don’t' : 'song doesn’t'} contain audio`,
          mainText: `${getNoAudioText()} ${
            isMultipleNoAudioSelected ? 'have' : 'has'
          } no audio to ${word}. Continue to ${word} other songs.`,
          btnDoneText: 'Continue',
          onClickSubmit: method,
        })
      );
      return;
    }

    await method();
  };

  const deleteSelectedNodes = () => {
    const selectedNodes = gridRef.current?.getSelectedNodes();

    selectedNodes?.forEach((node) => node.deleteRow());
  };

  const updateSelectedNodes = (colKey: string, v: string | boolean) => {
    const selectedNodes = gridRef.current?.getSelectedNodes();

    selectedNodes?.forEach((node) => node.setData({ ...node.data, [colKey]: v }));
    setTimeout(() => gridRef.current?.refreshCells({ force: true }), 10);
    setSelectedRows(gridRef.current?.getSelectedRows() as ISongData[]);
  };

  const updateRecentEditedSongs = (update: Partial<ISongData>) => {
    dispatch(
      setRecentEditedSongs({
        songs: changingSongs.map((item) => ({ ids: { songId: item.id, mediaId: item.mediaId || '' }, update })),
      })
    );
  };

  const handleItemsActions = async (type: ActionButtonsTypes) => {
    switch (type) {
      case ActionButtonsTypes.visibility:
        setDetailsPanelAction(detailsPanelAction === type ? '' : type);
        break;
      case ActionButtonsTypes.merge:
        dispatch(
          setMainConfirmPopup({
            isOpen: true,
            questionText: 'The following Songs will be merged',
            mainText: `${selectedRows[0].title} (${selectedRows[0].workCode}) \n ${selectedRows[1].title} (${selectedRows[1].workCode})`,
            btnDoneText: 'Merge',
            onClickSubmit: handleMergeSongs,
          })
        );
        break;
      case ActionButtonsTypes.addToPlaylist:
        await progressAction('Add To Playlist', handleOpenAddToPlaylist);
        break;
      case ActionButtonsTypes.download:
        await progressAction(ActionButtonsTypes.download, handleDownloadSongs);
        break;
      case ActionButtonsTypes.pitch: {
        await progressAction(ActionButtonsTypes.pitch, handlePitchSongs);
        break;
      }
      case ActionButtonsTypes.archive:
        dispatch(
          setMainConfirmPopup({
            isOpen: true,
            questionText: songsThatCanNotBeChangedTitles.length ? `Unable to Archive` : `Archive Selected Song(s)?`,
            mainText: !songsThatCanNotBeChangedTitles.length
              ? `This will remove it from songs, playlists and pitches. If you restore, it will only restore the song(s) to the songs list.`
              : notAbleToChangeSomeSongs
              ? `Only songs that belong to your default team can be archived by you.`
              : `Only PWs belonging your default team will be archived. This will prevent the songs from being accessed.
                 \n\n Cannot archive: ${songsThatCanNotBeChangedTitles.join(', ')}`,
            btnDoneText: notAbleToChangeSomeSongs
              ? 'Go Back'
              : `Archive${songsThatCanNotBeChangedTitles.length ? ' Others' : ''}`,
            onClickSubmit: handleArchiveSongs,
            hideButton1: notAbleToChangeSomeSongs,
          })
        );
        break;
      case ActionButtonsTypes.restore:
        dispatch(
          setMainConfirmPopup({
            isOpen: true,
            questionText: songsThatCanNotBeChangedTitles.length
              ? 'Selected song(s) cannot be restored'
              : `Restore Selected Song(s)?`,
            mainText: !songsThatCanNotBeChangedTitles.length
              ? `This will restore the song(s) to the songs list and allow the song(s) to be pitched and added to playlists. This will not restore the song(s) to previously added playlists and created pitches.`
              : notAbleToChangeSomeSongs
              ? 'Only PWs belonging your default team can be restored.'
              : `Only PWs belonging your default team will be restored. This will restore the song(s) to the songs list.
                 \n\n Cannot restore: ${songsThatCanNotBeChangedTitles.join(', ')}`,
            btnDoneText: notAbleToChangeSomeSongs
              ? 'Go Back'
              : `Restore${songsThatCanNotBeChangedTitles.length ? ' Others' : ''}`,
            onClickSubmit: handleRestoreSongs,
          })
        );
        break;
      case ActionButtonsTypes.delete:
        dispatch(
          setMainConfirmPopup({
            isOpen: true,
            isRedDoneButton: !notAbleToChangeSomeSongs,
            questionText: songsThatCanNotBeChangedTitles.length
              ? `Selected song(s) cannot be deleted`
              : `Delete Selected Song(s)?`,
            mainText: !songsThatCanNotBeChangedTitles.length
              ? `This will permanently delete the song(s) from the system.`
              : notAbleToChangeSomeSongs
              ? 'Only PWs belonging your default team can be deleted.'
              : `Only PWs belonging your default team will be deleted. This will permanently delete the song(s) from the system.
                 \n\n Cannot delete: ${songsThatCanNotBeChangedTitles.join(', ')}`,
            btnDoneText: notAbleToChangeSomeSongs
              ? 'Go Back'
              : `Permanently Delete${songsThatCanNotBeChangedTitles.length ? ' Others' : ''}`,
            onClickSubmit: handleDeleteSongs,
          })
        );
        break;
      default:
        break;
    }
  };

  const getNoAudioText = () =>
    noAudioSelectedRows.reduce(
      (total, item) => `${total}${total.length ? ', ' : ''} ${item.title} (${item.workCode})`,
      ''
    );

  const isFirstPreliminaryWork =
    selectedRows[0]?.type === SongsWorkTypes.preliminaryWork && selectedRows[1]?.type === SongsWorkTypes.work;
  const isSecondPreliminaryWork =
    selectedRows[0]?.type === SongsWorkTypes.work && selectedRows[1]?.type === SongsWorkTypes.preliminaryWork;

  const handleMergeSongs = async () => {
    const res = await Api.mergeSongs(
      {
        workId: isFirstPreliminaryWork ? selectedRows[1].id : selectedRows[0].id,
        preliminaryWorkId: isFirstPreliminaryWork ? selectedRows[0].id : selectedRows[1].id,
      },
      { errorPopupConfig: DEFAULT_ERROR_CONFIG }
    );

    if (!res) return;

    closeConfirmPopupSetNotificationReloadTable('Songs Merged');
  };

  const handleDownloadSongs = async () => {
    closeMainConfirmPopup();

    await downloadSongs({ ids: mediaIds });
  };

  const handlePitchSongs = () => {
    closeMainConfirmPopup();

    dispatch(
      setPitchesModule({
        systemPitchForm: {
          songs: selectedRows.reduce(
            (total, item) =>
              item.mediaId
                ? [
                    ...total,
                    {
                      id: item.id,
                      mediaId: item.mediaId,
                      path: item.mediaPath,
                      playbackPath: item.mediaPlaybackPath,
                      title: item.title,
                      version: item.version,
                      writers: item.writers,
                    },
                  ]
                : total,
            [] as ISong[]
          ),
        },
        pitchContentType: PitchContentType.multipleSongPitch,
      })
    );
  };

  const handleOpenAddToPlaylist = () => {
    closeMainConfirmPopup();
    setTimeout(() => addToPlaylistRef.current?.setOpen(true), 100);
  };

  const handleRestoreSongs = async () => {
    if (notAbleToChangeSomeSongs || !changingSongIds.length) {
      closeMainConfirmPopup();
      return;
    }

    const res = await Api.restoreSongs(changingSongIds, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    if (!res) {
      closeMainConfirmPopup();
      return;
    }

    updateRecentEditedSongs({ status: SongStatusTypes.ACTIVE });
    deleteSelectedNodes();
    closeConfirmPopupSetNotificationReloadTable(`Song(s) Restored`);
  };

  const handleDeleteSongs = async () => {
    if (notAbleToChangeSomeSongs || !changingSongIds.length) {
      closeMainConfirmPopup();
      return;
    }

    const res = await Api.deleteSongs(changingSongIds, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    if (!res) {
      closeMainConfirmPopup();
      return;
    }

    updateRecentEditedSongs({ status: SongStatusTypes.DELETED });
    deleteSelectedNodes();
    closeConfirmPopupSetNotificationReloadTable(`Song(s) Deleted`);
  };

  const handleArchiveSongs = async () => {
    if (notAbleToChangeSomeSongs || !changingSongIds.length) {
      closeMainConfirmPopup();
      return;
    }

    const res = await Api.archiveSongs(changingSongIds, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    if (!res) {
      closeMainConfirmPopup();
      return;
    }

    updateRecentEditedSongs({ status: SongStatusTypes.ARCHIVED });
    deleteSelectedNodes();
    closeConfirmPopupSetNotificationReloadTable(`Song(s) Archived`);
  };

  const onClickSongsLabelView = async () => {
    if (isSomeSongNotHaveAudio && !labelView) {
      dispatch(
        setMainConfirmPopup({
          isOpen: true,
          questionText: 'Label User Visibility',
          mainText:
            'Only songs with audio can be shared with Label Users.  Click “Continue” to share all selected tracks that have audio.',
          btnDoneText: 'Continue',
          onClickSubmit: handleChangeSongsLabelView,
        })
      );
    } else {
      await handleChangeSongsLabelView();
    }
  };

  const handleChangeSongsLabelView = async () => {
    const newValue = !labelView;
    setLabelView(newValue);

    const res = await Api.changeSongsLabelView(
      selectedRows.reduce(
        (total, item) => (item.mediaId && !total.includes(item.id) ? [...total, item.id] : total),
        [] as string[]
      ),
      newValue,
      { errorPopupConfig: DEFAULT_ERROR_CONFIG }
    );

    if (!res) {
      setLabelView((prevState) => !prevState);
      closeMainConfirmPopup();
      return;
    }

    updateRecentEditedSongs({ labelView: newValue });
    updateSelectedNodes('labelView', newValue);
    setDetailsPanelAction('');
    closeMainConfirmPopup();
    setNotification('Song(s) Label View Changed');
  };

  const withTotal = `${selectedRows.length} out of ${total} ${total === 1 ? 'item' : 'items'} selected`;
  const withoutTotal = `${selectedRows.length} ${selectedRows.length === 1 ? 'Item Selected' : 'Items Selected'}`;

  if (selectedRows.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.selectedItemsActionContainer}>
        <span className={styles.selectedItemsCountContainer}>
          {total ? withTotal : withoutTotal}
          <DeleteIcon onClick={() => gridRef.current?.deselectAll()} />
        </span>
        <span className={styles.selectedItemsActionButtonsContainer}>
          {(permissions.songsSelectedItemsActions as ISelectedItemsActions[])?.map((item) => {
            const isRestoreAndDelete = [ActionButtonsTypes.restore, ActionButtonsTypes.delete].includes(item.type);

            if ((!isArchived && isRestoreAndDelete) || (isArchived && !isRestoreAndDelete)) {
              return false;
            }

            if (item.type === ActionButtonsTypes.addToPlaylist) {
              return (
                <AddToPlaylistPopup medias={addToPlaylistMedias} addToPlaylistRef={addToPlaylistRef} key={item.id}>
                  <button
                    key={item.id}
                    className={styles.selectedItemsActionButton}
                    onClick={() => handleItemsActions(item.type)}
                  >
                    {item.title}
                  </button>
                </AddToPlaylistPopup>
              );
            }

            return (
              <button
                key={item.id}
                className={styles.selectedItemsActionButton}
                onClick={() => handleItemsActions(item.type)}
              >
                {item.title}
              </button>
            );
          })}
          {selectedRows.length === 2 &&
          permissions.viewMergeSongsButton &&
          (isFirstPreliminaryWork || isSecondPreliminaryWork) ? (
            <button
              className={styles.selectedItemsActionButton}
              onClick={() => handleItemsActions(ActionButtonsTypes.merge)}
            >
              Merge
            </button>
          ) : null}
        </span>
      </div>
      {detailsPanelAction === ActionButtonsTypes.visibility && (
        <div className={styles.selectedItemsDetailsPanel}>
          <div className={styles.labelViewSwitchContainer}>
            <div className={styles.labelViewTitleContainer}>
              <p className={globalStyles.f13h16SuisseSB_gray}>Label View</p>
              <Tooltip
                text={
                  isEverySongNotHaveAudio
                    ? 'Please upload audio to allow Label Users to see this demo'
                    : 'When activated, this song will be visible to non-WCM Label users.'
                }
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <SwitchBtn
              label={labelView ? 'Yes' : 'No'}
              labelPlacement="end"
              formRootClassName={styles.labelViewForm}
              switchRootClassName={styles.labelSwitchRoot}
              onChangeSwitch={onClickSongsLabelView}
              checked={labelView}
              disabled={isEverySongNotHaveAudio}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedPanel;
