import classNames from 'classnames';

import { Tooltip } from 'components/UI';

import globalStyles from 'styles/modules/Global.module.scss';

const PitchesTitleCell = ({ title }: { title?: string }) => {
  return (
    <Tooltip text={title || ''}>
      <span className={classNames(globalStyles.textEllipsis, globalStyles.f13h16SuisseSB_grayDarker)}>
        {title || '-'}
      </span>
    </Tooltip>
  );
};

export default PitchesTitleCell;
