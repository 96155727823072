import { Control, Controller, useWatch } from 'react-hook-form';
import { FormControlLabel, Slider } from '@material-ui/core';
import classNames from 'classnames';

import { SearchDataKeys, SearchDataType } from 'components/Popups/DesktopSearch/data';

import styles from './InputRange.module.scss';

export interface IInputRangeProps {
  label: string;
  name: SearchDataKeys;
  control: Control<SearchDataType>;
  disabledBpm?: boolean;
  singleValue?: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

const InputRange = ({
  control,
  label,
  name,
  disabledBpm,
  singleValue,
  labelPlacement = 'start',
}: IInputRangeProps): JSX.Element => {
  const bpmWatch = useWatch({ control, name: SearchDataKeys.bpm }) as number[];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          value={value}
          label={label}
          labelPlacement={labelPlacement}
          disabled={disabledBpm}
          control={
            <Slider
              onChange={(_, data) => onChange(data)}
              valueLabelDisplay="auto"
              min={0}
              max={220}
              aria-labelledby="range-slider"
              valueLabelFormat={(value) => (
                <span className={styles.valueText}>{value === 220 ? `${value}+` : value}</span>
              )}
              classes={{
                rail: styles.rail,
                root: styles.root,
                thumb: styles.thumb,
                track: classNames(styles.track, {
                  [styles.defaultTrack]: bpmWatch && bpmWatch[0] === 0 && bpmWatch[1] === 220 && !singleValue,
                }),
                valueLabel: styles.valueLabel,
                disabled: styles.disabledSlider,
              }}
            />
          }
          classes={{
            root: styles.formControl,
            label: styles.formLabel,
            disabled: styles.disabledFormLabel,
          }}
        />
      )}
    />
  );
};

export default InputRange;
