import { Tooltip } from 'components/UI';

import { IUserData } from 'store/reducers/general/types';

import styles from './AdminControls.module.scss';

interface IUsersCell {
  data?: IUserData;
}

const UsersCell = ({ data }: IUsersCell) => {
  return data ? (
    <Tooltip text={`${data.firstName} ${data.lastName} ${data.email}`} textAlign="left">
      <span className={styles.usersCellContainer}>
        <span>
          {data.firstName} {data.lastName}
        </span>
        <span>{data.email}</span>
      </span>
    </Tooltip>
  ) : null;
};

export default UsersCell;
