import { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { GridApi, RowClickedEvent } from 'ag-grid-community';

import songsStyles from 'components/Songs/Songs.module.scss';
import DataGrid, { DefaultColDefConfigs, GetRowsHandler } from 'components/UI/DataGrid';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getHoldRequestActiveTab, getHoldRequestReloadHash } from 'store';
import { Paths, TABLE_PAGE_SIZE } from 'utils';

import { getColumns } from './data';
import { HoldRequestTypes, IHoldRequestData } from 'types';

interface IHoldRequestsTable {
  type: HoldRequestTypes;
  height?: string;
}

const holdListTypes = {
  user: Api.getUserHolds,
  team: Api.getTeamHolds,
};

const HoldRequestTable = ({ type, height }: IHoldRequestsTable) => {
  const history = useHistory();
  const gridRef = useRef<GridApi | null>(null);

  const { activeTab, reloadHash } = useMemoSelector((state) => ({
    activeTab: getHoldRequestActiveTab(state),
    reloadHash: getHoldRequestReloadHash(state),
  }));

  const getRowsHandler: GetRowsHandler<IHoldRequestData> = useCallback(
    async (params) => {
      const { startRow } = params;
      const filtersParams = params.filterModel?.filters;

      return await holdListTypes[type]({ skip: startRow, take: TABLE_PAGE_SIZE, ...filtersParams });
    },
    [type]
  );

  const onRowClicked = ({ data: { songId } }: RowClickedEvent) => {
    if (!songId) return;

    history.push(`${Paths.songs}/${songId}`);
  };

  return (
    <div data-testid="hold-request-table" className={songsStyles.dataGridContainer} style={{ height }}>
      <DataGrid
        reloadHash={reloadHash}
        disableColumnsConfig
        refreshHeaderOnAutoSizeActionsColumn
        ref={gridRef}
        columns={getColumns(type)}
        datasource={{
          type: 'infinite',
          pageSize: TABLE_PAGE_SIZE,
          getRows: getRowsHandler,
        }}
        onRowClicked={onRowClicked}
        className={songsStyles.dataGrid}
        defaultColDef={DefaultColDefConfigs}
        initialFilterModel={{ filters: { status: activeTab } }}
      />
    </div>
  );
};

export default HoldRequestTable;
