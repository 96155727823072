export enum PlaylistActionButtonsTypes {
  edit = 'edit',
  share = 'share',
  pitchPlaylist = 'pitchPlaylist',
  download = 'download',
  archive = 'archive',
  delete = 'delete',
  restore = 'restore',
}

export interface IPlaylistMoreButton {
  id: number;
  title: string;
  type: PlaylistActionButtonsTypes;
}

export const playlistMoreButtons: IPlaylistMoreButton[] = [
  {
    id: 1,
    title: 'Edit',
    type: PlaylistActionButtonsTypes.edit,
  },
  {
    id: 2,
    title: 'Share',
    type: PlaylistActionButtonsTypes.share,
  },
  {
    id: 3,
    title: 'Pitch Playlist',
    type: PlaylistActionButtonsTypes.pitchPlaylist,
  },
  {
    id: 4,
    title: 'Download',
    type: PlaylistActionButtonsTypes.download,
  },
  {
    id: 5,
    title: 'Archive',
    type: PlaylistActionButtonsTypes.archive,
  },
  {
    id: 6,
    title: 'Delete',
    type: PlaylistActionButtonsTypes.delete,
  },
  {
    id: 7,
    title: 'Restore',
    type: PlaylistActionButtonsTypes.restore,
  },
];
