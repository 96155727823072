import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { Button, Dialog } from 'components/Reusable';
import { ButtonVariant } from 'components/Reusable/Button';

import { useDidUpdate } from 'hooks';
import { isEmpty } from 'utils';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import styles from './FilterPopup.module.scss';

export interface FilterPopupProps {
  children?: ((props: UseFormReturn) => React.ReactNode) | React.ReactNode;
  filters: unknown;
  onClose: () => void;
  onSubmit: (values: unknown) => void;
  open: boolean;
}

const cleanupValues = (values: Record<string, unknown>): Record<string, unknown> => {
  const cleanValues = { ...values };
  Object.keys(cleanValues).forEach((k) => {
    isEmpty(cleanValues[k], true) && delete cleanValues[k];
  });
  return cleanValues;
};

const FilterPopup = (props: FilterPopupProps) => {
  const methods = useForm({ defaultValues: props.filters as Record<string, unknown> });
  const onSubmit = (values: Record<string, unknown>) => {
    const cleanValues = cleanupValues(values);
    props.onSubmit(cleanValues);
  };
  useDidUpdate(() => methods.reset(props.filters as Record<string, unknown>), [props.filters]);
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>
        <div className={styles.header}>
          <div className={styles.title}>Filter</div>
          <Button className={styles.closeButton} onClick={props.onClose} variant={ButtonVariant.Text}>
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>{typeof props.children === 'function' ? props.children(methods) : props.children}</DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={() => methods.reset({})} text="Reset" variant={ButtonVariant.Text} />
        <Button
          className={styles.submitButton}
          onClick={methods.handleSubmit(onSubmit)}
          text="Filter"
          variant={ButtonVariant.Primary}
        />
      </DialogActions>
    </Dialog>
  );
};

export default FilterPopup;
