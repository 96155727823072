import { useDispatch } from 'react-redux';
import { Dialog } from '@material-ui/core';

import { useMemoSelector } from 'hooks';
import { getIsOpenMobileMockup, setIsOpenMobileMockup } from 'store';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import XMockup from 'assets/XMockup.png';
import styles from './MobileMockup.module.scss';

const MobileMockup = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpen = useMemoSelector(getIsOpenMobileMockup);

  const closeMobileMockup = () => dispatch(setIsOpenMobileMockup(false));

  return (
    <Dialog
      onClose={closeMobileMockup}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      classes={{ paper: styles.paper }}
      BackdropProps={{ className: styles.dialogBackdrop }}
    >
      <CloseIcon onClick={closeMobileMockup} className={styles.closeBtn} />
      <h3>VIEW IN APP</h3>
      <span>For the full ARROW experience, please download the app</span>
      <img src={XMockup} alt="XMockup" />
      <button className={styles.openBtn}>Open ARROW App</button>
    </Dialog>
  );
};

export default MobileMockup;
