import { useDispatch } from 'react-redux';
import { ColDef } from 'ag-grid-community';

import { CellRendererProps, DataGridColDef } from 'components/UI/DataGrid';

import { useMount } from 'hooks';
import { setAdminControls } from 'store';
import { IUserData } from 'store/reducers/general/types';
import { getUserRoleTitle } from 'utils';

import styles from './AdminControls.module.scss';

import ActionBtnsGroup from './ActionBtnsGroup';
import { ColumnFields } from './data';

interface IExpandedRowProps {
  p: CellRendererProps<DataGridColDef<IUserData>>;
}

const ExpandedRow = ({ p }: IExpandedRowProps) => {
  const dispatch = useDispatch();

  // Note: Here role, territory and default team hidden
  const hiddenColumns = p.api.getColumnDefs().filter((col: ColDef) => col.hide);
  const pData = { ...p.data } as IUserData;

  const getColumnValue = (col: ColDef) => {
    if (col.field === ColumnFields.team) {
      return pData.defaultTeam?.name;
    } else if (col.field === ColumnFields.role) {
      return getUserRoleTitle(pData.role);
    } else if (col.field === ColumnFields.isTeamAdmin) {
      return pData.isTeamAdmin ? 'Yes' : 'No';
    } else {
      return pData[col.field as keyof IUserData];
    }
  };

  useMount(() =>
    dispatch(
      setAdminControls({
        activeEditingUser: {
          data: { ...pData },
          rowId: p.node.id?.replace('detail_', '') as string,
        },
      })
    )
  );

  return (
    <div className={styles.detailRowContainer}>
      <div className={styles.hiddenColItemsContainer}>
        {hiddenColumns.map((col: ColDef) =>
          col.headerName ? (
            <div key={col.headerName} className={styles.hiddenColItemContainer}>
              <div className={styles.hiddenColTitle}>{col.headerName}</div>
              <div className={styles.hiddenColValue}>{getColumnValue(col)}</div>
            </div>
          ) : null
        )}
      </div>
      <ActionBtnsGroup data={pData} className={styles.expandedActionsCell} node={p.node} />
    </div>
  );
};

export default ExpandedRow;
