import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import classNames from 'classnames';

import { defaultAddToPlaylist } from 'components/MusicPlayer/data';
import MarkersContainerMobile from 'components/MusicPlayer/MarkersContainerMobile';
import WaveBar from 'components/MusicPlayer/WaveBar';

import { useClickOutside, useContainerWidth, useMemoSelector } from 'hooks';
import Api from 'services/Api';
import {
  getIsExpandedPlaybar,
  getIsMarkersVisible,
  getIsOpenMobileMockup,
  getIsOpenSearch,
  getRecentEditedSongs,
  getUserPermissions,
  setError,
  setIsExpandedPlaybar,
  setIsOpenMobileMockup,
  setLoading,
  setRecentEditedSongs,
} from 'store';
import { UsageKeys } from 'store/reducers/general/types';
import { DEFAULT_ERROR_CONFIG, findRecentEditedSongById, getGUID, MARKER_WIDTH, MARKERS_LIMIT } from 'utils';

import styles from './MusicPlayer.module.scss';

import { MarkersContainer, PlaybarHeader, PlayerAudio, PlayerNotifications } from '.';
import {
  ActionTypes,
  IActionMarker,
  IAddToPlaylist,
  IFavoriteConfig,
  IMarker,
  MarkerValues,
  MusicPlayerProps,
  PlayerPopups,
  TrackInfo,
} from './IMusicPlayer';
import { IWriterItem } from 'types';

// eslint-disable-next-line react/display-name
const MusicPlayer = React.forwardRef<HTMLAudioElement, MusicPlayerProps>(
  (
    {
      tracks,
      setTracks,
      updateTracks,
      onProgress,
      onTrackChange,
      isExternal,
      isDark,
      addPlayActivity,
      openMobileMarkersView,
      setOpenMobileMarkersView,
    }: MusicPlayerProps,
    ref
  ) => {
    const dispatch = useDispatch();

    const {
      authState: { isAuthenticated },
    } = useOktaAuth();

    const { isOpenMobileMockup, isOpenSearch, isExpandedPlaybar, permissions, isVisibleMarkers, recentEditedSongs } =
      useMemoSelector((state) => ({
        isOpenMobileMockup: getIsOpenMobileMockup(state),
        isOpenSearch: getIsOpenSearch(state),
        isExpandedPlaybar: getIsExpandedPlaybar(state),
        permissions: getUserPermissions(state),
        isVisibleMarkers: getIsMarkersVisible(state),
        recentEditedSongs: getRecentEditedSongs(state),
      }));

    const aRef = useRef<HTMLAudioElement>();
    const [waveRef, waveContainerWidth] = useContainerWidth<HTMLDivElement>();

    const [track, setTrack] = useState<TrackInfo>(tracks[0]);
    const [duration, setDuration] = useState<number>(0);
    const [progress, setProgress] = useState<number>(0);
    const [time, setTime] = useState<number>(0);
    const [markers, setMarkers] = useState<IMarker[]>([]);
    const [openFavoriteSnackbar, setOpenFavoriteSnackbar] = useState<boolean>(false);
    const [favoriteConfig, setFavoriteConfig] = useState<IFavoriteConfig>({ isFavorite: false, animation: '' });
    const [openPopup, setOpenPopup] = useState<PlayerPopups | ''>('');
    const [addToPlaylist, setAddToPlaylist] = useState<IAddToPlaylist>(defaultAddToPlaylist);
    const [shouldAddActivity, setShouldAddActivity] = useState(false);

    const changeOpenPopup = (type: PlayerPopups | '') =>
      setOpenPopup(type ? (openPopup === PlayerPopups[type] ? '' : PlayerPopups[type]) : '');
    const checkIsPopup = (type: PlayerPopups) => openPopup === PlayerPopups[type];

    const popupContainer = useClickOutside((e, element) => {
      const isNotSvgElem = !(e.target instanceof SVGElement);

      if (!isNotSvgElem) return;
      openPopup && changeOpenPopup('');
      element?.className.includes('moreContainer') &&
        isOpenMobileMockup &&
        !isOpenSearch &&
        dispatch(setIsOpenMobileMockup(false));
    });

    useEffect(() => {
      if (!track) return;

      const recentEditedSong = findRecentEditedSongById(recentEditedSongs, track.id);

      if (!recentEditedSongs.length || !recentEditedSong) return;

      const { writers, ...other } = recentEditedSong.update;

      setTrack((prev) => ({
        ...prev,
        ...other,
        ...(writers && {
          writers: writers?.map((item) => (item as IWriterItem).name || ''),
        }),
      }));
      dispatch(setRecentEditedSongs({ usages: [UsageKeys.playbar] }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentEditedSongs, setTrack]);

    useEffect(() => {
      const audio = aRef.current as HTMLAudioElement;

      // Forwarding ref to parent container
      if (ref) {
        typeof ref === 'function' ? ref(audio) : (ref.current = audio);
      }
    }, [ref]);

    useEffect(() => {
      if (!track) return;
      setMarkers(track.markers as IMarker[]);
      setShouldAddActivity(!!isAuthenticated);
    }, [track, isAuthenticated]);

    useEffect(() => {
      if (shouldAddActivity && time > 10) {
        setShouldAddActivity(false);
        addPlayActivity && addPlayActivity();
      }
    }, [addPlayActivity, shouldAddActivity, time]);

    const trackSrc = useMemo(() => {
      return track?.presignForPlayback || (track?.playbackPath && `/audios/${track?.playbackPath}`);
    }, [track]);

    const findOverlapped = (currentXAxisPercent: number, markerId?: string) => {
      const currentXAxisPx = (currentXAxisPercent * (waveContainerWidth || 0)) / 100;

      return markers.find(({ xAxisPercent, id }) => {
        const elXAxis = (xAxisPercent * (waveContainerWidth || 0)) / 100;
        return currentXAxisPx <= elXAxis + MARKER_WIDTH && currentXAxisPx >= elXAxis - MARKER_WIDTH && id !== markerId;
      });
    };

    const updateCurrentTrack = useCallback(
      (newMarkers: IMarker[]) => {
        setTracks &&
          setTracks((prevState) => prevState.map((el) => (el.id === track?.id ? { ...el, markers: newMarkers } : el)));

        setMarkers(newMarkers);
      },
      [setTracks, track?.id]
    );

    const actionMarker = async ({ actionType, type, value, marker }: IActionMarker) => {
      const { add, edit, remove } = ActionTypes;

      const overlappedMarker = findOverlapped(
        type === MarkerValues.xAxisPercent ? Number(value) : progress,
        marker?.id
      );

      switch (actionType) {
        case add: {
          if (!value) return;

          changeOpenPopup('');

          if (markers.length >= MARKERS_LIMIT) {
            dispatch(
              setError({
                title: '',
                text: 'Maximum marker limit has been reached. Please remove existing markers before adding new ones',
                isOpen: true,
              })
            );
            return;
          }

          dispatch(setLoading(true));

          const res = await Api.addMarkerToSong(
            {
              title: value as string,
              markerTime: Math.round((progress * track.duration) / 100),
              mediaId: track.id,
            },
            {
              errorPopupConfig: DEFAULT_ERROR_CONFIG,
              handler: (details, status) => {
                if (status === 400 && details?.errorCode === '4806') {
                  dispatch(
                    setError({
                      isOpen: true,
                      title: 'Duplicate marker time',
                      text: 'There is already a marker at this timecode. The existing marker must be removed before adding a new one to this specific time in the song.',
                    })
                  );
                }
              },
            }
          );

          if (!res) {
            dispatch(setLoading(false));
            return;
          }

          const newMarkerTime = Math.round((progress * track.duration) / 100);

          const newMarkers = [
            ...markers.filter((marker) => marker.markerTime !== newMarkerTime),
            {
              title: value as string,
              xAxisPercent: progress,
              id: res.id,
              markerTime: newMarkerTime,
            },
          ];

          updateCurrentTrack(newMarkers);
          dispatch(setLoading(false));

          break;
        }
        case edit: {
          if (!marker) return;

          const isDragged = typeof value === 'number';
          const oldValue = isDragged ? marker.xAxisPercent : marker.title;

          if ((!value && !isDragged) || value === oldValue || overlappedMarker) return;

          dispatch(setLoading(true));

          const newMarkers = markers.map((elem) => {
            if (elem.id === marker.id) {
              return {
                ...elem,
                [type as string]: value,
                markerTime: isDragged ? Math.round((value * track.duration) / 100) : elem.markerTime,
              };
            }

            return elem;
          });

          updateCurrentTrack(newMarkers);

          const res = await Api.updateMarkerOfSong(
            {
              title: (isDragged ? marker.title : value) as string,
              markerTime: isDragged ? Math.round((value * track.duration) / 100) : marker.markerTime,
              mediaId: track.id,
            },
            { pathId: marker.id, errorPopupConfig: DEFAULT_ERROR_CONFIG }
          );

          if (!res) {
            const prevMarkers = markers.map((elem) => {
              if (elem.id === marker.id) {
                return {
                  ...elem,
                  [type as string]: oldValue,
                };
              }

              return elem;
            });

            updateCurrentTrack(prevMarkers);
            dispatch(setLoading(false));
          }

          dispatch(setLoading(false));
          break;
        }
        case remove: {
          if (!marker) return;

          dispatch(setLoading(true));

          const res = await Api.removeMarkerFromSong({
            pathId: marker.id,
            errorPopupConfig: DEFAULT_ERROR_CONFIG,
            handler: (details, status) => {
              if (status === 400 && details?.errorCode === '4701') {
                const newMarkers = markers.filter((elem) => elem.id !== marker.id);

                updateCurrentTrack(newMarkers);
              }
            },
          });

          if (!res) {
            dispatch(setLoading(false));
            return;
          }

          const newMarkers = markers.filter((elem) => elem.id !== marker.id);

          updateCurrentTrack(newMarkers);
          dispatch(setLoading(false));

          break;
        }
        default:
          break;
      }
    };

    const toggleViewMode = () => {
      setOpenMobileMarkersView && setOpenMobileMarkersView(!openMobileMarkersView);
    };

    const onMobileMarkerPlayBtnClick = (markerTime: number) => {
      if (!aRef?.current) return;

      aRef.current.currentTime = markerTime;
    };

    return (
      <>
        <div
          className={classNames(styles.musicPlayer, {
            [styles.expanded]: isExpandedPlaybar,
            [styles.darkMusicPlayer]: isDark,
            [styles.externalMusicPlayer]: isExternal,
            [styles.withMarginBottom]: isVisibleMarkers && !openMobileMarkersView,
          })}
        >
          <div onClick={() => dispatch(setIsExpandedPlaybar(false))} className={styles.swipeDownBtn}>
            <div />
          </div>
          <PlayerNotifications
            openFavoriteSnackbar={openFavoriteSnackbar}
            setOpenFavoriteSnackbar={setOpenFavoriteSnackbar}
            isFavorite={favoriteConfig.isFavorite}
            addToPlaylist={addToPlaylist}
            setAddToPlaylist={setAddToPlaylist}
          />
          <PlayerAudio aRef={aRef} trackPath={trackSrc} />
          <PlaybarHeader
            updateTracks={updateTracks}
            favoriteConfig={favoriteConfig}
            setFavoriteConfig={setFavoriteConfig}
            aRef={aRef}
            track={track}
            setTrack={setTrack}
            setDuration={setDuration}
            setProgress={setProgress}
            setTime={setTime}
            tracks={tracks}
            openFavoriteSnackbar={openFavoriteSnackbar}
            setOpenFavoriteSnackbar={setOpenFavoriteSnackbar}
            onProgress={onProgress}
            onTrackChange={onTrackChange}
            actionMarker={actionMarker}
            changeOpenPopup={changeOpenPopup}
            popupContainerRef={popupContainer}
            checkIsPopup={checkIsPopup}
            isExpanded={isExpandedPlaybar}
            isExternal={isExternal}
            isDark={isDark}
            setAddToPlaylist={setAddToPlaylist}
          />
          <WaveBar
            track={track}
            waveRef={waveRef}
            progress={progress}
            duration={duration}
            aRef={aRef}
            time={time}
            isExpanded={isExpandedPlaybar}
            peeks={track?.peeks || []}
          />
        </div>
        <MarkersContainer
          waveContainerWidth={waveContainerWidth}
          markers={[
            ...markers,
            ...(openPopup === 'marker'
              ? [
                  {
                    title: 'New Marker',
                    xAxisPercent: progress,
                    id: getGUID(),
                    markerTime: Math.round((progress * track.duration) / 100),
                    disabled: true,
                  },
                ]
              : []),
          ]}
          actionMarker={actionMarker}
          isExternal={isExternal || !permissions.playbarAddMarker}
        />
        <MarkersContainerMobile
          markers={markers}
          openMobileMarkersView={openMobileMarkersView}
          toggleViewMode={toggleViewMode}
          onMobileMarkerPlayBtnClick={onMobileMarkerPlayBtnClick}
        />
      </>
    );
  }
);

export default MusicPlayer;
