import React, { forwardRef, KeyboardEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AutocompleteGetTagProps, AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import classNames from 'classnames';

import { useDidUpdate, useIsMounted } from 'hooks';

import { ReactComponent as CloseIcon } from 'assets/close-2.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import styles from './InputAsyncAutocomplete.module.scss';

import { IAutocompleteOption } from 'types';

export interface IInputAutocompleteOption {
  id: number | string;
  title: string;
}

export interface IInputAsyncAutocompleteProps {
  addNewButtonOnClick?: (inputValue: string) => void;
  addNewButtonText?: string | JSX.Element;
  addNotFoundedOptionText?: string | JSX.Element;
  addNotFoundedOptionIndefiniteArticle?: 'a' | 'an';
  disabled?: boolean;
  error?: boolean;
  label?: string | JSX.Element;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  multiple?: boolean;
  className?: string;
  inputBaseClassName?: string;
  labelRootClassName?: string;
  labelClassName?: string;
  autocompleteClassName?: string;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChange?: (
    event: React.ChangeEvent<{ name?: string; value?: IInputAutocompleteOption | IInputAutocompleteOption[] | null }>,
    value: IInputAutocompleteOption | IInputAutocompleteOption[] | null
  ) => void;
  onResolveSuggestions?: (text: string) => Promise<IInputAutocompleteOption[]>;
  options?: IInputAutocompleteOption[];
  placeholder?: string;
  required?: boolean;
  value?: IInputAutocompleteOption | IInputAutocompleteOption[] | null;
  disableCloseOnSelect?: boolean;
  disablePortal?: boolean;
  renderOption?: (option: IAutocompleteOption, state: AutocompleteRenderOptionState) => JSX.Element;
  renderTags?: (value: IAutocompleteOption[], getTagProps: AutocompleteGetTagProps) => ReactNode;
  disableClearable?: boolean;
  addCustomOption?: boolean;
}

const useDebounce = <T,>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useDidUpdate(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};

const InputAsyncAutocomplete = forwardRef((props: IInputAsyncAutocompleteProps, ref) => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IInputAutocompleteOption[]>([]);
  const [value, setValue] = useState(props.value || (props.multiple ? [] : null));
  const debouncedInputValue = useDebounce(inputValue, 1000);

  const LoadingOrSearchIcon = useMemo(
    () =>
      loading ? (
        <CircularProgress data-testid="loading-indicator" color="inherit" size={20} />
      ) : !inputValue && !(value as IAutocompleteOption[])?.length ? (
        <SearchIcon className={styles.searchIcon} />
      ) : null,
    [inputValue, loading, value]
  );

  // Note: don't open options when there is a value
  useEffect(() => {
    if (value) {
      setOpen(false);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = useCallback(
    (e: React.ChangeEvent<Record<string, never>>, v: IInputAutocompleteOption | IInputAutocompleteOption[] | null) => {
      const patchedEvent = { ...e, target: { ...e.target, name: props.name, value: v } };
      setValue(v);
      props.onChange && props.onChange(patchedEvent, v);
    },
    [props]
  );
  const onInputChange = (_: React.ChangeEvent<Record<string, never>>, v: string) => {
    setInputValue(v);
    if (!props.options && v) {
      if (!loading) setLoading(true);
      if (!open) setOpen(true);
    }
    if (!v && loading) {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Tab' || e.key === 'Enter') {
        e.preventDefault();
        const trimmedInputValue = inputValue.trim();

        if (!!trimmedInputValue && Array.isArray(value) && !value.some((e) => e.title === trimmedInputValue)) {
          onChange &&
            onChange(e as unknown as React.ChangeEvent<Record<string, never>>, [
              ...value,
              { title: trimmedInputValue, id: Date.now() },
            ]);
          setInputValue('');
        }
      }
    },
    [inputValue, onChange, value]
  );

  useDidUpdate(() => setValue(props.value || (props.multiple ? [] : null)), [props.value]);
  useDidUpdate(() => {
    if (props.options) return;
    if (debouncedInputValue) {
      (async () => {
        try {
          const suggestions = props.onResolveSuggestions && (await props.onResolveSuggestions(debouncedInputValue));
          if (isMounted()) {
            setOptions(suggestions || []);
          }
        } catch (e) {
          setOptions([]);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      !props.disableCloseOnSelect && setOpen(false);
      setLoading(false);
    }
  }, [debouncedInputValue]);

  const onAddNewButtonOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!props.addNotFoundedOptionText) {
      e.preventDefault();
    }
    props.addNewButtonOnClick && props.addNewButtonOnClick(inputValue);
  };

  const PaperWithButton = (paperProps: PaperProps) => {
    const showAddNewBtn = props.addNewButtonText && !props.addNotFoundedOptionText && !loading;
    const showAddNotFoundedOptionBtn = props.addNotFoundedOptionText && !loading && !options.length;

    return (
      <Paper {...paperProps} square={true}>
        {paperProps.children}
        {!!(showAddNewBtn || showAddNotFoundedOptionBtn) && (
          <>
            <hr className={styles.separator} />
            <button className={styles.addNewButton} onMouseDown={onAddNewButtonOnClick}>
              {showAddNewBtn
                ? props.addNewButtonText
                : `Add “${inputValue}” as ${props.addNotFoundedOptionIndefiniteArticle || 'a'} ${
                    props.addNotFoundedOptionText
                  }`}
            </button>
          </>
        )}
      </Paper>
    );
  };

  return (
    <FormControlLabel
      classes={{
        root: props.labelRootClassName,
        label: classNames(styles.label, props.labelClassName),
        labelPlacementTop: styles.labelPlacementTop,
      }}
      className={props.className}
      control={
        <Autocomplete
          ChipProps={{
            classes: { root: styles.chipRoot },
            deleteIcon: <CloseIcon />,
          }}
          disableClearable={props.disableClearable}
          classes={{
            clearIndicator: styles.clearIndicator,
            paper: styles.paper,
            inputRoot: props.inputBaseClassName,
            popupIndicatorOpen: classNames({ [styles.popupIndicatorOpen]: !props.options }),
            popupIndicator: styles.popupIndicator,
          }}
          className={classNames(styles.autocomplete, props.autocompleteClassName)}
          filterOptions={props.options ? undefined : (options) => options}
          getOptionLabel={(option) => option?.title || option?.name || ''}
          renderTags={props.renderTags}
          getOptionSelected={(option, value) =>
            props.addCustomOption && option?.title && value?.title
              ? option.title?.toLowerCase() === value.title?.toLowerCase()
              : option.id
              ? option.id === value.id
              : option.title?.toLowerCase() === value.title?.toLowerCase()
          }
          inputValue={inputValue}
          loading={loading && !props.options}
          multiple={props.multiple}
          noOptionsText="Nothing found"
          onBlur={props.onBlur}
          onChange={onChange}
          onClose={() => setOpen(false)}
          onInputChange={onInputChange}
          onOpen={props.options ? () => setOpen(true) : undefined}
          open={open}
          options={props.options || (loading ? [] : options)}
          PaperComponent={PaperWithButton}
          popupIcon={
            <>
              {!props.options && LoadingOrSearchIcon}
              {props.required && (
                <div
                  className={classNames(styles.requiredIcon, {
                    [styles.errorColor]: props.error,
                  })}
                />
              )}
            </>
          }
          disableCloseOnSelect={props.disableCloseOnSelect}
          disablePortal={props.disablePortal}
          renderOption={props.renderOption}
          renderInput={(params) => (
            <TextField
              onKeyDown={props.addCustomOption ? handleKeyDown : undefined}
              {...params}
              inputRef={ref}
              error={props.error}
              name={props.name}
              placeholder={!value || (Array.isArray(value) && value.length === 0) ? props.placeholder : undefined}
              variant="outlined"
            />
          )}
        />
      }
      disabled={props.disabled}
      label={props.label}
      labelPlacement={props.labelPlacement || 'top'}
      value={value}
    />
  );
});

export default InputAsyncAutocomplete;
