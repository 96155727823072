// https://onetrust.wminewmedia.com/code.php?url=warnerchappell.com&ot=363a2072-dc6c-4f93-8d3d-255778c71a20&lang=&plang=&plbl=0&sublbl=&artist=&sitetype=Site&pltf=&dvt=0
// https://www.analyticsmania.com/post/gdpr-cookie-consent-notification-with-google-tag-manager/

import { useEffect } from 'react';

import { cookiesPolicyUrl, oneTrustDomainId, oneTrustStyleUrl } from 'utils';

import { optanon } from './optanon';

export interface OneTrustProps {
  domainId: string; // 63a2072-dc6c-4f93-8d3d-255778c71a20
  cookiesPolicyUrl: string; // https://www.wminewmedia.com/cookies-policy
  cssStylesUrl?: string; // https://www.wminewmedia.com/cookies-policy/onetrust/ot.css
}

const OneTrust = (props: OneTrustProps) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const oneTrustEl = document.getElementById('onetrustcdn');
      if (oneTrustEl) {
        // Force OneTrust events on partial page transition
        oneTrustEl.remove();
        const bannerSdks = document.querySelectorAll('script[src$="otBannerSdk.js"]');
        bannerSdks.forEach((el) => el.remove());
      }

      // Remove OneTrust SDK element
      const otPlaceholder = document.getElementById('onetrust-consent-sdk');
      if (otPlaceholder) {
        otPlaceholder.remove();
      }

      // Add OneTrust script
      const scriptEl = document.createElement('script');
      scriptEl.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
      scriptEl.setAttribute('id', 'onetrustcdn');
      scriptEl.setAttribute('charset', 'UTF-8');
      scriptEl.setAttribute('data-document-language', 'true');
      scriptEl.setAttribute('data-domain-script', props.domainId);
      document.body.appendChild(scriptEl);

      // Define OptanonWrapper functions
      // @ts-ignore
      window['OptanonWrapper'] = optanon(props.cookiesPolicyUrl);
      // @ts-ignore
      window['OptanonWrapper']();

      // Add styles element
      if (props.cssStylesUrl && !document.getElementById('onetrust-styles')) {
        const styleEl = document.createElement('style');
        styleEl.setAttribute('id', 'onetrust-styles');
        styleEl.setAttribute('href', props.cssStylesUrl);
        document.head.appendChild(styleEl);
      }
    }
  }, [props]);
  return null;
};

const withSettings = (Component: typeof OneTrust) => {
  // eslint-disable-next-line react/display-name
  if (!oneTrustDomainId) return () => null;
  // eslint-disable-next-line react/display-name
  return () => (
    <Component domainId={oneTrustDomainId} cookiesPolicyUrl={cookiesPolicyUrl} cssStylesUrl={oneTrustStyleUrl} />
  );
};

export default withSettings(OneTrust);

// <!-- OneTrust Cookies Consent Notice start -->
// <script id="onetrustcdn" src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="363a2072-dc6c-4f93-8d3d-255778c71a20-test"></script>
// <script type="text/javascript">
// function OptanonWrapper() {
//    var allLinks = document.querySelectorAll('a[href*="https://www.wminewmedia.com/cookies-policy/"]:not([href*="?ot="])');
//    for (i = 0; i < allLinks.length; i++) {
//       var href = allLinks[i].href;
//       href = href + '?ot=' + document.getElementById('onetrustcdn').getAttribute('data-domain-script') + '&url=' + window.location.hostname;
//       allLinks[i].setAttribute("href", href);
//       allLinks[i].setAttribute("target", "_blank");
//    }
//    var eOT = new Event("OneTrustGroupsUpdated");
//    document.dispatchEvent(eOT);
// }
// </script>
// <link rel="stylesheet" type="text/css" href="https://www.wminewmedia.com/cookies-policy/onetrust/ot.css">
// <!-- OneTrust Cookies Consent Notice end -->
