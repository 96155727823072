import { useDispatch } from 'react-redux';
import { Dialog } from '@material-ui/core';
import classNames from 'classnames';

import { useMemoSelector } from 'hooks';
import { getError, setError } from 'store';
import { INITIAL_ERROR_POPUP_CONFIG } from 'utils';

import styles from './ErrorPopup.module.scss';

const ErrorPopup = () => {
  const dispatch = useDispatch();

  const { isOpen, title, text, containerId, btnText = 'Go Back', onClick } = useMemoSelector(getError);

  const handleCloseErrorPopup = () => {
    dispatch(setError(INITIAL_ERROR_POPUP_CONFIG));
  };

  const handleBtnClick = () => {
    handleCloseErrorPopup();
    onClick && onClick();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseErrorPopup}
      // Note: styles.hideDialog because after close dialog moved to body, and prevented all clicks of page, need to check later
      classes={{ paper: styles.paper, root: classNames(styles.dialog, { [styles.hideDialog]: !isOpen }) }}
      BackdropProps={{ className: styles.dialogBackdrop }}
      container={document.getElementById(containerId || '')}
    >
      <span className={styles.errorTitle}>{title}</span>
      <span className={styles.errorText} dangerouslySetInnerHTML={{ __html: text }} />
      <button className={styles.goBackBtn} onClick={handleBtnClick}>
        {btnText}
      </button>
    </Dialog>
  );
};

export default ErrorPopup;
