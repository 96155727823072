import { useEffect } from 'react';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useWindowSize } from 'hooks';
import { setIsMarkersVisible } from 'store';
import { getIsMobile } from 'utils';

import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import styles from './MusicPlayer.module.scss';

import { ActionTypes, IActionMarker, IMarker, MarkerValues } from './IMusicPlayer';

interface IMarkersContainerProps {
  markers: IMarker[];
  waveContainerWidth: number;
  actionMarker: (props: IActionMarker) => void;
  isExternal?: boolean;
}

const MarkersContainer = ({ markers, waveContainerWidth, actionMarker, isExternal }: IMarkersContainerProps) => {
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    dispatch(setIsMarkersVisible(!!markers.length));
  }, [markers.length, dispatch]);

  return markers.length && !getIsMobile(windowWidth) ? (
    <div className={classNames(styles.markersContainer, { [styles.disableCursor]: isExternal })}>
      {markers.map((item, index) => (
        <Draggable
          disabled={isExternal}
          key={index}
          handle="#drag-handler"
          axis="x"
          positionOffset={{ x: '-50%', y: 0 }}
          bounds={{ left: 0, right: waveContainerWidth || 0 }}
          position={{ x: (item.xAxisPercent * (waveContainerWidth || 0)) / 100, y: 0 }}
          onStop={(_, data) =>
            actionMarker({
              actionType: ActionTypes.edit,
              value: (data.x / (waveContainerWidth || 0)) * 100,
              marker: item,
              type: MarkerValues.xAxisPercent,
            })
          }
        >
          <div
            className={classNames(styles.markerItem, {
              [styles.disabledMarker]: item.disabled,
            })}
          >
            <div className={styles.markerCircle} id="drag-handler" />
            <div className={styles.markerTitleContainer}>
              <div
                className={styles.markerTitle}
                contentEditable={!isExternal}
                suppressContentEditableWarning
                onBlur={(e) =>
                  actionMarker({
                    actionType: ActionTypes.edit,
                    value: (e.target as HTMLDivElement).textContent as string,
                    marker: item,
                    type: MarkerValues.title,
                  })
                }
              >
                {item.title}
              </div>
              {!isExternal && (
                <DeleteIcon
                  className={styles.deleteIcon}
                  onClick={() => actionMarker({ actionType: ActionTypes.remove, marker: item })}
                />
              )}
            </div>
          </div>
        </Draggable>
      ))}
    </div>
  ) : null;
};

export default MarkersContainer;
