import React from 'react';
import { GridApi } from 'ag-grid-community';

import { useMemoSelector } from 'hooks';
import { getActiveTrack } from 'store';

import { ReactComponent as PlayCircleIcon } from 'assets/play-circle.svg';
import { ReactComponent as WaveformIcon } from 'assets/waveform.svg';

import { IExternalMedia, playSong } from './data';

interface IPlayIconCellProps {
  api: GridApi;
  data?: IExternalMedia;
}

const PlayIconCell = ({ api, data }: IPlayIconCellProps) => {
  const activeTrack = useMemoSelector(getActiveTrack);

  return data?.id && [activeTrack.mediaId, activeTrack.songId].includes(data?.id) ? (
    <WaveformIcon style={{ pointerEvents: 'none' }} />
  ) : (
    <PlayCircleIcon data-testid="play-icon-cell" onClick={() => playSong({ api, data })} />
  );
};

export default PlayIconCell;
