import { getSelectors, getUserId, getUserRole, getUserTeamName, getUserTerritory } from 'store';

import { DigitalData } from './AdobeLaunch.types';

export const getDefaultData = (): DigitalData => {
  const { userId, userTeams, userRole, userTerritory } = getSelectors((state) => ({
    userId: getUserId(state),
    userTeams: getUserTeamName(state),
    userRole: getUserRole(state),
    userTerritory: getUserTerritory(state),
  }));
  return {
    settings: {
      reportSuites: 'wmg',
    },
    page: {
      pageInfo: {
        server: 'WCM Arrow:Site',
        platform: '',
        devTeam: 'Warner Chappell',
      },
    },
    content: {
      artist: 'WCM Arrow',
      label: 'Warner Chappell',
    },
    user: {
      profile: {
        profileID: userId,
        userTeams: userTeams as string,
        userRole: userRole,
        userTerritory: userTerritory as string,
      },
    },
  };
};
