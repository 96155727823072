import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';

import { Dialog, DoubleBtnGroup } from 'components/Reusable';
import { NewTextField as TextField } from 'components/UI';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getUserTeamId } from 'store';
import { debouncePromise, DEFAULT_ERROR_CONFIG, isEmpty, isValidEmail } from 'utils';

import styles from './Contacts.module.scss';

export interface IContact {
  createdBy?: string;
  createdOn?: Date;
  email?: string;
  id?: string;
  name?: string;
  organizationName?: string;
  phone?: string;
  role?: string;
  team?: string;
}

export interface IContactsFormProps {
  item: IContact;
  onClose?: () => void;
  onSubmit?: (item: IContact) => void;
}

const ContactsForm = ({ item, onClose, onSubmit }: IContactsFormProps) => {
  const teamId = useMemoSelector(getUserTeamId) || '';
  const methods = useForm<IContact>({ defaultValues: item, mode: 'all' });
  const submitForm = async (values: IContact) => {
    const result = await Api.addOrUpdateContact(values, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    result && onSubmit && onSubmit(result);
  };
  return (
    <Dialog classes={{ paper: styles.formPaper }} onClose={onClose} open>
      <DialogTitle className={styles.formTitle}>{item?.id ? 'Edit Contact' : 'Create Contact'}</DialogTitle>
      <DialogContent className={styles.formContent}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              control={methods.control}
              name="name"
              render={({ field, fieldState: { error }, formState }) => (
                <TextField
                  {...field}
                  className={styles.control}
                  disabled={formState.isSubmitting}
                  error={error?.message}
                  highlightValid
                  label="Name"
                  required
                />
              )}
              rules={{ validate: (v) => (isEmpty(v, true) ? 'Required Field.' : undefined) }}
            />
            <Controller
              control={methods.control}
              name="organizationName"
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  className={styles.control}
                  disabled={formState.isSubmitting}
                  label="Organization"
                />
              )}
            />
            <Controller
              control={methods.control}
              name="phone"
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  className={styles.control}
                  disabled={formState.isSubmitting}
                  label="Phone"
                  type="tel"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={methods.control}
              name="email"
              render={({ field, fieldState: { error }, formState }) => (
                <TextField
                  {...field}
                  className={styles.control}
                  disabled={formState.isSubmitting}
                  error={error?.message}
                  highlightValid
                  label="Email"
                  required
                  type="email"
                />
              )}
              rules={{
                validate: async (v) => {
                  if (isEmpty(v, true)) return 'Required Field.';
                  if (!isValidEmail(v as string)) return 'Email is not valid';
                  return await debouncePromise(
                    () =>
                      Api.getContactByEmail(v as string, teamId, { errorPopupConfig: DEFAULT_ERROR_CONFIG }).then(
                        (contact) => (contact && contact.id !== item.id ? 'Email is already added' : undefined)
                      ),
                    'contacts-form-email'
                  );
                },
              }}
            />
            <Controller
              control={methods.control}
              name="role"
              render={({ field, formState }) => (
                <TextField {...field} className={styles.control} disabled={formState.isSubmitting} label="Role" />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.formActions}>
        <DoubleBtnGroup
          disabled={!methods.formState.isValid || !methods.formState.isDirty}
          isPending={methods.formState.isSubmitting}
          name1="Cancel"
          name2={item?.id ? 'Save' : 'Create'}
          onClick1={onClose}
          onClick2={methods.handleSubmit(submitForm)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ContactsForm;
