import { Dispatch, FunctionComponent, SetStateAction, SVGProps } from 'react';
import { useDispatch } from 'react-redux';

import { Snackbar } from 'components/Reusable';

import { setMainNotification } from 'store';

import styles from './NotificationPopup.module.scss';

interface INotificationPopupProps {
  isOpen: boolean;
  text: string;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  hideDuration?: number;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  message?: JSX.Element;
}

const NotificationPopup = ({
  isOpen,
  setIsOpen,
  Icon,
  text,
  hideDuration = 2000,
  message,
}: INotificationPopupProps) => {
  const dispatch = useDispatch();

  return (
    <Snackbar
      containerClassName={styles.successSnackbarContainer}
      contentClassName={styles.successSnackbarContent}
      open={isOpen}
      onClose={() => (setIsOpen ? setIsOpen(false) : dispatch(setMainNotification('')))}
      autoHideDuration={hideDuration}
      message={
        message || (
          <div className={styles.successSnackbar}>
            {Icon && <Icon />}
            <span className={styles.successText} dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        )
      }
    />
  );
};

export default NotificationPopup;
