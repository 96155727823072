import React from 'react';
import { batch } from 'react-redux';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { PitchesStatusCell } from 'components/Pitches';
import { DataGridColDef } from 'components/UI/DataGrid';

import { dispatch, setActiveTrack, setCurrentTracks } from 'store';
import { getDataGridItems, getSongVersion, immediatelyPlayAudio } from 'utils';

import { ReactComponent as PlayCircleIcon } from 'assets/play-circle.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './PitchDetails.module.scss';

import { ActionsCell, PlayIconCell, ResultCell, SongTitleCell } from './';
import { IPitchContact } from 'types';

export enum IPitchDetailsFields {
  id = 'id',
  status = 'Status',
  pitchType = 'Pitch Type',
  pitchedTo = 'Pitched To',
  pitchedBy = 'Pitched By',
  sentDate = 'Sent Date',
  expiryDate = 'Expiry Date',
  team = 'Team',
  views = 'Views',
  downloadable = 'Downloadable',
}

export interface IPitchMedia {
  id: null | string;
  result: null | string;
  title: string;
  version: string;
  workId: string;
  writers: null | string[];
  mediaPath: string;
  mediaPlaybackPath: string;
  workCode: string;
}

export interface IPitchActivity {
  id: string;
  title: string;
  contact: string;
  action: string;
  workId: string;
  date: string;
}

export interface IPitchDetailsData {
  id: string;
  title: string;
  expireDate: string;
  sentDate: string;
  message: string;
  status: string;
  type: string;
  teamName: string;
  publicCode: string;
  isDownloadable: boolean;
  // artist: string;
  // label: string;
  pitchedBy: string;
  viewCount: number;
  medias: IPitchMedia[];
  contacts: IPitchContact[];
  activities: IPitchActivity[];
}

export enum PitchSongsColumnsFields {
  title = 'title',
  writers = 'writers',
  version = 'version',
  songId = 'workCode',
  result = 'result',
  actions = 'actions',
}

export enum PitchSongColumnHeaderNames {
  title = 'Song Title',
  writers = 'Writer(s)',
  version = 'Version',
  songId = 'ID',
  result = 'Result',
  actions = 'Actions',
  empty = '',
}

export const playSong = (api: GridApi, data?: IPitchMedia, isFromHeader = false) => {
  const items = getDataGridItems(api) as IPitchMedia[];

  batch(() => {
    dispatch(
      setCurrentTracks(
        items.map((item) => ({
          mediaId: item.id || '',
          id: item.workId,
          title: item.title || '',
          path: item.mediaPath || '',
          playbackPath: item.mediaPlaybackPath || '',
          writers: item.writers || [],
          version: item.version || '',
        }))
      )
    );
    dispatch(setActiveTrack({ index: isFromHeader ? 0 : items.findIndex((el) => el.workId === data?.workId) }));
  });

  immediatelyPlayAudio();
};

export const hiddenPitchSongsColumnsTablet: PitchSongsColumnsFields[] = [
  PitchSongsColumnsFields.version,
  PitchSongsColumnsFields.songId,
  PitchSongsColumnsFields.result,
];

export const hiddenPitchSongsColumnsMobile: PitchSongsColumnsFields[] = [
  ...hiddenPitchSongsColumnsTablet,
  PitchSongsColumnsFields.writers,
];

export function getPitchSongsColumns(isManualPitch = false, hideActions: boolean): DataGridColDef<IPitchMedia>[] {
  const columns: DataGridColDef<IPitchMedia>[] = [
    {
      field: PitchSongColumnHeaderNames.empty,
      headerName: PitchSongColumnHeaderNames.empty,
      headerComponentFramework: ({ api }: { api: GridApi }) => (
        <PlayCircleIcon onClick={() => playSong(api, undefined, true)} />
      ),
      cellRendererFramework: ({ api, data }) => <PlayIconCell data={data} api={api} />,
      cellClass: styles.playIcon,
      headerClass: styles.playIcon,
      maxWidth: 50,
    },
    {
      field: PitchSongsColumnsFields.title,
      headerName: PitchSongColumnHeaderNames.title,
      headerClass: globalStyles.f13h16SuisseSB_grayDarker,
      cellClass: classNames(globalStyles.f13h16SuisseSB_grayDarker, styles.songTitleCell),
      cellRendererFramework: ({ data }) => (
        <SongTitleCell id={data?.id} title={data?.title} writers={data?.writers} version={data?.version} />
      ),
    },
    {
      field: PitchSongsColumnsFields.writers,
      headerName: PitchSongColumnHeaderNames.writers,
      headerClass: globalStyles.f13h16SuisseSB_grayDarker,
      cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    },
    {
      field: PitchSongsColumnsFields.version,
      headerName: PitchSongColumnHeaderNames.version,
      headerClass: globalStyles.f13h16SuisseSB_grayDarker,
      cellClass: globalStyles.f13h16SuisseREG_grayDarker,
      valueGetter: ({ data }) => getSongVersion(data?.version),
      width: 100,
      maxWidth: 100,
    },
    {
      field: PitchSongsColumnsFields.songId,
      headerName: PitchSongColumnHeaderNames.songId,
      headerClass: globalStyles.f13h16SuisseSB_grayDarker,
      cellClass: globalStyles.f13h16SuisseREG_grayDarker,
      valueGetter: ({ data }) => data?.workCode?.split(' ')[0],
      width: 80,
      maxWidth: 80,
    },
  ];

  if (isManualPitch) {
    columns.push({
      field: PitchSongsColumnsFields.result,
      headerName: PitchSongColumnHeaderNames.result,
      headerClass: globalStyles.f13h16SuisseSB_grayDarker,
      cellClass: styles.resultCell,
      cellRendererFramework: ({ data, api, node }) => (
        <ResultCell result={data?.result} api={api} node={node} mediaId={data?.id} isReadOnly={hideActions} />
      ),
      width: 110,
      maxWidth: 110,
    });
  }

  columns.push({
    field: PitchSongsColumnsFields.actions,
    headerName: '',
    width: 92,
    maxWidth: 92,
    suppressColumnsToolPanel: true,
    cellClass: 'actionsCell',
    cellRendererFramework: ({ data, node }) => (
      <ActionsCell isManualPitch={isManualPitch} node={node} id={data?.id} title={data?.title} />
    ),
    hide: hideActions,
  });

  return columns;
}

export const resultData = [
  { id: 1, title: 'Pass' },
  { id: 2, title: 'Keep' },
];

export enum PitchActivitiesColumnsFields {
  title = 'title',
  contact = 'contact',
  action = 'action',
  songId = 'songId',
  date = 'date',
}

export type IPitchActivitiesData = {
  action: string;
  contact: null | string;
  date: null | string;
  id: null | string;
  title: null | string;
  workId: null | string;
};

export enum PitchActivitiesColumnHeaderNames {
  title = 'Song Title',
  contact = 'Contact',
  action = 'Action',
  songId = 'ID',
  date = 'Date',
}

export const pitchActivitiesColumns: DataGridColDef<IPitchActivitiesData>[] = [
  {
    field: PitchActivitiesColumnsFields.title,
    headerName: PitchActivitiesColumnHeaderNames.title,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseSB_grayDarker,
  },
  {
    field: PitchActivitiesColumnsFields.contact,
    headerName: PitchActivitiesColumnHeaderNames.contact,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
  },
  {
    field: PitchActivitiesColumnsFields.action,
    headerName: PitchActivitiesColumnHeaderNames.action,
    cellRendererFramework: ({ data }) => <PitchesStatusCell status={data?.action} />,
  },
  {
    field: PitchActivitiesColumnsFields.songId,
    headerName: PitchActivitiesColumnHeaderNames.songId,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
  },
  {
    field: PitchActivitiesColumnsFields.date,
    headerName: PitchActivitiesColumnHeaderNames.date,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
  },
];

export const hiddenPitchActivitiesColumnsTablet: PitchActivitiesColumnsFields[] = [
  PitchActivitiesColumnsFields.songId,
  PitchActivitiesColumnsFields.date,
];

export const hiddenPitchActivitiesColumnsMobile: PitchActivitiesColumnsFields[] = [
  ...hiddenPitchActivitiesColumnsTablet,
  PitchActivitiesColumnsFields.contact,
];
