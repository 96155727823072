import { MouseEvent, useMemo } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';

// import { SwitchBtn, Tooltip } from 'components/UI/index';
import { useMemoSelector } from 'hooks';
import { getUserPermissions } from 'store';
import { getIsMobile } from 'utils';

// import { ReactComponent as InfoIcon } from 'assets/info.svg';
import styles from './SongMoreMenu.module.scss';

import { ActionButtonsTypes, ISongMoreButton } from './data';

export interface IAnchorOrigin {
  horizontal: 'center' | 'left' | 'right' | number;
  vertical: 'bottom' | 'center' | 'top' | number;
}

interface ISongMoreMenuProps {
  open: boolean;
  onClick: (event: MouseEvent<HTMLLIElement> | MouseEvent<HTMLAnchorElement>, type: ActionButtonsTypes) => void;
  onClose: () => void;
  anchorEl?: Element | null;
  anchorOrigin?: IAnchorOrigin;
  paperClassName?: string;
  isForPlaylist?: boolean;
  isForSongsModule?: boolean;
  isArchived?: boolean;
  isPWSong?: boolean;
  isWithAudio?: boolean;
  checkedLabelView?: boolean;
  onChangeLabelView?: (checked: boolean) => void;
  isFromDashboard?: boolean;
  canEditPlaylist?: boolean;
}

const SongMoreMenu = ({
  open,
  anchorEl,
  anchorOrigin,
  onClose,
  onClick,
  paperClassName,
  isForPlaylist,
  isForSongsModule,
  isArchived,
  isPWSong,
  isWithAudio,
  // checkedLabelView,
  // onChangeLabelView,
  isFromDashboard,
  canEditPlaylist = true,
}: ISongMoreMenuProps) => {
  const permissions = useMemoSelector(getUserPermissions);

  const menuItems = useMemo(() => {
    return (permissions.songsActions as ISongMoreButton[]).filter(({ type, hideInMobile }) => {
      const isRestoreAndDelete = [ActionButtonsTypes.restore, ActionButtonsTypes.delete].includes(type);
      const isRemoveFromPlaylist = type === ActionButtonsTypes.removeFromPlaylist;

      if (!canEditPlaylist && isRemoveFromPlaylist) {
        return false;
      }

      if (getIsMobile(window.innerWidth) && hideInMobile) {
        return false;
      }

      if (isForSongsModule && [ActionButtonsTypes.songDetails, ActionButtonsTypes.addToPlaylist].includes(type)) {
        return false;
      }

      if (
        ((!isArchived || isForPlaylist) && isRestoreAndDelete) ||
        (isArchived && !isRestoreAndDelete && !isRemoveFromPlaylist)
      ) {
        return false;
      }

      if (!isPWSong && type === ActionButtonsTypes.archive) {
        return false;
      }

      if (
        !isWithAudio &&
        [ActionButtonsTypes.download, ActionButtonsTypes.pitch, ActionButtonsTypes.share].includes(type)
      ) {
        return false;
      }

      if (isFromDashboard && isRemoveFromPlaylist) {
        return false;
      }

      return isForPlaylist ? type !== ActionButtonsTypes.addToPlaylist : type !== ActionButtonsTypes.removeFromPlaylist;
    });
  }, [
    canEditPlaylist,
    isArchived,
    isForPlaylist,
    isForSongsModule,
    isFromDashboard,
    isPWSong,
    isWithAudio,
    permissions.songsActions,
  ]);

  return (
    <Menu
      open={!!menuItems.length && open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
      classes={{ paper: classNames(styles.menuPaper, paperClassName), list: styles.menuList }}
    >
      {menuItems.map(({ type, title }) => (
        <MenuItem
          key={title}
          onClick={(e) => {
            e.stopPropagation();
            onClick(e, type);
          }}
        >
          {title}
        </MenuItem>
      ))}
      {/*Note: was visible only in sidebar */}
      {/*{permissions.playbarLabelView && !isArchived && !isForSongsModule && (*/}
      {/*  <SwitchBtn*/}
      {/*    checked={checkedLabelView}*/}
      {/*    label={*/}
      {/*      <>*/}
      {/*        <p>Label view</p>*/}
      {/*        <Tooltip text="When activated, this song will be visible to non-WCM Label users.">*/}
      {/*          <InfoIcon />*/}
      {/*        </Tooltip>*/}
      {/*      </>*/}
      {/*    }*/}
      {/*    labelPlacement="start"*/}
      {/*    labelClassName={classNames(styles.moreItem, styles.label)}*/}
      {/*    onChangeSwitch={onChangeLabelView}*/}
      {/*  />*/}
      {/*)}*/}
    </Menu>
  );
};

export default SongMoreMenu;
