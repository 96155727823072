import moment from 'moment';

import { IContact, IContactGroup } from 'components/Contacts';
import { SearchDataType } from 'components/Popups/DesktopSearch/data';
import { IContactAutocompleteOption, IManualPitch } from 'components/Popups/ManualPitchForm';
import { IShareToContacts } from 'components/Popups/ShareSongPopup/data';
import { ISystemPitch } from 'components/Popups/SystemPitchForm';
import { IReportSong } from 'components/ReportDetails';
import { IReport } from 'components/ReportForm/ReportForm';
import { IInputAutocompleteOption } from 'components/UI/InputAsyncAutocomplete';

import LocalStorage from 'services/LocalStorage';
import oktaAuth from 'services/Okta';
import { dispatch, getIsOpenError, getSelectors, getUserTerritory, resetGeneral, setError } from 'store';
import { MAX_NOTIFICATIONS_REQUEST_COUNT, sendAnalyticsEvent, USER_NOT_AUTHORIZED_ERROR_MESSAGE } from 'utils';

import {
  getQuickSearchBody,
  handleNotificationsRequestCount,
  mapReportFiltersToItem,
  mapReportFiltersToRequest,
  mapToContact,
  mapToGroup,
  updateAllPlaylists,
  updateFavoritesList,
  updateRecentPlayedList,
} from './api-utils';
import { HEADER } from './constants';
import {
  IAddMediasToManualPitchBody,
  IAddPitchSystemActivity,
  IAddSongStatusBody,
  IAddSongTagBody,
  IAddWorkItem,
  IAddWorkResponse,
  IAPIOptions,
  IAsset,
  IChangeUserStatus,
  IContactBody,
  IContactGroupBody,
  IContactGroupResponseItem,
  IContactResponseItem,
  ICreateFeaturedPlaylistBody,
  ICreatePlaylistBody,
  IGetFeaturedPlaylistsBody,
  IGetHoldsBody,
  IGetNotifications,
  IGetPitchesBody,
  IGetPlaylists,
  IGetSongs,
  IGetUsers,
  IMarkerBody,
  IMergeSongsBody,
  IMethods,
  IRegisterUserBody,
  IRequestParams,
  ISearchAlert,
  IShareInternalBody,
  ITeamBody,
  IUpdateNotificationPreferenceBody,
  IUpdateNotificationStatusesBody,
  IUpdateSongDetailsBody,
  IUpdateSongMediasBody,
  IWorkHoldRequestBody,
} from './types';
import { AnalyticsEventTypes, IAutocompleteOption } from 'types';

class Api {
  // constants
  private baseUrl = '/api';

  public paths = {
    userProfile: '/users/user-profile',
    usersProfile: '/users/profile',
    teamsAll: '/teams/all',
    teams: '/teams',
    search: '/search',
    searchContacts: '/search/contacts',
    searchContactsTeamGroups: '/search/contacts-team-groups',
    searchTeamGroups: '/search/team-groups',
    searchWorks: '/search/work',
    searchWriters: '/search/writers',
    searchArtists: '/search/artists',
    searchPlaylist: '/search/playlist',
    users: '/users',
    usersSearch: '/users/search',
    usersAccess: '/users/access',
    usersAutocomplete: '/users/autocomplete',
    usersLookupCompany: '/users/lookup/COMPANY',
    usersLookupLabel: '/users/lookup/LABEL',
    usersLookupSubTeams: '/users/lookup/sub-teams',
    usersStatusCount: '/users/status-count',
    songs: '/songs',
    alerts: '/alerts',
    alertsPublicSongs: '/alerts/public/songs',
    audios: '/audios',
    assetsBulkDelete: '/assets/bulkDelete',
    assetsGetByIds: '/assets/getByIds',
    assetsMy: '/assets/myAssets',
    assetsUpload: '/assets/upload',
    song: '/song',
    pitch: '/pitch',
    pitchSearch: '/pitch/search',
    pitchManual: '/pitch/manual',
    pitchSystem: '/pitch/system',
    pitchSystemActivate: '/pitch/system/activate',
    pitchPublic: '/pitch/system/public',
    pitchPublicPlay: '/pitch/system/public/play',
    pitchPublicDownload: '/pitch/system/public/download',
    playlist: '/playlist',
    playlistDelete: '/playlist/delete-permanently',
    playlistActivate: '/playlist/activate',
    playlistFavorite: '/playlist/favorite',
    playlistSearch: '/playlist/search',
    playlistFavoriteList: '/playlist/favorite',
    playlistPublic: '/playlist/public',
    featuredPlaylist: '/featured-playlist',
    featuredPlaylistsDashboard: '/featured-playlists/dashboard',
    featuredPlaylistsAdmin: '/featured-playlists/admin',
    marker: '/marker',
    mediaPublic: '/media/public',
    media: '/media',
    mediasRecent: '/media/recent-played-medias',
    works: '/works',
    worksMedia: '/works/media',
    worksMerge: '/works/merge',
    worksBulkArchive: '/works/bulk/archive',
    worksBulkRestore: '/works/bulk/restore',
    worksBulkLabelView: '/works/bulkLabelView',
    worksBulk: '/works/bulk',
    worksLookupLabel: '/works/lookup/LABEL',
    worksLookupArtist: '/works/lookup/ARTIST',
    worksLookupContacts: '/works/lookup/CONTACTS',
    writers: '/writers',
    contacts: '/contacts',
    contactsSearch: '/contacts/search',
    teamGroups: '/team-groups',
    teamGroupsSearch: '/team-groups/search',
    downloadSongs: '/download/songs',
    downloadPublic: '/download/public',
    reports: '/reports',
    reportsSearch: '/reports/search',
    preferences: '/preferences',
    approvalRequests: '/approval-requests',
    approvalRequestsMy: '/approval-requests/my',
    approvalRequestsTeam: '/approval-requests/team',
    approvalRequestsStatusCount: '/approval-requests/status-count',
    assetsDashboardImages: '/assets/dashboard/images',
    cookies: '/cookies',
    notifications: '/notifications',
    notificationsStatuses: '/notifications/statuses',
    notificationPreferences: '/notification/preferences',
    notificationPreferencesType: '/notification/preferences/type',
  };

  private methods: IMethods = {
    delete: 'DELETE',
    get: 'GET',
    put: 'PUT',
    post: 'POST',
    patch: 'PATCH',
  };

  private notificationsRequestsCount = 0;

  // helpers

  private getToken = () => {
    return oktaAuth.getAccessToken();
  };

  private removeAllCookiesAndStorage() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      document.cookie = cookies[i] + '=;expires=' + new Date(0).toUTCString();
    }
    LocalStorage.clear();
    dispatch(resetGeneral());
  }

  private signOut = () => {
    this.removeAllCookiesAndStorage();
    window.location.href = '/';
  };

  private resetNotificationsRequestsCount = () => {
    this.notificationsRequestsCount = 0;
  };

  // requests

  public getUser = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.userProfile,
      method: methods.put,
      params,
    });
  };

  public registerUser = (body: IRegisterUserBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.usersProfile,
      method: methods.post,
      body: { ...body },
      params: { ...params, withoutToken: true },
      // params,
    });
  };

  public getTeams = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.teamsAll,
      method: methods.get,
      params,
    });
  };

  public createTeam = (body: ITeamBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.teams,
      method: methods.post,
      body: { ...body },
      params,
    });
  };

  public putTeams = (checkedTeams: string[], params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.teams,
      method: methods.put,
      body: { teamIdes: checkedTeams },
      params,
    });
  };

  getUserPreferencesByPreferenceName = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.preferences,
      method: methods.get,
      params,
    });
  };

  createUpdateUserPreferences = (body?: { [key: string]: unknown }, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.preferences,
      method: methods.put,
      body: { preference: JSON.stringify(body) },
      params,
    });
  };

  deleteUserPreferences = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.preferences,
      method: methods.delete,
      params,
    });
  };

  public getQuickSearchResults = (fields: SearchDataType, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.search,
      method: methods.post,
      body: getQuickSearchBody(fields),
      params: { ...params, externalHeaders: { 'ARROW-API-VERSION': 2 } },
    });
  };

  public getUsers = (body: IGetUsers, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.usersSearch,
      method: methods.post,
      body: { ...body },
      params,
    });
  };

  public changeUserStatus = ({ body, path }: IChangeUserStatus, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.users}/${path}`,
      method: methods.put,
      body: { ...body },
      params,
    });
  };

  public requestAccessUser = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.usersAccess,
      method: methods.patch,
      params: { ...params, withoutResponseData: true },
    });
  };

  public getStatusCount = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.usersStatusCount,
      method: methods.get,
      params,
    });
  };

  public getUsersByQuery = (query: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.usersAutocomplete,
      method: methods.get,
      query: `?query=${query}`,
      params,
    });
  };

  public getCompaniesByQuery = (query: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.usersLookupCompany,
      method: methods.get,
      query: `?value=${query}`,
      params,
    }).then((res) => res.map((el: { value: string }) => ({ id: el.value, title: el.value })));
  };

  public getLabelsByQuery = (query: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.usersLookupLabel,
      method: methods.get,
      query: `?value=${query}`,
      params,
    }).then((res) => res.map((el: { id: string; value: string }) => ({ id: el.id, title: el.value })));
  };

  public getSubLabelsByQueryAndLabel = (query: string, parentId: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.usersLookupSubTeams,
      method: methods.get,
      query: `?value=${query}&parentId=${parentId}`,
      params,
    }).then((res) => res.map((el: { id: string; value: string }) => ({ id: el.id, title: el.value })));
  };

  public getSongs = (body: IGetSongs, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.songs,
      method: methods.post,
      body: { ...body },
      params: { ...params, externalHeaders: { 'ARROW-API-VERSION': 2 } },
    });
  };

  public createSearchAlert = (body: ISearchAlert, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.alerts,
      method: methods.post,
      body: { ...body },
      params,
    });
  };

  public getSearchAlerts = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.alerts,
      method: methods.get,
      params,
    });
  };

  public turnOffSearchAlert = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.alerts,
      method: methods.put,
      params,
    });
  };

  public getAlertsPublicSongs = (code: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.alertsPublicSongs,
      method: methods.get,
      query: `?code=${code}`,
      params,
    });
  };

  // Song Details

  public getSongDetails = (params?: IRequestParams) => {
    const { methods, request, paths } = this;
    const territory = getSelectors(getUserTerritory);

    return request({
      path: paths.song,
      method: methods.post,
      body: { territory },
      params,
    });
  };

  public updateSongDetails = (body?: IUpdateSongDetailsBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.works,
      method: methods.patch,
      body: { ...body },
      params,
    });
  };

  public updateSongMediaVersion = (body: IUpdateSongMediasBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.worksMedia,
      method: methods.put,
      body: { ...body },
      params,
    });
  };

  public removeSongMedia = (songId: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/media`,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
    });
  };

  public mergeSongs = (body: IMergeSongsBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.worksMerge,
      method: methods.put,
      body: { ...body },
      params,
    });
  };

  public archiveSongs = (body: string[], params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.worksBulkArchive,
      method: methods.patch,
      body,
      params,
    })
      .then(updateAllPlaylists)
      .catch(() => null);
  };

  public restoreSongs = (body: string[], params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.worksBulkRestore,
      method: methods.patch,
      body,
      params,
    });
  };

  public deleteSongs = (body: string[], params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.worksBulk,
      method: methods.delete,
      body,
      params: { ...params, withoutResponseData: true },
    });
  };

  public addRecentPlayedMedia = (id: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.media}/${id}/play`,
      method: methods.post,
      params: { ...params, withoutResponseData: true },
    })
      .then(updateRecentPlayedList)
      .catch(() => null);
  };

  public getRecentPlayedMedias = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.mediasRecent,
      method: methods.get,
      params,
    });
  };

  // Song Notes

  public addSongNote = (songId: string, note: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/note`,
      method: methods.post,
      body: { note },
      params: { ...params, externalHeaders: { 'ARROW-API-VERSION': 1 } },
    });
  };

  public updateSongNote = (songId: string, note: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/note`,
      method: methods.put,
      body: { note },
      params,
    });
  };

  public removeSongNote = (songId: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/note`,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
    });
  };

  // Song Status

  public addSongStatus = (songId: string, body: Partial<IAddSongStatusBody>, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/status`,
      method: methods.post,
      body: { ...body },
      params: { ...params, externalHeaders: { 'ARROW-API-VERSION': 1 } },
    });
  };

  public updateSongStatus = (
    songId: string,
    statusId: string,
    body: Partial<IAddSongStatusBody>,
    params?: IRequestParams
  ) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/status/${statusId}`,
      method: methods.patch,
      body: { ...body },
      params: { ...params, externalHeaders: { 'ARROW-API-VERSION': 1 } },
    });
  };

  public removeSongStatus = (songId: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/status`,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
    });
  };

  // Song Tag

  public addSongTags = (songId: string, body: IAddSongTagBody[], params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/tag`,
      method: methods.post,
      params: { ...params, externalHeaders: { 'ARROW-API-VERSION': 1 } },
      body,
    });
  };

  public removeSongTag = (songId: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/tag`,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
    });
  };

  public removeSongTags = (songId: string, body: string[], params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/tag/bulkDelete`,
      method: methods.delete,
      body,
      params: { ...params, withoutResponseData: true },
    });
  };

  public changeSongLabelView = (songId: string, labelView: boolean, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.works}/${songId}/labelView`,
      method: methods.put,
      query: `?labelView=${labelView}`,
      params,
    });
  };

  public changeSongsLabelView = (ids: string[], labelView: boolean, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.worksBulkLabelView,
      method: methods.put,
      query: `?labelView=${labelView}`,
      body: ids,
      params,
    });
  };

  public getAudio = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.audios,
      method: methods.get,
      params,
    });
  };

  // Download

  public downloadPreparedFile = (params?: IRequestParams) => {
    const { methods, request } = this;

    return request({
      method: methods.get,
      params,
    });
  };

  // Download Songs

  public generateSongsZip = (ids: string[], params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      method: methods.post,
      path: `${paths.downloadSongs}/generate`,
      body: ids,
      params,
    });
  };

  public getGenerateSongsZipStatus = (id: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      method: methods.get,
      path: `${paths.downloadSongs}/${id}/generate/status`,
      params,
    });
  };

  public getSongsZipUrl = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.downloadSongs,
      method: methods.get,
      params,
    });
  };

  // Download Playlist

  public generatePlaylistZip = (id: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      method: methods.post,
      path: `${paths.playlist}/${id}/generate`,
      params: { ...params, withoutResponseData: true },
    });
  };

  public getGeneratePlaylistZipStatus = (id: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      method: methods.get,
      path: `${paths.playlist}/${id}/generate/status`,
      params,
    });
  };

  public getPlaylistZipUrl = (id: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.playlist}/${id}/download`,
      method: methods.get,
      params,
    });
  };

  // Download Pitch and Shared songs

  public generatePitchSharedSongsZip = (type: string, code: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      method: methods.post,
      path: `${paths.downloadPublic}/${type}/generate`,
      query: `?code=${code}`,
      params: { ...params, withoutResponseData: true },
    });
  };

  public getGeneratePitchSharedSongsZipStatus = (type: string, code: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      method: methods.get,
      path: `${paths.downloadPublic}/${type}/generate/status`,
      query: `?code=${code}`,
      params,
    });
  };

  public getPitchSharedSongsZipUrl = (type: string, code: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.downloadPublic}/${type}`,
      query: `?code=${code}`,
      method: methods.get,
      params,
    });
  };

  // Pitches

  public getPitches = (body: IGetPitchesBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.pitchSearch,
      method: methods.post,
      params,
      body: { ...body },
    });
  };

  public getPitchById = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.pitch,
      method: methods.get,
      params,
    });
  };

  public removeManualPitch = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.pitchManual,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
    });
  };

  public removeSystemPitch = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.pitchSystem,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
    });
  };

  public reactivateSystemPitch = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.pitchSystemActivate,
      method: methods.post,
      params,
    });
  };

  public updatePitchSystemExpiration = (pitchId: string, expireOn: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.pitchSystem}/${pitchId}/expiration`,
      method: methods.put,
      body: { expireOn },
      params: { ...params },
    });
  };

  public updatePitchManualResult = (pitchId: string, mediaId: string, result: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.pitchManual}/${pitchId}/media/${mediaId}/result`,
      method: methods.put,
      body: { result },
      params: { ...params },
    });
  };

  public addMediasToManualPitch = (pitchId: string, body?: IAddMediasToManualPitchBody[], params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.pitchManual}/${pitchId}/media`,
      method: methods.post,
      body: { medias: body },
      params,
    });
  };

  public removeMediaFromManualPitch = (pitchId: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.pitchManual}/${pitchId}/media`,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
    });
  };

  public addManualPitch = (pitch: IManualPitch, params?: IRequestParams) => {
    const { methods, paths, request } = this;
    return request({
      body: {
        artist: pitch.artist,
        contactGroupId: pitch.contact?.isGroup ? pitch.contact.id : undefined,
        contactId: pitch.contact?.isGroup ? undefined : pitch.contact?.id,
        label: pitch.label,
        medias: pitch.songs?.map((s) => ({ mediaId: s.mediaId, result: s.result?.id || null })),
        sentDate: pitch.sentDate ? moment(pitch.sentDate).format('MM-DD-YYYY') : undefined,
        title: pitch.title,
      },
      method: methods.post,
      params: { ...params },
      path: paths.pitchManual,
    });
  };

  public addSystemPitch = (pitch: ISystemPitch, params?: IRequestParams) => {
    const { methods, paths, request } = this;
    return request({
      body: {
        contactGroupIds: pitch.contacts?.filter((c) => c.isGroup).map((c) => c.id),
        contactIds: pitch.contacts?.filter((c) => !c.isGroup).map((c) => c.id),
        expireOn: pitch.expiryDate ? moment(pitch.expiryDate).format('MM-DD-YYYY') : undefined,
        isDownloadable: pitch.downloadable,
        mediaIds: pitch.songs?.map((s) => s.mediaId),
        message: pitch.message,
        title: pitch.title,
      },
      method: methods.post,
      params: { ...params },
      path: paths.pitchSystem,
    });
  };

  public getPublicPitchById = (code: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.pitchPublic,
      method: methods.get,
      query: `?code=${code}`,
      params,
    });
  };

  public playPublicPitch = (code: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.pitchPublicPlay,
      method: methods.get,
      query: `?code=${code}`,
      params,
    });
  };

  public addPitchSystemActivity = (
    { pitchId, mediaIds, contact, activity }: IAddPitchSystemActivity,
    params?: IRequestParams
  ) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.pitchSystem}/${pitchId}/contact/${contact}/activity/${activity}`,
      method: methods.put,
      body: { mediaIds },
      params: { ...params },
    });
  };

  // Playlists

  public getPlaylistDetails = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.playlist,
      method: methods.get,
      params,
    });
  };

  public createPlaylist = (body?: ICreatePlaylistBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.playlist,
      method: methods.post,
      body: { ...body },
      params: { ...params },
    });
  };

  public updatePlaylist = (body?: ICreatePlaylistBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.playlist,
      method: methods.put,
      body: { ...body },
      params,
    })
      .then(updateFavoritesList)
      .catch(() => null);
  };

  public deletePlaylist = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.playlist,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
    });
  };

  public deletePlaylistPermanently = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.playlistDelete,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
    });
  };

  public activatePlaylist = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.playlistActivate,
      method: methods.post,
      params,
    });
  };

  public getQuickSearchPlaylists = (query: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.searchPlaylist,
      method: methods.get,
      query: `?query=${query}`,
      params,
    });
  };

  public getPlaylists = (body: IGetPlaylists, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.playlistSearch,
      method: methods.post,
      body: { ...body },
      params,
    });
  };

  public addToFavorite = (mediaIds: string[], favoritePlaylistId: string, params?: IRequestParams) => {
    const { addToPlaylist } = this;

    return addToPlaylist(mediaIds, favoritePlaylistId, params)
      .then(updateFavoritesList)
      .catch(() => null)
      .finally(() => sendAnalyticsEvent(AnalyticsEventTypes.addToFavorites));
  };

  public removeFromFavorite = (mediaIds: string[], favoritePlaylistId: string, params?: IRequestParams) => {
    const { removeFromPlaylist } = this;

    return removeFromPlaylist(mediaIds, favoritePlaylistId, params)
      .then(updateFavoritesList)
      .catch(() => null)
      .finally(() => sendAnalyticsEvent(AnalyticsEventTypes.removeFromFavorites));
  };

  public getFavoritePlaylist = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.playlistFavoriteList,
      method: methods.post,
      params,
    });
  };

  public addToPlaylist = (mediaIds: string[], playlistId: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.playlist}/${playlistId}/medias`,
      method: methods.post,
      body: { mediaIds },
      params: { ...params, isThrow: true },
    });
  };

  public removeFromPlaylist = (mediaIds: string[], playlistId: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.playlist}/${playlistId}/medias`,
      method: methods.delete,
      body: { mediaIds },
      params: { ...params, isThrow: true, withoutResponseData: true },
    });
  };

  public sharePlaylistInternal = (playlistId: string, body: IShareInternalBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.playlist}/${playlistId}/share/internal`,
      method: methods.put,
      body: { ...body },
      params,
    });
  };

  public preparePlaylistForShare = (playlistId: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.playlist}/${playlistId}/share/public/prepare`,
      method: methods.get,
      params,
    });
  };

  public changeSharedPlaylistExpiration = (playlistId: string, expirationDate: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.playlist}/${playlistId}/share/public`,
      method: methods.put,
      body: { expirationDate },
      params,
    });
  };

  public getSharedPlaylistById = (code: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.playlistPublic,
      method: methods.get,
      query: `?code=${code}`,
      params,
    });
  };

  public shareSongInternal = (songId: string, body: IShareInternalBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.media}/share/${songId}/internal`,
      method: methods.put,
      body: { ...body },
      params,
    });
  };

  public prepareSongForShare = (songId: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.media}/share/${songId}/public/prepare`,
      method: methods.get,
      params,
    });
  };

  public changeSharedSongExpiration = (songId: string, expirationDate: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.media}/share/${songId}/public`,
      method: methods.put,
      body: { expirationDate },
      params,
    });
  };

  public getSharedSongById = (code: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.mediaPublic,
      method: methods.get,
      query: `?code=${code}`,
      params,
    });
  };

  // Featured Playlists

  public getFeaturedPlaylistsForDashboard = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.featuredPlaylistsDashboard,
      method: methods.post,
      body: {
        pagination: {
          skip: 0,
          take: 100,
        },
      },
      params,
    });
  };

  public getFeaturedPlaylistsForAdmin = (body: IGetFeaturedPlaylistsBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.featuredPlaylistsAdmin,
      method: methods.post,
      body: { ...body },
      params,
    });
  };

  public createFeaturedPlaylist = (body: ICreateFeaturedPlaylistBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.featuredPlaylist,
      method: methods.post,
      body: { ...body },
      params,
    });
  };

  public updateFeaturedPlaylist = (body: ICreateFeaturedPlaylistBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.featuredPlaylist,
      method: methods.put,
      body: { ...body },
      params,
    });
  };

  public pinFeaturedPlaylist = (id: string, force: boolean, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.featuredPlaylist}/${id}/pin`,
      body: { force },
      method: methods.put,
      params,
    });
  };

  public unpinFeaturedPlaylist = (id: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.featuredPlaylist}/${id}/unpin`,
      method: methods.put,
      params,
    });
  };

  public reactivateFeaturedPlaylist = (
    id: string,
    body: { releaseOn: string; expireOn: string },
    params?: IRequestParams
  ) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.featuredPlaylist}/${id}/reactivate`,
      method: methods.put,
      body: { ...body },
      params,
    });
  };

  public expireFeaturedPlaylist = (id: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: `${paths.featuredPlaylist}/${id}/expire`,
      method: methods.put,
      params,
    });
  };

  // Markers

  public addMarkerToSong = (marker: IMarkerBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.marker,
      method: methods.post,
      body: { ...marker },
      params,
    });
  };

  public updateMarkerOfSong = (marker: IMarkerBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.marker,
      method: methods.put,
      body: { ...marker },
      params,
    });
  };

  public removeMarkerFromSong = (params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.marker,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
    });
  };

  // Assets

  public bulkDeleteAssets = (assetIds: string[], params?: IRequestParams): Promise<void> => {
    const { methods, paths, request } = this;

    return request({
      body: assetIds,
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
      path: paths.assetsBulkDelete,
    });
  };

  public getAssetsByIds = (assetIds: string[], params?: IRequestParams): Promise<IAsset[]> => {
    const { methods, paths, request } = this;

    return request({
      body: assetIds,
      method: methods.post,
      params,
      path: paths.assetsGetByIds,
    });
  };

  public getMyAssets = (
    body: { skip: number; take: number; workId?: string },
    params?: IRequestParams
  ): Promise<IAsset[]> => {
    const { methods, paths, request } = this;

    return request({
      body: { ...body, sorting: [{ direction: 'DESC', field: 'createdOn' }] },
      method: methods.post,
      params,
      path: paths.assetsMy,
    });
  };

  public uploadAsset = (body: { workId?: string; originalFilename?: string; contentType: string }): Promise<IAsset> => {
    const { methods, paths, request } = this;
    return request({
      method: methods.post,
      path: paths.assetsUpload,
      body,
    });
  };

  public uploadAssetToBucket = (
    body: { file: File; url: string },
    progressCallback: (percent: number, xhr: XMLHttpRequest) => void
  ) =>
    new Promise<void>((resolve, reject) => {
      const { methods } = this;

      const xhr = new XMLHttpRequest();

      xhr.onerror = () => reject(new Error('Connection error'));

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          xhr.status === 200 ? resolve() : reject(new Error(xhr.statusText));
        }
      };

      xhr.upload.onerror = () => reject(new Error('Connection error during upload'));

      xhr.upload.onprogress = (e) => {
        progressCallback(Math.round((e.loaded / e.total) * 100), xhr);
      };

      xhr.open(methods.put, body.url, true);
      xhr.setRequestHeader('Accept', HEADER.contentTypes.json);
      xhr.setRequestHeader('Content-Type', body.file.type);

      const reader = new FileReader();
      reader.onload = () => {
        xhr.send(reader.result);
      };
      reader.onerror = () => reject(new Error('Error reading file'));
      reader.readAsArrayBuffer(body.file);
    });

  // Works

  public addWork = (body: IAddWorkItem, params?: IRequestParams): Promise<IAddWorkResponse | undefined> => {
    const { methods, paths, request } = this;

    return request({
      body: { ...body },
      method: methods.post,
      params: { ...params },
      path: paths.works,
    });
  };

  public getWorks = (query: string, params?: IRequestParams): Promise<IInputAutocompleteOption[] | undefined> => {
    const { methods, paths, request } = this;

    return request({
      method: methods.get,
      params: { ...params },
      path: paths.searchWorks,
      query: `?query=${encodeURIComponent(query)}`,
    }).then((res: { data?: { id: string; code: string }[] } | undefined) =>
      res?.data?.map(({ id, code }): IInputAutocompleteOption => ({ id, title: code }))
    );
  };

  public addSongToWork = (workId: string, body: { assetId: string; version: string }[], params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      body,
      method: methods.patch,
      params,
      path: `${paths.worksMedia}/${workId}`,
    });
  };

  public workHoldRequest = (workId: string, body: IWorkHoldRequestBody, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      body: { ...body },
      method: methods.post,
      params: { ...params, withoutResponseData: true },
      path: `${paths.works}/${workId}/requestHold`,
    });
  };

  public getWorkLabelsByQuery = (query: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.worksLookupLabel,
      method: methods.get,
      query: `?value=${query}`,
      params,
    }).then((res) => res.map((el: { value: string }) => ({ id: el.value, title: el.value })));
  };

  public getWorkContactsByQuery = (query: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.worksLookupContacts,
      method: methods.get,
      query: `?value=${query}`,
      params,
    }).then((res) => res.map((el: IShareToContacts) => ({ ...el, id: el.id || el.value, name: el.name || el.value })));
  };

  public getWorkArtistsByQuery = (query: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      path: paths.worksLookupArtist,
      method: methods.get,
      query: `?value=${query}`,
      params,
    }).then((res) => res.map((el: { value: string }) => ({ id: el.value, title: el.value })));
  };

  // Writers

  public addWriter = (
    body: { firstName: string; lastName: string },
    params?: IRequestParams
  ): Promise<{ id?: string; name?: string } | undefined> => {
    const { methods, paths, request } = this;

    return request({
      body: { ...body },
      method: methods.post,
      params: { ...params },
      path: paths.writers,
    });
  };

  public getWriters = (query: string, params?: IRequestParams): Promise<IAutocompleteOption[]> => {
    const { methods, paths, request } = this;

    return request({
      method: methods.get,
      params: { ...params },
      path: paths.searchWriters,
      query: `?query=${encodeURIComponent(query)}`,
    }).then(({ data }: { data: { id: string; name: string }[] }) =>
      data.map(({ id, name }): IAutocompleteOption => ({ id, title: name }))
    );
  };

  public getArtists = (query: string, params?: IRequestParams): Promise<IAutocompleteOption[]> => {
    const { methods, paths, request } = this;

    return request({
      method: methods.get,
      params,
      path: paths.searchArtists,
      query: `?query=${encodeURIComponent(query)}`,
    }).then(({ data }: { data: { id: string; name: string }[] }) =>
      data.map(({ id, name }): IAutocompleteOption => ({ id, title: name }))
    );
  };

  // Contacts

  public addContactsToGroup = (groupId: string, contactIds: string[], params?: IRequestParams): Promise<void> => {
    const { methods, paths, request } = this;

    return request({
      body: { contactIds },
      method: methods.post,
      params: { ...params, withoutResponseData: true },
      path: `${paths.teamGroups}/${groupId}/contacts`,
    });
  };

  public addOrUpdateContact = (body: IContact, params?: IRequestParams): Promise<IContact | undefined> => {
    return body.id ? this.updateContact(body, params) : this.addContact(body, params);
  };

  public addContact = (
    { email, name, organizationName, phone, role }: IContact,
    params?: IRequestParams
  ): Promise<IContact | undefined> => {
    const { methods, paths, request } = this;

    const body: IContactBody = {
      email: email || '',
      name: name || '',
      organizationName,
      phone,
      role,
    };

    return request({
      body: { ...body },
      method: methods.post,
      params: { ...params },
      path: paths.contacts,
    }).then((result?: IContactResponseItem) => result && mapToContact(result));
  };

  public addGroup = (
    { contacts, title }: IContactGroup,
    params?: IRequestParams
  ): Promise<IContactGroup | undefined> => {
    const { methods, paths, request } = this;

    const body: IContactGroupBody = {
      contactIds: contacts?.map(({ id }) => id.toString()) || [],
      name: title || '',
    };

    return request({
      body: { ...body },
      method: methods.post,
      params: { ...params },
      path: paths.teamGroups,
    }).then((result?: IContactGroupResponseItem) => result && mapToGroup(result));
  };

  public deleteContact = (contactId: string, params?: IRequestParams): Promise<void> => {
    const { methods, paths, request } = this;

    return request({
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
      path: `${paths.contacts}/${contactId}`,
    });
  };

  public deleteContactsFromGroup = (groupId: string, contactIds: string[], params?: IRequestParams): Promise<void> => {
    const { methods, paths, request } = this;

    return request({
      body: { contactIds },
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
      path: `${paths.teamGroups}/${groupId}/contacts`,
    });
  };

  public deleteGroup = (groupId: string, params?: IRequestParams): Promise<void> => {
    const { methods, paths, request } = this;

    return request({
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
      path: `${paths.teamGroups}/${groupId}`,
    });
  };

  public getContactByEmail = (
    email: string,
    teamId: string,
    params?: IRequestParams
  ): Promise<IAutocompleteOption | undefined> => {
    const { methods, paths, request } = this;

    return request({
      method: methods.get,
      params: { ...params },
      path: paths.searchContacts,
      query: `?email=${encodeURIComponent(email)}&teamId=${encodeURIComponent(teamId)}`,
    }).then(
      (results?: { contacts?: { id?: string; name?: string }[] }): IAutocompleteOption | undefined =>
        results?.contacts &&
        results.contacts[0] && { id: results.contacts[0].id || '', title: results.contacts[0].name || '' }
    );
  };

  public getContacts = async (
    body: {
      filter?: {
        email?: string;
        filterType?: 'created_by_me' | 'created_by_my_team';
        name?: string;
        teamIds?: string[];
        userId?: string;
      };
      skip: number;
      take: number;
    },
    params?: IRequestParams
  ): Promise<{ items: IContact[]; total: number }> => {
    const { methods, paths, request } = this;

    body.filter && (body.filter.filterType = body.filter.userId ? 'created_by_me' : 'created_by_my_team');

    const data: { contacts: IContactResponseItem[]; total: number } | undefined = await request({
      body: {
        filter: body.filter,
        pagination: { skip: body.skip, take: body.take },
      },
      method: methods.post,
      params,
      path: paths.contactsSearch,
    });
    return { items: data?.contacts.map(mapToContact) ?? [], total: data?.total ?? 0 };
  };

  public getContactsAndTeamGroups = (
    filter: string,
    params?: IRequestParams
  ): Promise<IContactAutocompleteOption[]> => {
    const { methods, paths, request } = this;

    return request({
      method: methods.get,
      params,
      path: paths.searchContactsTeamGroups,
      query: `?name=${encodeURIComponent(filter)}`,
    }).then(
      (result?: {
        items: {
          email?: string;
          group?: boolean;
          id?: string;
          name?: string;
          teamId?: string;
          teamName?: string;
        }[];
      }) =>
        result?.items?.map(
          (i): IContactAutocompleteOption => ({
            email: i.email,
            id: i.id || '',
            isGroup: i.group,
            team: i.teamName,
            title: i.name || '',
            name: i.name || '',
            teamName: i.teamName,
          })
        ) || []
    );
  };

  public getGroupByName = (
    email: string,
    teamId: string,
    params?: IRequestParams
  ): Promise<IAutocompleteOption | undefined> => {
    const { methods, paths, request } = this;

    return request({
      method: methods.get,
      params: { ...params },
      path: paths.searchTeamGroups,
      query: `?name=${encodeURIComponent(email)}&teamId=${encodeURIComponent(teamId)}`,
    }).then(
      (results?: { teamGroups?: { id?: string; name?: string }[] }): IAutocompleteOption | undefined =>
        results?.teamGroups &&
        results.teamGroups[0] && { id: results.teamGroups[0].id || '', title: results.teamGroups[0].name || '' }
    );
  };

  public getGroupContacts = async (
    body: { id: string; skip: number; take: number },
    params?: IRequestParams
  ): Promise<{ items: IContact[]; total: number }> => {
    const { methods, paths, request } = this;

    const data: { contacts: IContactResponseItem[]; total: number } | undefined = await request({
      body: { pagination: { skip: body.skip, take: body.take } },
      method: methods.post,
      params,
      path: `${paths.teamGroups}/${body.id}/contacts/search`,
    });
    return { items: data?.contacts.map(mapToContact) ?? [], total: data?.total ?? 0 };
  };

  public getGroupDetails = (groupId: string, params?: IRequestParams): Promise<IContactGroup | undefined> => {
    const { methods, paths, request } = this;

    return request({
      body: { pagination: { skip: 0, take: 0 } },
      method: methods.post,
      params,
      path: `${paths.teamGroups}/${groupId}/contacts/search`,
    }).then(
      (result?: {
        contacts?: IContactResponseItem[];
        groupId?: string;
        groupName?: string;
      }): IContactGroup | undefined =>
        result && {
          id: result.groupId,
          title: result.groupName,
        }
    );
  };

  public getGroups = async (
    body: { filter?: { name?: string; teamIds?: string[] }; skip: number; take: number },
    params?: IRequestParams
  ): Promise<{ items: IContactGroup[]; total: number }> => {
    const { methods, paths, request } = this;
    const data: { teamGroups: IContactGroupResponseItem[]; total: number } | undefined = await request({
      body: {
        filter: body.filter,
        pagination: { skip: body.skip, take: body.take },
      },
      method: methods.post,
      params,
      path: paths.teamGroupsSearch,
    });
    return { items: data?.teamGroups.map(mapToGroup) ?? [], total: data?.total ?? 0 };
  };

  public updateContact = (
    { email, id, name, organizationName, phone, role }: IContact,
    params?: IRequestParams
  ): Promise<IContact | undefined> => {
    const { methods, paths, request } = this;

    const body: IContactBody = {
      email: email || '',
      name: name || '',
      organizationName,
      phone,
      role,
    };

    return request({
      body: { ...body },
      method: methods.put,
      params: { ...params },
      path: `${paths.contacts}/${id}`,
    }).then((result?: IContactResponseItem) => result && mapToContact(result));
  };

  public updateGroup = ({ id, title }: IContactGroup, params?: IRequestParams): Promise<IContactGroup | undefined> => {
    const { methods, paths, request } = this;

    return request({
      body: { name: title || '' },
      method: methods.put,
      params: { ...params },
      path: `${paths.teamGroups}/${id}`,
    }).then((result?: IContactGroupResponseItem) => result && mapToGroup(result));
  };

  // Reports

  public addOrUpdateReport = (body: IReport, params?: IRequestParams): Promise<IReport | undefined> => {
    const { methods, paths, request } = this;

    return request({
      body: { ...body, filters: body.filters?.map(mapReportFiltersToRequest) },
      method: body.id ? methods.put : methods.post,
      params: { ...params },
      path: body.id ? `${paths.reports}/${body.id}` : paths.reports,
    });
  };

  public deleteReport = (id: string, params?: IRequestParams): Promise<void> => {
    const { methods, paths, request } = this;

    return request({
      method: methods.delete,
      params: { ...params, withoutResponseData: true },
      path: `${paths.reports}/${id}`,
    });
  };

  public generateReport = (id: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      method: methods.post,
      path: `${paths.reports}/${id}/generate`,
      params: { ...params, withoutResponseData: true },
    });
  };

  public getGenerateReportStatus = (id: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      method: methods.get,
      path: `${paths.reports}/${id}/generate/status`,
      params,
    });
  };

  public getReportUrl = (id: string, params?: IRequestParams) => {
    const { methods, request, paths } = this;

    return request({
      method: methods.get,
      path: `${paths.reports}/${id}/download`,
      params,
    });
  };

  public getReportById = async (id: string, params?: IRequestParams): Promise<IReport | undefined> => {
    const { methods, paths, request } = this;

    return request({ method: methods.get, params, path: `${paths.reports}/${id}` }).then((report?: IReport) =>
      report ? { ...report, filters: report.filters?.map(mapReportFiltersToItem) } : report
    );
  };

  public getReports = async (
    body: {
      filter: {
        filterType?: 'created_by_me' | 'created_by_my_team';
        teamIds: string[];
        userId?: string;
      };
      skip: number;
      take: number;
    },
    params?: IRequestParams
  ): Promise<IReport[]> => {
    const { methods, paths, request } = this;

    body.filter && (body.filter.filterType = body.filter.userId ? 'created_by_me' : 'created_by_my_team');

    return request({
      body: {
        filter: body.filter,
        pagination: { skip: body.skip, take: body.take },
      },
      method: methods.post,
      params,
      path: paths.reportsSearch,
    }).then((result: { reports?: IReport[] } | undefined) => result?.reports || []);
  };

  public getReportSongs = (
    { id, skip, take }: { id: string; skip: number; take: number },
    params?: IRequestParams
  ): Promise<IReportSong[]> => {
    const { methods, paths, request } = this;

    return (
      request({
        body: { pagination: { skip, take } },
        method: methods.post,
        params,
        path: `${paths.reports}/${id}/content`,
      })
        // Make unique id
        .then((result?: IReportSong[]) => result?.map((s, i) => ({ ...s, id: (skip + i).toString() })) || [])
    );
  };

  // Hold

  public getUserHolds = (body: IGetHoldsBody, params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      body: { ...body },
      method: methods.post,
      path: paths.approvalRequestsMy,
      params,
    });
  };

  public getTeamHolds = (body: IGetHoldsBody, params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      body: { ...body },
      method: methods.post,
      path: paths.approvalRequestsTeam,
      params,
    });
  };

  public getHoldsStatusCount = (params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.get,
      path: paths.approvalRequestsStatusCount,
      params,
    });
  };

  public getHoldDetails = (params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.get,
      path: paths.approvalRequests,
      params,
    });
  };

  public holdApprove = (id: string, params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.patch,
      path: `${paths.approvalRequests}/${id}/approve`,
      params: { ...params, withoutResponseData: true },
    });
  };

  public holdReject = (id: string, message: string, params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      body: { message },
      method: methods.put,
      path: `${paths.approvalRequests}/${id}/reject`,
      params: { ...params, withoutResponseData: true },
    });
  };

  public holdCancel = (id: string, params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.patch,
      path: `${paths.approvalRequests}/${id}/cancel`,
      params: { ...params, withoutResponseData: true },
    });
  };

  public uploadFileToBucket = (file: File, params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.put,
      path: paths.assetsDashboardImages,
      body: file,
      params: {
        ...params,
        withoutResponseData: true,
        withoutApi: true,
        withoutStringifyBody: true,
        skipJsonResIfError: true,
        fileType: file.type,
      },
    });
  };

  // Signed Cookies

  public addSignedCookies = (params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.put,
      path: paths.cookies,
      params: { ...params, withoutResponseData: true },
    });
  };

  public removeSignedCookies = (params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.delete,
      path: paths.cookies,
      params: { ...params, withoutResponseData: true },
    });
  };

  public getAssetsDashboardImage = (params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.get,
      path: paths.assetsDashboardImages,
      params: {
        ...params,
        withoutResponseData: true,
        externalHeaders: { cache: 'reload' },
        handler: async (_, status) => {
          if (status === 403) {
            await this.addSignedCookies();
          }
        },
      },
    });
  };

  // Notifications

  public getNotifications = (body: IGetNotifications, params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.post,
      body: { ...body },
      path: paths.notifications,
      params,
    });
  };

  public updateNotificationsStatuses = (
    { status, notificationIds = [] }: IUpdateNotificationStatusesBody,
    params?: IRequestParams
  ) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.patch,
      path: paths.notificationsStatuses,
      body: { status, notificationIds },
      params,
    });
  };

  // Notifications Preferences

  getNotificationsPreferences = (params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.get,
      path: paths.notificationPreferencesType,
      params,
    });
  };

  updateNotificationPreferences = (body: IUpdateNotificationPreferenceBody, params?: IRequestParams) => {
    const { methods, paths, request } = this;

    return request({
      method: methods.put,
      path: paths.notificationPreferences,
      body: { ...body },
      params: {
        ...params,
        withoutResponseData: true,
      },
    });
  };

  private request = async (options: IAPIOptions) => {
    const { path, method, body, params, query } = options;
    const {
      errorPopupConfig,
      handler,
      pathId,
      getWithBlob,
      getWithText,
      withoutApi,
      withoutResponseData,
      isThrow,
      url,
      withoutToken,
      withoutStringifyBody,
      fileType,
      skipJsonResIfError,
      isNotificationsIntervalCall,
    } = params || {};

    this.notificationsRequestsCount = isNotificationsIntervalCall ? this.notificationsRequestsCount + 1 : 0;

    try {
      const res = await fetch(
        url || `${withoutApi ? '' : this.baseUrl}${path}${pathId ? `/${pathId}` : ''}${query || ''}`,
        {
          method,
          headers: {
            Accept: HEADER.contentTypes.json,
            ...(!withoutToken && { Authorization: `Bearer ${this.getToken()}` }),
            ...(body && { 'Content-Type': fileType || HEADER.contentTypes.json }),
            ...(params?.externalHeaders && params.externalHeaders),
          },
          ...(body && { body: withoutStringifyBody ? (body as BodyInit) : JSON.stringify(body) }),
          credentials: 'same-origin',
        }
      );

      if (!res.ok && skipJsonResIfError) {
        errorPopupConfig && dispatch(setError(errorPopupConfig));

        if (handler) {
          await handler({}, res.status);
        }
        return;
      }

      const data =
        withoutResponseData && res.ok
          ? {}
          : getWithBlob
          ? await res.blob()
          : getWithText
          ? await res.text()
          : await res.json();

      if (!res.ok && data?.message === USER_NOT_AUTHORIZED_ERROR_MESSAGE) {
        if (path === this.paths.userProfile) {
          this.signOut();
        } else {
          this.notificationsRequestsCount = MAX_NOTIFICATIONS_REQUEST_COUNT;
        }
        return;
      }

      if (
        !res.ok ||
        data.arrowStatus ||
        data.error ||
        data.errorCode ||
        [400, 500, 403, 406, 409].includes(data.status)
      ) {
        errorPopupConfig &&
          dispatch(
            setError({
              ...errorPopupConfig,
              ...(data.arrowStatus && { text: data.arrowStatus.message, title: data.arrowStatus.title }),
              ...(data.message && { text: data.message }),
            })
          );
        handler && (await handler(data, res.status));

        if (isThrow) {
          throw new Error(data.message);
        }
        return;
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.debug(err);

      const isOpenError = getSelectors(getIsOpenError);

      !isOpenError && errorPopupConfig && dispatch(setError(errorPopupConfig));
      handler && (await handler(err));

      if (isThrow) {
        throw new Error((err as Error).message);
      }
    } finally {
      if (this.notificationsRequestsCount) {
        handleNotificationsRequestCount(
          this.notificationsRequestsCount,
          this.signOut,
          this.resetNotificationsRequestsCount
        );
      }
    }
  };
}

export default new Api();

export * from './api-utils';
export * from './types';
