import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Slider } from '@material-ui/core';

import { ReactComponent as MuteIcon } from 'assets/mute.svg';
import { ReactComponent as VolumeIcon } from 'assets/volume.svg';
import styles from './MusicPlayer.module.scss';

import { IPlayerPopupsProps } from './IMusicPlayer';

interface IPlayerVolumePopupProps extends IPlayerPopupsProps {
  aRef: MutableRefObject<HTMLAudioElement | undefined>;
}

const PlayerVolumePopup = ({ isShow, popupContainerRef, aRef }: IPlayerVolumePopupProps) => {
  const [volume, setVolume] = useState({ isMuted: false, value: 1 });

  const toggleMute = useCallback(() => setVolume({ ...volume, isMuted: !volume.isMuted }), [volume]);

  const onChangeVolume = useCallback(
    (value) => setVolume({ ...volume, value, ...(volume.isMuted && { isMuted: false }) }),
    [volume]
  );

  useEffect(() => {
    const audio = aRef.current as HTMLAudioElement;

    audio.volume = volume.value;
    audio.muted = volume.isMuted;
  }, [aRef, volume.isMuted, volume.value]);

  return isShow ? (
    <div className={styles.volumeContainer} ref={popupContainerRef}>
      <Slider
        min={0}
        max={1}
        defaultValue={volume.value}
        step={0.01}
        value={volume.isMuted ? 0 : volume.value}
        orientation="vertical"
        aria-labelledby="range-slider"
        onChange={(_, data) => onChangeVolume(data)}
        classes={{
          rail: styles.rail,
          root: styles.root,
          thumb: styles.thumb,
          track: styles.track,
        }}
      />
      {volume.isMuted ? <VolumeIcon onClick={toggleMute} /> : <MuteIcon onClick={toggleMute} />}
    </div>
  ) : null;
};

export default PlayerVolumePopup;
