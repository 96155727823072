import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { useMemoSelector } from 'hooks';
import { getActiveRoute, getUserRole } from 'store';
import { ADMIN_USER_ROLES, IRoute, Paths } from 'utils';

interface IRoutesListProps {
  itemClassName: string;
  activeItemClassName: string;
  routes: IRoute[];
  hideClassName?: string;
  hideInMobileClassName?: string;
  onClick?: (route: IRoute) => void;
}

const RoutesList = ({
  onClick,
  itemClassName,
  activeItemClassName,
  hideClassName,
  routes,
  hideInMobileClassName,
}: IRoutesListProps): JSX.Element => {
  const history = useHistory();

  const { activeRoute, userRole } = useMemoSelector((state) => ({
    activeRoute: getActiveRoute(state),
    userRole: getUserRole(state),
  }));

  const onClickRoute = (route: IRoute) => {
    history.push(route.path);
    onClick && onClick(route);
  };

  return (
    <>
      {routes.map((route) =>
        route.path === Paths.admin && !ADMIN_USER_ROLES.includes(userRole)
          ? null
          : route.name && (
              <div
                key={route.id}
                onClick={() => onClickRoute(route)}
                className={classNames(itemClassName, {
                  [activeItemClassName]: route.id === activeRoute?.id || route.id === activeRoute?.relativeRouteId,
                  [hideClassName || '']: route.isForMobile,
                  [hideInMobileClassName || '']: route.hideInMobile,
                })}
              >
                {route.name}
              </div>
            )
      )}
    </>
  );
};

export default RoutesList;
