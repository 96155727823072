import { Button, PlayButton } from 'components/Reusable';
import { ButtonVariant } from 'components/Reusable/Button';
import { NewAutocomplete as Autocomplete, Tooltip } from 'components/UI';

import { getSongVersion } from 'utils';

import { ReactComponent as TrashIcon } from 'assets/trash-2.svg';
import styles from './ManualPitchForm.module.scss';

import { IAutocompleteOption } from 'types';

interface ISongRow {
  editable?: boolean;
  onChange?: (song: ISong) => void;
  onDelete: (song: ISong) => void;
  song: ISong;
  songs: ISong[];
  showResultColumn?: boolean;
}

export interface ISong {
  id: string;
  mediaId: string | null;
  path: string | null;
  playbackPath: string | null;
  result?: IAutocompleteOption;
  title: string;
  version?: string | null;
  writers?: string[];
}

const SongRow = ({ editable, onChange, onDelete, song, songs, showResultColumn }: ISongRow) => {
  const writers = song.writers?.join(' / ');

  return (
    <div className={styles.row}>
      <div className={styles.playCell}>
        <PlayButton
          extension={song.playbackPath}
          getTracks={() =>
            songs.map((item) => ({
              mediaId: item.mediaId || '',
              id: item.id,
              title: item.title || '',
              writers: item.writers || [],
              path: item.path || '',
              playbackPath: item.playbackPath || '',
            }))
          }
          mediaId={song.mediaId}
        />
      </div>
      <div className={styles.titleCell}>
        <div>
          <Tooltip text={song.title}>
            <div className={styles.title}>{song.title}</div>
          </Tooltip>
        </div>
        {writers && (
          <div>
            <Tooltip text={writers}>
              <div className={styles.description}>{writers}</div>
            </Tooltip>
          </div>
        )}
      </div>
      {showResultColumn ? (
        editable ? (
          <div className={styles.resultEditableCell}>
            <Autocomplete
              onChange={(r) => {
                song.result = (r as IAutocompleteOption | null) || undefined;
                onChange && onChange(song);
              }}
              options={[
                { id: 'KEEP', title: 'Keep' },
                { id: 'PASS', title: 'Pass' },
              ]}
              placeholder="No Result"
              value={song.result}
            />
          </div>
        ) : (
          <div className={styles.resultCell}>{song.result?.title || 'No Result'}</div>
        )
      ) : null}
      <div className={styles.versionCell}>{getSongVersion(song.version)}</div>
      <div className={styles.actionsCell}>
        <Button onClick={() => onDelete(song)} variant={ButtonVariant.Text}>
          <TrashIcon height={16} width={16} />
        </Button>
      </div>
    </div>
  );
};

export default SongRow;
