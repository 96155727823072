/* eslint-disable @typescript-eslint/no-inferrable-types */

export interface RoundingStyle {
  tl: number;
  tr: number;
  bl: number;
  br: number;
}

// Transforms canvas element to fill parent container
export const fitToContainer = (canvas: HTMLCanvasElement): void => {
  // Make it visually fill the positioned parent
  canvas.style.width = '100%';
  canvas.style.height = '100%';
  // Then set the internal size to match
  canvas.width = canvas.offsetWidth;
  canvas.height = canvas.offsetHeight;
};

/**
 * Draws a rounded rectangle using the current state of the canvas
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 0] The corner radius; It can also be an object to specify different radiuses for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = true] Whether to fill the rectangle
 * @param {Boolean} [stroke = false] Whether to stroke the rectangle
 */
export const roundRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number | Partial<RoundingStyle> = 0,
  fill: boolean = true,
  stroke: boolean = false
): void => {
  if (typeof radius === 'number') {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    radius.tr = radius.tr || 0;
    radius.tl = radius.tl || 0;
    radius.br = radius.br || 0;
    radius.bl = radius.bl || 0;
  }
  const r = radius as RoundingStyle;
  ctx.beginPath();
  ctx.moveTo(x + r.tl, y);
  ctx.lineTo(x + width - r.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + r.tr);
  ctx.lineTo(x + width, y + height - r.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - r.br, y + height);
  ctx.lineTo(x + r.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - r.bl);
  ctx.lineTo(x, y + r.tl);
  ctx.quadraticCurveTo(x, y, x + r.tl, y);
  ctx.closePath();
  if (fill) ctx.fill();
  if (stroke) ctx.stroke();
};
