import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import classNames from 'classnames';

import { ToggleBtnGroup } from 'components/Reusable';
import { SwitchBtn } from 'components/UI';

import { useMemoSelector } from 'hooks';
import { getSearchData, getUserPermissions } from 'store';

import styles from './DesktopSearch.module.scss';

import {
  AudioInclusionTypes,
  IUISearchData,
  SearchDataKeys,
  SearchDataType,
  switchButtons,
  toggleButtons,
} from './data';

interface ISwitchButtonsListProps {
  control?: Control<SearchDataType>;
  onChangeSwitch?: (checked: boolean, name?: string) => void;
  onChangeToggle?: (value: string) => void;
  className?: string;
  labelClassName?: string;
  switchBtnRootClassName?: string;
}

const SwitchButtonsList = ({
  control,
  onChangeSwitch,
  onChangeToggle,
  className,
  labelClassName,
  switchBtnRootClassName,
}: ISwitchButtonsListProps): JSX.Element => {
  const { searchData, permissions } = useMemoSelector((state) => ({
    searchData: getSearchData(state),
    permissions: getUserPermissions(state),
  }));

  const isFullVisible = useMemo(
    () => (permissions.searchSwitchButtons as IUISearchData[]).length === switchButtons.length,
    [permissions.searchSwitchButtons]
  );

  return (
    <div
      className={classNames(styles.switchBtnsContainer, className, {
        [styles.isNotFullSwitchBtnsContainer]: !isFullVisible,
      })}
    >
      {permissions.searchAudioToggle && (
        <ToggleBtnGroup
          name={SearchDataKeys.audioInclusionType}
          control={control}
          value={searchData[SearchDataKeys.audioInclusionType] as AudioInclusionTypes}
          onChangeToggle={onChangeToggle}
          items={toggleButtons}
        />
      )}
      {(permissions.searchSwitchButtons as IUISearchData[]).map(({ id, title, value }) => (
        <SwitchBtn
          checked={!!searchData[value as SearchDataKeys]}
          key={id}
          name={value as SearchDataKeys}
          control={control}
          label={title}
          labelPlacement="start"
          labelClassName={labelClassName}
          formRootClassName={switchBtnRootClassName}
          onChangeSwitch={onChangeSwitch}
        />
      ))}
    </div>
  );
};

export default SwitchButtonsList;
