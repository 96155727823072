import React from 'react';
import clsx from 'clsx';

import { ReactComponent as CloseIcon } from 'assets/loginIcons/closeIcon.svg';
import { ReactComponent as Success } from 'assets/loginIcons/success.svg';
import { ReactComponent as ErrorIcon } from 'assets/loginIcons/warning.svg';
import styles from './ResponseAlert.module.scss';

export enum ResponseAlertType {
  Error,
  Warning,
  Success,
}

export interface ResponseAlerts {
  className?: string;
  hideCloseButton?: boolean;
  onClick: () => void;
  type: ResponseAlertType;
  children: JSX.Element;
}

const renderAlertClass = (props: ResponseAlerts): string => {
  return clsx(
    `${styles.responseAlert}`,
    (typeof props.type === 'undefined' || props.type === ResponseAlertType.Success) &&
      `${styles.responseAlert_success}`,
    props.type === ResponseAlertType.Error && `${styles.responseAlert_error}`,
    props.type === ResponseAlertType.Warning && `${styles.responseAlert_warning}`,
    props.className
  );
};
const ResponseAlert = (props: ResponseAlerts) => {
  const { hideCloseButton, onClick, type, children } = props;
  return (
    <div className={renderAlertClass(props)}>
      {type === ResponseAlertType.Error && (
        <ErrorIcon className={`icon-error-info ${styles.responseAlert_icon} ${styles.responseAlert_icon_error}`} />
      )}
      {type === ResponseAlertType.Warning && (
        <ErrorIcon className={`icon-error-Warning ${styles.responseAlert_icon} ${styles.responseAlert_icon_warning}`} />
      )}
      {type === ResponseAlertType.Success && (
        <Success className={`icon-validated ${styles.responseAlert_icon} ${styles.responseAlert_icon_success}`} />
      )}
      {!hideCloseButton && <CloseIcon className={styles.responseAlert_close} onClick={onClick} />}
      <div className={styles.responseAlert_wrapper}>{children}</div>
    </div>
  );
};
export default ResponseAlert;
