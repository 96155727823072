import React from 'react';

export interface TabProps extends React.PropsWithChildren<unknown> {
  label: string | JSX.Element;
  value: string;
  hideInMobile?: boolean;
}

const Tab = (props: TabProps): JSX.Element => <div>{props.children}</div>;

export default Tab;
