import { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popover, { PopoverProps } from '@material-ui/core/Popover';

import ConfirmPopup from 'components/Popups/ConfirmPopup';

import { useDidUpdate } from 'hooks';
import Api from 'services/Api';
import { DEFAULT_ERROR_CONFIG } from 'utils';

import { ReactComponent as SearchIcon } from 'assets/search.svg';
import styles from './SongsUpload.module.scss';

import { IAutocompleteOption } from 'types';

export interface IPWPopupProps {
  anchorEl: PopoverProps['anchorEl'];
  onClose: () => void;
  onSubmit: (work: IAutocompleteOption) => void;
}

const useDebounce = <T extends unknown>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useDidUpdate(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};

const SearchBox = ({ onSearch }: { onSearch: (value: string) => void }) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 1000);

  useDidUpdate(() => onSearch(debouncedInputValue), [debouncedInputValue]);

  return (
    <div className={styles.pwSearch}>
      <input
        className={styles.pwSearchInput}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Search For PW"
        type="text"
        value={inputValue}
      />
      <SearchIcon className={styles.pwSearchIcon} />
    </div>
  );
};

const PWPopup = ({ anchorEl, onClose, onSubmit }: IPWPopupProps) => {
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [items, setItems] = useState<IAutocompleteOption[]>([]);
  const [selectedPW, setSelectedPW] = useState<IAutocompleteOption>();

  const onAdd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setConfirmPopup(true);
  };

  const onSearch = async (value: string) => {
    setSelectedPW(undefined);
    if (!value) return setItems([]);
    const works = await Api.getWorks(value, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    works && setItems(works);
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        className={styles.pwPopup}
        open
        onClose={onClose}
        PaperProps={{ classes: { root: styles.pwPaper }, square: true }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <SearchBox onSearch={onSearch} />
        <div className={styles.pwItems}>
          {items.map((item, i) => (
            <FormControlLabel
              checked={selectedPW?.id === item.id}
              className={styles.formControlLabel}
              control={<input onChange={(e) => setSelectedPW(e.target.checked ? item : undefined)} type="radio" />}
              key={i}
              label={item.title || '(no title)'}
              labelPlacement="end"
            />
          ))}
        </div>
        <div className={styles.pwFooter}>
          <button className={styles.pwAddButton} disabled={!selectedPW} onClick={onAdd}>
            Add
          </button>
        </div>
      </Popover>
      <ConfirmPopup
        btnDoneText="Add Songs"
        isOpen={confirmPopup}
        mainText="You are adding new version(s) to an existing PW"
        onClickSubmit={() => selectedPW && onSubmit(selectedPW)}
        questionText={`Add Selected Audio Files as Versions to ${selectedPW?.title || '(no title)'}?`}
        setIsOpen={() => setConfirmPopup(false)}
      />
    </>
  );
};

export default PWPopup;
