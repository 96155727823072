import React, { FunctionComponent, SVGProps } from 'react';
import classNames from 'classnames';

import { Tooltip } from 'components/UI';

import { ReactComponent as CancelMarkIcon } from 'assets/cancel-mark.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/check-mark.svg';
import styles from './DoubleIconBtnGroup.module.scss';

interface IDoubleIconBtnGroupProps {
  className?: string;
  type1?: 'button' | 'submit' | 'reset';
  type2?: 'button' | 'submit' | 'reset';
  spacing?: number;
  textSpacing?: number;
  onClick1?: (e: React.MouseEvent) => void;
  onClick2?: (e: React.MouseEvent) => void;
  disableSubmit?: boolean;
  Icon1?: FunctionComponent<SVGProps<SVGSVGElement>>;
  Icon2?: FunctionComponent<SVGProps<SVGSVGElement>>;
  name1?: string;
  name2?: string;
  tooltip1?: string;
  tooltip2?: string;
  namesClassName?: string;
  hideButton1?: boolean;
  hideButton2?: boolean;
}

const DoubleIconBtnGroup = ({
  className,
  type1,
  type2,
  spacing = 3,
  textSpacing = 5,
  onClick1,
  onClick2,
  disableSubmit,
  Icon1,
  Icon2,
  name1,
  name2,
  namesClassName,
  tooltip1,
  tooltip2,
  hideButton1 = false,
  hideButton2 = false,
}: IDoubleIconBtnGroupProps): JSX.Element => {
  return (
    <div className={classNames(styles.doubleIconBtnGroup, className)}>
      {!hideButton1 && (
        <Tooltip text={tooltip1}>
          <button
            type={type1}
            className={classNames(styles.confirmBtn, {
              [styles.disabled]: disableSubmit,
            })}
            style={{ marginRight: `${spacing}px` }}
            onClick={onClick1}
            disabled={disableSubmit}
          >
            {Icon1 ? <Icon1 /> : <CheckMarkIcon />}
            {name1 && (
              <span className={namesClassName} style={{ marginLeft: `${textSpacing}px` }}>
                {name1}
              </span>
            )}
          </button>
        </Tooltip>
      )}
      {!hideButton2 && (
        <Tooltip text={tooltip2}>
          <button type={type2} className={styles.cancelBtn} onClick={onClick2}>
            {Icon2 ? <Icon2 /> : <CancelMarkIcon />}
            {name2 && (
              <span className={namesClassName} style={{ marginLeft: `${textSpacing}px` }}>
                {name2}
              </span>
            )}
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default DoubleIconBtnGroup;
