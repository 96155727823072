import { FunctionComponent, SVGProps } from 'react';
import classNames from 'classnames';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './IconButton.module.scss';

interface IIconButtonProps {
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  text?: string;
  className?: string;
  onClick: () => void;
}

const IconButton = ({ Icon, text, onClick, className }: IIconButtonProps): JSX.Element => {
  return (
    <button onClick={onClick} className={classNames(styles.iconButton, className)}>
      {Icon && <Icon />}
      {text && <span className={globalStyles.f13h16SuisseREG_grayDarker}>{text}</span>}
    </button>
  );
};

export default IconButton;
