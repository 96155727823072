import React from 'react';
import { History } from 'history';

import { Tab } from 'components/Reusable';
import { TabProps } from 'components/Reusable/Tab';

import { TabsProps } from './Tabs.types';

export const getItems = (props: TabsProps): TabProps[] => {
  const items: TabProps[] = [];
  React.Children.forEach(React.Children.toArray(props.children), (child) => {
    if (React.isValidElement(child) && (child.type as React.ComponentType)?.name === Tab.name) {
      items.push(child.props);
    }
  });
  return items;
};

export const getHistoryTab = (props: TabsProps, items: TabProps[]): string | null => {
  if (props.disableHistory) {
    return null;
  }
  const hash = typeof window === 'undefined' ? '#' : window.location.hash;
  const urlSearchParams = new URLSearchParams(hash.replace('#', '?'));
  const urlSelectedKey = urlSearchParams.get(props.historyKey ?? 'tab');
  const item = urlSelectedKey ? items.find((item) => item.value === urlSelectedKey) : undefined;
  return item?.value ?? null;
};

export const updateHistoryTab = (
  selectedTab: string | undefined | null,
  history: History<unknown>,
  historyKey?: string
): void => {
  if (selectedTab) {
    const hash = typeof window === 'undefined' ? '#' : window.location.hash;
    const urlSearchParams = new URLSearchParams(hash.replace('#', '?'));
    urlSearchParams.set(historyKey ?? 'tab', selectedTab);
    history.push(`#${urlSearchParams.toString().replace('?', '')}`);
  }
};
