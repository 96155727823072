import { Controller, UseFormReturn } from 'react-hook-form';

import { validateRequired } from 'utils';

import styles from './SystemPitchForm.module.scss';

import { ISystemPitch, SongRow } from '.';

export interface IAddSongProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  methods: UseFormReturn<ISystemPitch, object>;
}

const AddSongs = ({ methods }: IAddSongProps) => (
  <Controller
    control={methods.control}
    name="songs"
    render={({ field: { onChange, value } }) => (
      <div>
        {(!value || value.length === 0) && <div className={styles.message}>No songs added</div>}
        {value?.map((s, i, arr) => (
          <SongRow
            key={i}
            onDelete={value.length > 1 ? (song) => onChange((value || []).filter((d) => d !== song)) : undefined}
            song={s}
            songs={arr}
          />
        ))}
      </div>
    )}
    rules={{ validate: validateRequired }}
  />
);

export default AddSongs;
