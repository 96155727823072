import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { EditTooltip } from 'components/Reusable';
import { TextEditor } from 'components/UI';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getUserId, setLoading, setSongDetails } from 'store';
import { DEFAULT_ERROR_CONFIG, getUTCTimeToShowStr, isEmptyHtmlString } from 'utils';

import { ReactComponent as RemoveIcon } from 'assets/remove.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './SongDetails.module.scss';

import { RemoveActionTypes } from './data';
import { ISong, ISongNote } from 'types';

interface ISongNotesProps {
  notes: ISongNote[];
  updateSongDetails: (closeLoading?: boolean, newSong?: Partial<ISong>) => void;
  setNotificationPopupConfig: Dispatch<SetStateAction<{ isOpen: boolean; text: string }>>;
}

const SongNotes = ({ notes, updateSongDetails, setNotificationPopupConfig }: ISongNotesProps): JSX.Element => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const userId = useMemoSelector(getUserId);

  const [activeNote, setActiveNote] = useState<ISongNote | null>(null);

  const onEditNote = async () => {
    if (!activeNote) return;

    dispatch(setLoading(true));

    const res = await Api.updateSongNote(id, activeNote.note, {
      pathId: activeNote.id,
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
    });

    if (!res) {
      dispatch(setLoading(false));
      return;
    }

    await updateSongDetails(false, {
      notes: notes.map((item) => (item.id === activeNote.id ? { ...item, ...res } : item)),
    });

    dispatch(setLoading(false));
    setNotificationPopupConfig({ isOpen: true, text: 'Notes Updated' });
  };

  const onClickRemoveNote = (createdByName: string, id: string) => {
    dispatch(
      setSongDetails({
        deletePopupConfig: {
          isOpen: true,
          questionText: `Delete ${createdByName}'s Note?`,
          mainText: 'This action cannot be undone',
          notificationText: 'Note Deleted',
          type: RemoveActionTypes.note,
          id,
        },
      })
    );
  };

  return (
    <div className={classNames(styles.notesSection, styles.sectionContainer)}>
      <span className={styles.sectionTitle}>Notes</span>
      {notes.map(({ createdByName, createdOn, note, id, createdBy }) => (
        <EditTooltip
          className={styles.editTooltipContainer}
          editIconClassName={styles.notesEditIcon}
          key={note}
          onSubmit={onEditNote}
          onOpen={() => setActiveNote({ createdByName, createdOn, note, id, createdBy })}
          disableSubmit={isEmptyHtmlString(activeNote?.note || '')}
          Component={
            <TextEditor
              value={activeNote?.note || ''}
              onChange={(value) => setActiveNote((prevState) => (prevState ? { ...prevState, note: value } : null))}
              className={styles.notesTextEditor}
            />
          }
          disableEditing={userId !== createdBy}
        >
          <div className={styles.note}>
            {userId === createdBy && (
              <div className={styles.noteBtnGroup}>
                <RemoveIcon onClick={() => onClickRemoveNote(createdByName, id)} />
              </div>
            )}
            <span className={classNames(globalStyles.f13h16SuisseSB_grayDarker, styles.noteAuthor)}>
              {createdByName}
            </span>
            <span
              className={classNames(globalStyles.f13h16SuisseREG_grayDarker, styles.noteText)}
              dangerouslySetInnerHTML={{ __html: note }}
            />
            <span className={globalStyles.f11h13SuisseREG_gray}>
              Posted {createdOn ? getUTCTimeToShowStr(createdOn) : '-'}
            </span>
          </div>
        </EditTooltip>
      ))}
    </div>
  );
};

export default SongNotes;
