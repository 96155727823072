import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Fade from '@material-ui/core/Fade';
import { GridApi } from 'ag-grid-community';

import { ConfirmPopup, DatePickerPopup, NotificationPopup, PitchesFiltersPopup } from 'components/Popups';
import { TableFilters } from 'components/Reusable';
import DataGrid, {
  CellRendererProps,
  DataGridColDef,
  DefaultColDefConfigs,
  FilteringProps,
  GetRowsHandler,
} from 'components/UI/DataGrid';
import { autoSizeActionsColumn } from 'components/UI/DataGrid/utils';
import { IInputAutocompleteData } from 'components/UI/InputAutocomplete/InputAutocomplete';

import { useDebounce, useMemoSelector, useUnMount, useWindowSize } from 'hooks';
import Api, { IGetPitchesBody } from 'services/Api';
import {
  getEditingPitchRowId,
  getIsOpenSidebar,
  getPitchesModuleConfirmPopupConfig,
  getPitchesModuleExpirationClickedId,
  getPitchesModuleFiltersData,
  getPitchesModuleIsOpenExpirationPopup,
  getPitchesModuleIsOpenFiltersPopup,
  getPitchesModuleMyFilters,
  getPitchesModuleReloadHash,
  setPitchesModule,
} from 'store';
import {
  DEFAULT_ERROR_CONFIG,
  defaultPitchesConfirmPopupConfig,
  defaultPitchesExpirationConfig,
  Paths,
  SCREEN_BREAKPOINTS,
  TABLE_PAGE_SIZE,
} from 'utils';

import styles from './Pitches.module.scss';

import { ExpandedRow } from './';
import { hiddenPitchesColumnsMobile, hiddenPitchesColumnsTablet, PitchesColumnHeaderNames } from './data';
import {
  confirmPopupConfigs,
  ConfirmPopupTypes,
  defaultPitchesModuleFilterData,
  IFilterPitchType,
  IPitchesData,
  IPitchStatus,
  PitchesColumnFields,
  pitchesColumns,
  PitchesModuleFilterKeys,
  PitchesModuleFilterTitles,
  PitchStatuses,
} from './data';

const PitchesTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowSize();
  const { desktopBig, desktopSmall, tablet } = SCREEN_BREAKPOINTS;

  const parentRef = useRef<HTMLDivElement | null>(null);
  const gridRef = useRef<GridApi | null>(null);

  const {
    filtersData,
    isOpenFiltersPopup,
    isOpenExpirationPopup,
    pitchesConfirmPopup,
    expirationClickedId,
    myFilter,
    editingPitchRowId,
    reloadHash,
    isOpenSidebar,
  } = useMemoSelector((state) => ({
    filtersData: getPitchesModuleFiltersData(state),
    isOpenFiltersPopup: getPitchesModuleIsOpenFiltersPopup(state),
    isOpenExpirationPopup: getPitchesModuleIsOpenExpirationPopup(state),
    expirationClickedId: getPitchesModuleExpirationClickedId(state),
    pitchesConfirmPopup: getPitchesModuleConfirmPopupConfig(state),
    myFilter: getPitchesModuleMyFilters(state),
    editingPitchRowId: getEditingPitchRowId(state),
    reloadHash: getPitchesModuleReloadHash(state),
    isOpenSidebar: getIsOpenSidebar(state),
  }));

  const [columns, setColumns] = useState(pitchesColumns);
  const [confirmPopupConfig, setConfirmPopupConfig] = useState({
    isOpen: false,
    questionText: '',
    ...confirmPopupConfigs[ConfirmPopupTypes.deactivate],
  });
  const [notificationPopupConfig, setNotificationPopupConfig] = useState({ isOpen: false, text: '' });
  const [expirationDate, setExpirationDate] = useState<string>();

  const getRowsHandler: GetRowsHandler<IPitchesData> = useCallback(async (params) => {
    const { startRow } = params;
    const filtersParams = params.filterModel?.filters;

    const body = {
      pagination: {
        skip: startRow,
        take: TABLE_PAGE_SIZE,
      },
      ...(filtersParams?.title && { title: filtersParams.title }),
      ...(filtersParams?.status.length && {
        status: (filtersParams.status as IPitchStatus[]).map((item) => item.type),
      }),
      ...(filtersParams?.pitchType.length && {
        type: (filtersParams.pitchType as IFilterPitchType[]).map((item) => item.type),
      }),
      ...(filtersParams?.pitchedTo && { contact: filtersParams.pitchedTo }),
      ...(filtersParams?.pitchedBy && { pitchedBy: filtersParams.pitchedBy }),
      ...(filtersParams?.sent && { sentDate: filtersParams.sent }),
      ...(filtersParams?.team?.length && {
        teamId: (filtersParams?.team as IInputAutocompleteData[]).map((item) => item.id),
      }),
      ...(filtersParams?.myFilter && { filter: filtersParams.myFilter }),
    };

    const { pitches } = (await Api.getPitches(body as unknown as IGetPitchesBody)) || {};

    return pitches;
  }, []);

  const deleteTag = (type: PitchesModuleFilterKeys): void => {
    dispatch(
      setPitchesModule({
        filtersData: {
          [type]: defaultPitchesModuleFilterData[type],
        },
      })
    );
  };

  const onChangeSingleDate = useCallback((date?: string) => {
    setExpirationDate(date || '');
  }, []);

  const onCloseDateRangePicker = useCallback(() => {
    if (isOpenExpirationPopup) {
      dispatch(setPitchesModule({ expirationConfig: defaultPitchesExpirationConfig }));
    }
  }, [dispatch, isOpenExpirationPopup]);

  const onSubmitSingleDate = useCallback(
    async (date?: string) => {
      if (!date) return;

      const res = await Api.updatePitchSystemExpiration(expirationClickedId, date, {
        errorPopupConfig: DEFAULT_ERROR_CONFIG,
      });

      if (!res) return;

      setExpirationDate(date || '');
      onCloseDateRangePicker();
      setNotificationPopupConfig({ text: 'Expiration Set', isOpen: true });
    },
    [expirationClickedId, onCloseDateRangePicker]
  );

  const handleCloseConfirmPopup = () => {
    dispatch(setPitchesModule({ confirmPopupConfig: defaultPitchesConfirmPopupConfig }));
    setConfirmPopupConfig({ ...confirmPopupConfig, isOpen: false });
  };

  const handleSubmitConfirmPopup = async () => {
    let res;

    const params = {
      pathId: pitchesConfirmPopup.id,
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
    };

    const activeNode = gridRef.current?.getRowNode(editingPitchRowId || '');

    switch (pitchesConfirmPopup.type) {
      case ConfirmPopupTypes.delete:
        res = await Api.removeManualPitch(params);
        res && activeNode?.deleteRow();
        break;
      case ConfirmPopupTypes.deactivate:
        res = await Api.removeSystemPitch(params);
        res && activeNode?.setDataValue('status', PitchStatuses.deactivated);
        break;
      case ConfirmPopupTypes.reactivate:
        res = await Api.reactivateSystemPitch(params);
        res && activeNode?.setDataValue('status', PitchStatuses.active);
        break;
      default:
        break;
    }

    handleCloseConfirmPopup();

    if (!res) return;

    setTimeout(() => {
      gridRef.current?.refreshCells({ force: true, columns: [PitchesColumnFields.actions] });
    }, 10);

    setNotificationPopupConfig({ isOpen: true, text: confirmPopupConfig.handleNotificationText });
  };

  const changeColumns = useDebounce((disabledColumns: PitchesColumnFields[]) => {
    const isMobile = windowWidth < tablet;

    setColumns((prevState) =>
      prevState.map((item) => {
        if (isMobile && item.field === PitchesColumnFields.actions) {
          return {
            ...item,
            maxWidth: 50,
            pinned: '',
            headerName: '',
          };
        }

        return item.field === 'filters'
          ? { ...item }
          : {
              ...item,
              hide: disabledColumns.includes(item.field as PitchesColumnFields),
              ...(item.field === PitchesColumnFields.actions && {
                maxWidth: 94,
                pinned: 'right',
                headerName: PitchesColumnHeaderNames.actions,
              }),
            };
      })
    );

    setTimeout(() => autoSizeActionsColumn(gridRef.current), 100);
  }, 200);

  useEffect(() => {
    if (!isOpenSidebar) {
      if (windowWidth >= desktopSmall) {
        setColumns((prevState) => prevState.map((item) => ({ ...item, hide: item.field === 'filters' })));
      }
      if (windowWidth >= tablet && windowWidth < desktopSmall) {
        changeColumns(hiddenPitchesColumnsTablet);
      }
    } else {
      if (windowWidth >= tablet && windowWidth < desktopBig) {
        changeColumns(hiddenPitchesColumnsTablet);
      }
      if (windowWidth >= desktopBig) {
        setColumns((prevState) => prevState.map((item) => ({ ...item, hide: item.field === 'filters' })));
      }
    }

    if (windowWidth < tablet) {
      changeColumns(hiddenPitchesColumnsMobile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSidebar, windowWidth, setColumns]);

  useEffect(() => {
    setConfirmPopupConfig((prevState) => ({
      ...prevState,
      ...confirmPopupConfigs[pitchesConfirmPopup.type as ConfirmPopupTypes],
      questionText: pitchesConfirmPopup.questionText,
      isOpen: !!pitchesConfirmPopup.type,
    }));
  }, [pitchesConfirmPopup]);

  useUnMount(() => {
    if (history.location.pathname.includes(`${Paths.pitches}/`)) return;
    dispatch(setPitchesModule({ filtersData: defaultPitchesModuleFilterData }));
  });

  return (
    <div ref={parentRef} className={styles.dataGridContainer}>
      <PitchesFiltersPopup parent={parentRef} />
      <TableFilters
        data={filtersData}
        dataTitles={PitchesModuleFilterTitles}
        isOpenFiltersPopup={isOpenFiltersPopup}
        onClickFilterIcon={() => dispatch(setPitchesModule({ isOpenFiltersPopup: true }))}
        onDeleteTag={deleteTag}
      />
      <DatePickerPopup
        blockPastDates
        containerClassName={styles.datePickerSnackbar}
        isSingleDayPicker
        showSingleDateActionButtons
        open={isOpenExpirationPopup}
        TransitionComponent={Fade}
        onClose={onCloseDateRangePicker}
        date={expirationDate}
        onSubmitSingleDate={onSubmitSingleDate}
        onChangeSingleDate={onChangeSingleDate}
      />
      <DataGrid
        ref={gridRef}
        disableColumnsConfig
        reloadHash={reloadHash}
        className={styles.dataGrid}
        columns={columns}
        datasource={{
          type: 'infinite',
          pageSize: TABLE_PAGE_SIZE,
          getRows: getRowsHandler,
        }}
        defaultColDef={{
          ...DefaultColDefConfigs,
          ...FilteringProps,
        }}
        isFullWidthCell={(p) => p.level === 1}
        fullWidthCellRendererFramework={(p: CellRendererProps<DataGridColDef<IPitchesData>>) => <ExpandedRow p={p} />}
        rowClassRules={{
          [styles.expandedRow]: (p) => p.node.detail,
        }}
        rowHeight={54}
        onRowClicked={({ data: { id } }) => {
          if (!id) return;

          history.push(`${Paths.pitches}/${id}`);
        }}
        initialFilterModel={{ filters: { ...filtersData, myFilter } }}
      />
      <ConfirmPopup
        isOpen={confirmPopupConfig.isOpen}
        mainText={confirmPopupConfig.mainText}
        questionText={confirmPopupConfig.questionText}
        btnDoneText={confirmPopupConfig.btnDoneText}
        setIsOpen={handleCloseConfirmPopup}
        onClickSubmit={handleSubmitConfirmPopup}
      />
      <NotificationPopup
        isOpen={notificationPopupConfig.isOpen}
        text={notificationPopupConfig.text}
        setIsOpen={() => setNotificationPopupConfig({ ...notificationPopupConfig, isOpen: false })}
      />
    </div>
  );
};

export default PitchesTable;
