import { IWriterItem } from 'types';

export enum SearchDataKeys {
  searchQuery = 'searchQuery',
  genre = 'genre',
  subGenre = 'subGenre',
  mood = 'mood',
  vocal = 'vocal',
  language = 'language',
  artists = 'artists',
  bpm = 'bpm',
  audioInclusionType = 'audioInclusionType',
  onHold = 'onHold',
  offHold = 'offHold',
  cut = 'cut',
  unreleasedCut = 'unreleasedCut',
  searchType = 'searchType',
}

export enum SongsSearchTypes {
  viewAllSongsWriters = 'VIEW_ALL_SONGS_WRITERS',
  viewAllSongs = 'VIEW_ALL_SONGS',
  songSearch = 'SONG_SEARCH',
  viewAllSongsAndWriters = 'VIEW_ALL_SONGS_AND_WRITERS',
}

export enum SearchItemsNums {
  seven = 7,
  three = 3,
}

export enum AudioInclusionTypes {
  withAudio = 'WITH_AUDIO',
  withoutAudio = 'WITHOUT_AUDIO',
  both = 'BOTH',
}

export type SearchDataType = {
  [K in SearchDataKeys]:
    | string
    | boolean
    | IUISearchData[]
    | IUISearchDataNested
    | AudioInclusionTypes
    | number[]
    | number
    | null;
};

export interface IUISearchNestedDataNested {
  id: number;
  title: string;
  typeId?: number;
}

export interface IUISearchDataNested {
  id: number;
  title: string;
  data?: IUISearchNestedDataNested[];
  hideInDashboard?: boolean;
}

export interface IUISearchData {
  id: number;
  title: string;
  name?: string;
  data?: IUISearchDataNested[];
  value?: SearchDataKeys;
}

export interface IQuickSearchSongTitles {
  id: string;
  workType: string;
  songTitle: string;
  writers: IWriterItem[];
}

export interface IBackendQuickSearchData {
  songTitles: IQuickSearchSongTitles[];
  songWriter: string[];
}

export const defaultBpmData = [0, 220];

export const defaultSearchData: SearchDataType = {
  [SearchDataKeys.searchQuery]: '',
  [SearchDataKeys.genre]: null,
  [SearchDataKeys.subGenre]: [],
  [SearchDataKeys.mood]: [],
  [SearchDataKeys.vocal]: [],
  [SearchDataKeys.language]: [],
  [SearchDataKeys.artists]: [],
  [SearchDataKeys.bpm]: defaultBpmData,
  [SearchDataKeys.cut]: false,
  [SearchDataKeys.offHold]: false,
  [SearchDataKeys.onHold]: false,
  [SearchDataKeys.unreleasedCut]: false,
  [SearchDataKeys.audioInclusionType]: AudioInclusionTypes.withAudio,
  [SearchDataKeys.searchType]: SongsSearchTypes.songSearch,
};

export const switchButtons: IUISearchData[] = [
  { id: 1, title: 'On Hold', value: SearchDataKeys.onHold },
  { id: 2, title: 'Off Hold', value: SearchDataKeys.offHold },
  { id: 3, title: 'Cut', value: SearchDataKeys.cut },
  { id: 4, title: 'Unreleased Cut', value: SearchDataKeys.unreleasedCut },
];

export const toggleButtons = [
  { title: 'With Audio', value: AudioInclusionTypes.withAudio },
  { title: 'Without Audio', value: AudioInclusionTypes.withoutAudio },
  { title: 'Both', value: AudioInclusionTypes.both },
];

export const songCategoriesTitles: { [key: string]: string } = {
  [SearchDataKeys.genre]: 'Genre',
  [SearchDataKeys.language]: 'Language',
  [SearchDataKeys.mood]: 'Mood',
  [SearchDataKeys.subGenre]: 'Sub-Genre',
  [SearchDataKeys.vocal]: 'Vocal',
  [SearchDataKeys.bpm]: 'BPM',
  [SearchDataKeys.artists]: 'Artists',
};

export const songGenresList: IUISearchDataNested[] = [
  {
    id: 1,
    title: 'Alternative',
    data: [
      { id: 1, title: 'General', typeId: 1 },
      { id: 2, title: 'Hip-Hop', typeId: 1 },
      { id: 3, title: 'Indie', typeId: 1 },
      { id: 4, title: 'Pop', typeId: 1 },
      { id: 5, title: 'R&B', typeId: 1 },
      { id: 6, title: 'Rock', typeId: 1 },
    ],
  },
  {
    id: 2,
    title: 'Chinese Pop',
  },
  {
    id: 3,
    title: 'Country',
    data: [
      { id: 1, title: 'Acoustic', typeId: 3 },
      { id: 2, title: 'Alternative', typeId: 3 },
      { id: 3, title: 'Ballad', typeId: 3 },
      { id: 4, title: 'Bluegrass', typeId: 3 },
      { id: 5, title: 'Folk', typeId: 3 },
      { id: 6, title: 'General', typeId: 3 },
      { id: 7, title: 'Holiday', typeId: 3 },
      { id: 8, title: 'Pop', typeId: 3 },
      { id: 9, title: 'Rock', typeId: 3 },
      { id: 10, title: 'Texas', typeId: 3 },
      { id: 11, title: 'Traditional', typeId: 3 },
    ],
  },
  {
    id: 4,
    title: 'Electronic',
    data: [
      { id: 1, title: 'Atmospheric', typeId: 4 },
      { id: 2, title: 'Classic', typeId: 4 },
      { id: 3, title: 'Dance', typeId: 4 },
      { id: 4, title: 'Dubstep', typeId: 4 },
      { id: 5, title: 'Experimental', typeId: 4 },
      { id: 6, title: 'General', typeId: 4 },
      { id: 7, title: 'Groovy', typeId: 4 },
      { id: 8, title: 'House', typeId: 4 },
      { id: 9, title: 'Minimal', typeId: 4 },
      { id: 10, title: 'Pop', typeId: 4 },
      { id: 11, title: 'Techno', typeId: 4 },
      { id: 12, title: 'Trap', typeId: 4 },
    ],
  },
  {
    id: 5,
    title: 'Gospel',
    data: [
      { id: 1, title: 'General', typeId: 5 },
      { id: 2, title: 'Inspirational', typeId: 5 },
      { id: 3, title: 'Soul', typeId: 5 },
      { id: 4, title: 'Southern Gospel', typeId: 5 },
      { id: 5, title: 'Spiritual', typeId: 5 },
    ],
  },
  {
    id: 6,
    title: 'Holiday',
    data: [
      { id: 1, title: 'Birthday', typeId: 6 },
      { id: 2, title: 'Christmas', typeId: 6 },
      { id: 3, title: 'Halloween', typeId: 6 },
      { id: 4, title: 'Patriotic', typeId: 6 },
      { id: 5, title: 'Religious', typeId: 6 },
      { id: 6, title: 'Valentine"s Day', typeId: 6 },
    ],
  },
  {
    id: 7,
    title: 'Jazz',
    data: [
      { id: 1, title: 'Bee Bop', typeId: 7 },
      { id: 2, title: 'Big Band', typeId: 7 },
      { id: 3, title: 'Bosanova', typeId: 7 },
      { id: 4, title: 'Contemporary', typeId: 7 },
      { id: 5, title: 'Free', typeId: 7 },
      { id: 6, title: 'Fusion', typeId: 7 },
      { id: 7, title: 'General', typeId: 7 },
      { id: 8, title: 'Latin', typeId: 7 },
      { id: 9, title: 'Soul', typeId: 7 },
      { id: 10, title: 'Swing', typeId: 7 },
    ],
  },
  {
    id: 8,
    title: 'K-Pop',
  },
  {
    id: 9,
    title: 'Latin',
    data: [
      { id: 1, title: 'Alternative', typeId: 9 },
      { id: 2, title: 'Arrocha', typeId: 9 },
      { id: 3, title: 'Bachata', typeId: 9 },
      { id: 4, title: 'Ballad', typeId: 9 },
      { id: 5, title: 'Brazilian Funk', typeId: 9 },
      { id: 6, title: 'Cumbia', typeId: 9 },
      { id: 7, title: 'Dancehall', typeId: 9 },
      { id: 8, title: 'Dembow', typeId: 9 },
      { id: 9, title: 'Electronic', typeId: 9 },
      { id: 10, title: 'Forro', typeId: 9 },
      { id: 11, title: 'Hip-Hop', typeId: 9 },
      { id: 12, title: 'Jazz', typeId: 9 },
      { id: 13, title: 'Merengue', typeId: 9 },
      { id: 14, title: 'MPB', typeId: 9 },
      { id: 15, title: 'Pop', typeId: 9 },
      { id: 16, title: 'R&B', typeId: 9 },
      { id: 17, title: 'Ragge', typeId: 9 },
      { id: 18, title: 'Reggaeton', typeId: 9 },
      { id: 19, title: 'Salsa', typeId: 9 },
      { id: 20, title: 'Samba', typeId: 9 },
      { id: 21, title: 'Sertanejo', typeId: 9 },
      { id: 22, title: 'Arrocha', typeId: 9 },
      { id: 23, title: 'Trap', typeId: 9 },
    ],
  },
  {
    id: 10,
    title: 'Asia Pacific',
    hideInDashboard: true,
  },
  {
    id: 11,
    title: 'Pop',
    data: [
      { id: 1, title: 'Acoustic', typeId: 11 },
      { id: 2, title: 'Alternative', typeId: 11 },
      { id: 3, title: 'Ballad', typeId: 11 },
      { id: 4, title: 'Electronic', typeId: 11 },
      { id: 5, title: 'Folk', typeId: 11 },
      { id: 6, title: 'Funk', typeId: 11 },
      { id: 7, title: 'General', typeId: 11 },
      { id: 8, title: 'Latin', typeId: 11 },
      { id: 9, title: 'R&B', typeId: 11 },
      { id: 10, title: 'Rock', typeId: 11 },
      { id: 11, title: 'Soul', typeId: 11 },
      { id: 12, title: 'Hip-Hop', typeId: 11 },
    ],
  },
  {
    id: 12,
    title: 'R&B',
    data: [
      { id: 1, title: 'Acoustic', typeId: 12 },
      { id: 2, title: 'Alternative', typeId: 12 },
      { id: 3, title: 'Ballad', typeId: 12 },
      { id: 4, title: 'Funk', typeId: 12 },
      { id: 5, title: 'General', typeId: 12 },
      { id: 6, title: 'Hip-Hop', typeId: 12 },
      { id: 7, title: 'Motown', typeId: 12 },
      { id: 8, title: 'Neo-Soul', typeId: 12 },
      { id: 9, title: 'Pop', typeId: 12 },
      { id: 10, title: 'Soul', typeId: 12 },
    ],
  },
  {
    id: 13,
    title: 'Reggae',
    data: [{ id: 1, title: 'General', typeId: 13 }],
  },
  {
    id: 14,
    title: 'Rock',
    data: [
      { id: 1, title: 'Alternative', typeId: 14 },
      { id: 2, title: 'Ballad', typeId: 14 },
      { id: 3, title: 'General', typeId: 14 },
      { id: 4, title: 'Heavy Metal', typeId: 14 },
      { id: 5, title: 'Indie', typeId: 14 },
      { id: 6, title: 'Latin', typeId: 14 },
      { id: 7, title: 'Motown', typeId: 14 },
      { id: 8, title: 'Pop', typeId: 14 },
      { id: 9, title: 'Punk', typeId: 14 },
    ],
  },
  {
    id: 15,
    title: 'Singer/Songwriter',
    data: [{ id: 1, title: 'Acoustic', typeId: 15 }],
  },
  {
    id: 16,
    title: 'Sync',
    data: [{ id: 1, title: 'General', typeId: 18 }],
  },
  {
    id: 17,
    title: 'Hip-Hop',
    data: [
      { id: 1, title: 'Alternative', typeId: 16 },
      { id: 2, title: 'Ballad', typeId: 16 },
      { id: 3, title: 'General', typeId: 16 },
      { id: 5, title: 'Latin', typeId: 16 },
      { id: 6, title: 'Pop', typeId: 16 },
      { id: 7, title: 'R&B', typeId: 16 },
      { id: 8, title: 'Rap', typeId: 16 },
      { id: 9, title: 'Soul', typeId: 16 },
      { id: 10, title: 'Trap', typeId: 16 },
    ],
  },
  {
    id: 18,
    title: 'World',
    data: [{ id: 1, title: 'General', typeId: 17 }],
  },
];

export const songMoodsList: IUISearchDataNested[] = [
  { id: 1, title: 'Aggressive' },
  { id: 2, title: 'Anthemic' },
  { id: 3, title: 'Ballin' },
  { id: 4, title: 'Bouncy' },
  { id: 5, title: 'Breakup' },
  { id: 6, title: 'Celebratory' },
  { id: 7, title: 'Club' },
  { id: 8, title: 'Confidence' },
  { id: 9, title: 'Crossover' },
  { id: 10, title: 'Dark' },
  { id: 11, title: 'Edgy' },
  { id: 12, title: 'Empowerment' },
  { id: 13, title: 'Friends/Friendship' },
  { id: 14, title: 'Fun' },
  { id: 15, title: 'Good Vibration' },
  { id: 16, title: 'Guitar' },
  { id: 17, title: 'Happy' },
  { id: 18, title: 'Hard' },
  { id: 19, title: 'Haunting/Mysterious' },
  { id: 20, title: 'High Energy' },
  { id: 21, title: 'Home' },
  { id: 22, title: 'Hopeful' },
  { id: 23, title: 'Inspired' },
  { id: 24, title: 'Instrumentation' },
  { id: 25, title: 'Lively' },
  { id: 26, title: 'Love' },
  { id: 27, title: 'Love Life/Family' },
  { id: 28, title: 'Love Song' },
  { id: 29, title: 'Mid-Tempo' },
  { id: 30, title: 'Money' },
  { id: 31, title: 'Moody' },
  { id: 32, title: 'Non-Romantic Love Songs' },
  { id: 33, title: 'Piano' },
  { id: 34, title: 'Playful' },
  { id: 35, title: 'Sad' },
  { id: 36, title: 'Samples' },
  { id: 37, title: 'Sexy' },
  { id: 38, title: 'Soft' },
  { id: 39, title: 'Sorrow' },
  { id: 40, title: 'Sparse' },
  { id: 41, title: 'Street' },
  { id: 42, title: 'Stripped Down' },
  { id: 43, title: 'Summer' },
  { id: 44, title: 'Swagger' },
  { id: 45, title: 'Togetherness' },
  { id: 46, title: 'Trap' },
  { id: 47, title: 'Turn Up' },
  { id: 48, title: 'Upbeat' },
  { id: 49, title: 'Uplifting' },
  { id: 50, title: 'West Coast' },
];

export const songVocalsList = [
  { id: 1, title: 'Acapella' },
  { id: 2, title: 'Duet Female' },
  { id: 3, title: 'Duet Female/Male' },
  { id: 4, title: 'Duet Male' },
  { id: 5, title: 'Female' },
  { id: 6, title: 'Group' },
  { id: 7, title: 'Male' },
  { id: 8, title: 'Rap' },
  { id: 9, title: 'Vocal Samples' },
];

export const songLanguagesList = [
  { id: 1, title: 'Cantonese' },
  { id: 2, title: 'Danish' },
  { id: 3, title: 'Dutch' },
  { id: 4, title: 'English' },
  { id: 5, title: 'French' },
  { id: 6, title: 'Italian' },
  { id: 7, title: 'Japanese' },
  { id: 8, title: 'Korean' },
  { id: 9, title: 'Malay' },
  { id: 10, title: 'Mandarin' },
  { id: 11, title: 'Polish' },
  { id: 12, title: 'Portuguese' },
  { id: 13, title: 'Russian' },
  { id: 14, title: 'Spanish' },
  { id: 15, title: 'Swedish' },
];

export const songCategories: IUISearchData[] = [
  {
    id: 1,
    title: songCategoriesTitles[SearchDataKeys.genre],
    value: SearchDataKeys.genre,
    data: songGenresList,
  },
  {
    id: 2,
    title: songCategoriesTitles[SearchDataKeys.subGenre],
    value: SearchDataKeys.subGenre,
    data: [],
  },
  {
    id: 3,
    title: songCategoriesTitles[SearchDataKeys.mood],
    value: SearchDataKeys.mood,
    data: songMoodsList,
  },
  {
    id: 4,
    title: songCategoriesTitles[SearchDataKeys.vocal],
    value: SearchDataKeys.vocal,
    data: songVocalsList,
  },
  {
    id: 5,
    title: songCategoriesTitles[SearchDataKeys.language],
    value: SearchDataKeys.language,
    data: songLanguagesList,
  },
];
