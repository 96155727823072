import { AssetStatus } from 'services/Api';

/**
 * Gets percentage of asset processing progress
 * @param assetStatus Asset status
 * @returns Percentage or undefined if processing completed
 */
export const getProcessingPercentage = (assetStatus: AssetStatus | undefined | null): number | undefined => {
  if (!assetStatus) {
    return 0;
  }
  if (assetStatus === AssetStatus.Uploaded) {
    return 25;
  }
  if (assetStatus === AssetStatus.Processing) {
    return 50;
  }
  return undefined;
};

/**
 * Normalizes value for futher comparison. Empty string, empty array and undefined values will be converted to null.
 * @param value Value to normalize
 * @param defaultValue Will be returned if current value is an empty string, empty array or undefined.
 * Undefined can't be used as a default value.
 * @returns Normalized value
 */
export const normalizeValueToCompare = <T>(value: T, defaultValue?: T): T | null => {
  if (
    typeof value === 'undefined' ||
    (typeof value === 'string' && value === '') ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return typeof defaultValue === 'undefined' ? null : defaultValue;
  }
  return value;
};
