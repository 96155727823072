import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';
import classNames from 'classnames';

import { InputAsyncAutocomplete, InputAutocomplete, InputRange } from 'components/UI';
import { IInputAutocompleteOption } from 'components/UI/InputAsyncAutocomplete';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getSearchData } from 'store';

import styles from './DesktopSearch.module.scss';

import { IUISearchData, SearchDataKeys, SearchDataType, songCategories } from './data';

interface ISearchAutocompletesListProps {
  control: Control<SearchDataType>;
  setValue: UseFormSetValue<SearchDataType>;
  disabledBpm?: boolean;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  className?: string;
  type?: 'create' | 'search';
  withBPM?: boolean;
}

const SearchAutocompletesList = ({
  control,
  setValue,
  disabledBpm,
  labelPlacement = 'start',
  className,
  type,
  withBPM = true,
}: ISearchAutocompletesListProps): JSX.Element => {
  const genre = useWatch({ control, name: SearchDataKeys.genre }) as unknown as IUISearchData;

  const searchData = useMemoSelector(getSearchData);

  const [subGenreList, setSubGenreList] = useState<IUISearchData[]>([]);

  const isForCreate = type === 'create';

  useEffect(() => {
    if (!isEqual(searchData.genre, genre)) {
      setValue(SearchDataKeys.subGenre, []);
    }

    if (!genre) {
      setValue(SearchDataKeys.subGenre, []);
      setSubGenreList([]);
      return;
    }

    const allSubgenres = songCategories[0].data?.find((item) => item.title === genre.title)?.data;
    setSubGenreList(allSubgenres || []);
  }, [searchData.genre, genre, setValue]);

  return (
    <div className={classNames(styles.autocompleteContainer, className)}>
      {songCategories.map(({ title, value, data, id }) => (
        <InputAutocomplete
          multiple={value !== SearchDataKeys.genre}
          disableClearable={value !== SearchDataKeys.genre}
          key={id}
          name={value as SearchDataKeys}
          label={title}
          data={value === SearchDataKeys.subGenre ? subGenreList : (data as IUISearchData[])}
          control={control}
          labelPlacement={labelPlacement}
        />
      ))}
      <Controller
        control={control}
        name={SearchDataKeys.artists}
        render={({ field: { onChange, value } }) => (
          <InputAsyncAutocomplete
            multiple
            addCustomOption={isForCreate}
            label="Artist"
            labelPlacement={labelPlacement}
            value={value as IInputAutocompleteOption[]}
            onChange={(_, value) => onChange(value)}
            inputBaseClassName={styles.artistsInputBaseClassName}
            autocompleteClassName={styles.artistsAutocomplete}
            className={isForCreate ? styles.addTagsArtistsAutocompleteLabel : styles.searchArtistsAutocompleteLabel}
            onResolveSuggestions={(text) => Api.getArtists(text).catch(() => [])}
            disableClearable
            disableCloseOnSelect
          />
        )}
      />
      {withBPM && (
        <InputRange
          name={SearchDataKeys.bpm}
          label="BPM"
          singleValue={isForCreate}
          control={control}
          disabledBpm={disabledBpm}
          labelPlacement={labelPlacement}
        />
      )}
    </div>
  );
};

export default SearchAutocompletesList;
