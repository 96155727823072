import { FC, MutableRefObject } from 'react';
import { Dialog } from '@material-ui/core';
import classNames from 'classnames';

import { useMemoSelector } from 'hooks';
import { getIsOpenSidebar } from 'store';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import styles from './FiltersPopupWrapper.module.scss';

export interface IFiltersPopupWrapperProps {
  isOpen: boolean;
  onCancel: () => void;
  component: FC;
  parent?: MutableRefObject<HTMLDivElement | null>;
}

const FiltersPopupWrapper = ({ isOpen, onCancel, parent, component: FiltersComponent }: IFiltersPopupWrapperProps) => {
  const isOpenSidebar = useMemoSelector(getIsOpenSidebar);

  return (
    <Dialog
      className={classNames(styles.dialog, {
        [styles.openSidebar]: isOpenSidebar,
      })}
      BackdropProps={{ className: styles.dialogBackdrop }}
      PaperProps={{ className: styles.dialogPaper }}
      open={isOpen}
      onClose={onCancel}
      container={parent?.current}
    >
      <div className={styles.dialogHeader}>
        <span>Filter</span>
        <CloseIcon onClick={onCancel} />
      </div>
      <FiltersComponent />
    </Dialog>
  );
};

export default FiltersPopupWrapper;
