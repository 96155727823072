import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { Path } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import {
  AdditionalUserDataKeys,
  adminControlsAdditionalData,
  adminControlsData,
  ColumnFields,
  ColumnHeaderNames,
  IAdminControlsAdditionalData,
  IAdminControlsData,
  ROLES,
  statusTitles,
  UserRoles,
} from 'components/AdminControls/data';
import { ConfirmPopup, NotificationPopup } from 'components/Popups';
import { DoubleBtnGroup } from 'components/Reusable';
import { InputAsyncAutocomplete, InputAutocomplete, StatusCard, Tooltip } from 'components/UI';
import { UserStatuses } from 'components/UI/StatusCard/data';

import { useMemoSelector } from 'hooks';
import Api, { ITeamResBody, updateStatusTabValues, updateUser, UserStatusActions } from 'services/Api';
import {
  getAdminControlsActiveEditingUserData,
  getAdminControlsActiveEditingUserRowId,
  getAdminControlsFiltersData,
  getAdminControlsIsOpenEditPopup,
  getUserId,
  getUserTeamsForAutocomplete,
  setAdminControls,
} from 'store';
import { IUserData } from 'store/reducers/general/types';
import { ADMIN_EDIT_POPUP_ID, camelize, DEFAULT_ERROR_CONFIG, getGUID, UserRoleCodes } from 'utils';

import { ReactComponent as RejectIcon } from 'assets/reject.svg';
// import { ReactComponent as ResendIcon } from 'assets/resend.svg';
import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import styles from './AdminControlsEditPopup.module.scss';

import editUserData, { confirmPopupConfig, IConfirmPopupState, IEditingData, IEditUserData } from './data';
import { IAdminControlsNestedData } from 'types';

interface IEditPopupProps {
  gridRef: MutableRefObject<GridApi | null>;
  onClose?: () => void;
  parent?: MutableRefObject<HTMLDivElement | null>;
}

const AdminControlsEditPopup = ({ onClose, parent, gridRef }: IEditPopupProps) => {
  const dispatch = useDispatch();

  const { isOpenEditPopup, activeEditingUserData, activeEditingUserRowId, filtersData, teams, userId } =
    useMemoSelector((state) => ({
      isOpenEditPopup: getAdminControlsIsOpenEditPopup(state),
      activeEditingUserData: getAdminControlsActiveEditingUserData(state),
      activeEditingUserRowId: getAdminControlsActiveEditingUserRowId(state),
      filtersData: getAdminControlsFiltersData(state),
      teams: getUserTeamsForAutocomplete(state),
      userId: getUserId(state),
    }));

  const [editingData, setEditingData] = useState<IEditingData>({ role: null, team: null, teamAdmin: 'No' });
  const [isPending, setIsPending] = useState<boolean>(false);
  const [confirmPopupSetting, setConfirmPopupSetting] = useState<IConfirmPopupState>({
    isOpen: false,
    ...confirmPopupConfig.archive,
  });
  const [notificationSettings, setNotificationSettings] = useState({ isOpen: false, text: '' });

  const isPendingUser = activeEditingUserData?.status === UserStatuses.PENDING;
  const isArchivedUser = activeEditingUserData?.status === UserStatuses.ARCHIVED;
  const isRejectedUser = activeEditingUserData?.status === UserStatuses.REJECTED;
  const isActiveUser = activeEditingUserData?.status === UserStatuses.ACTIVE;

  // Selected Role
  const isSelectedRoleArrowRead = [UserRoles.ARROW_READ, UserRoleCodes.ArrowReadOnly].includes(
    editingData.role as UserRoles
  );
  const isSelectedRoleLabel = [UserRoles.LABEL, UserRoleCodes.Label].includes(editingData.role as UserRoles);
  const isSelectedRolePartner = [UserRoles.PARTNER, UserRoleCodes.Partner].includes(editingData.role as UserRoles);
  const isSelectedRoleSongWriter = [UserRoles.SONGWRITER, UserRoleCodes.Songwriter].includes(
    editingData.role as UserRoles
  );

  const editData = useMemo(() => {
    if (isSelectedRoleLabel) {
      return [
        ...editUserData.slice(0, 3),
        editUserData[4],
        adminControlsData[1],
        adminControlsAdditionalData[0],
        adminControlsAdditionalData[1],
      ];
    } else if (isSelectedRolePartner) {
      return [
        ...editUserData.slice(0, 3),
        editUserData[4],
        adminControlsData[1],
        adminControlsAdditionalData[2],
        adminControlsData[2],
      ];
    } else if (isSelectedRoleSongWriter) {
      return [...editUserData.slice(0, 3), adminControlsData[1], adminControlsData[2]];
    } else if (isSelectedRoleArrowRead) {
      return [...editUserData.slice(0, 4), adminControlsData[1], adminControlsData[2]];
    } else {
      return [...editUserData.slice(0, 4), adminControlsData[1], adminControlsData[2], adminControlsData[3]];
    }
  }, [isSelectedRoleArrowRead, isSelectedRoleLabel, isSelectedRolePartner, isSelectedRoleSongWriter]);

  const isInvalid = useMemo(() => {
    if (isSelectedRoleLabel) {
      return !editingData.role || !editingData.label?.title;
    }

    if (isSelectedRolePartner) {
      return !editingData.role || !editingData.company?.title || !editingData.team;
    }

    if (isSelectedRoleSongWriter) {
      return !editingData.role || (!editingData.team && !isSelectedRoleArrowRead);
    }

    return !editingData.role || ((!editingData.team || !editingData.teamAdmin) && !isSelectedRoleArrowRead);
  }, [editingData, isSelectedRoleArrowRead, isSelectedRoleLabel, isSelectedRolePartner, isSelectedRoleSongWriter]);

  useEffect(() => {
    isOpenEditPopup &&
      setEditingData({
        role: activeEditingUserData?.role || null,
        team: [UserStatuses.ARCHIVED, UserStatuses.REJECTED].includes(activeEditingUserData?.status as UserStatuses)
          ? null
          : activeEditingUserData?.defaultTeam?.name || null,
        teamAdmin: activeEditingUserData?.isTeamAdmin ? 'Yes' : 'No',
        ...(activeEditingUserData?.company && { company: { id: getGUID(), title: activeEditingUserData.company } }),
        ...(activeEditingUserData?.defaultTeam?.parent
          ? {
              label: {
                id: activeEditingUserData.defaultTeam.parent.id,
                title: activeEditingUserData.defaultTeam.parent.name,
              },
            }
          : {
              label: {
                id: activeEditingUserData?.defaultTeam?.id || '',
                title: activeEditingUserData?.defaultTeam?.name || '',
              },
            }),
        ...(activeEditingUserData?.defaultTeam?.parent && {
          subLabel: { id: activeEditingUserData.defaultTeam.id, title: activeEditingUserData.defaultTeam.name },
        }),
      });
  }, [isOpenEditPopup, setEditingData, activeEditingUserData, isActiveUser]);

  const handleClose = () => {
    dispatch(setAdminControls({ isOpenEditPopup: false }));
    setConfirmPopupSetting({ ...confirmPopupSetting, isOpen: false });
    onClose && onClose();
  };

  const handleNotification = async (text: string, type?: UserStatusActions | '', isOpenConfirmPopup?: boolean) => {
    setIsPending(true);

    const { setupUserDefault, archive, approve, reject, restore, resend } = UserStatusActions;

    if (type === restore && isOpenConfirmPopup) {
      setConfirmPopupSetting({ isOpen: true, ...confirmPopupConfig.restore });
      return;
    }

    // TODO: add request for resend email
    if (type === resend) {
      setNotificationSettings({ isOpen: true, text });
      handleClose();
      return;
    }

    let response;

    const defaultTeamId =
      !isSelectedRoleArrowRead && !isSelectedRoleLabel
        ? { defaultTeamId: teams.find((item) => item.title === editingData.team)?.id || '' }
        : isSelectedRoleLabel
        ? { defaultTeamId: editingData.subLabel?.id || editingData.label?.id }
        : {};
    const role = ROLES.find(({ title, code }) => [title, code].includes(editingData.role as string))?.code;

    if (type) {
      response = await Api.changeUserStatus(
        {
          body: {
            ...([reject, archive, restore, approve, setupUserDefault].includes(type) && {
              id: activeEditingUserData?.id,
            }),
            ...([restore, approve, setupUserDefault].includes(type) && {
              role,
              ...defaultTeamId,
              isTeamAdmin:
                !isSelectedRoleArrowRead &&
                !isSelectedRoleLabel &&
                !isSelectedRolePartner &&
                !isSelectedRoleSongWriter &&
                editingData?.teamAdmin === 'Yes',
            }),
            ...((isSelectedRolePartner || isSelectedRoleLabel) && {
              company: editingData.company?.title,
            }),
          },
          path: type,
        },
        { errorPopupConfig: { ...DEFAULT_ERROR_CONFIG, containerId: ADMIN_EDIT_POPUP_ID } }
      );
    }

    setIsPending(false);

    const activeNode = gridRef.current?.getRowNode(activeEditingUserRowId || '');

    if (response) {
      activeNode?.setData({
        ...activeNode.data,
        [ColumnFields.role]: response.role,
        [ColumnFields.team]: response.defaultTeam,
        [ColumnFields.isTeamAdmin]: response.isTeamAdmin,
        [AdditionalUserDataKeys.company]: response.company,
      });

      if (
        !filtersData.status ||
        filtersData.status === 'All' ||
        (filtersData.status as IAdminControlsNestedData)?.title === response.status
      ) {
        activeNode?.setDataValue(ColumnFields.status, response.status);
      } else {
        activeNode?.deleteRow();

        if (activeNode?.expanded) {
          activeNode.expanded = false;
        }
      }

      if (activeNode?.expanded) {
        gridRef.current?.redrawRows({ rowNodes: [activeNode.detailNode] });
      }

      setTimeout(() => {
        gridRef.current?.refreshCells({ force: true, columns: [ColumnFields.actions, ColumnFields.index] });
      });

      handleClose();
      setNotificationSettings({ isOpen: true, text });

      await updateStatusTabValues();

      if (activeEditingUserData?.id === userId) {
        await updateUser();
      }
    }
  };

  const addNewTeam = async (name: string, type: AdditionalUserDataKeys) => {
    const res: ITeamResBody = await Api.createTeam({
      name,
      parentId: type === AdditionalUserDataKeys.label ? null : (editingData.label?.id as string),
    });

    if (!res) return;

    onChangeInput({ title: res.name, id: res.id }, type);
  };

  const onChangeInput = (data: IAdminControlsNestedData, type: Path<IEditingData>) => {
    const title = data?.title || '';
    const isAsyncValue = [
      AdditionalUserDataKeys.label,
      AdditionalUserDataKeys.subLabel,
      AdditionalUserDataKeys.company,
    ].includes(type as AdditionalUserDataKeys);

    const camelType = camelize(type);

    setEditingData({
      ...editingData,
      [camelType]: isAsyncValue ? data : title,
      ...(title === UserRoles.ARROW_READ && { team: '', teamAdmin: 'No' }),
      ...(camelType === 'role' && { team: '', label: undefined }),
      ...(type === AdditionalUserDataKeys.label && { [AdditionalUserDataKeys.subLabel]: undefined }),
    });
  };

  return (
    <>
      <Dialog
        className={styles.dialog}
        BackdropProps={{ className: styles.dialogBackdrop }}
        PaperProps={{
          id: ADMIN_EDIT_POPUP_ID,
          className: classNames(styles.dialogPaper, { [styles.whiteBackground]: confirmPopupSetting.isOpen }),
        }}
        open={isOpenEditPopup}
        onClose={handleClose}
        container={parent?.current}
      >
        <div className={styles.editHeader}>
          <span className={styles.editHeaderTitle}>
            {activeEditingUserData?.firstName} {activeEditingUserData?.lastName}
          </span>
          {activeEditingUserData?.status && <StatusCard value={statusTitles[activeEditingUserData.status]} />}
        </div>
        <div className={styles.editItemsContainer}>
          {editData.map((item) => {
            const dataList = ((item.title === ColumnHeaderNames.team ? teams : (item as IAdminControlsData)?.data) ||
              []) as IAdminControlsNestedData[];

            return (
              <div
                key={item.id}
                className={classNames(styles.editItemContainer, {
                  [styles.withMarginBottom]: isSelectedRoleSongWriter && (item as IEditUserData).dataKey === 'lastName',
                })}
              >
                <div className={styles.editItemTitle}>
                  {item.title === ColumnHeaderNames.team ? ColumnHeaderNames.defaultTeam : item.title}
                  {(item?.required ||
                    ([ColumnHeaderNames.team, ColumnHeaderNames.teamAdmin].includes(item.title as ColumnHeaderNames) &&
                      !isSelectedRoleArrowRead &&
                      editingData.role)) && <span>*</span>}
                </div>
                {(item as IEditUserData).dataKey && activeEditingUserData ? (
                  <div className={styles.editItemDataKey}>
                    <span>{activeEditingUserData[(item as IEditUserData).dataKey as keyof IUserData]}</span>
                  </div>
                ) : (item as IAdminControlsData)?.data ? (
                  <InputAutocomplete
                    multiple={false}
                    value={
                      dataList?.find(
                        (elem) =>
                          (editingData[camelize(item.title) as keyof IEditingData] as string)?.includes(elem.title) ||
                          (elem?.code &&
                            (editingData[item.title.toLowerCase() as keyof IEditingData] as string)?.includes(
                              elem.code
                            ))
                      ) || null
                    }
                    data={dataList}
                    disableClearable={false}
                    onChangeInput={onChangeInput}
                    name={item.title as never}
                    disabled={
                      [ColumnHeaderNames.team, ColumnHeaderNames.teamAdmin].includes(item.title as ColumnHeaderNames) &&
                      (isSelectedRoleArrowRead || !editingData.role)
                    }
                    autocompleteRootClass={classNames(styles.autocompleteRoot, {
                      [styles.autocompleteRootDisabled]:
                        item.title === ColumnHeaderNames.team && (isSelectedRoleArrowRead || !editingData.role),
                    })}
                    autocompleteInputRootReplaceClass={styles.autocompleteInputRoot}
                    autocompleteInputClass={styles.autocompleteInput}
                    autocompleteEndAdornmentClass={styles.autocompleteEndAdornment}
                    autocompletePopperClass={styles.autocompletePopper}
                    autocompletePaperClass={styles.autocompletePaper}
                  />
                ) : (
                  <InputAsyncAutocomplete
                    value={editingData[(item as IAdminControlsAdditionalData).value]}
                    onChange={(_, value) => {
                      onChangeInput(value as IAdminControlsNestedData, (item as IAdminControlsAdditionalData).value);
                    }}
                    disabled={item.title === ColumnHeaderNames.subLabel && !editingData.label}
                    addNewButtonOnClick={(inputValue) => {
                      [AdditionalUserDataKeys.label, AdditionalUserDataKeys.subLabel].includes(
                        (item as IAdminControlsAdditionalData).value
                      )
                        ? addNewTeam(inputValue, (item as IAdminControlsAdditionalData).value)
                        : onChangeInput(
                            { title: inputValue, id: getGUID() },
                            (item as IAdminControlsAdditionalData).value
                          );
                    }}
                    addNotFoundedOptionText={item.title}
                    className={styles.asyncAutocomplete}
                    onResolveSuggestions={(text) =>
                      item.title === ColumnHeaderNames.subLabel
                        ? (item as IAdminControlsAdditionalData)
                            .apiMethod(text, (editingData.label?.id as string) || '')
                            .catch(() => [])
                        : (item as IAdminControlsAdditionalData).apiMethod(text).catch(() => [])
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.editFooter}>
          <div className={styles.iconsContainer}>
            {isPendingUser || isArchivedUser ? (
              <Tooltip text="Deactivate User">
                <RejectIcon
                  onClick={() =>
                    setConfirmPopupSetting({
                      isOpen: true,
                      ...confirmPopupConfig.reject,
                      questionText: `Reject ${activeEditingUserData?.firstName} ${activeEditingUserData?.lastName}?`,
                    })
                  }
                />
              </Tooltip>
            ) : null}
            {!isArchivedUser && (
              <Tooltip text="Archive User">
                <TrashIcon
                  onClick={() =>
                    setConfirmPopupSetting({
                      isOpen: true,
                      ...confirmPopupConfig.archive,
                      questionText: `Archive ${activeEditingUserData?.firstName} ${activeEditingUserData?.lastName}?`,
                    })
                  }
                />
              </Tooltip>
            )}
            {/* Note: this functionality should work later */}
            {/*{isActiveUser && (*/}
            {/*  <Tooltip text="Resend Setup Email">*/}
            {/*    <ResendIcon onClick={() => setConfirmPopupSetting({ isOpen: true, ...confirmPopupConfig.resend })} />*/}
            {/*  </Tooltip>*/}
            {/*)}*/}
          </div>
          <DoubleBtnGroup
            name1="Cancel"
            name2={isArchivedUser ? 'Restore User' : isPendingUser ? 'Approve' : 'Save Changes'}
            onClick1={handleClose}
            onClick2={() =>
              handleNotification(
                isArchivedUser ? 'User Restored' : isPendingUser ? 'User Approved' : 'Changes Saved',
                isArchivedUser
                  ? UserStatusActions.restore
                  : isPendingUser || isRejectedUser
                  ? UserStatusActions.approve
                  : UserStatusActions.setupUserDefault,
                isArchivedUser
              )
            }
            isPending={isPending}
            disabled={isInvalid}
          />
        </div>
        <ConfirmPopup
          isOpen={confirmPopupSetting.isOpen}
          setIsOpen={(isOpen) => setConfirmPopupSetting({ ...confirmPopupSetting, isOpen })}
          questionText={confirmPopupSetting.questionText}
          mainText={confirmPopupSetting.mainText}
          btnCancelText={confirmPopupSetting.btnCancelText}
          btnDoneText={confirmPopupSetting.btnDoneText}
          onClickSubmit={() =>
            handleNotification(confirmPopupSetting.handleNotificationText, confirmPopupSetting.statusAction)
          }
        />
      </Dialog>
      <NotificationPopup
        isOpen={notificationSettings.isOpen}
        setIsOpen={() => setNotificationSettings({ ...notificationSettings, isOpen: false })}
        text={notificationSettings.text}
      />
    </>
  );
};

export default AdminControlsEditPopup;
