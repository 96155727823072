import FavoriteContainer from './FavoriteContainer';
import MarkerControl from './MarkerControl';
import MarkersContainer from './MarkersContainer';
import MarkersPopup from './MarkersPopup';
import MusicPlayer from './MusicPlayer';
import PlaybarHeader from './PlaybarHeader';
import PlaybarHeaderTitle from './PlaybarHeaderTitle';
import PlayControls from './PlayControls';
import PlayerActions from './PlayerActions';
import PlayerAudio from './PlayerAudio';
import PlayerMorePopup from './PlayerMorePopup';
import PlayerNotifications from './PlayerNotifications';
import PlayerVolumePopup from './PlayerVolumePopup';

export default MusicPlayer;
export type {
  IFavoriteConfig,
  IPlayControlsProps,
  IPlayerNotificationsProps,
  IPlayerPopupsProps,
  MusicPlayerProps,
  TrackInfo,
} from './IMusicPlayer';
export {
  FavoriteContainer,
  MarkerControl,
  MarkersContainer,
  MarkersPopup,
  PlaybarHeader,
  PlaybarHeaderTitle,
  PlayControls,
  PlayerActions,
  PlayerAudio,
  PlayerMorePopup,
  PlayerNotifications,
  PlayerVolumePopup,
};
