import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';

import { DatePickerPopup } from 'components/Popups/index';
import { ContactsAsyncAutocomplete, DoubleBtnGroup } from 'components/Reusable';
import { InputKeywords, InputText } from 'components/UI';
import { KeywordsValueType } from 'components/UI/InputKeywords/InputKeywords';

import { useMemoSelector } from 'hooks';
import Api, { IShareInternalBody } from 'services/Api';
import {
  getIsOpenSharePopup,
  getSharedPlaylistId,
  getSharedSongId,
  getShareItemType,
  getUserPermissions,
  setLoading,
  setMainNotification,
  setShareConfig,
} from 'store';
import { ShareItemTypes } from 'store/reducers/general/types';
import {
  DEFAULT_ERROR_CONFIG,
  DEFAULT_SHARE_CONFIG,
  getDateDiffFromNow,
  getFormattedDate,
  isValidEmail,
  Paths,
  sendAnalyticsEvent,
} from 'utils';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import styles from './ShareSongPopup.module.scss';

import { IChangeExpirationConfig, IShareToContacts } from './data';
import { AnalyticsEventTypes } from 'types';

const ShareSongPopup = () => {
  const dispatch = useDispatch();

  const { isOpen, songId, type, playlistId, permissions } = useMemoSelector((state) => ({
    isOpen: getIsOpenSharePopup(state),
    songId: getSharedSongId(state),
    playlistId: getSharedPlaylistId(state),
    type: getShareItemType(state),
    permissions: getUserPermissions(state),
  }));

  const [shareToContacts, setShareToContacts] = useState<IShareToContacts[]>([]);
  const [shareToEmails, setShareToEmails] = useState<string[]>([]);
  const [showCopied, setShowCopied] = useState(false);
  const [link, setLink] = useState('');
  const [changeExpirationConfig, setChangeExpirationConfig] = useState<IChangeExpirationConfig>({
    open: false,
    date: getFormattedDate(),
  });

  const isSong = useMemo(() => type === ShareItemTypes.song, [type]);
  const title = useMemo(() => (isSong ? 'Song' : 'Playlist'), [isSong]);
  const onClickSend = async () => {
    dispatch(setLoading(true));
    const shareBody = permissions.shareSongOrPlaylistToInternalUsers
      ? shareToContacts.reduce(
          (total, item) => {
            item.team ? total.teamIds?.push(item.id) : total.userIds?.push(item.id);
            return total;
          },
          { teamIds: [], userIds: [] } as IShareInternalBody
        )
      : { emails: shareToEmails };

    const res = isSong
      ? await Api.shareSongInternal(songId, shareBody, { errorPopupConfig: DEFAULT_ERROR_CONFIG })
      : await Api.sharePlaylistInternal(playlistId, shareBody, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    dispatch(setLoading(false));

    if (!res) return;
    sendAnalyticsEvent(AnalyticsEventTypes.shareInternally);
    dispatch(setMainNotification(`${title} Sent`));
    handleCloseShareSongPopup();
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    sendAnalyticsEvent(AnalyticsEventTypes.copyLink);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 2000);
  };

  const handleCloseShareSongPopup = () => {
    dispatch(setShareConfig(DEFAULT_SHARE_CONFIG));
    setShareToContacts([]);
    setShareToEmails([]);
    setLink('');
  };

  const onCloseDatePicker = useCallback(() => {
    setChangeExpirationConfig((prevState) => ({ ...prevState, open: false }));
  }, []);

  const onSubmitSingleDate = useCallback(
    async (date?: string) => {
      if (!date) return;

      const res = isSong
        ? await Api.changeSharedSongExpiration(songId, date, {
            errorPopupConfig: DEFAULT_ERROR_CONFIG,
          })
        : await Api.changeSharedPlaylistExpiration(playlistId, date, {
            errorPopupConfig: DEFAULT_ERROR_CONFIG,
          });

      if (!res) {
        onCloseDatePicker();
        return;
      }

      setChangeExpirationConfig({ open: false, date });
    },
    [isSong, onCloseDatePicker, playlistId, songId]
  );

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setChangeExpirationConfig({
          open: false,
          date: getFormattedDate(),
        });
      }, 100);
      return;
    }

    (async () => {
      const preparedContent = isSong
        ? await Api.prepareSongForShare(songId, {
            errorPopupConfig: DEFAULT_ERROR_CONFIG,
          })
        : await Api.preparePlaylistForShare(playlistId, {
            errorPopupConfig: DEFAULT_ERROR_CONFIG,
          });

      if (!preparedContent) {
        handleCloseShareSongPopup();
        return;
      }

      const preparedContentData = isSong ? preparedContent : preparedContent.external;

      setLink(`https://${window.location.host}${Paths.publicSongs}/${preparedContentData.code}`);
      setChangeExpirationConfig({ open: false, date: preparedContentData.expirationDate });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      className={styles.dialog}
      BackdropProps={{ className: styles.dialogBackdrop }}
      PaperProps={{ className: styles.dialogPaper }}
      onClose={handleCloseShareSongPopup}
    >
      <div className={styles.dialogHeader}>
        <span>Share {title}</span>
        <CloseIcon onClick={handleCloseShareSongPopup} />
      </div>
      {permissions.shareSongOrPlaylistToInternalUsers ? (
        <ContactsAsyncAutocomplete
          label="Share Internally"
          placeHolder="Search for a user or team"
          shareToItems={shareToContacts}
          setShareToItems={setShareToContacts}
          onResolveSuggestions={(text) => Api.getUsersByQuery(text).catch(() => [])}
        />
      ) : (
        <InputKeywords
          inputKeywordsClassName={styles.inputKeywords}
          labelClassName={styles.inputKeywordsLabel}
          keywordChipClassName={styles.keywordChip}
          label="Share by Email"
          placeholder="Enter email address"
          value={shareToEmails}
          validate={isValidEmail}
          onChange={(value: KeywordsValueType) => setShareToEmails(value)}
          maxLength={320}
        />
      )}
      {shareToContacts.length || shareToEmails.length ? (
        <DoubleBtnGroup className={styles.sendBtn} hideButton1 name2="Send" onClick2={onClickSend} buttonSize="xl" />
      ) : null}
      <InputText
        label="Get Public Share Link"
        name={'publicLink' as never}
        labelPlacement="top"
        value={link}
        disableEditable
        Icon={CopyIcon}
        labelRootClass={classNames(styles.linkLabel, {
          [styles.linkLabelWithCopied]: showCopied,
        })}
        labelFormClass={styles.linkFormLabel}
        inputClass={styles.linkFormInput}
        inputIconClass={styles.linkIcon}
        onClickIcon={copyLink}
        onClickInput={copyLink}
      />
      <div className={styles.linkExpiresContainer}>
        <span>Link expires after {getDateDiffFromNow(changeExpirationConfig.date)}</span>
        <button onClick={() => setChangeExpirationConfig({ ...changeExpirationConfig, open: true })}>Change</button>
      </div>
      <DatePickerPopup
        blockPastDates
        containerClassName={styles.datePickerSnackbar}
        isSingleDayPicker
        showSingleDateActionButtons
        open={changeExpirationConfig.open}
        TransitionComponent={Fade}
        onClose={onCloseDatePicker}
        date={changeExpirationConfig.date}
        onSubmitSingleDate={onSubmitSingleDate}
      />
    </Dialog>
  );
};

export default ShareSongPopup;
