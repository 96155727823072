import { FunctionComponent, SVGProps } from 'react';

import { ActionButtonsTypes } from 'components/UI/SongMoreMenu/data';

import { ReactComponent as ArrowDownIcon } from 'assets/arrow.svg';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as HoldRequestIcon } from 'assets/hold-request.svg';
import { ReactComponent as MoreIcon } from 'assets/more.svg';
import { ReactComponent as PitchEmptyIcon } from 'assets/pitch_empty.svg';
import { ReactComponent as RemoveIcon } from 'assets/remove.svg';
import { ReactComponent as RestoreIcon } from 'assets/restore.svg';
import { ReactComponent as ShareIcon } from 'assets/share.svg';
import { ReactComponent as TrashIcon } from 'assets/trash.svg';

export interface ISelectedItemsActions {
  id: number;
  title: string;
  type: ActionButtonsTypes;
}

export const selectedItemsActions: ISelectedItemsActions[] = [
  {
    id: 1,
    title: 'Add To Playlist',
    type: ActionButtonsTypes.addToPlaylist,
  },
  {
    id: 2,
    title: 'Pitch',
    type: ActionButtonsTypes.pitch,
  },
  {
    id: 3,
    title: 'Visibility',
    type: ActionButtonsTypes.visibility,
  },
  {
    id: 4,
    title: 'Download',
    type: ActionButtonsTypes.download,
  },
  {
    id: 5,
    title: 'Archive',
    type: ActionButtonsTypes.archive,
  },
  {
    id: 6,
    title: 'Restore',
    type: ActionButtonsTypes.restore,
  },
  {
    id: 7,
    title: 'Delete',
    type: ActionButtonsTypes.delete,
  },
];

export interface IActionButtons {
  id: number;
  title: string;
  type: ActionButtonsTypes;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  hideInMobile?: boolean;
}

export const songsActionButtons: IActionButtons[] = [
  {
    id: 1,
    title: 'Request Hold',
    type: ActionButtonsTypes.holdRequest,
    Icon: HoldRequestIcon,
  },
  {
    id: 2,
    title: 'Download',
    type: ActionButtonsTypes.download,
    Icon: DownloadIcon,
  },
  {
    id: 3,
    title: 'Pitch',
    type: ActionButtonsTypes.pitch,
    Icon: PitchEmptyIcon,
  },
  {
    id: 4,
    title: 'Share',
    type: ActionButtonsTypes.share,
    Icon: ShareIcon,
  },
  {
    id: 5,
    title: 'Archive',
    type: ActionButtonsTypes.archive,
    Icon: TrashIcon,
  },
  {
    id: 6,
    title: 'Restore',
    type: ActionButtonsTypes.restore,
    Icon: RestoreIcon,
  },
  {
    id: 7,
    title: 'Delete',
    type: ActionButtonsTypes.delete,
    Icon: RemoveIcon,
  },
  {
    id: 8,
    title: 'Expand',
    type: ActionButtonsTypes.expand,
    Icon: ArrowDownIcon,
    hideInMobile: true,
  },
  {
    id: 9,
    title: 'Actions',
    type: ActionButtonsTypes.actions,
    Icon: MoreIcon,
  },
];

export enum UserRoleCodes {
  ArrowReadOnly = 'ARROW_READ_ONLY',
  SuperAdmin = 'ARROW_SUPER_ADMIN',
  ArrowTeamMember = 'ARROW_TEAM_MEMBER',
  Label = 'LABEL',
  Partner = 'PARTNER',
  Songwriter = 'SONGWRITER',
}
