import { IPitchDetailsData, IPitchDetailsFields } from 'components/PitchDetails/data';
import { PitchesStatusCell, PitchViewsCell } from 'components/Pitches';
import { PitchTypes } from 'components/Pitches/data';
import { EditTooltip } from 'components/Reusable';
import { InputText } from 'components/UI';

import { getUTCTimeToShowStr } from 'utils';

import styles from './PitchDetails.module.scss';

const PitchHeader = ({
  pitch: { title, type, pitchedBy, contacts, status, viewCount, teamName, isDownloadable, sentDate, expireDate },
}: {
  pitch: IPitchDetailsData;
}): JSX.Element => {
  const isSystemPitch = type === PitchTypes.system;

  return (
    <div className={styles.pitchHeader}>
      <EditTooltip
        Component={<InputText value={title} labelRootClass={styles.inputTextLabelRoot} name={'songTitle' as never} />}
        // Note: this should check with PO, if we remove EditTooltip, we should add "display: grid" to pitchHeader to make span ellipsis work
        disableEditing={true}
        className={styles.pitchTitleEditTooltip}
      >
        <span className={styles.pitchTitle}>{title}</span>
      </EditTooltip>
      <div className={styles.pitchHeaderCols}>
        <div>
          <span className={styles.grayTitle}>{IPitchDetailsFields.status}</span>
          <PitchesStatusCell status={status} />
        </div>
        <div>
          <span className={styles.grayTitle}>{IPitchDetailsFields.pitchType}</span>
          <span className={styles.infoText}>{type}</span>
        </div>
        <div>
          <span className={styles.grayTitle}>{IPitchDetailsFields.pitchedTo}</span>
          <span className={styles.infoText}>{contacts[0]?.contact}</span>
        </div>
        <div>
          <span className={styles.grayTitle}>{IPitchDetailsFields.pitchedBy}</span>
          <span className={styles.infoText}>{pitchedBy}</span>
        </div>
        <div>
          <span className={styles.grayTitle}>{IPitchDetailsFields.sentDate}</span>
          <span className={styles.infoText}>{sentDate ? getUTCTimeToShowStr(sentDate) : '-'}</span>
        </div>
        {isSystemPitch && (
          <div>
            <span className={styles.grayTitle}>{IPitchDetailsFields.expiryDate}</span>
            <span className={styles.infoText}>{expireDate ? getUTCTimeToShowStr(expireDate) : '-'}</span>
          </div>
        )}
        <div>
          <span className={styles.grayTitle}>{IPitchDetailsFields.team}</span>
          <span className={styles.infoText}>{teamName}</span>
        </div>
        {isSystemPitch && (
          <>
            <div>
              <span className={styles.grayTitle}>{IPitchDetailsFields.views}</span>
              <PitchViewsCell views={Number(viewCount)} />
            </div>
            <div>
              <span className={styles.grayTitle}>{IPitchDetailsFields.downloadable}</span>
              <span className={styles.infoText}>{isDownloadable ? 'Yes' : 'No'}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PitchHeader;
