import { useState } from 'react';
import { Popover } from '@material-ui/core';
import { GridApi, RowNode } from 'ag-grid-community';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/close-2.svg';
import styles from './SelectionPanel.module.scss';

export interface ISelectionPanelMenuItemProps<T> {
  className?: string;
  key?: React.Key;
  onClick?: (nodes: RowNode[], items: T[], e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>;
  text?: string;
}

export interface ISelectionPanelButtonProps<T> {
  className?: string;
  menuItems?: ISelectionPanelMenuItemProps<T>[];
  onClick?: (nodes: RowNode[], items: T[], e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>;
  text: string | JSX.Element;
}

export interface ISelectionPanelProps<T> extends React.PropsWithChildren<unknown> {
  className?: string;
  buttons?: (ISelectionPanelButtonProps<T> | false | undefined)[];
  gridRef: React.MutableRefObject<GridApi | null>;
  selectedItems: T[];
  total?: number;
}

interface IMenuState<T> {
  anchorEl: HTMLElement;
  items: ISelectionPanelMenuItemProps<T>[];
}

const SelectionPanel = <T,>(props: ISelectionPanelProps<T>): JSX.Element | null => {
  const { buttons, children, className, gridRef, selectedItems, total } = props;
  const [menu, setMenu] = useState<IMenuState<T>>();
  const [processing, setProcessing] = useState(false);

  const onCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    gridRef.current?.deselectAll();
  };

  const withTotal = `${selectedItems.length} out of ${total} ${total === 1 ? 'item' : 'items'} selected`;
  const withoutTotal = `${selectedItems.length} ${selectedItems.length === 1 ? 'Item Selected' : 'Items Selected'}`;

  if (selectedItems.length === 0) {
    return null;
  }

  return (
    <div className={classNames(styles.selectionPanel, className)}>
      <div className={styles.header}>
        <div className={styles.selectionText}>{total ? withTotal : withoutTotal}</div>
        <button className={styles.closeButton} onClick={onCloseClick}>
          <CloseIcon />
        </button>
        <div className={styles.actions}>
          {buttons?.map((b, i) =>
            b ? (
              <button
                className={classNames(styles.actionButton, b.className)}
                key={i}
                onClick={async (e) => {
                  e.preventDefault();
                  if (processing) return;
                  setProcessing(true);
                  const nodes = gridRef.current?.getSelectedNodes() || [];
                  const items = nodes.map(({ data }) => data);
                  b.onClick && (await b.onClick(nodes, items, e));
                  setProcessing(false);
                  b.menuItems && setMenu({ anchorEl: e.currentTarget, items: b.menuItems });
                }}
              >
                {b.text}
              </button>
            ) : null
          )}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
      {menu && (
        <Popover
          anchorEl={menu.anchorEl}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          className={styles.popover}
          onClose={() => setMenu(undefined)}
          open
          PaperProps={{
            classes: { root: styles.paperRoot },
            square: true,
            style: { minWidth: menu.anchorEl.clientWidth + 2 },
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          {menu.items.map((item, i) => (
            <button
              className={classNames(styles.menuItemButton, item.className)}
              key={i}
              onClick={async (e) => {
                e.preventDefault();
                setMenu(undefined);
                if (processing) return;
                setProcessing(true);
                const nodes = gridRef.current?.getSelectedNodes() || [];
                const items = nodes.map(({ data }) => data);
                item.onClick && (await item.onClick(nodes, items, e));
                setProcessing(false);
              }}
            >
              {item.text}
            </button>
          ))}
        </Popover>
      )}
    </div>
  );
};

export default SelectionPanel;
