import isEqual from 'react-fast-compare';
import { AccessToken, RefreshToken } from '@okta/okta-auth-js';
import { GridApi } from 'ag-grid-community';
import moment from 'moment';

import { ROLES } from 'components/AdminControls/data';
import {
  AudioInclusionTypes,
  defaultBpmData,
  defaultSearchData,
  IUISearchData,
  IUISearchDataNested,
  SearchDataKeys,
  SearchDataType,
  SearchItemsNums,
  songGenresList,
  songLanguagesList,
  songMoodsList,
  SongsSearchTypes,
  songVocalsList,
} from 'components/Popups/DesktopSearch/data';
import { ISongData } from 'components/Songs/data';
import { getVersionOptions } from 'components/SongsUpload/data';
import { IPlaylistMedia } from 'containers/Sidebar/data';

import LocalStorage from 'services/LocalStorage';
import {
  dispatch,
  getPlaybarPlayingState,
  getSelectors,
  getUserPermissions,
  setMainConfirmPopup,
  setPlaybarPlayingState,
} from 'store';
import { IRecentEditedSong, PlayingStateTypes } from 'store/reducers/general/types';
import { IRoute, Paths } from 'utils/configs';
import {
  dateFormat,
  dateFormatWithSlash,
  DEFAULT_MAIN_CONFIRM_POPUP,
  EMAIL_REGEX,
  IS_OPEN_SIDEBAR,
  PLAYBAR_AUDIO_ELEMENT_ID,
  SCREEN_BREAKPOINTS,
} from 'utils/constants';
import { UnknownObject } from 'utils/permissions';

import { IMarkerBE } from 'types';

export const fillArray = <T>(arr: T[], length: number, item: string | number): T[] => {
  if (arr.length < length) {
    return arr.concat(new Array(length - arr.length).fill(item));
  }
  return arr;
};

export const convertCategoriesToText = <T extends { title?: string; name?: string }>(
  arr: T[],
  onlyFirstCharCapitalized?: boolean,
  separator = ','
) => {
  let str = '';

  arr.forEach((item, index) => {
    const name = item.title || item.name || '';

    str += `${index === 0 ? ' ' : `${separator} `}${onlyFirstCharCapitalized ? capitalizeOnlyFirstChar(name) : name}`;
  });

  return str;
};

// defaults
export const getDefaultSearchItemsNum = (): SearchItemsNums =>
  window.innerWidth <= SCREEN_BREAKPOINTS.desktopSmall ? SearchItemsNums.three : SearchItemsNums.seven;
export const getDefaultIsOpenSidebar = () =>
  LocalStorage.get(IS_OPEN_SIDEBAR) ?? window.innerWidth > SCREEN_BREAKPOINTS.desktopSmall;

export function getGUID(): string {
  let d = Date.now();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function getIsMobile(width: number): boolean {
  return width < SCREEN_BREAKPOINTS.tablet;
}

export const capitalizeOnlyFirstChar = (str: string): string => {
  if (!str) return '';
  return `${str[0].toUpperCase()}${str.substr(1, str.length).toLowerCase()}`;
};

export const camelize = (str: string): string => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const getTitlesArray = <T extends IUISearchData>(arr: T[]) => {
  return arr.map((item) => item.title);
};

export const noop = () => ({});

export const getStartEndDateFromString = (dateRange: string, separator = ' to ', format = dateFormat) => {
  const splittedDate = dateRange.split(separator);

  return {
    start: moment(splittedDate[0]).isValid()
      ? moment(splittedDate[0]).format(format)
      : moment(splittedDate[0].replace(/-/g, '/')).format(format),
    end: moment(splittedDate[1]).isValid()
      ? moment(splittedDate[1]).format(format)
      : moment(splittedDate[1].replace(/-/g, '/')).format(format),
  };
};

export const getStringFromStartEndDate = (startDate?: Date, endDate?: Date, format = dateFormat): string =>
  startDate && endDate ? `${moment(startDate).format(format)} to ${moment(endDate).format(format)}` : '';

export const isDateRangeValid = (startDate?: Date, endDate?: Date): boolean => {
  if (!startDate || !endDate) return false;
  const startDateMoment = moment(startDate, dateFormat, true);
  const endDateMoment = moment(endDate, dateFormat, true);
  return (
    startDateMoment.isValid() &&
    endDateMoment.isValid() &&
    endDateMoment?.isSameOrAfter(startDateMoment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
  );
};

export const getTimeFromString = (str: string) => new Date(str).getTime();

export const checkDroppedSongsForPlaylistMedia = (item: ISongData | IPlaylistMedia, data: IPlaylistMedia) => {
  return 'mediaId' in item ? item.mediaId === data.id : item.id === data.id;
};

export const checkDroppedSongsForSongData = (item: ISongData | IPlaylistMedia, data: ISongData) => {
  return 'mediaId' in item ? item.mediaId === data.mediaId : item.id === data.mediaId;
};

export const getDroppedSongsIds = (droppedSongs: Array<ISongData | IPlaylistMedia>) => {
  return droppedSongs.map((item) => ('mediaId' in item ? item.mediaId || '' : item.id));
};

export const shuffleArray = <T>(array: T[]): T[] => {
  const newArr = array.slice();

  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  return newArr;
};

export const isEmpty = (value: unknown, trimSpaces = false): boolean => {
  return (
    typeof value === 'undefined' ||
    value === null ||
    (typeof value === 'string' && (trimSpaces ? !value.trim() : !value)) ||
    (Array.isArray(value) && value.length === 0)
  );
};

export const getDataGridItems = <T>(grid: GridApi): T[] => {
  const items: T[] = [];
  grid.forEachNode((n) => items.push(n.data));
  return items;
};

export const getUTCTimeFromNow = (tUTC: string): string => moment.utc(tUTC, 'YYYY-MM-DD HH:mm:ss').fromNow();
export const getDateDiffFromNow = (date: string): string => {
  if (!date) return '';

  const formattedDate = moment(date).isValid() ? date : date.replace(/-/g, '/');

  const diffDays = Math.abs(moment({ hours: 0 }).diff(formattedDate, 'days'));
  const diffMonths = Math.abs(moment({ hours: 0 }).diff(formattedDate, 'months'));

  return diffDays < 30 ? `${diffDays} days` : `${diffMonths} months`;
};
export const getUTCTimeToShowStr = (tUTC: string): string =>
  moment.utc(tUTC, 'YYYY-MM-DD HH:mm:ss').format('MM-DD-YYYY');
// Note: if you need to get current Date then date should be undefined, if you need to get notValidStr you need sent empty string ''
export const getFormattedDate = (date?: string | Date | number, notValidStr = '', shouldConvertToUTC = false) => {
  const momentDate = typeof date === 'number' ? moment(date * 1000) : moment(date);
  return momentDate.isValid()
    ? shouldConvertToUTC
      ? momentDate.utcOffset(0).format(dateFormat)
      : momentDate.format(dateFormat)
    : notValidStr;
};

export const getInitialDateForFeaturedPlaylist = (releaseOn?: string) => {
  const releaseOnDate = moment(new Date(releaseOn || ''))
    .add(1, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const todayStart = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  return (releaseOnDate.isAfter(todayStart) ? releaseOnDate : todayStart).format(dateFormatWithSlash);
};

const debounceStore: { [x: string]: NodeJS.Timeout } = {};

export const debouncePromise = <T>(callback: () => T, key: string, delay = 1000): Promise<T> =>
  new Promise<T>((resolve) => {
    debounceStore[key] && clearTimeout(debounceStore[key]);
    debounceStore[key] = setTimeout(() => resolve(callback()), delay);
  });

export const validateRequired = (v: unknown) => (isEmpty(v, true) ? 'Required Field.' : undefined);

export const isGuid = (str: string): boolean => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
};

export const sanitizeFileName = (fileName: string): string => {
  return fileName.replace(/([^a-z0-9-]+)/gi, '_');
};

export const getSongVersion = (version?: string | null, by = 'shortTitle') => {
  if (!version) return '';

  const versionObj = getVersionOptions().find((v) => [v.id, v.title, v.shortTitle].includes(version));

  return by === 'shortTitle' ? versionObj?.shortTitle || versionObj?.title || '' : versionObj?.title || '';
};

export const getUserRoleTitle = (role?: string, by: 'title' | 'code' = 'title') => {
  if (!role) return '-';

  return ROLES.find((item) => item.code === role)?.[by] || '-';
};

export const convertBackendMarkersToState = (markers?: IMarkerBE[], duration?: string) => {
  return (
    markers?.map(({ id, markerTime, title }) => ({
      id,
      title,
      markerTime: Number(markerTime),
      xAxisPercent: (Number(markerTime) / Number(duration || 0)) * 100,
    })) || []
  );
};

export const getReadableTime = (duration: number): string => {
  if (!duration) return '00:00';

  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  const minutesFirstNumber = minutes > 9 ? '' : '0';
  const secondsFirstNumber = seconds > 9 ? '' : '0';

  return `${minutesFirstNumber}${minutes}:${secondsFirstNumber}${seconds}`;
};

export const removeBlankSpacesFromTextHtml = (html: string): string => {
  const contentArr = Array.from(new DOMParser().parseFromString(html, 'text/html').body.childNodes);

  for (let i = contentArr.length - 1; i >= 0; i--) {
    const nodeChildArr = Array.from(contentArr[i].childNodes);

    if (!(nodeChildArr.length === 1 && nodeChildArr[0] instanceof HTMLBRElement)) break;

    contentArr.splice(i, 1);
  }

  return contentArr
    .map((item) => {
      if (item instanceof Text) {
        const p = document.createElement('p');
        p.appendChild(item);

        return p.outerHTML;
      }
      return (item as HTMLElement).outerHTML;
    })
    .join('');
};

export const getFilteredRoutes = (routes: IRoute[], permissions: UnknownObject) => {
  const { myAccountPage, adminControlsPage, pitchesPage, contactsPage, reportsPage } = permissions;

  return routes.filter((item) => {
    const hideRoute =
      (item.path === Paths.myAccount && !myAccountPage) ||
      (item.path === Paths.admin && !adminControlsPage) ||
      ([Paths.pitches, Paths.pitchesId].includes(item.path) && !pitchesPage) ||
      ([Paths.contacts, Paths.contactGroupId, Paths.contactGroupId].includes(item.path) && !contactsPage) ||
      ([Paths.contacts, Paths.contactGroupId, Paths.contactGroupId].includes(item.path) && !contactsPage) ||
      ([Paths.reports, Paths.reportsId, Paths.reportsNew, Paths.reportsIdEdit].includes(item.path) && !reportsPage);

    return !hideRoute;
  });
};

export const getNormalizedRoute = (routes: IRoute[], locationPathname: string) => {
  const normalizedLocationPath = locationPathname
    .toLowerCase()
    .split('/')
    .filter((i) => i)
    .map((i) => {
      const isValidPath = Object.values(Paths).some((p) => p.includes(i));

      return isValidPath ? i : ':id';
    }) // Replace GUID by ':id'
    .join('/');
  const { id, name, pageHeaderName } =
    routes.find((r) => {
      const normalizedRoutePath = r.path
        .toLowerCase()
        .split('/')
        .filter((r) => r)
        .join('/');
      return normalizedRoutePath === normalizedLocationPath;
    }) || {};

  return { id, name, pageHeaderName };
};

export const isEmptyHtmlString = (html: string) => html.replace(/<(.|\n)*?>/g, '').trim().length === 0;

// eslint-disable-next-line
const URLPattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export const isValidURL = (str: string): boolean => {
  if (str.includes(' ')) return false;

  const res = str.match(URLPattern);
  return res !== null;
};

export const isValidEmail = (email: string) => EMAIL_REGEX.test(email);

export const isSameStringWithoutOrder = (str1: string, str2: string): boolean => {
  return str1.split('').sort().join('') === str2.split('').sort().join('');
};

const isToggleVisible = (value: SearchDataKeys, searchSwitchButtons: IUISearchData[]): boolean =>
  searchSwitchButtons.some((btn) => btn.value === value);

export const getSongsSearchDataFromQueries = (searchParams: URLSearchParams): Partial<SearchDataType> => {
  const permissions = getSelectors(getUserPermissions);
  const searchSwitchButtons = permissions.searchSwitchButtons as IUISearchData[];

  const searchQuery = searchParams.get(SearchDataKeys.searchQuery);
  const genre = searchParams.get(SearchDataKeys.genre);
  const subGenre = searchParams.get(SearchDataKeys.subGenre)?.split(',') || [];
  const mood = searchParams.get(SearchDataKeys.mood)?.split(',') || [];
  const vocal = searchParams.get(SearchDataKeys.vocal)?.split(',') || [];
  const language = searchParams.get(SearchDataKeys.language)?.split(',') || [];
  const artists = searchParams.get(SearchDataKeys.artists)?.split(',') || [];
  const bpm = searchParams.get(SearchDataKeys.bpm)?.split(',');
  const audioInclusionType = permissions.searchAudioToggle
    ? searchParams.get(SearchDataKeys.audioInclusionType)
    : AudioInclusionTypes.withAudio;
  const onHold =
    searchParams.get(SearchDataKeys.onHold) === 'true' && isToggleVisible(SearchDataKeys.onHold, searchSwitchButtons);
  const offHold =
    searchParams.get(SearchDataKeys.offHold) === 'true' && isToggleVisible(SearchDataKeys.offHold, searchSwitchButtons);
  const cut =
    searchParams.get(SearchDataKeys.cut) === 'true' && isToggleVisible(SearchDataKeys.cut, searchSwitchButtons);
  const unreleasedCut =
    searchParams.get(SearchDataKeys.unreleasedCut) === 'true' &&
    isToggleVisible(SearchDataKeys.unreleasedCut, searchSwitchButtons);
  const searchType = searchParams.get(SearchDataKeys.searchType);

  const genreOption = songGenresList.find((el) => el.title === genre);
  const subGenreOption = genreOption?.data?.filter((el) => subGenre.includes(el.title)) || [];
  const moodOptions = songMoodsList.filter((el) => mood.includes(el.title));
  const vocalOptions = songVocalsList.filter((el) => vocal.includes(el.title));
  const languageOptions = songLanguagesList.filter((el) => language.includes(el.title));
  const artistsOptions = artists.map((el, index) => ({ id: index, title: el }));
  const bpmValidValues = bpm
    ? [
        Number(bpm[0]) >= 0 && Number(bpm[0]) <= 220 ? Number(bpm[0]) : 0,
        Number(bpm[1]) >= 0 && Number(bpm[1]) <= 220 ? Number(bpm[1]) : 220,
      ]
    : defaultBpmData;
  const audioInclusionTypeValid = Object.values(AudioInclusionTypes).includes(audioInclusionType as AudioInclusionTypes)
    ? audioInclusionType
    : AudioInclusionTypes.withAudio;
  const searchTypeValid = Object.values(SongsSearchTypes).includes(searchType as SongsSearchTypes)
    ? searchType
    : SongsSearchTypes.songSearch;

  return {
    [SearchDataKeys.searchQuery]: searchQuery,
    [SearchDataKeys.genre]: genreOption || null,
    [SearchDataKeys.subGenre]: subGenreOption,
    [SearchDataKeys.mood]: moodOptions,
    [SearchDataKeys.vocal]: vocalOptions,
    [SearchDataKeys.language]: languageOptions,
    [SearchDataKeys.artists]: artistsOptions,
    [SearchDataKeys.bpm]: bpmValidValues,
    [SearchDataKeys.audioInclusionType]: audioInclusionTypeValid,
    [SearchDataKeys.onHold]: onHold,
    [SearchDataKeys.offHold]: offHold,
    [SearchDataKeys.cut]: cut,
    [SearchDataKeys.unreleasedCut]: unreleasedCut,
    [SearchDataKeys.searchType]: searchTypeValid,
  };
};

export const getSongsSearchQueriesFromData = (searchData: SearchDataType, searchParams: URLSearchParams): string => {
  if (!searchData) return '';

  let str = '';

  for (const key in searchData) {
    if (!Object.prototype.hasOwnProperty.call(searchData, key)) continue;

    const value = searchData[key as SearchDataKeys];

    if (Array.isArray(value) && value.length) {
      if (
        key === 'bpm' &&
        (isEqual(value, defaultSearchData.bpm) ||
          searchData[SearchDataKeys.audioInclusionType] === AudioInclusionTypes.withoutAudio)
      ) {
        continue;
      }

      const query = value
        .map((item) => {
          if (typeof item === 'number') {
            return item;
          } else {
            return item?.title || '';
          }
        })
        .join(',');
      searchParams.set(key, query);
      str += `&${key}=${query}`;
    }

    if (typeof value === 'boolean') {
      searchParams.set(key, `${value}`);
      str += `&${key}=${value}`;
    }

    if (typeof value === 'string' && value.trim().length) {
      searchParams.set(key, value);
      str += `&${key}=${encodeURIComponent(value)}`;
    }

    if (['object'].includes(typeof value) && (value as IUISearchDataNested)?.title) {
      searchParams.set(key, (value as IUISearchDataNested).title);
      str += `&${key}=${encodeURIComponent((value as IUISearchDataNested).title)}`;
    }
  }

  return str.length ? `?${str}` : str;
};

export const getUniqueArrayByKey = <T>(arr: T[], key: keyof T) => [
  ...new Map(arr.map((item) => [item[key], item])).values(),
];

export const loadImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = '';
    let loadCount = 0;

    image.onload = () => {
      resolve(image);
    };
    image.onerror = () => {
      if (loadCount > 0) {
        return reject(new Error(`Could not load image at ${url}`));
      }

      loadCount++;
      image.src = url;
    };

    image.src = url;
  });

export const findRecentEditedSongById = (recentEditedSongs: IRecentEditedSong[], id: string, by = '') => {
  return recentEditedSongs.find(
    (item) =>
      (!by || Object.prototype.hasOwnProperty.call(item.update, by)) && [item.ids.songId, item.ids.mediaId].includes(id)
  );
};

export const immediatelyPlayAudio = () => {
  setTimeout(() => {
    const audio = document.getElementById(PLAYBAR_AUDIO_ELEMENT_ID) as HTMLAudioElement;
    const playbarPlayingState = getSelectors(getPlaybarPlayingState);

    if (!audio || playbarPlayingState === PlayingStateTypes.playing) return;

    audio
      .play()
      .then((res) => {
        dispatch(setPlaybarPlayingState(PlayingStateTypes.playing));
        return res;
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }, 500);
};

export const checkIfSongIsInFeaturedPlaylist = (
  featured: boolean,
  checked: boolean,
  onChangeLabelView: (checked: boolean) => void
) => {
  if (featured && !checked) {
    dispatch(
      setMainConfirmPopup({
        isOpen: true,
        questionText: 'Song Belongs to a Featured Playlists',
        mainText:
          'This song belongs to a featured playlist which requires the song to be visible to all users. Removing Label View will remove the song from the playlist and notify the playlist owner.',
        btnDoneText: 'Continue',
        onClickSubmit: () => {
          dispatch(setMainConfirmPopup(DEFAULT_MAIN_CONFIRM_POPUP));
          return onChangeLabelView(checked);
        },
      })
    );
  } else {
    void onChangeLabelView(checked);
  }
};
export const sendAnalyticsEvent = (eventName: string) => {
  // @ts-ignore
  window.executeLinkOnlyTL_NoPN?.(eventName);
};

export const sendAnalyticsEventForLogin = () => {
  // @ts-ignore
  window.executeGlobalTL?.('event2', 'event', 'login');
};
export const sendAnalyticsEventForRegistration = () => {
  // @ts-ignore
  window.executeGlobalTL?.('event1', 'event', 'registration');
};

// Note: sometimes okta authState is wrong that's why needed to check expiresAt properties
export const getIsNeedLogin = (isAuthenticated?: boolean, accessToken?: AccessToken, refreshToken?: RefreshToken) => {
  return (
    !isAuthenticated ||
    (accessToken?.expiresAt && accessToken?.expiresAt * 1000 < Date.now()) ||
    (refreshToken?.expiresAt && refreshToken?.expiresAt * 1000 < Date.now())
  );
};
