import classNames from 'classnames';

import { useIsTextOverflowing } from 'hooks';

import styles from './TextWithFade.module.scss';

interface ITextWithFadeProps {
  children?: JSX.Element | string | string[];
  className?: string;
  isDark?: boolean;
}

const TextWithFade = ({ children, className, isDark = false }: ITextWithFadeProps) => {
  const [containerRef, isOverflowing] = useIsTextOverflowing<HTMLSpanElement>();

  return (
    <span
      ref={containerRef}
      className={classNames(className, styles.textWithFade, {
        [styles.overflowing]: isOverflowing,
        [styles.dark]: isDark,
      })}
    >
      {children}
    </span>
  );
};

export default TextWithFade;
