import { MutableRefObject, useRef, useState } from 'react';
import { Dialog } from '@material-ui/core';
import classNames from 'classnames';

import { ConfirmPopup } from 'components/Popups';
import { DoubleBtnGroup } from 'components/Reusable';

import { useMemoSelector } from 'hooks';
import { getSongDetailsAddPopupConfig } from 'store';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './AddPopupWrapper.module.scss';

interface IAddPopupWrapperProps {
  onAdd?: (res: unknown) => void;
  subtitle?: string;
  onCancel: () => void;
  parent?: MutableRefObject<HTMLDivElement | null>;
}

const AddPopupWrapper = ({ onCancel, parent, onAdd, subtitle }: IAddPopupWrapperProps) => {
  const addPopupConfig = useMemoSelector(getSongDetailsAddPopupConfig);
  const addComponentRef = useRef<{ onHandleSubmit: () => void } | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);

  const AddComponent = addPopupConfig.component;

  const onSubmit = async () => {
    setLoading(true);
    const res = await addComponentRef.current?.onHandleSubmit();
    res && onAdd && onAdd(res);
    setLoading(false);
  };

  return (
    <Dialog
      className={styles.dialog}
      BackdropProps={{ className: styles.dialogBackdrop }}
      PaperProps={{ className: styles.dialogPaper }}
      open={addPopupConfig.isOpen}
      onClose={onCancel}
      container={parent?.current}
    >
      <div className={styles.dialogHeader}>
        <span>
          {addPopupConfig.isEdit ? 'Edit' : 'Add'} {addPopupConfig.type}
        </span>
        <span className={classNames(styles.addTitle, globalStyles.textEllipsis)}>For: {subtitle}</span>
      </div>
      <AddComponent addRef={addComponentRef} setBtnDisabled={setBtnDisabled} />
      <DoubleBtnGroup
        className={styles.addDoubleBtnContainer}
        onClick1={() => (addPopupConfig.isEdit ? setIsOpenConfirm(true) : onCancel())}
        name2={`${addPopupConfig.isEdit ? 'Save' : 'Add'} ${addPopupConfig.type}`}
        disabled={btnDisabled}
        onClick2={onSubmit}
        isPending={loading}
      />
      <ConfirmPopup
        isOpen={isOpenConfirm}
        setIsOpen={setIsOpenConfirm}
        questionText="Are you sure you want to cancel?"
        mainText="Any changes you’ve made will not be saved"
        btnCancelText="Don’t Save"
        btnDoneText="Save Changes"
        onClickCancel={() => {
          onCancel();
          setIsOpenConfirm(false);
        }}
        onClickSubmit={onSubmit}
      />
    </Dialog>
  );
};

export default AddPopupWrapper;
