import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { Dialog, DoubleBtnGroup } from 'components/Reusable';

import styles from './ConfirmDialogPopup.module.scss';

export interface IConfirmDialogPopupProps {
  cancelText?: string;
  description?: string;
  hideCancelButton?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  open: boolean;
  submitText?: string;
  title?: string;
}

const ConfirmDialogPopup = (props: IConfirmDialogPopupProps) => (
  <Dialog
    closeFromBackdropClick
    onClose={props.onCancel}
    open={props.open}
    PaperProps={{ classes: { root: styles.paper }, square: false }}
  >
    {props.title && <DialogTitle className={styles.title}>{props.title}</DialogTitle>}
    {props.description && <DialogContent className={styles.content}>{props.description}</DialogContent>}
    <DialogActions>
      <DoubleBtnGroup
        hideButton1={props.hideCancelButton}
        name1={props.cancelText}
        name2={props.submitText}
        onClick1={props.onCancel}
        onClick2={props.onSubmit}
        spacing={21}
      />
    </DialogActions>
  </Dialog>
);

export default ConfirmDialogPopup;
