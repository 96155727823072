export enum NotificationsFilterTypes {
  all = 'All',
  unread = 'Unread',
}

export enum NotificationTypes {
  inAppApprovalStatusChanged = 'INAPP_APPROVAL_STATUS_CHANGED',
  inAppSongAddedToPlaylist = 'INAPP_SONG_ADDED_TO_PLAYLIST',
  inAppSongRemovedFromPlaylist = 'INAPP_SONG_REMOVED_FROM_PLAYLIST',
  inAppSongRemovedFromPlaylistRemaining = 'INAPP_SONG_REMOVED_FROM_PLAYLIST_REMAINING',
  inAppSongRemovedFromPlaylistDeactivated = 'INAPP_SONG_REMOVED_FROM_PLAYLIST_DEACTIVATED',
  inAppSongShared = 'INAPP_SONG_SHARED',
  inAppPitchViewed = 'INAPP_PITCH_VIEWED',
  inAppCutStatusCreated = 'INAPP_CUT_STATUS_CREATED',
  inAppHoldOnStatusCreated = 'INAPP_HOLD_ON_STATUS_CREATED',
  inAppHoldOffStatusCreated = 'INAPP_HOLD_OFF_STATUS_CREATED',
  inAppUnreleasedCutStatusCreated = 'INAPP_UNRELEASED_CUT_STATUS_CREATED',
  inAppHoldRequestCreated = 'INAPP_HOLD_REQUEST_CREATED',
  inAppHoldRequestApproveReminder = 'INAPP_HOLD_REQUEST_APPROVE_REMINDER',
  inAppUnAnsweredHoldRequestReminder = 'INAPP_UNANSWERED_HOLD_REQUEST_REMINDER',
  inAppSearchAlert = 'INAPP_SEARCH_ALERT',
}

export enum NotificationStatuses {
  new = 'NEW',
  read = 'READ',
  deleted = 'DELETED',
}

interface IRefMeta {
  refKey: string;
  id: string | null;
  displayName: string;
}

export interface IBENotification {
  id: string;
  type: NotificationTypes;
  status: NotificationStatuses;
  message: string;
  date: string;
  refMetas: IRefMeta[];
}
