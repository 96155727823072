import { lazy, LazyExoticComponent } from 'react';

const Dashboard = lazy(() => import(/* webpackChunkName: "DashboardComponent" */ 'components/Dashboard'));
const Songs = lazy(() => import(/* webpackChunkName: "SongsComponent" */ 'components/Songs'));
const Pitches = lazy(() => import(/* webpackChunkName: "PitchesComponent" */ 'components/Pitches'));
const Reports = lazy(() => import(/* webpackChunkName: "ReportsComponent" */ 'components/Reports'));
const Playlists = lazy(() => import(/* webpackChunkName: "PlaylistsComponent" */ 'components/Playlists'));
const Contacts = lazy(() => import(/* webpackChunkName: "ContactsComponent" */ 'components/Contacts'));
const ContactsGroupDetails = lazy(
  () => import(/* webpackChunkName: "ContactsGroupDetails" */ 'components/Contacts/GroupDetails')
);
const Search = lazy(() => import(/* webpackChunkName: "ContactsComponent" */ 'components/Search'));
const SongDetails = lazy(() => import(/* webpackChunkName: "SongDetailsComponent" */ 'components/SongDetails'));
const PitchDetails = lazy(() => import(/* webpackChunkName: "SongDetailsComponent" */ 'components/PitchDetails'));
const MyAccount = lazy(() => import(/* webpackChunkName: "MyAccountComponent" */ 'components/MyAccount'));
const AdminControls = lazy(() => import(/* webpackChunkName: "AdminControlsComponent" */ 'components/AdminControls'));
const LogOut = lazy(() => import(/* webpackChunkName: "LogOutComponent" */ 'components/LogOut'));
const Notifications = lazy(() => import(/* webpackChunkName: "NotificationsComponent" */ 'components/Notifications'));
const NotificationPreferences = lazy(
  () => import(/* webpackChunkName: "NotificationPreferencesComponent" */ 'components/NotificationPreferences')
);
const Support = lazy(() => import(/* webpackChunkName: "SupportComponent" */ 'components/Support'));
const SupportPublic = lazy(() => import(/* webpackChunkName: "SupportPublicComponent" */ 'components/SupportPublic'));
const ReportDetails = lazy(() => import(/* webpackChunkName: "ReportDetails" */ 'components/ReportDetails'));
const ReportForm = lazy(() => import(/* webpackChunkName: "ReportForm" */ 'components/ReportForm'));

const ErrorPageLogged = lazy(
  () => import(/* webpackChunkName: "ErrorPageLoggedComponent" */ 'components/ErrorPageLogged')
);

export enum Paths {
  dashboard = '/dashboard',
  songs = '/songs',
  pitches = '/pitches',
  reports = '/reports',
  playlists = '/playlists',
  contacts = '/contacts',
  contactGroups = '/contacts/groups',
  contactGroupId = '/contacts/groups/:id',
  myAccount = '/myaccount',
  notifications = '/notifications',
  support = '/support',
  logout = '/logout',
  all = '*',
  empty = '/',
  home = '/home',
  privacyPolicy = '/privacy-policy',
  termsOfUse = '/terms-of-use',
  registration = '/registration',
  oktaLogin = '/callback',
  search = '/search',
  searchId = '/search/:id',
  turnedOff = '/turned-off',
  admin = '/admin',
  songsId = '/songs/:id',
  pitchesId = '/pitches/:id',
  publicPitches = '/public/pitches',
  publicPitchesId = '/public/pitches/:id',
  publicSongs = '/public/songs',
  publicSongsId = '/public/songs/:id',
  publicAlert = '/public/alert',
  publicAlertId = '/public/alert/:id',
  reportsId = '/reports/:id',
  reportsIdEdit = '/reports/:id/edit',
  reportsNew = '/reports/new',
  notificationPreferences = '/notification-preferences',
}

type ComponentType = () => JSX.Element;

export interface IRoute {
  id: number;
  name: string;
  path: Paths;
  component: LazyExoticComponent<() => JSX.Element> | ComponentType;
  componentPublic?: LazyExoticComponent<() => JSX.Element> | ComponentType;
  hide?: boolean;
  isForMobile?: boolean;
  hideInMobile?: boolean;
  browserTabName?: string;
  pageHeaderName?: string;
  exact?: boolean;
  relativeRouteId?: number;
  universal?: boolean;
}

export const routes: IRoute[] = [
  {
    id: 1,
    name: 'DASHBOARD',
    path: Paths.dashboard,
    component: Dashboard,
  },
  {
    id: 2,
    name: 'SONGS',
    path: Paths.songs,
    component: Songs,
    exact: true,
  },
  {
    id: 3,
    name: 'PITCHES',
    path: Paths.pitches,
    component: Pitches,
    exact: true,
  },
  {
    id: 4,
    name: 'REPORTS',
    path: Paths.reports,
    component: Reports,
    exact: true,
  },
  {
    id: 5,
    name: 'PLAYLISTS',
    path: Paths.playlists,
    component: Playlists,
    isForMobile: true,
    hide: true,
  },
  {
    id: 6,
    name: 'CONTACTS',
    path: Paths.contacts,
    component: Contacts,
    exact: true,
  },
  {
    id: 7,
    name: '',
    path: Paths.search,
    component: Search,
    hide: true,
    browserTabName: 'Search',
    pageHeaderName: 'SEARCH',
    exact: true,
  },
  {
    id: 8,
    name: '',
    path: Paths.songsId,
    component: SongDetails,
    hide: true,
    browserTabName: 'Song Details',
    pageHeaderName: 'SONG DETAILS',
    relativeRouteId: 2,
  },
  {
    id: 9,
    name: '',
    path: Paths.contactGroupId,
    component: ContactsGroupDetails,
    hide: true,
    browserTabName: 'Contact Group',
    pageHeaderName: 'CONTACT GROUP',
    relativeRouteId: 6,
  },
  {
    id: 10,
    name: '',
    path: Paths.pitchesId,
    component: PitchDetails,
    hide: true,
    browserTabName: 'Pitch Details',
    pageHeaderName: 'PITCH DETAILS',
  },
  {
    id: 11,
    name: '',
    path: Paths.reportsNew,
    component: ReportForm,
    hide: true,
    browserTabName: 'Create Report',
    pageHeaderName: 'CREATE REPORT',
    relativeRouteId: 4,
  },
  {
    id: 12,
    name: '',
    path: Paths.reportsIdEdit,
    component: ReportForm,
    hide: true,
    browserTabName: 'Edit Report',
    pageHeaderName: 'EDIT REPORT',
    relativeRouteId: 4,
  },
  {
    id: 13,
    name: '',
    path: Paths.reportsId,
    component: ReportDetails,
    hide: true,
    browserTabName: 'View Report',
    pageHeaderName: 'VIEW REPORT',
    relativeRouteId: 4,
  },
  {
    id: 14,
    name: '',
    path: Paths.searchId,
    component: Search,
    hide: true,
    hideInMobile: true,
    browserTabName: 'Search Alert',
    pageHeaderName: 'SEARCH ALERT',
    relativeRouteId: 7,
  },
  {
    id: 0,
    name: '',
    path: Paths.all,
    component: ErrorPageLogged,
  },
];

// Note: all routes with id >= MyAccount route id will make profile button icon active
export const additionalRoutes: IRoute[] = [
  {
    id: 32,
    name: 'MY ACCOUNT',
    path: Paths.myAccount,
    component: MyAccount,
  },
  {
    id: 33,
    name: 'ADMIN',
    component: AdminControls,
    path: Paths.admin,
  },
  {
    id: 30,
    name: 'NOTIFICATIONS',
    path: Paths.notifications,
    component: Notifications,
    hide: true,
    hideInMobile: true,
  },
  {
    id: 31,
    name: 'NOTIFICATION PREFERENCES',
    path: Paths.notificationPreferences,
    component: NotificationPreferences,
    hide: true,
    hideInMobile: true,
  },
  {
    id: 34,
    name: 'SUPPORT',
    path: Paths.support,
    component: Support,
    componentPublic: SupportPublic,
    universal: true,
  },
  {
    id: 36,
    name: 'LOG OUT',
    path: Paths.logout,
    component: LogOut,
  },
];

export const isDev = process.env.NODE_ENV === 'development';

export enum UserPreferenceTypes {
  songs = 'songs',
  songs_search = 'songs_search',
  contacts = 'contacts',
  contactsGroup = 'contactsGroup',
}

export interface IColumnConfig {
  field: string;
  hide: boolean;
}
