import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { PitchTypes } from 'components/Pitches/data';
import { ManualPitchForm } from 'components/Popups';
import { ConfirmPopup, NotificationPopup } from 'components/Popups';
import { IconButton, PageHeader } from 'components/Reusable';
import DataGrid, {
  CellRendererProps,
  DataGridColDef,
  DefaultColDefConfigs,
  GetRowsHandler,
} from 'components/UI/DataGrid';

import { useBreakpoints, useDebounce, useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getIsOpenSidebar, getPitchDetailsDeletePopupConfig, getUserPermissions, setPitchDetails } from 'store';
import { DEFAULT_ERROR_CONFIG, Paths, TABLE_PAGE_SIZE } from 'utils';

import { ReactComponent as ArrowBackIcon } from 'assets/arrow-back.svg';
import { ReactComponent as PlusCircleIcon } from 'assets/plus-circle.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './PitchDetails.module.scss';

import { ExpandedRow, PitchEmail, PitchHeader } from './';
import {
  getPitchSongsColumns,
  hiddenPitchActivitiesColumnsMobile,
  hiddenPitchActivitiesColumnsTablet,
  hiddenPitchSongsColumnsMobile,
  hiddenPitchSongsColumnsTablet,
  IPitchActivitiesData,
  IPitchDetailsData,
  IPitchMedia,
  pitchActivitiesColumns,
  PitchActivitiesColumnsFields,
  PitchSongsColumnsFields,
} from './data';
import { SongStatusTypes } from 'types';

const PitchDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { isMobile, isTablet, isDesktopSmall, isDesktopBig, isLarge } = useBreakpoints();

  const { isOpenSidebar, deletePopupConfig, permissions } = useMemoSelector((state) => ({
    isOpenSidebar: getIsOpenSidebar(state),
    deletePopupConfig: getPitchDetailsDeletePopupConfig(state),
    permissions: getUserPermissions(state),
  }));

  const [pitch, setPitch] = useState<IPitchDetailsData>();
  const [reloadHash, setReloadHash] = useState(new Date().toISOString());
  const [openManualPitchForm, setManualPitchForm] = useState(false);
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [songsColumns, setSongsColumns] = useState<DataGridColDef<IPitchMedia>[]>(
    getPitchSongsColumns(false, !permissions.pitchActions)
  );
  const [activitiesColumns, setActivitiesColumns] =
    useState<DataGridColDef<IPitchActivitiesData>[]>(pitchActivitiesColumns);

  const songsGridRef = useRef<GridApi | null>(null);
  const activitiesGridRef = useRef<GridApi | null>(null);

  const updatePitchDetails = useCallback(async () => {
    const newPitch = await Api.getPitchById({ pathId: id, errorPopupConfig: DEFAULT_ERROR_CONFIG });

    setPitch(newPitch);
    setSongsColumns(getPitchSongsColumns(newPitch?.type === PitchTypes.manual, !permissions.pitchActions));
    setReloadHash(new Date().toISOString());
  }, [id, permissions.pitchActions]);

  const onClickBack = () => {
    history.goBack();
  };

  useEffect(() => {
    updatePitchDetails();
  }, [updatePitchDetails]);

  const getRowsHandlerActivities: GetRowsHandler<IPitchActivitiesData> = useCallback(
    async () => pitch?.activities || [],
    [pitch?.activities]
  );

  const onManualPitchFormSubmit = async () => {
    setManualPitchForm(false);
    await updatePitchDetails();
  };

  const isManualPitch = useMemo(() => pitch?.type === PitchTypes.manual, [pitch]);
  const isLastMedia = useMemo(() => pitch && pitch.medias.length === 1, [pitch]);
  const hiddenSongsActions = useMemo(() => (isManualPitch ? [] : [PitchSongsColumnsFields.actions]), [isManualPitch]);

  const onDeleteMediaFromPitch = async () => {
    const res = await Api.removeMediaFromManualPitch(id, {
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
      pathId: deletePopupConfig.id,
    });

    if (!res) return;

    dispatch(setPitchDetails({ deletePopupConfig: { ...deletePopupConfig, isOpen: false } }));
    setIsOpenNotification(true);

    setTimeout(() => {
      if (!isLastMedia && pitch) {
        setPitch({ ...pitch, medias: pitch.medias.filter((item) => item.id !== deletePopupConfig.id) });
      }
      isLastMedia && history.push(Paths.pitches);
    }, 1000);
  };

  const changeSongsColumns = useDebounce((disabledSongsColumns: PitchSongsColumnsFields[]) => {
    setSongsColumns((prevState) =>
      prevState.map((item) => {
        const isActionsColumn = item.field === PitchSongsColumnsFields.actions;
        const isHiddenColumn = disabledSongsColumns.includes(item.field as PitchSongsColumnsFields);

        return {
          ...item,
          hide: isActionsColumn ? !permissions.pitchActions || isHiddenColumn : isHiddenColumn,
        };
      })
    );
  }, 200);

  const changeActivitiesColumns = useDebounce((disabledActivitiesColumns: PitchActivitiesColumnsFields[]) => {
    setActivitiesColumns((prevState) =>
      prevState.map((item) => {
        return {
          ...item,
          hide: disabledActivitiesColumns.includes(item.field as PitchActivitiesColumnsFields),
        };
      })
    );
  }, 200);

  useEffect(() => {
    if (!pitch) return;

    if (!isOpenSidebar) {
      if (isDesktopBig || isLarge) {
        changeSongsColumns(hiddenSongsActions);
        changeActivitiesColumns([]);
      }
      if (isDesktopSmall) {
        changeSongsColumns(hiddenPitchSongsColumnsTablet);
        changeActivitiesColumns(hiddenPitchActivitiesColumnsTablet);
      }
    } else {
      if (isDesktopSmall || isDesktopBig) {
        changeSongsColumns(hiddenPitchSongsColumnsTablet);
        changeActivitiesColumns(hiddenPitchActivitiesColumnsTablet);
      }
      if (isLarge) {
        changeSongsColumns(hiddenSongsActions);
        changeActivitiesColumns([]);
      }
    }

    if (isMobile || isTablet) {
      changeSongsColumns([...hiddenPitchSongsColumnsMobile, ...hiddenSongsActions]);
      changeActivitiesColumns(hiddenPitchActivitiesColumnsMobile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSidebar, pitch, isMobile, isTablet, isDesktopSmall, isDesktopBig]);

  const SongsDataGrid = useMemo(
    () =>
      pitch ? (
        <DataGrid
          ref={songsGridRef}
          className={styles.pitchSongsDataGrid}
          columns={songsColumns}
          datasource={{
            type: 'classic',
            rowData: pitch.medias,
          }}
          defaultColDef={DefaultColDefConfigs}
          disableColumnsConfig={true}
          rowHeight={54}
          getGridHeight={() =>
            `${
              (pitch?.medias.length ? 58 + 70 : 20) + (pitch?.medias.length <= 5 ? pitch?.medias.length || 2 : 5) * 54
            }px`
          }
          reloadHash={reloadHash}
          isFullWidthCell={(p) => p.level === 1}
          fullWidthCellRendererFramework={(p: CellRendererProps<DataGridColDef<IPitchMedia>>) => <ExpandedRow p={p} />}
          rowClassRules={{
            [styles.expandedRow]: (p) => p.node.detail,
          }}
        />
      ) : null,
    [songsColumns, pitch, reloadHash]
  );

  const onDragStartGridParent = (e: React.DragEvent) => {
    const t = e.target as HTMLElement;
    if (!t.getAttribute) return;
    if (t.getAttribute('role') !== 'row') return;

    const selectedSongData = songsGridRef.current?.getRowNode(t.getAttribute('row-id') as string)?.data;

    e.dataTransfer?.setData(
      'text',
      JSON.stringify({ ...selectedSongData, id: selectedSongData.workId, mediaId: selectedSongData.id })
    );
  };

  const onMouseDownGridParent = (e: React.MouseEvent) => {
    const t = e.target as HTMLElement;
    const rowEl = t.closest('div.ag-row[role="row"]');

    if (rowEl === null) return;

    const selectedSongData = songsGridRef.current?.getRowNode(rowEl.getAttribute('row-id') as string)?.data;

    if (!selectedSongData?.id || selectedSongData.status === SongStatusTypes.ARCHIVED) return;

    if (!rowEl.getAttribute || !rowEl.classList.contains('ag-row-level-0')) return;
    if (rowEl.getAttribute('draggable') === 'true') return;
    rowEl.setAttribute('draggable', 'true');
  };

  return (
    <div
      className={classNames(styles.pitchDetails, globalStyles.wrapper, {
        [styles.pitchDetailsOpenSidebar]: isOpenSidebar,
      })}
    >
      <ArrowBackIcon onClick={onClickBack} className={styles.arrowBackIcon} />
      <PageHeader smallMargin />
      {pitch ? (
        <>
          <PitchHeader pitch={pitch} />
          <div className={styles.sectionContainer}>
            <div className={styles.sectionTitle}>Songs</div>
            <div
              style={{ display: 'contents' }}
              onDragStart={onDragStartGridParent}
              onMouseDown={onMouseDownGridParent}
            >
              {SongsDataGrid}
            </div>
            <ConfirmPopup
              isOpen={deletePopupConfig.isOpen}
              setIsOpen={() =>
                dispatch(setPitchDetails({ deletePopupConfig: { ...deletePopupConfig, isOpen: false } }))
              }
              questionText={
                isLastMedia
                  ? `Remove "${deletePopupConfig.title}" and Delete Pitch?`
                  : `Remove "${deletePopupConfig.title}"?`
              }
              mainText={
                isLastMedia
                  ? 'Removing this song will delete the pitch from the system, proceed?'
                  : `This song will be removed from the pitch “${pitch.title}”`
              }
              btnDoneText={isLastMedia ? 'Remove and Delete Pitch' : 'Remove'}
              onClickSubmit={onDeleteMediaFromPitch}
            />
            <NotificationPopup
              isOpen={isOpenNotification}
              setIsOpen={setIsOpenNotification}
              text={isLastMedia ? 'Song and Pitch Removed' : 'Song Removed'}
            />
            {isManualPitch && permissions.pitchActions && (
              <IconButton
                className={styles.addSongBtn}
                Icon={PlusCircleIcon}
                onClick={() => setManualPitchForm(true)}
                text="Add Songs"
              />
            )}
          </div>
          {!isManualPitch && (
            <div className={styles.sectionContainer}>
              <div className={styles.sectionTitle}>Activity</div>
              <DataGrid
                ref={activitiesGridRef}
                columns={activitiesColumns}
                datasource={{
                  type: 'infinite',
                  pageSize: TABLE_PAGE_SIZE,
                  getRows: getRowsHandlerActivities,
                  total: pitch?.activities.length,
                }}
                isFullWidthCell={(p) => p.level === 1}
                fullWidthCellRendererFramework={(p: CellRendererProps<DataGridColDef<IPitchActivitiesData>>) => (
                  <ExpandedRow p={p} />
                )}
                rowClassRules={{
                  [styles.expandedRow]: (p) => p.node.detail,
                }}
                defaultColDef={DefaultColDefConfigs}
                disableColumnsConfig={true}
                rowHeight={54}
                reloadHash={reloadHash}
                getGridHeight={() => '170px'}
              />
            </div>
          )}
          {!isManualPitch && (
            <div className={styles.sectionContainer}>
              <div className={styles.sectionTitle}>Email Sent</div>
              <PitchEmail
                title={pitch?.title}
                publicCode={pitch?.publicCode}
                expireDate={pitch?.expireDate}
                message={pitch?.message}
                contacts={pitch?.contacts}
              />
            </div>
          )}
          {openManualPitchForm && (
            <ManualPitchForm
              addedSongs={pitch.medias?.map((el) => ({
                id: el.workId,
                mediaId: el.id,
                path: el.mediaPath,
                playbackPath: el.mediaPlaybackPath,
                result: el.result ? { id: el.id || '', title: el.id || '' } : undefined,
                title: el.title,
                version: el.version,
                writers: el.writers || undefined,
              }))}
              item={{ id: pitch.id }}
              onClose={() => setManualPitchForm(false)}
              onSubmit={onManualPitchFormSubmit}
            />
          )}
        </>
      ) : null}
    </div>
  );
};

export default PitchDetails;
