import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';

import { DatePickerPopup } from 'components/Popups';
import WritersAutocompleteFilterField from 'components/Popups/SongsModuleFiltersPopup/WritersAutocompleteFilterField';
import { DoubleBtnGroup } from 'components/Reusable';
import {
  defaultSongsModuleFilterData,
  SongsModuleFilterDataType,
  SongsModuleFilterKeys,
  SongsModuleFilterTitles,
  songStatuses,
  songTypes,
} from 'components/Songs/data';
import { InputAutocomplete, InputDateRange, InputText } from 'components/UI';
import FiltersPopupWrapper from 'containers/FiltersPopupWrapper';

import { useMemoSelector } from 'hooks';
import {
  getIsOpenSidebar,
  getSongsModuleFiltersData,
  getSongsModuleIsOpenFiltersPopup,
  getUserPermissions,
  getUserTeamsForAutocomplete,
  setSongsModule,
} from 'store';
import { dateFormatWithSlash, getStartEndDateFromString, getStringFromStartEndDate } from 'utils';

import { ReactComponent as PercentageIcon } from 'assets/percentage.svg';
import styles from './SongsModuleFiltersPopup.module.scss';

interface IFiltersPopup {
  parent?: MutableRefObject<HTMLDivElement | null>;
  customCancel?: () => void;
  customSubmit?: () => void;
}

interface IDateRangePickerConfig {
  isOpen: boolean;
  name: SongsModuleFilterKeys | null;
}

const initDateRangePickerConfig: IDateRangePickerConfig = {
  isOpen: false,
  name: null,
};

const SongsModuleFiltersPopup = ({ customCancel, customSubmit, parent }: IFiltersPopup): JSX.Element => {
  const dispatch = useDispatch();

  const { control, handleSubmit, reset, setValue, watch } = useForm<SongsModuleFilterDataType>({
    defaultValues: defaultSongsModuleFilterData,
  });

  const { isOpenFiltersPopup, filtersData, teams, isOpenSidebar, permissions } = useMemoSelector((state) => ({
    isOpenFiltersPopup: getSongsModuleIsOpenFiltersPopup(state),
    filtersData: getSongsModuleFiltersData(state),
    teams: getUserTeamsForAutocomplete(state),
    isOpenSidebar: getIsOpenSidebar(state),
    permissions: getUserPermissions(state),
  }));

  const [dateRangePickerConfig, setDateRangePickerConfig] = useState<IDateRangePickerConfig>(initDateRangePickerConfig);

  const onSubmit = (data: SongsModuleFilterDataType) => {
    dispatch(setSongsModule({ filtersData: data, isOpenFiltersPopup: false }));
    customSubmit && customSubmit();
  };

  const onCancel = (): void => {
    reset(filtersData);
    dispatch(setSongsModule({ isOpenFiltersPopup: false }));
    customCancel && customCancel();
  };

  const onReset = () => {
    reset(defaultSongsModuleFilterData);
    dispatch(setSongsModule({ filtersData: defaultSongsModuleFilterData }));
  };

  const onSubmitDateRangePicker = useCallback(
    (startDate?: Date, endDate?: Date) => {
      if (dateRangePickerConfig.name) {
        setValue(dateRangePickerConfig.name, getStringFromStartEndDate(startDate, endDate));
        setDateRangePickerConfig(initDateRangePickerConfig);
      }
    },
    [dateRangePickerConfig, setValue]
  );

  const onCloseDateRangePicker = useCallback(() => {
    if (dateRangePickerConfig.isOpen) {
      setDateRangePickerConfig(initDateRangePickerConfig);
    }
  }, [dateRangePickerConfig]);

  useEffect(() => {
    if (!isOpenFiltersPopup) {
      setDateRangePickerConfig(initDateRangePickerConfig);
      return;
    }

    reset(filtersData);
  }, [filtersData, isOpenFiltersPopup, reset]);

  return (
    <FiltersPopupWrapper
      isOpen={isOpenFiltersPopup}
      onCancel={onCancel}
      parent={parent}
      component={() => (
        <form
          className={classNames(styles.filtersForm, {
            [styles.openSidebar]: isOpenSidebar,
          })}
          onSubmit={handleSubmit(onSubmit)}
          onReset={onReset}
        >
          {permissions.songsModuleFilters && (
            <>
              <DatePickerPopup
                TransitionComponent={Fade}
                open={dateRangePickerConfig.isOpen}
                onClose={onCloseDateRangePicker}
                startDate={
                  dateRangePickerConfig.name && watch(SongsModuleFilterKeys[dateRangePickerConfig.name])
                    ? new Date(
                        getStartEndDateFromString(
                          watch(SongsModuleFilterKeys[dateRangePickerConfig.name]) as string,
                          'to',
                          dateFormatWithSlash
                        ).start
                      )
                    : undefined
                }
                endDate={
                  dateRangePickerConfig.name && watch(SongsModuleFilterKeys[dateRangePickerConfig.name])
                    ? new Date(
                        getStartEndDateFromString(
                          watch(SongsModuleFilterKeys[dateRangePickerConfig.name]) as string,
                          'to',
                          dateFormatWithSlash
                        ).end
                      )
                    : undefined
                }
                onSubmit={onSubmitDateRangePicker}
              />
              <div className={styles.filtersContainer}>
                <InputText
                  control={control}
                  name={SongsModuleFilterKeys.songTitle}
                  label={SongsModuleFilterTitles.songTitle}
                  placeholder="filter by song title"
                />
                <InputAutocomplete
                  data={teams}
                  control={control}
                  name={SongsModuleFilterKeys.team}
                  label={SongsModuleFilterTitles.team}
                  labelPlacement="start"
                  placeholder="filter by team"
                  autocompleteRootClass={styles.autocompleteRoot}
                />
                <InputText
                  control={control}
                  name={SongsModuleFilterKeys.workId}
                  label={SongsModuleFilterTitles.workId}
                  placeholder="filter by work ID"
                />
                <InputText
                  control={control}
                  name={SongsModuleFilterKeys.wcm}
                  label={SongsModuleFilterTitles.wcm}
                  placeholder="filter by WCM control"
                  Icon={PercentageIcon}
                  inputIconClass={styles.percentageIcon}
                  type="number"
                />
                <InputAutocomplete
                  data={songTypes}
                  control={control}
                  name={SongsModuleFilterKeys.type}
                  label={SongsModuleFilterTitles.type}
                  labelPlacement="start"
                  multiple={false}
                  disableClearable={false}
                  placeholder="All"
                  autocompleteRootClass={styles.autocompleteRoot}
                />
                <InputText
                  control={control}
                  name={SongsModuleFilterKeys.uploadedBy}
                  label={SongsModuleFilterTitles.uploadedBy}
                  placeholder="filter by uploaded by"
                />
                <WritersAutocompleteFilterField control={control} />
                <InputDateRange
                  control={control}
                  name={SongsModuleFilterKeys.uploadedOn}
                  label={SongsModuleFilterTitles.uploadedOn}
                  placeholder="filter by uploaded on"
                  onClick={() => setDateRangePickerConfig({ isOpen: true, name: SongsModuleFilterKeys.uploadedOn })}
                />
                <InputAutocomplete
                  data={songStatuses}
                  control={control}
                  name={SongsModuleFilterKeys.status}
                  label={SongsModuleFilterTitles.status}
                  labelPlacement="start"
                  placeholder="filter by status"
                  autocompleteRootClass={styles.autocompleteRoot}
                />
                <InputText
                  control={control}
                  name={SongsModuleFilterKeys.modifiedBy}
                  label={SongsModuleFilterTitles.modifiedBy}
                  placeholder="filter by modified by"
                />
                <InputDateRange
                  control={control}
                  name={SongsModuleFilterKeys.creationDate}
                  label={SongsModuleFilterTitles.creationDate}
                  placeholder="filter by creation date"
                  onClick={() => setDateRangePickerConfig({ isOpen: true, name: SongsModuleFilterKeys.creationDate })}
                />
                <InputDateRange
                  control={control}
                  name={SongsModuleFilterKeys.modifiedOn}
                  label={SongsModuleFilterTitles.modifiedOn}
                  placeholder="filter by modified on"
                  onClick={() => setDateRangePickerConfig({ isOpen: true, name: SongsModuleFilterKeys.modifiedOn })}
                />
              </div>
            </>
          )}
          {!permissions.songsModuleFilters && (
            <div className={styles.filtersContainer}>
              {permissions.songsModuleFiltersTitle && (
                <InputText
                  control={control}
                  name={SongsModuleFilterKeys.songTitle}
                  label={SongsModuleFilterTitles.songTitle}
                  placeholder="filter by song title"
                />
              )}
              {permissions.songsModuleFiltersTeam && (
                <InputAutocomplete
                  data={teams}
                  control={control}
                  name={SongsModuleFilterKeys.team}
                  label={SongsModuleFilterTitles.team}
                  labelPlacement="start"
                  placeholder="filter by team"
                  autocompleteRootClass={styles.autocompleteRoot}
                />
              )}
              {permissions.songsModuleFiltersWriters && <WritersAutocompleteFilterField control={control} />}
            </div>
          )}
          <DoubleBtnGroup
            className={styles.doubleBtnGroupAdditional}
            name1="Reset"
            name2="Filter"
            type1="reset"
            type2="submit"
            spacing={13}
          />
        </form>
      )}
    />
  );
};

export default SongsModuleFiltersPopup;
