import React from 'react';

import styles from './ActionButton.module.scss';

export interface ActionButtonProps {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
}

const ActionButton = (props: ActionButtonProps): JSX.Element => {
  const className = [styles.actionButton, props.className].filter((c) => c).join(' ');
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.onClick && props.onClick(e);
  };
  return (
    <button className={className} onClick={onClick}>
      {props.text}
    </button>
  );
};

export default ActionButton;
