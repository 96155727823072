import { Tooltip } from 'components/UI';

import globalStyles from 'styles/modules/Global.module.scss';

interface IInternalContactCell {
  contactInternal?: string;
}

const InternalContactCell = ({ contactInternal }: IInternalContactCell) => {
  return (
    <Tooltip text={contactInternal || ''}>
      <span className={globalStyles.f13h16SuisseREG_grayDarker}>{contactInternal}</span>
    </Tooltip>
  );
};

export default InternalContactCell;
