import { IReportFilter } from '.';
import { IAutocompleteOption } from 'types';

// moved from ColumnSelection
export interface IColumn {
  hasFilter?: boolean;
  label: string;
  name: ReportColumnName;
}

// moved from ReportForm.tsx
export enum Status {
  Hold = 'HOLD',
  OffHold = 'OFF',
  Cut = 'CUT',
  UnreleasedCut = 'UNRELEASED_CUT',
}
export enum SubStatus {
  Hold1 = 'HOLD1',
  Hold2 = 'HOLD2',
  Hold3 = 'HOLD3',
  Hold4 = 'HOLD4',
  Hold5 = 'HOLD5',
  CoWriter = 'HOLD_CO_WRITER',
}

export enum LabelView {
  yes = 'YES',
  no = 'NO',
}

export enum ReportColumnName {
  Artist = 'artist',
  CreationDate = 'creationDate',
  ExternalContact = 'externalContact',
  InternalContact = 'internalContact',
  Label = 'label',
  Producer = 'producer',
  Released = 'released',
  SongTitle = 'songTitle',
  Status = 'status',
  StatusDate = 'statusDate',
  SubStatus = 'subStatus',
  Team = 'team',
  Version = 'version',
  Wcm = 'wcm',
  WorkId = 'workId',
  Writers = 'writers',
  UploadDate = 'uploadDate',
  UploadByName = 'uploadByName',
  LabelView = 'labelView',
}

export const getColumnsAsObject = (): { [key in ReportColumnName]: IColumn } => ({
  songTitle: { hasFilter: true, label: 'Song Title', name: 'songTitle' as ReportColumnName },
  writers: { hasFilter: true, label: 'Writer(s)', name: 'writers' as ReportColumnName },
  status: { hasFilter: true, label: 'Status', name: 'status' as ReportColumnName },
  subStatus: { hasFilter: true, label: 'Sub-Status', name: 'subStatus' as ReportColumnName },
  artist: { label: 'Artist', name: 'artist' as ReportColumnName },
  label: { hasFilter: true, label: 'Releasing Label', name: 'label' as ReportColumnName },
  labelView: { hasFilter: true, label: 'Label View', name: 'labelView' as ReportColumnName },
  version: { label: 'Version', name: 'version' as ReportColumnName },
  statusDate: { label: 'Status Date', name: 'statusDate' as ReportColumnName },
  wcm: { hasFilter: true, label: 'WCM%', name: 'wcm' as ReportColumnName },
  creationDate: { label: 'Creation Date', name: 'creationDate' as ReportColumnName },
  externalContact: {
    hasFilter: true,
    label: 'External Contact',
    name: 'externalContact' as ReportColumnName,
  },
  internalContact: {
    hasFilter: true,
    label: 'Internal Contact',
    name: 'internalContact' as ReportColumnName,
  },
  producer: { hasFilter: true, label: 'Producer', name: 'producer' as ReportColumnName },
  uploadDate: { label: 'Uploaded On', name: 'uploadDate' as ReportColumnName },
  uploadByName: { label: 'Uploaded By', name: 'uploadByName' as ReportColumnName },
  released: { hasFilter: true, label: 'Released', name: 'released' as ReportColumnName },
  team: { hasFilter: true, label: 'Team', name: 'team' as ReportColumnName },
  workId: { label: 'Work ID', name: 'workId' as ReportColumnName },
});

export const getColumnsAsArray = (): IColumn[] => Object.values(getColumnsAsObject());

export const getColumnLabel = (name: ReportColumnName | null | undefined): string =>
  (name && getColumnsAsObject()[name]?.label) || '';

export const getStatusesAsObject = (): { [key in Status]: IAutocompleteOption } => ({
  HOLD: { id: 'HOLD', title: 'Hold' },
  OFF: { id: 'OFF', title: 'Off-Hold' },
  CUT: { id: 'CUT', title: 'Cut' },
  UNRELEASED_CUT: { id: 'UNRELEASED_CUT', title: 'Unreleased Cut' },
});

export const getStatusesAsArray = (): IAutocompleteOption[] => Object.values(getStatusesAsObject());

export const getStatusLabel = (name: Status | undefined | null): string =>
  (name && getStatusesAsObject()[name]?.title) || name?.toString() || '';

export const getSubStatusesAsObject = (): { [key in SubStatus]: IAutocompleteOption } => ({
  HOLD1: { id: 'HOLD1', title: 'Hold 1' },
  HOLD2: { id: 'HOLD2', title: 'Hold 2' },
  HOLD3: { id: 'HOLD3', title: 'Hold 3' },
  HOLD4: { id: 'HOLD4', title: 'Hold 4' },
  HOLD5: { id: 'HOLD5', title: 'Hold 5' },
  HOLD_CO_WRITER: { id: 'HOLD_CO_WRITER', title: 'Co-Writer' },
});

export const getSubStatusesAsArray = (): IAutocompleteOption[] => Object.values(getSubStatusesAsObject());

export const getSubStatusLabel = (name: SubStatus | null | undefined): string =>
  (name && getSubStatusesAsObject()[name]?.title) || name?.toString() || '';

export const getLabelViewAsObject = (): { [key in LabelView]: IAutocompleteOption } => ({
  YES: { id: 'YES', title: 'Yes' },
  NO: { id: 'NO', title: 'No' },
});

export const getLabelViewAsArray = (): IAutocompleteOption[] => Object.values(getLabelViewAsObject());

export const getFilterOptions = (
  filters: IReportFilter[],
  filterColumn: ReportColumnName | null | undefined
): IAutocompleteOption[] =>
  getColumnsAsArray()
    .filter((c) => c.hasFilter && (c.name === filterColumn || !filters.find((f) => f.key === c.name)))
    .map((c) => ({ id: c.name, title: c.label }));

export const getRequiredColumnNames = (): ReportColumnName[] => [
  'songTitle' as ReportColumnName,
  'writers' as ReportColumnName,
];
