import { useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';
import classNames from 'classnames';

import ProgressBar from 'components/Reusable/ProgressBar';

import { useMemoSelector } from 'hooks';
import { getActiveTrack, setActiveTrack, setCurrentTracks } from 'store';
import { immediatelyPlayAudio } from 'utils';

import { ReactComponent as PlayCircleIcon } from 'assets/play-circle.svg';
import { ReactComponent as WaveformIcon } from 'assets/waveform.svg';
import styles from './PlayButton.module.scss';

import { ICurrentTrack } from 'types';

export interface IPlayButtonProps {
  className?: string;
  disabled?: boolean;
  /**
   * Will disable button if it's not possible to play
   */
  extension?: string | null;
  getTracks?: () => ICurrentTrack[];
  mediaId?: string | null;
  /**
   * Percent
   */
  processing?: number;
  isFromHeader?: boolean;
}

const isAllowedExtension = (fileNameOrPath: string | null | undefined): boolean => {
  const allowedExt = ['mp3', 'm4a'];
  const ext = fileNameOrPath?.split('.').pop()?.toLowerCase();
  return Boolean(ext && allowedExt.includes(ext));
};

const Icon = ({ disabled, mediaId, processing }: IPlayButtonProps) => {
  const activeTrack = useMemoSelector(getActiveTrack);

  return activeTrack.mediaId === mediaId ? (
    <WaveformIcon style={{ pointerEvents: 'none' }} />
  ) : (
    <PlayCircleIcon
      data-testid="play-icon"
      className={classNames((disabled || typeof processing === 'number') && styles.disabledIcon)}
      height="16"
      width="16"
    />
  );
};

const IconWrapper = (props: IPlayButtonProps) => (
  <div data-testid="waveform-icon" className={styles.iconWrapper}>
    <Icon {...props} />
  </div>
);

const ProgressBarWithIcon = (props: IPlayButtonProps) => (
  <ProgressBar color="#008642" curcle height="18" progress={props.processing || 0} width="18">
    <Icon {...props} />
  </ProgressBar>
);

const PlayButton = (props: IPlayButtonProps) => {
  const activeTrack = useMemoSelector(getActiveTrack);
  const dispatch = useDispatch();

  const allowedExt = useMemo(
    () => (props.mediaId ? isAllowedExtension(props.extension) : false),
    [props.extension, props.mediaId]
  );
  const disabled = !props.isFromHeader ? props.disabled || typeof props.processing === 'number' || !allowedExt : false;

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (activeTrack.mediaId === props.mediaId) return;

    batch(() => {
      const tracks = props.getTracks && props.getTracks().filter((track) => isAllowedExtension(track.playbackPath));
      if (!tracks) return;

      dispatch(setCurrentTracks(tracks));
      dispatch(setActiveTrack({ index: props.mediaId ? tracks.findIndex((el) => el.mediaId === props.mediaId) : 0 }));

      immediatelyPlayAudio();
    });
  };

  return (
    <button
      data-testid="play-button"
      className={classNames(styles.playButton, props.className)}
      disabled={disabled}
      onClick={onClick}
    >
      {typeof props.processing === 'number' ? (
        <ProgressBarWithIcon {...props} disabled={disabled} />
      ) : (
        <IconWrapper {...props} disabled={disabled} />
      )}
    </button>
  );
};

export default PlayButton;
