import React from 'react';
import { Fade } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import classNames from 'classnames';
import moment from 'moment';

import { Snackbar } from 'components/Reusable';
import { DateRangePicker } from 'components/UI';
import WrapperComponent from 'containers/WrapperComponent';

import { useMemoSelector } from 'hooks';
import { getIsOpenSidebar } from 'store';
import { dateFormat } from 'utils';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import styles from './DatePickerPopup.module.scss';

export interface IBlockDaysConfig {
  day: Date;
  period: 'before' | 'after';
  isDayIncluded?: boolean;
}

interface IDatePickerPopupProps {
  open: boolean;
  TransitionComponent?: React.ComponentType<TransitionProps & { children?: React.ReactElement<never, never> }>;
  onClose: () => void;
  onSubmit?: () => void;
  containerClassName?: string;
  contentClassName?: string;
  startDate?: Date;
  endDate?: Date;
  smallSize?: boolean;
  disablePortal?: boolean;
  top200?: boolean;

  // Single Day Picker Props
  blockPastDates?: boolean;
  blockDaysConfig?: IBlockDaysConfig;
  isSingleDayPicker?: boolean;
  showSingleDateActionButtons?: boolean;
  date?: string;
  onChangeSingleDate?: (date: string | undefined) => void;
  onSubmitSingleDate?: (date: string | undefined) => void;
}

const DatePickerPopup = ({
  containerClassName,
  contentClassName,
  open,
  onClose,
  onSubmit,
  TransitionComponent = Fade,
  startDate,
  endDate,
  isSingleDayPicker,
  showSingleDateActionButtons,
  date,
  onChangeSingleDate,
  onSubmitSingleDate,
  blockPastDates,
  blockDaysConfig,
  smallSize,
  disablePortal,
  top200,
}: IDatePickerPopupProps): JSX.Element => {
  const isOpenSidebar = useMemoSelector(getIsOpenSidebar);

  return (
    <WrapperComponent disablePortal={disablePortal}>
      <Snackbar
        TransitionComponent={TransitionComponent}
        containerClassName={classNames(containerClassName, styles.datePickerSnackbarContainer, {
          [styles.singleDayPickerContainer]: isSingleDayPicker,
          [styles.singleDayPickerContainerWitchActionBtns]: isSingleDayPicker && showSingleDateActionButtons,
          [styles.datePickerSnackbarContainerOpenSidebar]: isOpenSidebar,
          [styles.datePickerSnackbarContainerTop200]: top200,
          [styles.datePickerSnackbarSmallSize]: smallSize,
          [styles.datePickerSnackbarContainerDisabledPortal]: disablePortal,
        })}
        contentClassName={classNames(contentClassName, styles.datePickerSnackbarContent)}
        open={open}
        onClose={onClose}
        message={
          <>
            {!(isSingleDayPicker && !showSingleDateActionButtons) && <CloseIcon onClick={onClose} />}
            <DateRangePicker
              isOpenSidebar={isOpenSidebar}
              date={moment(date)?.isValid() ? date : date?.replace(/-/g, '/')}
              blockPastDates={blockPastDates}
              blockDaysConfig={blockDaysConfig}
              startDate={startDate}
              endDate={endDate}
              onCancel={onClose}
              onSubmit={onSubmit}
              smallSize={smallSize}
              // Single Date Picker
              isSingleDayPicker={isSingleDayPicker}
              showSingleDateActionButtons={showSingleDateActionButtons}
              onSubmitSingleDate={(date) => {
                onSubmitSingleDate && onSubmitSingleDate(date ? moment(new Date(date)).format(dateFormat) : date);
              }}
              onChangeSingleDate={
                onChangeSingleDate
                  ? (date) => onChangeSingleDate(date ? moment(new Date(date)).format(dateFormat) : date)
                  : undefined
              }
            />
          </>
        }
      />
    </WrapperComponent>
  );
};

export default DatePickerPopup;
