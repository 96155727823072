import { PageHeader } from 'components/Reusable';

import { useMemoSelector } from 'hooks';
import { getUserPermissions } from 'store';

import styles from './Songs.module.scss';

const SongsHeader = () => {
  const permissions = useMemoSelector(getUserPermissions);

  return (
    <div className={styles.songsHeading}>
      <PageHeader
        mb={permissions.songsPageHeaderMarginBottom as number}
        mediumMargin={!permissions.songsPageHeaderMarginBottom}
      />
    </div>
  );
};

export default SongsHeader;
