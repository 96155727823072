import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { IDroppedSongs, IDroppedSongType, IPlaylistDetails, IPlaylistMedia } from 'containers/Sidebar/data';

import { useMemoSelector } from 'hooks/index';
import { getRecentEditedSongs, setRecentEditedSongs } from 'store';
import { UsageKeys } from 'store/reducers/general/types';
import { findRecentEditedSongById } from 'utils';

import { IWriterItem } from 'types';

interface IUsePlaylistSongsUpdateProps extends Partial<IDroppedSongs> {
  playlist?: IPlaylistDetails;
  setPlaylist?: Dispatch<SetStateAction<IPlaylistDetails | undefined>>;
}

const usePlaylistSongsUpdate = ({
  droppedSongs,
  setDroppedSongs,
  playlist,
  setPlaylist,
}: IUsePlaylistSongsUpdateProps) => {
  const dispatch = useDispatch();
  const recentEditedSongs = useMemoSelector(getRecentEditedSongs);

  useEffect(() => {
    if (!recentEditedSongs.length) return;

    if (droppedSongs?.length && setDroppedSongs) {
      setDroppedSongs((prevState) =>
        prevState.map((media) => {
          const recentEditedMedia =
            findRecentEditedSongById(recentEditedSongs, media.id) ||
            findRecentEditedSongById(recentEditedSongs, (media as IPlaylistMedia).songId, 'labelView');
          if (recentEditedMedia) {
            return {
              ...media,
              ...recentEditedMedia.update,
              ...(recentEditedMedia.update.writers && {
                writers: recentEditedMedia.update.writers.map((item) => (item as IWriterItem).name || ''),
              }),
            } as IDroppedSongType;
          } else {
            return media;
          }
        })
      );
    }

    if (playlist?.medias.length && setPlaylist) {
      let isSomeSongUpdated = false;
      const newMedias = playlist?.medias.map((media) => {
        const recentEditedMedia =
          findRecentEditedSongById(recentEditedSongs, media.id) ||
          findRecentEditedSongById(recentEditedSongs, media.songId, 'labelView');
        if (recentEditedMedia) {
          isSomeSongUpdated = true;
          return {
            ...media,
            ...recentEditedMedia.update,
            ...(recentEditedMedia.update.writers && {
              writers: recentEditedMedia.update.writers.map((item) => (item as IWriterItem).name || ''),
            }),
          };
        } else {
          return media;
        }
      });
      if (!isSomeSongUpdated) return;

      setPlaylist((prev) => ({ ...prev, medias: newMedias } as IPlaylistDetails));
      dispatch(setRecentEditedSongs({ usages: [UsageKeys.playlist] }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentEditedSongs]);
};

export default usePlaylistSongsUpdate;
