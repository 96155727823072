import { Controller, UseFormReturn } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';

import { getFormattedDate, validateRequired } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './ManualPitchForm.module.scss';

import { IManualPitch, SongRow } from '.';

export interface IConfirmProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  methods: UseFormReturn<IManualPitch, object>;
}

interface IFieldProps {
  label: string;
  value?: string;
  valueClassName?: string;
}

const Field = ({ label, value, valueClassName }: IFieldProps) => (
  <label>
    <div className={styles.labelText}>{label}</div>
    <div className={classNames(styles.value, valueClassName)}>{value}</div>
  </label>
);

const Confirm = ({ methods }: IConfirmProps) => {
  const { artist, contact, label, sentDate, title } = methods.getValues();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field label="Title" value={title} valueClassName={globalStyles.textEllipsis} />
        </Grid>
        <Grid item xs={6}>
          <Field label="Sent Date" value={getFormattedDate(sentDate || '')} />
        </Grid>
        <Grid item xs={12}>
          <Field label="Pitched To" value={contact?.title} />
        </Grid>
        <Grid item xs={6}>
          <Field label="Artist" value={artist} />
        </Grid>
        <Grid item xs={6}>
          <Field label="Label" value={label} />
        </Grid>
      </Grid>
      <h3 className={styles.heading}>Songs</h3>
      <Controller
        control={methods.control}
        name="songs"
        render={({ field: { onChange, value } }) => (
          <>
            {value?.length === 0 && <div className={styles.message}>No songs added</div>}
            {value?.map((s, i, arr) => (
              <SongRow key={i} onDelete={(song) => onChange(value.filter((v) => v !== song))} song={s} songs={arr} />
            ))}
          </>
        )}
        rules={{ validate: validateRequired }}
      />
    </>
  );
};

export default Confirm;
