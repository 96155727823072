const cache: Record<string, unknown[]> = {};

const invalidateCache = (key: string) => {
  cache[key] = [];
};

const addToCache = <TCache>(data: TCache[], key: string) => {
  const oldCache = cache[key] || [];
  cache[key] = [...oldCache, ...data];
};

// Get cache
const getCache = (key: string) => cache[key] || [];

export { addToCache, getCache, invalidateCache };
