import React, { ButtonHTMLAttributes, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import classNames from 'classnames';

import { Tooltip } from 'components/UI';

import { ReactComponent as ArrowBackIcon } from 'assets/arrow-back.svg';
import styles from './Button.module.scss';

import Spinner from '../Spinner';

export enum ButtonVariant {
  GoBack,
  Primary,
  Text,
  custom,
}

export interface IButtonMenuItemProps extends React.PropsWithChildren<unknown> {
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | SVGSVGElement, MouseEvent>) => void;
  text?: string | JSX.Element;
}

export interface IButtonProps {
  className?: string;
  disabled?: boolean;
  Icon?: JSX.Element;
  menuItems?: IButtonMenuItemProps[];
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void;
  text?: string | JSX.Element;
  tooltip?: string;
  isPending?: boolean;
  variant?: ButtonVariant;
  roundedMenu?: boolean;
  paperClassName?: string;
  skipPreventDefaultAndStopPropagation?: boolean;
  iconPlacement?: 'left' | 'right';
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

export const MenuItem = ({ children, className, disabled, onClick, text }: IButtonMenuItemProps): JSX.Element => {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    onClick && onClick(e);
  };

  return (
    <button className={classNames(styles.menuItem, className)} disabled={disabled} onClick={onButtonClick}>
      {typeof children === 'undefined' ? text : children}
    </button>
  );
};
const Button = ({
  children,
  className,
  disabled,
  Icon,
  menuItems,
  onClick,
  text,
  tooltip,
  isPending,
  variant,
  roundedMenu,
  paperClassName,
  iconPlacement = 'left',
  skipPreventDefaultAndStopPropagation,
  type,
}: React.PropsWithChildren<IButtonProps>): JSX.Element => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const c = classNames(styles.button, className, {
    [styles.goBackButton]: variant === ButtonVariant.GoBack,
    [styles.iconButton]: Icon && variant !== ButtonVariant.Primary,
    [styles.primaryButton]: (typeof variant === 'undefined' && !Icon) || variant === ButtonVariant.Primary,
    [styles.textButton]: variant === ButtonVariant.Text,
    [styles.customButton]: variant === ButtonVariant.custom,
  });
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (!skipPreventDefaultAndStopPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }
    onClick ? onClick(e) : variant === ButtonVariant.GoBack && history.goBack();
    menuItems && setAnchorEl(e.currentTarget);
  };

  return (
    <>
      {tooltip ? (
        <Tooltip text={tooltip}>
          <button className={c} disabled={disabled} onClick={onButtonClick}>
            {Icon && <div className={classNames(styles.icon, { [styles.withMarginRight]: !!text })}>{Icon}</div>}
            {typeof children === 'undefined' ? text : children}
            {variant === ButtonVariant.GoBack && <ArrowBackIcon />}
          </button>
        </Tooltip>
      ) : (
        <button className={c} disabled={disabled} onClick={onButtonClick} type={type}>
          {Icon && iconPlacement === 'left' && (
            <div className={classNames(styles.icon, { [styles.withMarginRight]: !!text })}>{Icon}</div>
          )}
          {typeof children === 'undefined' ? text : children}
          {Icon && iconPlacement === 'right' && (
            <div className={classNames(styles.icon, { [styles.withMarginLeft]: !!text })}>{Icon}</div>
          )}
          {variant === ButtonVariant.GoBack && <ArrowBackIcon />}
          {isPending && <Spinner className={styles.spinner} height="15" width="15" />}
        </button>
      )}
      {anchorEl && (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)} touchEvent="onTouchEnd" disableReactTree>
          <Popper
            open
            anchorEl={anchorEl}
            placement="bottom-end"
            className={styles.popover}
            onClick={(e) => e.stopPropagation()}
          >
            <Paper
              square
              classes={{
                root: classNames(styles.paperRoot, paperClassName, { [styles.roundedPaper]: roundedMenu }),
              }}
              style={{ minWidth: anchorEl.clientWidth + 2 }}
            >
              {menuItems?.map((item, i) => (
                <MenuItem
                  {...item}
                  key={i}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                    item.onClick && item.onClick(e);
                  }}
                />
              ))}
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default Button;
