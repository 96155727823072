import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GridApi } from 'ag-grid-community';

import { RowNodeExt } from 'components/UI/DataGrid';

import { useMemoSelector } from 'hooks';
import Api, { downloadAudio } from 'services/Api';
import { getUserPermissions, setRecentEditedSongs, setSongDetails } from 'store';
import { DEFAULT_ERROR_CONFIG, getDataGridItems, getSongVersion } from 'utils';

import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as RemoveIcon } from 'assets/remove.svg';
import { ReactComponent as SaveIcon } from 'assets/save.svg';
import styles from './SongDetails.module.scss';

import { RemoveActionTypes } from './data';
import { getVersionValue, validateDraft } from './utils';
import { ErrorType, ISongMedias } from 'types';

interface IVersionsActionsCellProps {
  api: GridApi;
  node: RowNodeExt;
  data?: ISongMedias;
}

const VersionsActionsCell = ({ api, data, node }: IVersionsActionsCellProps) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const permissions = useMemoSelector(getUserPermissions);

  const onSave = async () => {
    const errors = validateDraft(node, api);
    if (errors) {
      const errorType = Object.values(errors)[0];
      dispatch(
        setSongDetails({
          validationErrorPopupType:
            errorType && errorType === ErrorType.NotUniqueVersion ? 'versions-not-unique' : 'required-field',
        })
      );
    } else {
      const medias: ISongMedias[] = getDataGridItems(api);
      const hasUploadedBy = medias.some((el) => !el?.draft);

      const result = await Api.addSongToWork(id, [{ assetId: data?.id || '', version: getVersionValue(data) || '' }], {
        errorPopupConfig: DEFAULT_ERROR_CONFIG,
      });

      if (!result?.length) return;

      const songData = {
        ...data,
        ...result.find((item: ISongMedias) => item.id === data?.id),
        draft: false,
        version: getVersionValue(data),
      };

      node.setData(songData);

      // Note: this is fixing latency, later we should remove and use getSong request when it will be fixed in BE side
      dispatch(
        setRecentEditedSongs({
          songs: [
            {
              ids: { mediaId: data?.id, songId: id },
              update: { medias: result, ...(!hasUploadedBy && { createdByName: songData.uploadedByName }) },
            },
          ],
        })
      );
    }
  };

  const onClickRemoveVersion = () => {
    dispatch(
      setSongDetails({
        deletePopupConfig: {
          isOpen: true,
          mainText: 'This action cannot be undone',
          questionText: `Delete "${data?.title}" Version?`,
          notificationText: 'Version Deleted',
          id: data?.id || '',
          type: RemoveActionTypes.version,
        },
      })
    );
  };
  return !permissions.songDetailsActions ? null : (
    <div className={styles.actionsCellContainer}>
      {typeof data?.processing !== 'number' &&
        !data?.error &&
        (data?.draft ? (
          <SaveIcon onClick={onSave} style={{ height: '14px', width: '14px' }} />
        ) : (
          <DownloadIcon
            onClick={() =>
              downloadAudio(
                data?.path || '',
                `${data?.songTitle} (${
                  typeof data?.version === 'object' ? data?.version?.shortTitle : getSongVersion(data?.version)
                })`
              )
            }
          />
        ))}
      <RemoveIcon onClick={onClickRemoveVersion} />
    </div>
  );
};

export default VersionsActionsCell;
