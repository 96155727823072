import classNames from 'classnames';

import { TextWithFade } from 'components/UI';

import { getSongVersion } from 'utils';

import styles from './MusicPlayer.module.scss';

import { FavoriteContainer } from '.';
import { IPlaybarHeaderTitleProps } from './IMusicPlayer';
import { SongStatusTypes } from 'types';

const PlaybarHeaderTitle = ({ track, favoriteConfig, toggleFavorite, isDark }: IPlaybarHeaderTitleProps) => {
  return (
    <div
      className={classNames(styles.titleContainer, {
        [styles.hidden]: !track,
      })}
    >
      <FavoriteContainer
        favoriteConfig={favoriteConfig}
        toggleFavorite={toggleFavorite}
        disableIcon={!track || !track?.version || [SongStatusTypes.ARCHIVED, ''].includes(track?.status)}
      />
      <div className={styles.titleTextWrapper}>
        <TextWithFade className={styles.title} isDark={isDark}>
          {track ? track.title : ''}
          {track?.version ? ` (${getSongVersion(track.version)})` : ''}
        </TextWithFade>
        <span className={styles.writers}>{track ? track.writers?.join(' / ') : '-'}</span>
      </div>
    </div>
  );
};

export default PlaybarHeaderTitle;
