export const formatDuration = (duration: number): string => {
  let hours: string | number = Math.floor(duration / 3600);
  let minutes: string | number = Math.floor((duration - hours * 3600) / 60);
  let seconds: string | number = Math.floor(duration % 60);

  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;
  if (seconds < 10) seconds = '0' + seconds;

  if (hours === '00') {
    return `${minutes}:${seconds}`;
  }

  return `${hours}:${minutes}:${seconds}`;
};
