import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Dialog, DoubleBtnGroup } from 'components/Reusable';

import { useMemoSelector } from 'hooks';
import Api, { updateHoldRequestStatusTabValues } from 'services/Api';
import {
  getHoldRequestActionPopupConfig,
  setError,
  setHoldRequest,
  setLoading,
  setMainConfirmPopup,
  setMainNotification,
} from 'store';
import { HoldRequestActions } from 'store/reducers/general/types';
import {
  DEFAULT_ERROR_CONFIG,
  DEFAULT_HOLD_REQUEST_CONFIG,
  DEFAULT_MAIN_CONFIRM_POPUP,
  getFormattedDate,
  Paths,
} from 'utils';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './HoldRequestActionPopup.module.scss';

import { HoldStatuses } from 'types';

export enum HoldRequestActionTitles {
  approve = 'Approve',
  cancel = 'Cancel Hold',
}

interface IStatusInfo {
  artist: string;
  label: string;
  contact: string;
  extContact: string;
}

interface IHoldRequest {
  id: string;
  workTitle: string;
  workCode: string;
  workId: string;
  requesterName: string;
  requesterEmail: string;
  requestDate: string;
  writers: string[];
  message: string;
  status: HoldStatuses;
  statusInfo: IStatusInfo;
}

interface IHoldFields {
  id: number;
  title: string;
  dataKey: keyof IHoldRequest;
  dataKeyNested?: keyof IStatusInfo;
}

const holdFields: IHoldFields[] = [
  { id: 1, title: 'Song', dataKey: 'workTitle' },
  { id: 2, title: 'Writers', dataKey: 'writers' },
  { id: 3, title: 'Requester Name', dataKey: 'requesterName' },
  { id: 4, title: 'Requester Email', dataKey: 'requesterEmail' },
  { id: 5, title: 'Artist', dataKey: 'statusInfo', dataKeyNested: 'artist' },
  { id: 6, title: 'Request Date', dataKey: 'requestDate' },
  { id: 7, title: 'Label', dataKey: 'statusInfo', dataKeyNested: 'label' },
  { id: 8, title: 'Message', dataKey: 'message' },
  // { id: 9, title: 'External Contact', dataKey: 'statusInfo', dataKeyNested: 'extContact' },
  // { id: 10, title: 'Internal Contact', dataKey: 'statusInfo', dataKeyNested: 'contact' },
];

const HoldRequestActionPopup = () => {
  const dispatch = useDispatch();
  const { isOpen, type, holdId, cb } = useMemoSelector(getHoldRequestActionPopupConfig);

  const [hold, setHold] = useState<IHoldRequest | null>(null);

  const isApprove = useMemo(() => type === HoldRequestActions.approve, [type]);

  const onClose = useCallback(() => {
    dispatch(setHoldRequest({ action: { ...DEFAULT_HOLD_REQUEST_CONFIG.action, type } }));
    setHold(null);

    if (!window.location.href.includes('holdId')) return;

    const pathnameWithoutHoldId = window.location.href.replace(window.location.search, '');
    window.history.replaceState(null, 'Search | WCM Arrow', pathnameWithoutHoldId);
  }, [dispatch, type]);

  const onDeny = useCallback(async () => {
    dispatch(setHoldRequest({ reject: { isOpen: true, holdId, cb } }));
  }, [dispatch, holdId, cb]);

  const onClickSubmit = async () => {
    if (!hold) return;

    const res = isApprove
      ? await Api.holdApprove(hold.id, {
          errorPopupConfig: DEFAULT_ERROR_CONFIG,
          handler: (details, status) => {
            if (status === 412 && details?.errorCode === '4850') {
              dispatch(
                setError({
                  isOpen: true,
                  title: 'Unable to request hold',
                  text: 'We are unable to process this hold request. Please contact <a href="mailto:arrow@warnerchappell.com">arrow@warnerchappell.com.</a> for more information.',
                })
              );
            }
          },
        })
      : await Api.holdCancel(hold.id, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    if (!res) return;

    dispatch(setMainConfirmPopup(DEFAULT_MAIN_CONFIRM_POPUP));
    dispatch(setMainNotification(isApprove ? 'Hold Approved' : 'Hold Cancelled'));
    onClose();
    cb && type
      ? await cb(isApprove ? HoldStatuses.APPROVED : HoldStatuses.CANCELED)
      : await updateHoldRequestStatusTabValues(true);
  };

  const onOpenConfirmPopup = () => {
    dispatch(
      setMainConfirmPopup({
        isOpen: true,
        questionText: isApprove ? 'Approve Hold Request?' : 'Cancel Hold Request?',
        mainText: isApprove
          ? 'The song will be marked “On Hold” and the user will be notified of the approval.'
          : 'The song will be marked “Cancelled” and cannot be undone.',
        btnDoneText: type ? HoldRequestActionTitles[type] : '',
        onClickSubmit,
      })
    );
  };

  const getHoldFieldData = (dataKey: keyof IHoldRequest, dataKeyNested?: keyof IStatusInfo): string => {
    if (!hold) return '';

    if (dataKey === 'writers') {
      return hold[dataKey]?.join(' / ') || '-';
    }

    if (dataKey === 'statusInfo' && dataKeyNested) {
      return hold[dataKey][dataKeyNested];
    }

    if (dataKey === 'requestDate') {
      return getFormattedDate(hold[dataKey]);
    }

    return hold[dataKey] as string;
  };

  useEffect(() => {
    if (!isOpen) return;

    (async () => {
      dispatch(setLoading(true));

      const res = await Api.getHoldDetails({ pathId: holdId, errorPopupConfig: DEFAULT_ERROR_CONFIG });

      if (!res) {
        onClose();
        dispatch(setLoading(false));
        return;
      }

      setHold(res);

      dispatch(setLoading(false));
    })();
  }, [dispatch, holdId, isOpen, onClose]);

  return (
    <Dialog
      className={styles.dialog}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: styles.paper,
        },
      }}
      closeFromBackdropClick
    >
      <div className={styles.dialogHeader}>
        <span>Hold Request</span>
        <CloseIcon onClick={onClose} />
      </div>
      <div className={styles.infoContainer}>
        {hold &&
          holdFields.map(({ id, title, dataKey, dataKeyNested }) => {
            if (dataKey === 'statusInfo' && dataKeyNested) {
              const data = hold[dataKey][dataKeyNested];

              if (['contact', 'extContact'].includes(dataKeyNested) && !data) {
                return null;
              }
            }

            return (
              <div key={id} className={classNames(styles.infoItem, { [styles.infoItemMsg]: dataKey === 'message' })}>
                <span className={classNames(globalStyles.f13h16SuisseSB_gray, styles.infoTitle)}>{title}</span>
                {dataKey === 'message' ? (
                  <span dangerouslySetInnerHTML={{ __html: hold.message }} />
                ) : (
                  <span className={classNames(globalStyles.f13h16SuisseREG_grayDarker, styles.infoDetail)}>
                    {getHoldFieldData(dataKey, dataKeyNested)}
                    {dataKey === 'workTitle' && hold && (
                      <span className={classNames(globalStyles.f11h13SuisseREG_grayDarker, styles.workCode)}>
                        (<a href={`${Paths.songs}/${hold.workId}`}>{hold.workCode}</a>)
                      </span>
                    )}
                  </span>
                )}
              </div>
            );
          })}
      </div>
      {hold?.status === HoldStatuses.AWAITING_APPROVAL && (
        <DoubleBtnGroup
          className={styles.doubleBtnGroup}
          spacing={10}
          name1={isApprove ? 'Deny' : 'Cancel'}
          name2={type ? HoldRequestActionTitles[type] : ''}
          onClick1={isApprove ? onDeny : onClose}
          onClick2={onOpenConfirmPopup}
        />
      )}
    </Dialog>
  );
};

export default HoldRequestActionPopup;
