import { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { List } from 'react-virtualized';
import classNames from 'classnames';

import { ProgressBar } from 'components/Reusable';

import { useDidUpdate, useMemoSelector } from 'hooks';
import { getIsOpenSidebar } from 'store';

import { ReactComponent as ChevronDownIcon } from 'assets/arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/close-2.svg';
import styles from './UploadPopup.module.scss';

import UploadItemCard, { IUploadItem, UploadStatus } from './UploadItemCard';

export interface IUploadPopupProps {
  items: IUploadItem[];
  onClose: () => void;
  onComplete?: () => void;
  onItemCancel: (item: IUploadItem, status: UploadStatus) => void;
}

const getActiveItems = (items: IUploadItem[]): IUploadItem[] => {
  return items.filter(
    (i) =>
      i.status === UploadStatus.InProgress || i.status === UploadStatus.Uploaded || i.status === UploadStatus.Waiting
  );
};

const UploadPopup = ({ items, onClose, onComplete, onItemCancel }: IUploadPopupProps) => {
  const [expanded, setExpanded] = useState(true);
  const [uploadItems, setUploadItems] = useState(() => getActiveItems(items));

  const isOpenSidebar = useMemoSelector(getIsOpenSidebar);

  const onCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onClose();
  };
  const onExpandClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const curItem = useMemo(
    () =>
      uploadItems.find((i) => i.status === UploadStatus.InProgress) ||
      uploadItems
        .slice()
        .reverse()
        .find((i) => i.status === UploadStatus.Uploaded),
    [uploadItems]
  );
  const curItemIndex = curItem ? uploadItems.indexOf(curItem) : -1;
  const completed = useMemo(
    () => uploadItems.filter(({ status }) => status === UploadStatus.Uploaded).length === uploadItems.length,
    [uploadItems]
  );
  const progress = Math.round(((curItemIndex > -1 ? curItemIndex : 0) * 100) / uploadItems.length);

  useDidUpdate(() => {
    setUploadItems(getActiveItems(items));
  }, [items]);

  useDidUpdate(() => {
    if (completed) {
      onComplete?.();
    }
  }, [completed]);

  return ReactDOM.createPortal(
    <div className={classNames(styles.popup, { [styles.sidebarMargin]: isOpenSidebar })}>
      <div className={styles.header}>
        <div className={styles.title}>
          {completed ? 'Upload Complete' : `Uploading ${curItemIndex + 1} of ${uploadItems.length}`}
        </div>
        <div>
          <button className={styles.headerButton} onClick={onExpandClick}>
            <ChevronDownIcon className={classNames(expanded && styles.rotate180)} height="18" width="18" />
          </button>
          {completed && (
            <button className={styles.headerButton} onClick={onCloseClick}>
              <CloseIcon height="14" width="14" />
            </button>
          )}
        </div>
        {!completed && <ProgressBar className={styles.progressBar} progress={progress} />}
      </div>
      {expanded && (
        <div className={styles.details}>
          <List
            autoWidth
            className={styles.items}
            height={Math.min(items.length * 32, 160)}
            overscanRowCount={10}
            rowCount={items.length}
            rowHeight={32}
            rowRenderer={({ index, key, style }) => (
              <UploadItemCard item={items[index]} key={key} onCancel={onItemCancel} style={style} />
            )}
            width={358}
          />
        </div>
      )}
    </div>,
    document.body
  );
};

export default UploadPopup;
