import { forwardRef, ReactElement, Ref } from 'react';
import { Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

function Transition(props: TransitionProps & { children?: ReactElement }, ref: Ref<unknown>) {
  return <Slide direction="down" ref={ref} {...props} />;
}

export default forwardRef(Transition);

export class SlideProps {}
