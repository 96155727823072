import classNames from 'classnames';

import { useMemoSelector } from 'hooks';
import { getActiveRoute } from 'store';

import styles from './PageHeader.module.scss';

interface IPageHeaderProps {
  className?: string;
  title?: string;
  smallMargin?: boolean;
  mediumMargin?: boolean;
  Component?: JSX.Element;
  TopBtn?: JSX.Element;
  mb?: number;
}

const PageHeader = ({
  className,
  title,
  smallMargin,
  mediumMargin,
  Component,
  TopBtn,
  mb,
}: IPageHeaderProps): JSX.Element => {
  const activeRoute = useMemoSelector(getActiveRoute);

  return (
    <div
      className={classNames(
        styles.pageHeader,
        {
          [styles.smallMargin]: smallMargin,
          [styles.mediumMargin]: mediumMargin,
          [styles.topBtnExists]: TopBtn,
          [styles.hasMarginFromOutside]: Number.isInteger(mb),
        },
        className
      )}
      style={mb ? { marginBottom: mb } : {}}
    >
      {TopBtn && <div className={styles.topBtn}>{TopBtn}</div>}
      <span className={styles.pageTitle}>{title || activeRoute?.name || activeRoute?.pageHeaderName}</span>
      {Component && <div>{Component}</div>}
    </div>
  );
};

export default PageHeader;
