import classNames from 'classnames';

import { Tooltip } from 'components/UI';

import globalStyles from 'styles/modules/Global.module.scss';

interface IStatusArtistCellProps {
  artist?: string;
}

const StatusArtistCell = ({ artist }: IStatusArtistCellProps) => {
  return (
    <Tooltip text={artist || ''}>
      <span className={classNames(globalStyles.f13h16SuisseSB_grayDarker, globalStyles.textEllipsis)}>{artist}</span>
    </Tooltip>
  );
};

export default StatusArtistCell;
