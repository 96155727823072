import { RefObject, useEffect, useRef, useState } from 'react';

const useIsTextOverflowing = <T extends HTMLElement>(): [RefObject<T>, boolean] => {
  const containerRef = useRef<T>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  useEffect(() => {
    if (!containerRef?.current) return;

    setIsOverflowing(containerRef.current.offsetWidth < containerRef.current.scrollWidth);
    const observer = new ResizeObserver(() => {
      if (!containerRef?.current) return;
      setIsOverflowing(containerRef.current.offsetWidth < containerRef.current.scrollWidth);
    });
    containerRef.current && observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [containerRef]);

  return [containerRef, isOverflowing];
};

export default useIsTextOverflowing;
