import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMemoSelector } from 'hooks';
import LocalStorage from 'services/LocalStorage';
import { getUserId } from 'store';
import { adobeLaunchUrl, IS_OKTA_LOGIN, sendAnalyticsEventForLogin } from 'utils';

import { getDefaultData } from './defaultData';

let prevPathname: string;

const AdobeLaunch = () => {
  const location = useLocation();
  const userId = useMemoSelector(getUserId);

  const handleAdobeAnalyticsInitialized = () => {
    if (!LocalStorage.get(IS_OKTA_LOGIN)) return;

    sendAnalyticsEventForLogin();
    LocalStorage.remove(IS_OKTA_LOGIN);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        // @ts-ignore
        window['digitalData'] = getDefaultData();
      } catch (ex) {
        return;
      }
      const spaPageTriggered = !!document.getElementById('adobelaunch'); // when Adobe Launch script el exists
      if (!spaPageTriggered) {
        // Add Adobe Launch script
        const scriptEl = document.createElement('script');
        scriptEl.setAttribute('src', adobeLaunchUrl);
        scriptEl.setAttribute('id', 'adobelaunch');
        scriptEl.setAttribute('async', '');
        userId && document.body.appendChild(scriptEl);

        document.addEventListener('AdobeAnalyticsInitialized', handleAdobeAnalyticsInitialized);
      } else {
        // Prevents Adobe Launch bloat pages with SPA based on query string params active routing
        if (prevPathname !== location.pathname) {
          // force Adobe Launch events on partial page transitions
          document.dispatchEvent(new CustomEvent('SPAPageTriggered'));
        }
      }
      prevPathname = location.pathname;
    }

    return () => {
      document.removeEventListener('AdobeAnalyticsInitialized', handleAdobeAnalyticsInitialized);
    };
  }, [location, userId]);
  return null;
};

const withSettings = (Component: typeof AdobeLaunch) => {
  // eslint-disable-next-line react/display-name
  if (!adobeLaunchUrl) return () => null;
  // eslint-disable-next-line react/display-name
  return () => <Component />;
};

export default withSettings(AdobeLaunch);
