import { Control, Controller, Path, PathValue } from 'react-hook-form';
import { FormControlLabel } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import { Tooltip } from 'components/UI/index';

import { dateFormatWithSlash, getStartEndDateFromString } from 'utils';

import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as InfoLinedIcon } from 'assets/info-lined.svg';
import styles from './InputDateRange.module.scss';

export interface IInputTextProps<T> {
  label: string;
  tooltip?: string;
  name: Path<T>;
  control: Control<T>;
  placeholder?: string;
  inputReplaceClassName?: string;
  labelRootClassName?: string;
  maxLength?: number;
  onClick?: () => void;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  isValid?: (value: string) => boolean;
  labelClassName?: string;
  required?: boolean;
  onlyFutureDate?: boolean;
}

const InputDateRange = <T,>({
  control,
  label,
  tooltip,
  name,
  placeholder,
  inputReplaceClassName,
  labelRootClassName,
  maxLength = 80,
  onClick,
  labelPlacement,
  isValid,
  labelClassName,
  required,
  onlyFutureDate,
}: IInputTextProps<T>): JSX.Element => {
  const validate = (v: PathValue<T, Path<T>>) => {
    if (!onlyFutureDate || !v) return true;

    const { start } = getStartEndDateFromString(v as string, 'to', dateFormatWithSlash);
    const startDate = moment(start);

    const isValidPastDate = startDate?.isSameOrAfter(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    return isValidPastDate ? true : 'Select a Future Date';
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
        <FormControlLabel
          label={
            <>
              {label}
              {required && <span className={styles.required}>*</span>}
              {tooltip && (
                <Tooltip text={tooltip}>
                  <InfoLinedIcon className={styles.labelInfoIcon} />
                </Tooltip>
              )}
            </>
          }
          labelPlacement={labelPlacement || 'start'}
          control={
            <>
              <input
                value={value as string}
                maxLength={maxLength}
                className={classNames(inputReplaceClassName || styles.inputText, {
                  [styles.inputError]: error?.message,
                })}
                type="text"
                placeholder={placeholder}
                onChange={onChange}
                onBlur={(e) => {
                  onBlur();
                  if (!isValid) return;
                  !isValid(e.target.value) && onChange('');
                }}
                onClick={onClick}
              />
              <Tooltip text={error?.message} isError>
                <CalendarIcon
                  className={classNames(styles.inputIcon, {
                    [styles.active]: !!value,
                    [styles.error]: !!error?.message,
                  })}
                />
              </Tooltip>
            </>
          }
          classes={{
            root: classNames(styles.formControl, labelRootClassName),
            label: classNames(styles.formLabel, labelClassName),
          }}
        />
      )}
    />
  );
};

export default InputDateRange;
