import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { Dialog, DoubleBtnGroup } from 'components/Reusable';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import styles from './MultiStepsFormDialog.module.scss';

import { IDialogProps } from '../Dialog';

export interface IStep {
  subTitle?: string;
  title: string;
  Component: JSX.Element;
}

export interface IMultiStepsFormProps {
  contentMaxHeight?: string;
  isCurrentStepValid?: boolean;
  isSubmitting?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onStepChange?: () => void;
  open: boolean;
  steps: IStep[];
  parent?: MutableRefObject<HTMLDivElement | null>;
  playbarVisible?: IDialogProps['playbarVisible'];
  submitButtonText?: string;
}

const MultiStepsFormDialog = (props: IMultiStepsFormProps): JSX.Element => {
  const [step, setStep] = useState<number>(0);

  const changeStep = useCallback(
    (direction: 'next' | 'prev') => {
      setStep(step + (direction === 'next' ? 1 : -1));
      props.onStepChange && props.onStepChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step]
  );

  const { subTitle, title, Component } = useMemo(() => props.steps[step], [step, props.steps]);
  const isLastStep = useMemo(() => step === props.steps.length - 1, [step, props.steps]);

  return (
    <Dialog
      className={styles.dialog}
      container={props.parent?.current}
      open={props.open}
      onClose={props.onCancel}
      playbarVisible={props.playbarVisible}
      closeFromBackdropClick
    >
      <DialogTitle className={styles.content}>
        <div className={styles.dialogHeader}>
          <div>
            <div>{title}</div>
            <div className={styles.subTitle}>{subTitle}</div>
          </div>
          <CloseIcon onClick={props.onCancel} />
        </div>
      </DialogTitle>
      <DialogContent className={styles.content} key={step} style={{ maxHeight: props.contentMaxHeight }}>
        {Component}
      </DialogContent>
      <DialogActions className={styles.actions}>
        <DoubleBtnGroup
          hideButton1={step === 0}
          isPending={props.isSubmitting}
          name1="Back"
          name2={isLastStep ? props.submitButtonText || 'Save' : 'Next'}
          onClick1={() => changeStep('prev')}
          onClick2={isLastStep ? props.onSubmit : () => changeStep('next')}
          disabled={!props.isCurrentStepValid}
        />
      </DialogActions>
    </Dialog>
  );
};

export default MultiStepsFormDialog;
