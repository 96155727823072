import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { DoubleBtnGroup, Snackbar } from 'components/Reusable';
import WrapperComponent from 'containers/WrapperComponent';

import { setMainConfirmPopup } from 'store';
import { DEFAULT_MAIN_CONFIRM_POPUP } from 'utils';

import { ReactComponent as QuestionCircleIcon } from 'assets/question-circle.svg';
import styles from './ConfirmPopup.module.scss';

export interface IConfirmPopupProps {
  isOpen: boolean;
  questionText: string;
  mainText: string | JSX.Element;
  setIsOpen?: (isOpen: boolean) => void;
  btnDoneText?: string;
  btnDonePendingText?: string;
  btnCancelText?: string;
  onClickCancel?: () => void;
  onClickSubmit?: () => void;
  spacing?: number;
  isShowInfoIcon?: boolean;
  size?: 'xs';
  disabled?: boolean;
  hideButton1?: boolean;
  isRedDoneButton?: boolean;
  disablePortal?: boolean;
  withBackdrop?: boolean;
}

const ConfirmPopup = ({
  isOpen,
  setIsOpen,
  questionText,
  mainText,
  btnCancelText = 'Cancel',
  btnDoneText = 'Done',
  btnDonePendingText,
  onClickCancel,
  onClickSubmit,
  spacing = 21,
  isShowInfoIcon,
  disabled,
  size,
  hideButton1,
  isRedDoneButton,
  disablePortal,
  withBackdrop,
}: IConfirmPopupProps) => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);

  const closeSnackbar = () =>
    setIsOpen ? setIsOpen(false) : dispatch(setMainConfirmPopup(DEFAULT_MAIN_CONFIRM_POPUP));

  const onClick2 = async () => {
    setIsPending(true);
    onClickSubmit && (await onClickSubmit());
    setIsPending(false);
  };

  return (
    <WrapperComponent disablePortal={disablePortal || withBackdrop}>
      <>
        {withBackdrop && <div className={styles.backdrop} />}
        <Snackbar
          containerClassName={styles.confirmSnackbarContainer}
          contentClassName={classNames(styles.confirmSnackbarContent, {
            [styles.xs]: size === 'xs',
          })}
          open={isOpen}
          onClose={withBackdrop ? undefined : closeSnackbar}
          message={
            <div className={styles.confirmSnackbar}>
              {isShowInfoIcon && <QuestionCircleIcon />}
              <span className={styles.confirmQuestionText}>{questionText}</span>
              <span className={styles.confirmInfoText}>{mainText}</span>
              <DoubleBtnGroup
                className={styles.confirmDoubleBtnGroup}
                name1={btnCancelText}
                onClick1={onClickCancel || closeSnackbar}
                name2={btnDoneText}
                onClick2={onClick2}
                spacing={spacing}
                isPending={isPending}
                disabled={disabled}
                pendingName2={btnDonePendingText}
                hideButton1={hideButton1}
                isRedDoneButton={isRedDoneButton}
              />
            </div>
          }
        />
      </>
    </WrapperComponent>
  );
};

export default ConfirmPopup;
