import { memo, MutableRefObject, useCallback } from 'react';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { ShowHideColumns } from 'components/Reusable';
import DataGrid, {
  CellRendererProps,
  DataGridColDef,
  DefaultColDefConfigs,
  FilteringProps,
  GetRowsHandler,
} from 'components/UI/DataGrid';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getAdminControlsFiltersData, getIsOpenSidebar } from 'store';
import { IUserData } from 'store/reducers/general/types';
import { TABLE_PAGE_SIZE } from 'utils';

import styles from './AdminControls.module.scss';

import { ExpandedRow } from '.';
import { ColumnHeaderNames, IColumnsState } from './data';

interface IAdminControlsTableProps extends IColumnsState {
  gridRef: MutableRefObject<GridApi | null>;
}

const AdminControlsTable = ({ columns, setColumns, gridRef }: IAdminControlsTableProps) => {
  const { isOpenSidebar, filters } = useMemoSelector((state) => ({
    isOpenSidebar: getIsOpenSidebar(state),
    filters: getAdminControlsFiltersData(state),
  }));

  const getRowsHandler: GetRowsHandler<IUserData> = useCallback(async (params) => {
    const { startRow } = params;
    const { direction, fieldName: field } = params.sortModel[0] || {};
    const { firstName, lastName, email, status, role, defaultTerritory, defaultTeam, isTeamAdmin } =
      params.filterModel.filters || {};

    const body = {
      pagination: {
        skip: startRow,
        take: TABLE_PAGE_SIZE,
        ...((field || direction) && {
          sorting: [
            {
              field,
              direction: direction.toUpperCase(),
            },
          ],
        }),
      },
      userFilter: {
        firstName: firstName || null,
        lastName: lastName || null,
        email: email || null,
        status: !status || status === 'All' ? 'all' : status?.code || status,
        role: role?.code || 'all',
        defaultTerritory: defaultTerritory?.code || 'all',
        defaultTeamId: defaultTeam?.id,
        isTeamAdmin: isTeamAdmin ? isTeamAdmin.title === 'Yes' : null,
      },
    };

    const data = await Api.getUsers(body);

    return data || [];
  }, []);

  return (
    <div className={styles.dataGridContainer}>
      <DataGrid
        ref={gridRef}
        className={classNames(styles.dataGrid, {
          [styles.iconsCell]: !isOpenSidebar,
        })}
        columns={columns}
        datasource={{
          type: 'infinite',
          pageSize: TABLE_PAGE_SIZE,
          getRows: getRowsHandler,
        }}
        defaultColDef={{
          ...DefaultColDefConfigs,
          ...FilteringProps,
        }}
        rowHeight={54}
        rowClass={styles.rowContainer}
        rowClassRules={{
          [styles.expandedRow]: (p) => p.node.detail,
        }}
        isFullWidthCell={(p) => p.level === 1}
        fullWidthCellRendererFramework={(p: CellRendererProps<DataGridColDef<IUserData>>) => <ExpandedRow p={p} />}
        disableColumnsConfig={true}
        initialFilterModel={{ filters }}
      />
      <ShowHideColumns setColumns={setColumns} columns={columns} hideFromList={[ColumnHeaderNames.user]} />
    </div>
  );
};

export default memo(AdminControlsTable);
