import Popover from '@material-ui/core/Popover';
import classNames from 'classnames';

import { useMemoSelector } from 'hooks';
import { getIsOpenSidebar } from 'store';

import { ReactComponent as CloseIcon } from 'assets/close-2.svg';
import sidebarStyles from 'styles/modules/Sidebar.module.scss';
import styles from './ValidationErrorPopup.module.scss';

export interface IValidationErrorPopupProps {
  className?: string;
  description?: string | JSX.Element;
  isOpen: boolean;
  onClose?: () => void;
  title: string | JSX.Element;
}

const ValidationErrorPopup = ({ className, description, isOpen, onClose, title }: IValidationErrorPopupProps) => {
  const isOpenSidebar = useMemoSelector(getIsOpenSidebar);
  const onCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onClose && onClose();
  };
  return (
    <Popover
      anchorEl={document.body}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      className={className}
      marginThreshold={20}
      open={isOpen}
      onClose={onClose}
      PaperProps={{ classes: { root: classNames(styles.paperRoot, isOpenSidebar && sidebarStyles.marginTransform) } }}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <button className={styles.closeButton} onClick={onCloseClick}>
        <CloseIcon />
      </button>
    </Popover>
  );
};

export default ValidationErrorPopup;
