import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { AutocompleteCellRenderer, TextFieldCellRenderer } from 'components/Reusable';
import { getVersionOptions } from 'components/SongsUpload/data';
import { Tooltip } from 'components/UI';
import { DataGridColDef, RowNodeExt } from 'components/UI/DataGrid';

import { getFormattedDate, getSongVersion, getUTCTimeFromNow, getUTCTimeToShowStr, SONG_TITLE_MAX_LENGTH } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './SongDetails.module.scss';

import {
  ExternalContactCell,
  InternalContactCell,
  LabelsCell,
  PlayIconCell,
  PlayIconHeaderCell,
  StatusActionsCell,
  StatusArtistCell,
  StatusCell,
  VersionsActionsCell,
  VersionsCell,
} from './';
import { validateDraft } from './utils';
import { IPlaylistData, ISongMedias, ITeamPitchesData, PlaylistColumnFields, StatusColumnFields } from 'types';

export enum RemoveActionTypes {
  status = 'status',
  tag = 'tag',
  note = 'note',
  version = 'version',
}

export enum VersionsColumnFields {
  empty = '',
  title = 'title',
  fileType = 'type',
  version = 'version',
  uploadedBy = 'uploadedByName',
  uploadedOn = 'uploadedOn',
  state = 'state',
  processing = 'processing',
}

export enum VersionsColumnHeaderNames {
  empty = '',
  actions = 'Actions',
  title = 'Song Title',
  fileType = 'File Type',
  version = 'Version',
  uploadedBy = 'Uploaded By',
  uploadedOn = 'Uploaded On',
}

export const versionsColumns: DataGridColDef<ISongMedias>[] = [
  {
    field: VersionsColumnFields.processing,
    headerName: VersionsColumnHeaderNames.empty,
    headerComponentFramework: ({ api }: { api: GridApi }) => <PlayIconHeaderCell api={api} />,
    cellRendererFramework: ({ data, api }) => <PlayIconCell data={data} api={api} />,
    headerClass: styles.playIconHeaderCell,
    cellClass: styles.playCellIcon,
    maxWidth: 50,
  },
  {
    field: VersionsColumnFields.title,
    headerName: VersionsColumnHeaderNames.title,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseSB_grayDarker,
    flex: 2,
    cellRendererFramework: (props) =>
      props.data?.draft ? (
        <TextFieldCellRenderer
          {...props}
          disabled
          field={VersionsColumnFields.title}
          maxLength={SONG_TITLE_MAX_LENGTH}
          iconPlaceWidth={40}
        />
      ) : (
        <Tooltip text={props.value as string}>
          <span className={globalStyles.textEllipsis}>{props.value as string}</span>
        </Tooltip>
      ),
  },
  {
    field: VersionsColumnFields.fileType,
    headerName: VersionsColumnHeaderNames.fileType,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
  },
  {
    field: VersionsColumnFields.version,
    headerName: VersionsColumnHeaderNames.version,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: (n: { data?: ISongMedias }) =>
      classNames({ [styles.fullWidthCell]: n.data?.draft, [styles.cellWithEditTooltip]: !n.data?.draft }),
    minWidth: 162,
    cellRendererFramework: ({ data, api, node }) => {
      if (data?.error) return data.error;
      if (typeof data?.processing === 'number') return 'Processing...';

      return data?.draft ? (
        <AutocompleteCellRenderer
          disableClearable
          error={data.state?.errors?.version}
          field={VersionsColumnFields.version}
          onChange={() => {
            validateDraft(node, api);
          }}
          node={node}
          options={getVersionOptions()}
          value={data?.version}
        />
      ) : (
        <VersionsCell
          mediaId={data?.id}
          api={api}
          node={node}
          workCode={data?.workCode}
          version={
            typeof data?.version === 'object' && data.version !== null
              ? data.version.title
              : ((getSongVersion(data?.version) || data?.version) as string)
          }
        />
      );
    },
  },
  {
    field: VersionsColumnFields.uploadedBy,
    headerName: VersionsColumnHeaderNames.uploadedBy,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    valueGetter: ({ data }) => {
      const item = data as ISongMedias;
      return item.error || typeof item.processing === 'number' ? '' : item.uploadedByName;
    },
  },
  {
    field: VersionsColumnFields.uploadedOn,
    headerName: VersionsColumnHeaderNames.uploadedOn,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    valueGetter: ({ data }) => {
      const item = data as ISongMedias;
      if (item.error || typeof item.processing === 'number') return '';
      return getFormattedDate(item.uploadedOn || '');
    },
  },
  {
    field: VersionsColumnFields.state,
    headerName: VersionsColumnHeaderNames.actions,
    width: 92,
    maxWidth: 92,
    suppressColumnsToolPanel: true,
    cellClass: 'actionsCell',
    cellRendererFramework: ({ api, node, data }) => <VersionsActionsCell api={api} node={node} data={data} />,
  },
];

export type IStatusData = {
  [K in StatusColumnFields]: string;
};

export enum StatusColumnHeaderNames {
  actions = 'Actions',
  artist = 'Artist',
  status = 'Status',
  label = 'Label',
  contactInternal = 'Internal Contact',
  contactExternal = 'External Contact',
  statusDate = 'Status Date',
}

export interface IGridApiNode {
  api: GridApi;
  node: RowNodeExt;
}

export const statusColumns: DataGridColDef<IStatusData>[] = [
  {
    field: StatusColumnFields.artist,
    headerName: StatusColumnHeaderNames.artist,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: styles.cellWithEditTooltip,
    minWidth: 160,
    cellRendererFramework: ({ data }) => <StatusArtistCell artist={data?.artist} />,
  },
  {
    field: StatusColumnFields.status,
    headerName: StatusColumnHeaderNames.status,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: styles.statusCardContainer,
    minWidth: 170,
    cellRendererFramework: ({ data }) => <StatusCell status={data?.status} />,
  },
  {
    field: StatusColumnFields.label,
    headerName: StatusColumnHeaderNames.label,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: styles.cellWithEditTooltip,
    cellRendererFramework: ({ data }) => <LabelsCell label={data?.label} />,
  },
  {
    field: StatusColumnFields.contactInternal,
    headerName: StatusColumnHeaderNames.contactInternal,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: styles.cellWithEditTooltip,
    cellRendererFramework: ({ data }) => <InternalContactCell contactInternal={data?.contactInternal} />,
  },
  {
    field: StatusColumnFields.contactExternal,
    headerName: StatusColumnHeaderNames.contactExternal,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: styles.cellWithEditTooltip,
    cellRendererFramework: ({ data }) => <ExternalContactCell contactExternal={data?.contactExternal} />,
  },
  {
    field: StatusColumnFields.statusDate,
    headerName: StatusColumnHeaderNames.statusDate,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    valueGetter: ({ data: { statusDate } }) => getFormattedDate(statusDate || ''),
  },
  {
    field: StatusColumnFields.actions,
    headerName: StatusColumnHeaderNames.actions,
    width: 94,
    maxWidth: 94,
    suppressColumnsToolPanel: true,
    cellClass: 'actionsCell',
    cellRendererFramework: ({ data }) => <StatusActionsCell data={data} />,
  },
];

export enum TeamPitchesColumnFields {
  title = 'title',
  to = 'to',
  by = 'pitchedBy',
  date = 'sentDate',
  label = 'label',
}

export enum TeamPitchesColumnHeaderNames {
  title = 'Title',
  to = 'Pitch To',
  by = 'Pitch By',
  date = 'Pitch Date',
  label = 'Label',
}

export const teamPitchesColumns: DataGridColDef<ITeamPitchesData>[] = [
  {
    field: TeamPitchesColumnFields.title,
    headerName: TeamPitchesColumnHeaderNames.title,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseSB_grayDarker,
    valueGetter: ({ data }) => data?.title || '-',
  },
  {
    field: TeamPitchesColumnFields.to,
    headerName: TeamPitchesColumnHeaderNames.to,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    valueGetter: ({ data }) => (data?.contacts?.length ? data?.contacts[0].contact : '-'),
  },
  {
    field: TeamPitchesColumnFields.by,
    headerName: TeamPitchesColumnHeaderNames.by,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
  },
  {
    field: TeamPitchesColumnFields.date,
    headerName: TeamPitchesColumnHeaderNames.date,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    valueGetter: ({ data }) => (data?.sentDate ? getUTCTimeToShowStr(data?.sentDate) : '-'),
  },
  {
    field: TeamPitchesColumnFields.label,
    headerName: TeamPitchesColumnHeaderNames.label,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    valueGetter: ({ data }) => data?.label || '-',
  },
];

export enum PlaylistColumnHeaderNames {
  title = 'Title',
  songs = 'Songs',
  createdBy = 'Created By',
  createdOn = 'Created On',
  team = 'Team',
}

export const playlistColumns: DataGridColDef<IPlaylistData>[] = [
  {
    field: PlaylistColumnFields.title,
    headerName: PlaylistColumnHeaderNames.title,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseSB_grayDarker,
  },
  {
    field: PlaylistColumnFields.mediaCount,
    headerName: PlaylistColumnHeaderNames.songs,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
  },
  {
    field: PlaylistColumnFields.createdByName,
    headerName: PlaylistColumnHeaderNames.createdBy,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
  },
  {
    field: PlaylistColumnFields.createdOnTime,
    headerName: PlaylistColumnHeaderNames.createdOn,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    valueGetter: ({ data }) => getUTCTimeFromNow(data?.createdOnTime),
  },
  {
    field: PlaylistColumnFields.team,
    headerName: PlaylistColumnHeaderNames.team,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
  },
];

export enum SongHeaderCols {
  status = 'Status',
  createdOn = 'Created On',
  uploadedBy = 'Uploaded By',
  workId = 'Work ID',
  wcm = 'WCM%',
  bpm = 'BPM',
  labelView = 'Label View',
}
