import classNames from 'classnames';

import { ReactComponent as MarkerIcon } from 'assets/marker.svg';
import styles from './MusicPlayer.module.scss';

import { MarkersPopup } from '.';
import { IPlayerActions, PlayerPopups } from './IMusicPlayer';

const MarkerControl = ({
  checkIsPopup,
  actionMarker,
  changeOpenPopup,
  popupContainerRef,
  disableIcon,
}: IPlayerActions) => {
  return (
    <button className={classNames(styles.controlBtn, styles.desktopIcon, { [styles.disabledIcon]: disableIcon })}>
      <MarkerIcon
        className={classNames({ [styles.activeIcon]: checkIsPopup(PlayerPopups.marker) })}
        onClick={() => changeOpenPopup(PlayerPopups.marker)}
      />
      <MarkersPopup
        actionMarker={actionMarker}
        changeOpenPopup={changeOpenPopup}
        isShow={checkIsPopup(PlayerPopups.marker)}
        popupContainerRef={popupContainerRef}
      />
    </button>
  );
};

export default MarkerControl;
