import React from 'react';

import { Tooltip } from 'components/UI';

import styles from './SongsUpload.module.scss';

export interface IColumnHeaderProps extends React.PropsWithChildren<unknown> {
  required?: boolean;
  tooltip?: string;
}

const ColumnHeader = ({ children, required, tooltip }: IColumnHeaderProps): JSX.Element => {
  return (
    <Tooltip text={tooltip || ''}>
      <div className={styles.columnHeader}>
        {children}
        {required && <span className={styles.requiredIcon}>*</span>}
      </div>
    </Tooltip>
  );
};

export default ColumnHeader;
