import { useState } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';

import { useDidUpdate } from 'hooks';

import styles from './Tabs.module.scss';

import { TabsProps } from './Tabs.types';
import { getHistoryTab, getItems, updateHistoryTab } from './Tabs.utils';

const Tabs = (props: TabsProps): JSX.Element => {
  const history = useHistory();
  const items = getItems(props);

  const [selectedTab, setSelectedTab] = useState(() => {
    const selectedHistoryTab = getHistoryTab(props, items);
    if (selectedHistoryTab && selectedHistoryTab !== props.selectedTab) {
      props.onChange?.(selectedHistoryTab);
    }
    return selectedHistoryTab ?? props.selectedTab ?? null;
  });

  const selectedItem = items.find((i) => i.value === selectedTab) ?? items[0];

  const onChange = (newSelectedTab: string): void => {
    if (typeof props.selectedTab === 'undefined') {
      setSelectedTab(newSelectedTab);
      if (!props.disableHistory) {
        updateHistoryTab(newSelectedTab, history, props.historyKey);
      }
    }
    props.onChange?.(newSelectedTab);
  };

  useDidUpdate(() => {
    setSelectedTab(props.selectedTab ?? null);
    if (!props.disableHistory) {
      updateHistoryTab(props.selectedTab, history, props.historyKey);
    }
  }, [history, props.disableHistory, props.historyKey, props.selectedTab]);

  return (
    <div className={props.className}>
      <div>
        {items.map((item) => (
          <button
            className={classNames(styles.button, {
              [styles.selectedButton]: selectedItem === item,
              [styles.hideInMobile]: item.hideInMobile,
            })}
            key={item.value}
            onClick={(e) => {
              e.preventDefault();
              onChange(item.value);
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
      <div className={props.contentClassName} key={selectedItem?.value}>
        {selectedItem?.children ?? null}
      </div>
    </div>
  );
};

export default Tabs;
