import classNames from 'classnames';

import styles from './TabCard.module.scss';

interface ITabCardProps {
  title: string;
  value?: number;
  onClick?: () => void;
  isActive?: boolean;
}

const TabCard = ({ title, value, onClick, isActive }: ITabCardProps): JSX.Element => {
  return (
    <div className={classNames(styles.tabCard, { [styles.activeTabCard]: isActive })} onClick={onClick}>
      <span className={styles.tabTitle}>{title}</span>
      <span className={styles.tabValue}>{value ?? '-'}</span>
    </div>
  );
};

export default TabCard;
