import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dialog } from '@material-ui/core';
import classNames from 'classnames';

import { ROLES } from 'components/AdminControls/data';
import { InputAutocomplete, InputText } from 'components/UI';

import Api from 'services/Api';
import { DEFAULT_ERROR_CONFIG, isValidEmail, UserRoleCodes } from 'utils';

import RegisterLogo from 'assets/register_logo.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './RegisterPopup.module.scss';

enum RegisterFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  userType = 'userType',
  company = 'company',
}

enum RegisterErrors {
  emailNotValid = 'Email is not valid',
  completeAllFields = 'Please complete all fields',
}

interface IRegisterPopupProps {
  isOpen: boolean;
  onClose: () => void;
  openNotification?: () => void;
}

const RegisterPopup = ({ isOpen, onClose, openNotification }: IRegisterPopupProps) => {
  const { control, watch, reset } = useForm({ mode: 'onBlur' });

  const [error, setError] = useState('');

  const userType = watch(RegisterFields.userType);
  const isUserPartnerOrLabel = [UserRoleCodes.Partner, UserRoleCodes.Label].includes(userType?.code);

  const handleCloseRegisterPopup = () => {
    onClose();
    reset();
  };

  const onClickRegister = async () => {
    const { firstName, lastName, email, company } = watch();

    if (error === RegisterErrors.emailNotValid) return;

    if (!firstName || !lastName || !email || !userType || (userType && isUserPartnerOrLabel && !company)) {
      setError(RegisterErrors.completeAllFields);
      return;
    }

    const res = await Api.registerUser(
      {
        email,
        company,
        firstName,
        lastName,
        role: userType.code,
      },
      {
        errorPopupConfig: DEFAULT_ERROR_CONFIG,
      }
    );

    if (!res) return;

    handleCloseRegisterPopup();
    openNotification && openNotification();
  };

  return (
    <Dialog
      className={styles.dialog}
      BackdropProps={{ className: styles.dialogBackdrop }}
      PaperProps={{ className: styles.dialogPaper }}
      open={isOpen}
      onClose={handleCloseRegisterPopup}
    >
      <img src={RegisterLogo} alt="register-logo" draggable={false} />
      <div className={styles.registerContainer}>
        <p className={classNames(globalStyles.f16h20SuisseREG_grayLight, { [styles.errorText]: !!error })}>
          {error || 'All fields are required'}
        </p>
        <InputText
          inputClass={styles.registerInput}
          control={control}
          name={RegisterFields.firstName}
          placeholder="First Name"
          maxLength={45}
        />
        <InputText
          inputClass={styles.registerInput}
          control={control}
          name={RegisterFields.lastName}
          placeholder="Last Name"
          maxLength={45}
        />
        <InputText
          inputClass={classNames(styles.registerInput, {
            [styles.registerInputError]: error === RegisterErrors.emailNotValid,
          })}
          control={control}
          name={RegisterFields.email}
          placeholder="Email Address"
          rules={{
            validate: (v) => {
              const isNotValid = !isValidEmail(v as string);
              setError(isNotValid ? RegisterErrors.emailNotValid : '');

              if (isNotValid) {
                return 'Email is not valid';
              }
            },
          }}
          maxLength={128}
        />
        <InputAutocomplete
          data={ROLES.slice(3, 6)}
          name={RegisterFields.userType as never}
          control={control}
          placeholder="User Type"
          multiple={false}
          autocompleteRootClass={styles.registerAutocomplete}
          autocompletePaperClass={styles.registerAutocompletePaper}
          autocompleteEndAdornmentClass={styles.registerAutocompleteEndAdornment}
        />
        {userType && isUserPartnerOrLabel && (
          <InputText
            inputClass={styles.registerInput}
            control={control}
            name={RegisterFields.company}
            placeholder="Company"
            maxLength={45}
          />
        )}
      </div>
      <button className={styles.registerBtn} onClick={onClickRegister}>
        Register
      </button>
    </Dialog>
  );
};

export default RegisterPopup;
