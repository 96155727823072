import React, { useMemo, useState } from 'react';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { ColumnFields } from 'components/Songs/data';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getActiveTrack, getFavoritePlaylistId, getFavoritesIdsObj } from 'store';
import { DEFAULT_ERROR_CONFIG, getSongVersion } from 'utils';

import { ReactComponent as HeartSmallIcon } from 'assets/heart-small.svg';
import { ReactComponent as HeartSmallFilledIcon } from 'assets/heart-small-filled.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Songs.module.scss';

import { SongStatusTypes } from 'types';

interface ISongTitleCellProps {
  api: GridApi;
  title?: string;
  mediaId?: string | null;
  status?: SongStatusTypes | '';
  hideFavoriteIcon?: boolean;
  writers?: string[];
  version?: null | string;
}

const SongTitleCell = ({ title, status, mediaId, api, hideFavoriteIcon, writers, version }: ISongTitleCellProps) => {
  const { favoritesIdsObj, activeTrack, favoritePlaylistId } = useMemoSelector((state) => ({
    favoritesIdsObj: getFavoritesIdsObj(state),
    favoritePlaylistId: getFavoritePlaylistId(state),
    activeTrack: getActiveTrack(state),
  }));

  const [loading, setLoading] = useState<boolean>(false);

  const isFavorite = useMemo(() => favoritesIdsObj[mediaId || ''], [favoritesIdsObj, mediaId]);

  const onClickFavoriteIcon = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!mediaId || loading) return;

    setLoading(true);

    isFavorite
      ? await Api.removeFromFavorite([mediaId], favoritePlaylistId, { errorPopupConfig: DEFAULT_ERROR_CONFIG })
      : await Api.addToFavorite([mediaId], favoritePlaylistId, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    setLoading(false);

    setTimeout(() => {
      api?.refreshCells({ force: true, columns: [ColumnFields.songTitle] });
    }, 10);
  };

  return (
    <div className={styles.songTitleContainerWithVersion}>
      <div className={styles.songTitleContainerWithWriter}>
        <span
          className={classNames(styles.songTitle, globalStyles.textEllipsis, globalStyles.f13h16SuisseSB_grayDarker, {
            [globalStyles.songPlayingTitle]: mediaId && [activeTrack.mediaId, activeTrack.songId].includes(mediaId),
          })}
        >
          {title || '-'}
        </span>
        <span
          className={classNames(
            globalStyles.textEllipsis,
            globalStyles.f13h16SuisseREG_grayDarker,
            styles.showOnlyInMobile
          )}
        >
          {writers?.join(' / ')}
        </span>
      </div>
      <div
        className={classNames(
          globalStyles.f11h13SuisseREG_grayDark,
          styles.showOnlyInMobile,
          styles.songVersionInTitle
        )}
      >
        {getSongVersion(version)}
      </div>
      {mediaId &&
        !hideFavoriteIcon &&
        ![SongStatusTypes.ARCHIVED, ''].includes(status as SongStatusTypes) &&
        (isFavorite ? (
          <HeartSmallFilledIcon className={styles.heartIcon} onClick={onClickFavoriteIcon} />
        ) : (
          <HeartSmallIcon className={styles.heartIcon} onClick={onClickFavoriteIcon} />
        ))}
    </div>
  );
};

export default SongTitleCell;
