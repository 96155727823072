import { Controller, UseFormReturn } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';

import { getFormattedDate, validateRequired } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './SystemPitchForm.module.scss';

import { ISystemPitch, SongRow } from '.';

export interface IConfirmProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  methods: UseFormReturn<ISystemPitch, object>;
}

interface IFieldProps {
  label: string;
  value?: string | JSX.Element;
  valueClassName?: string;
}

interface ITagProps {
  title: string;
}

const Field = ({ label, value, valueClassName }: IFieldProps) => (
  <label>
    <div className={styles.labelText}>{label}</div>
    <div className={classNames(styles.value, valueClassName)}>{value}</div>
  </label>
);

const Tag = ({ title }: ITagProps) => (
  <div className={styles.tag}>
    <div className={styles.tagInner}>{title}</div>
  </div>
);

const Confirm = ({ methods }: IConfirmProps) => {
  const { contacts, downloadable, expiryDate, message, title } = methods.getValues();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field label="Subject" value={title} valueClassName={globalStyles.textEllipsis} />
        </Grid>
        <Grid item xs={6}>
          <Field label="Expiry Date" value={getFormattedDate(expiryDate || '')} />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Pitched To"
            value={
              <>
                {contacts?.map((c, i) => (
                  <Tag key={i} title={c.title} />
                ))}
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Field label="Downloadable" value={downloadable ? 'Yes' : 'No'} />
        </Grid>
        <Grid item xs={12}>
          <Field label="Message" value={<div dangerouslySetInnerHTML={{ __html: message || '' }} />} />
        </Grid>
      </Grid>
      <h3 className={styles.heading}>Songs</h3>
      <Controller
        control={methods.control}
        name="songs"
        render={({ field: { value } }) => (
          <>
            {value?.length === 0 && <div className={styles.message}>No songs added</div>}
            {value?.map((s, i, arr) => (
              <SongRow key={i} song={s} songs={arr} />
            ))}
          </>
        )}
        rules={{ validate: validateRequired }}
      />
    </>
  );
};

export default Confirm;
