import { RowNode } from 'ag-grid-community';

import { NewTextField as TextField } from 'components/UI';
import { ITextFieldProps } from 'components/UI/NewTextField';

import { ISongMedias } from 'types';

export interface ITextFieldCellRendererProps {
  disabled?: boolean;
  error?: string;
  field: string;
  getRelatedNodes?: (node: RowNode) => RowNode[];
  /**
   * Works with number type only
   */
  max?: number;
  maxLength?: number;
  /**
   * Works with number type only
   */
  min?: number;
  node: RowNode;
  onBlur?: ITextFieldProps['onBlur'];
  onChange?: ITextFieldProps['onChange'];
  placeholder?: string;
  type?: string;
  value?: unknown;
  data?: ISongMedias;
  iconPlaceWidth?: number;
}

const TextFieldCellRenderer = ({
  disabled,
  error,
  field,
  getRelatedNodes,
  max,
  maxLength,
  min,
  node,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  data,
  iconPlaceWidth,
}: ITextFieldCellRendererProps) => {
  const onChangeHandler = (v: string | number | null) => {
    node.setDataValue(field, v);
    [...(getRelatedNodes ? getRelatedNodes(node) : [])].forEach((n) => n.setDataValue(field, v));
    onChange && onChange(v);
  };
  return (
    <TextField
      disabled={disabled}
      error={error}
      max={max}
      maxLength={maxLength}
      min={min}
      onBlur={onBlur}
      iconRender={data?.type ? `.${data.type?.toLowerCase()}` : undefined}
      iconPlaceWidth={iconPlaceWidth}
      onChange={onChangeHandler}
      placeholder={placeholder}
      type={type}
      value={value as string | number | null | undefined}
    />
  );
};

export default TextFieldCellRenderer;
