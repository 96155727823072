import React from 'react';
import classNames from 'classnames';

import { HEADER_TITLE } from 'utils';

import { ReactComponent as LogoIcon } from 'assets/logo.svg';
import styles from './Logo.module.scss';

interface ILogoProps {
  showTitle?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const Logo = ({ showTitle = true, onClick }: ILogoProps): JSX.Element => (
  <div
    data-testid="logo-container"
    onClick={onClick && onClick}
    className={classNames(styles.logoContainer, { [styles.pointer]: !!onClick })}
  >
    <LogoIcon />
    {showTitle && <div className={styles.headerTitle}>{HEADER_TITLE}</div>}
  </div>
);

export default Logo;
