import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useMemoSelector } from 'hooks';
import { getIsOpenShowHideColumns, setIsOpenShowHideColumns } from 'store';

import { ReactComponent as MoreIcon } from 'assets/more.svg';
import styles from './AdminControls.module.scss';

const MoreIconContainer = () => {
  const dispatch = useDispatch();
  const isOpenShowHideColumns = useMemoSelector(getIsOpenShowHideColumns);

  return (
    <div className={styles.moreIconContainer}>
      <span>Actions</span>
      <MoreIcon
        className={classNames(styles.moreHeaderIcon, {
          [styles.activeIcon]: isOpenShowHideColumns,
        })}
        onClick={() => dispatch(setIsOpenShowHideColumns(!isOpenShowHideColumns))}
      />
    </div>
  );
};

export default MoreIconContainer;
