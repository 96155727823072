import { ReactComponent as AstronautIcon } from 'assets/astronaut.svg';
import styles from './NoDataAvailable.module.scss';

interface INoDataAvailable {
  text?: string;
}

const NoDataAvailable = ({ text = 'No Data Available' }: INoDataAvailable) => (
  <div className={styles.container}>
    <AstronautIcon />
    <div>{text}</div>
  </div>
);

export default NoDataAvailable;
