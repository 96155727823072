import { useEffect } from 'react';

import ActionBtnsGroup from 'components/AdminControls/ActionBtnsGroup';
import { RowNodeExt } from 'components/UI/DataGrid';

import { useDebounce, useMemoSelector, useWindowSize } from 'hooks';
import { getIsOpenSidebar } from 'store';
import { IUserData } from 'store/reducers/general/types';
import { SCREEN_BREAKPOINTS } from 'utils';

import { ReactComponent as ArrowIcon } from 'assets/arrow.svg';
import styles from './AdminControls.module.scss';

interface IActionsCellProps {
  node: RowNodeExt;
  data?: IUserData;
}

const ActionsCell = ({ data, node }: IActionsCellProps): JSX.Element => {
  const { width: windowWidth } = useWindowSize();

  const isOpenSidebar = useMemoSelector(getIsOpenSidebar);

  const changeColumns = useDebounce(() => {
    const { desktopBig, desktopSmall, tablet } = SCREEN_BREAKPOINTS;

    const isHideColumns =
      ((windowWidth < desktopBig && windowWidth >= desktopSmall && isOpenSidebar) ||
        (windowWidth < desktopSmall && windowWidth >= tablet)) &&
      node.expanded;

    node.setDetailsRow(isHideColumns, undefined, true);
  }, 100);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => changeColumns(), [windowWidth, isOpenSidebar]);

  return (
    <>
      <ActionBtnsGroup data={data} className={styles.actionsCell} node={node} />
      <ArrowIcon className={styles.arrowRowIcon} onClick={() => node.setDetailsRow(!node.expanded, 70, true)} />
    </>
  );
};

export default ActionsCell;
