import { useCallback, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';

import { DatePickerPopup } from 'components/Popups';
import { IChangeExpirationConfig } from 'components/Popups/ShareSongPopup/data';
import { ActionsCellRenderer } from 'components/Reusable';

import Api from 'services/Api';
import {
  setEditPlaylist,
  setError,
  setLoading,
  setMainConfirmPopup,
  setMainNotification,
  updateFeaturedPlaylistHash,
  updatePlaylistHash,
} from 'store';
import { PlaylistHashActionTypes } from 'store/reducers/general/types';
import {
  DEFAULT_ERROR_CONFIG,
  DEFAULT_MAIN_CONFIRM_POPUP,
  getFormattedDate,
  getInitialDateForFeaturedPlaylist,
} from 'utils';

import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as PinIcon } from 'assets/pin.svg';
import { ReactComponent as RejectIcon } from 'assets/reject.svg';
import { ReactComponent as ResendIcon } from 'assets/resend.svg';
import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import { ReactComponent as UnpinIcon } from 'assets/unpin.svg';

import { FeaturedPlaylistsStatuses, IFeaturedPlaylistData } from './data';

interface IFeaturedPlaylistActionsCellProps {
  data?: IFeaturedPlaylistData;
}

const FeaturedPlaylistActionsCell = ({ data }: IFeaturedPlaylistActionsCellProps) => {
  const dispatch = useDispatch();

  const [changeExpirationConfig, setChangeExpirationConfig] = useState<IChangeExpirationConfig>({
    open: false,
    date: getInitialDateForFeaturedPlaylist(data?.releaseOn),
  });

  const closeConfirmPopupSetNotificationReloadTable = useCallback(
    (notificationText: string) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setMainConfirmPopup(DEFAULT_MAIN_CONFIRM_POPUP));
        dispatch(updateFeaturedPlaylistHash());
        dispatch(setMainNotification(notificationText));
      });
    },
    [dispatch]
  );

  const handlePinFeaturedPlaylist = async (force = false) => {
    if (!data?.id) return;

    dispatch(setLoading(true));

    const res = await Api.pinFeaturedPlaylist(data?.id, force, {
      handler: (details) => {
        if (details?.errorCode === '3005') {
          dispatch(
            setMainConfirmPopup({
              isOpen: true,
              questionText: 'Pinned Playlist Limit Reached',
              mainText: details.message,
              btnDoneText: 'Continue',
              onClickSubmit: () => handlePinFeaturedPlaylist(true),
            })
          );
        } else {
          dispatch(setError(DEFAULT_ERROR_CONFIG));
        }
        dispatch(setLoading(false));
      },
    });

    if (!res) return;

    closeConfirmPopupSetNotificationReloadTable('Playlist Pinned');
  };

  const handleUnpinFeaturedPlaylist = async () => {
    if (!data?.id) return;

    dispatch(setLoading(true));

    const res = await Api.unpinFeaturedPlaylist(data?.id, {
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
    });

    if (!res) {
      dispatch(setLoading(false));
      return;
    }

    closeConfirmPopupSetNotificationReloadTable('Playlist Unpinned');
  };

  const handleArchiveFeaturedPlaylist = async () => {
    if (!data?.id) return;

    const res = await Api.deletePlaylist({
      pathId: data?.id,
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
    });

    if (!res) {
      dispatch(setMainConfirmPopup(DEFAULT_MAIN_CONFIRM_POPUP));
      return;
    }

    closeConfirmPopupSetNotificationReloadTable('Playlist Archived');
    dispatch(updatePlaylistHash(PlaylistHashActionTypes.featuredPlaylistTable));
  };

  const onClickArchive = () => {
    dispatch(
      setMainConfirmPopup({
        isOpen: true,
        questionText: 'Archive featured playlist?',
        mainText: 'The featured playlist will be removed and archived. You can recover it from the playlist panel.',
        btnDoneText: 'Archive',
        onClickSubmit: handleArchiveFeaturedPlaylist,
      })
    );
  };

  const onCloseDatePicker = useCallback(() => {
    setChangeExpirationConfig((prevState) => ({ ...prevState, open: false }));
  }, []);

  const onSubmitSingleDate = useCallback(
    async (date?: string) => {
      if (!data?.id || !date) return;

      const res = await Api.reactivateFeaturedPlaylist(
        data?.id,
        {
          releaseOn: getFormattedDate(data?.releaseOn),
          expireOn: date,
        },
        { errorPopupConfig: DEFAULT_ERROR_CONFIG }
      );

      if (!res) {
        onCloseDatePicker();
        return;
      }

      setChangeExpirationConfig({ open: false, date });
      closeConfirmPopupSetNotificationReloadTable('Playlist Activated');
      dispatch(updatePlaylistHash(PlaylistHashActionTypes.featuredPlaylistTable));
    },
    [data?.id, data?.releaseOn, closeConfirmPopupSetNotificationReloadTable, dispatch, onCloseDatePicker]
  );

  const handleExpireFeaturedPlaylist = async () => {
    if (!data?.id) return;

    const res = await Api.expireFeaturedPlaylist(data?.id, {
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
    });

    if (!res) return;

    closeConfirmPopupSetNotificationReloadTable('Playlist Expired');
    dispatch(updatePlaylistHash(PlaylistHashActionTypes.featuredPlaylistTable));
  };

  const handleRemoveFeaturedPlaylist = async () => {
    if (!data?.id) return;

    const res = await Api.deletePlaylistPermanently({
      pathId: data?.id,
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
    });

    if (!res) return;

    closeConfirmPopupSetNotificationReloadTable('Playlist Removed');
  };

  const handleEditFeaturedPlaylist = () => {
    if (!data?.id) return;
    dispatch(setEditPlaylist({ id: data?.id, isFeatured: true }));
  };

  const isPinned = useMemo(() => !!data?.hasPin, [data?.hasPin]);
  const isScheduled = useMemo(() => data?.status === FeaturedPlaylistsStatuses.scheduled, [data?.status]);
  const isExpired = useMemo(() => data?.status === FeaturedPlaylistsStatuses.expired, [data?.status]);
  const isUnpublished = useMemo(() => data?.status === FeaturedPlaylistsStatuses.unpublished, [data?.status]);

  return (
    <>
      <ActionsCellRenderer
        buttons={
          [
            FeaturedPlaylistsStatuses.active,
            FeaturedPlaylistsStatuses.scheduled,
            FeaturedPlaylistsStatuses.unpublished,
          ].includes(data?.status as FeaturedPlaylistsStatuses)
            ? [
                ...(isScheduled
                  ? []
                  : [
                      { text: <EditIcon />, tooltip: 'Edit', onClick: handleEditFeaturedPlaylist },
                      ...(isUnpublished
                        ? []
                        : [
                            isPinned
                              ? { text: <PinIcon />, tooltip: 'Unpin', onClick: handleUnpinFeaturedPlaylist }
                              : { text: <UnpinIcon />, tooltip: 'Pin', onClick: () => handlePinFeaturedPlaylist() },
                          ]),
                    ]),
                ...(isUnpublished
                  ? []
                  : [
                      isScheduled
                        ? { text: <RejectIcon />, tooltip: 'Remove Schedule', onClick: handleRemoveFeaturedPlaylist }
                        : { text: <RejectIcon />, tooltip: 'Expire', onClick: handleExpireFeaturedPlaylist },
                    ]),
                { text: <TrashIcon />, tooltip: 'Archive', onClick: onClickArchive },
              ]
            : [
                {
                  text: <ResendIcon />,
                  tooltip: 'Feature Again',
                  onClick: () => setChangeExpirationConfig({ ...changeExpirationConfig, open: true }),
                },
                ...(isExpired ? [{ text: <TrashIcon />, tooltip: 'Archive', onClick: onClickArchive }] : []),
              ]
        }
      />
      <DatePickerPopup
        blockPastDates
        blockDaysConfig={{
          day: new Date(data?.releaseOn || ''),
          period: 'before',
          isDayIncluded: true,
        }}
        isSingleDayPicker
        disablePortal
        open={changeExpirationConfig.open}
        onClose={onCloseDatePicker}
        date={changeExpirationConfig.date}
        onSubmitSingleDate={onSubmitSingleDate}
      />
    </>
  );
};

export default FeaturedPlaylistActionsCell;
