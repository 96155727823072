import { useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { ContactsForm, IContact } from 'components/Contacts';
import { Button, ContactOption } from 'components/Reusable';
import {
  NewAutocomplete as Autocomplete,
  NewDatePicker as DatePicker,
  NewTextField as TextField,
  SwitchBtn,
  TextEditor,
} from 'components/UI';

import Api from 'services/Api';
import { DEFAULT_ERROR_CONFIG, validateRequired } from 'utils';

import { ReactComponent as PlusCircleIcon } from 'assets/plus-circle.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import styles from './SystemPitchForm.module.scss';

import { ISystemPitch } from '.';

const validateExpiryDate = (v: Date | null | undefined): string | undefined => {
  const requiredErr = validateRequired(v);
  if (requiredErr) return requiredErr;
  if (v && v.getTime() < new Date().getTime()) return 'Select Future Date.';
};

// eslint-disable-next-line @typescript-eslint/ban-types
const CreatePitch = ({ methods }: { methods: UseFormReturn<ISystemPitch, object> }) => {
  const [contactsForm, setContactsForm] = useState<IContact>();
  const onContactsFormSubmit = ({ id, email, name, team }: IContact) => {
    setContactsForm(undefined);
    const contacts = methods.getValues().contacts?.slice() || [];
    contacts.push({ id: id || '', email, team, title: name || '' });
    methods.setValue('contacts', contacts, { shouldTouch: true, shouldValidate: true });
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            control={methods.control}
            name="title"
            render={({ field, fieldState: { error }, formState }) => (
              <TextField
                {...field}
                disabled={formState.isSubmitting}
                error={error?.message}
                highlightValid
                label="Subject"
                required
              />
            )}
            rules={{ validate: validateRequired }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={methods.control}
            name="expiryDate"
            render={({ field, fieldState: { error }, formState }) => (
              <DatePicker
                {...field}
                disabled={formState.isSubmitting}
                error={error?.message}
                highlightValid
                label="Expiry Date"
                showActionButtons
                required
              />
            )}
            rules={{ validate: validateExpiryDate }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={methods.control}
            name="contacts"
            render={({ field, fieldState: { error }, formState }) => (
              <Autocomplete
                {...field}
                className={styles.pitchedTo}
                disabled={formState.isSubmitting}
                error={error?.message}
                highlightValid
                Icon={<SearchIcon height="13" width="13" />}
                label="Pitched To"
                multiple
                onResolveSuggestions={(text) =>
                  Api.getContactsAndTeamGroups(text, { errorPopupConfig: DEFAULT_ERROR_CONFIG })
                }
                renderOption={ContactOption}
                required
              />
            )}
            rules={{ validate: validateRequired }}
          />
          <Button Icon={<PlusCircleIcon />} onClick={() => setContactsForm({})} text="New Contact" />
        </Grid>
        <Grid item xs={12}>
          <SwitchBtn
            disabled={methods.formState.isSubmitting}
            control={methods.control}
            formRootClassName={styles.pitchFormSwitchBtn}
            name="downloadable"
            label="Downloadable"
            labelPlacement="top"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={methods.control}
            name="message"
            render={({ field }) => (
              <>
                <div className={styles.textEditorLabel}>Message</div>
                <TextEditor maxLength={800} className={styles.textEditor} {...field} />
              </>
            )}
          />
        </Grid>
      </Grid>
      {contactsForm && (
        <ContactsForm item={contactsForm} onClose={() => setContactsForm(undefined)} onSubmit={onContactsFormSubmit} />
      )}
    </>
  );
};

export default CreatePitch;
