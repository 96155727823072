export enum NotificationPreferencesKeys {
  holdRequestsCreated = 'hold-request-created',
  songSharedWithMe = 'song-shared-with-me',
  pitchActivity = 'pitch-activity',
  playlistActivity = 'playlist-activity',
  holdStatus = 'new-status-created',
  searchAlerts = 'search-alerts',
}

const NotificationPreferencesTitles: { [K in NotificationPreferencesKeys]: string } = {
  [NotificationPreferencesKeys.holdRequestsCreated]: 'Hold Requests',
  [NotificationPreferencesKeys.songSharedWithMe]: 'Songs Shared with Me',
  [NotificationPreferencesKeys.pitchActivity]: 'Pitch Activity',
  [NotificationPreferencesKeys.playlistActivity]: 'Playlist Activity',
  [NotificationPreferencesKeys.holdStatus]: 'Hold Status',
  [NotificationPreferencesKeys.searchAlerts]: 'Search Alert',
};

export enum NotificationPlatformTypes {
  push = 'PUSH',
  inApp = 'IN_APP',
  email = 'EMAIL',
}

// Note: add here PlatformTypes.inApp, NotificationPlatformTypes.push, to enable other columns
export const PlatformTypesList = [NotificationPlatformTypes.email];

export type NotificationPreferencesConfig = {
  [K in NotificationPlatformTypes]: boolean;
};

export const defaultNotificationPreferencesConfig = {
  [NotificationPlatformTypes.push]: false,
  [NotificationPlatformTypes.inApp]: true,
  [NotificationPlatformTypes.email]: false,
};

export type NotificationPreferencesType = {
  [K in NotificationPreferencesKeys]: NotificationPreferencesConfig;
};

export const defaultNotificationPreferencesData: NotificationPreferencesType = Object.keys(
  NotificationPreferencesKeys
).reduce(
  (total, key) => ({ ...total, [key]: defaultNotificationPreferencesConfig }),
  {} as NotificationPreferencesType
);

export const notificationsList = Object.values(NotificationPreferencesKeys).map((key, index) => ({
  id: index + 1,
  value: key,
  title: NotificationPreferencesTitles[key],
}));

export interface IBENotificationPreference {
  name: NotificationPreferencesKeys;
  enabled: boolean;
}

export enum NotificationPreferencesBEKeywords {
  emailNotificationPreferences = 'emailNotificationPreferences',
  inAppNotificationPreferences = 'inAppNotificationPreferences',
  pushNotificationPreferences = 'pushNotificationPreferences',
}

export const NotificationPreferencesByTypes = {
  emailNotificationPreferences: NotificationPlatformTypes.email,
  inAppNotificationPreferences: NotificationPlatformTypes.inApp,
  pushNotificationPreferences: NotificationPlatformTypes.push,
};

export type IBENotificationsPreferences = {
  [K in NotificationPreferencesBEKeywords]: IBENotificationPreference[];
};
