import { cloneElement, MutableRefObject, useEffect, useImperativeHandle, useState } from 'react';
import { Dispatch, SetStateAction } from 'react';
import { batch, useDispatch } from 'react-redux';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import classNames from 'classnames';

import { IAddToPlaylist } from 'components/MusicPlayer/IMusicPlayer';
import { SearchQuery } from 'components/Reusable';
import { IPlaylist } from 'containers/Sidebar/data';

import { useDebounce, useMemoSelector } from 'hooks';
import Api from 'services/Api';
import {
  getUserId,
  getUserTeamIds,
  setChangedPlaylistId,
  setError,
  setMainNotification,
  updatePlaylistHash,
} from 'store';
import { PlaylistHashActionTypes } from 'store/reducers/general/types';
import { DEFAULT_ERROR_CONFIG, EDIT_TOOLTIP_CONTAINER_ID } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './AddToPlaylistPopup.module.scss';

interface IAddToPlaylistMedias {
  id: string;
  title: string;
}

export interface IAddToPlaylistRef {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface IAddToPlaylistPopupProps {
  children: JSX.Element;
  medias: IAddToPlaylistMedias[];
  onCancel?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  disableClosing?: boolean;
  setAddToPlaylist?: Dispatch<SetStateAction<IAddToPlaylist>>;
  setOpenFavoriteSnackbar?: Dispatch<SetStateAction<boolean>>;
  addToPlaylistRef?: MutableRefObject<IAddToPlaylistRef | null>;
}

const AddToPlaylistPopup = ({
  children,
  disableClosing,
  onCancel,
  onOpen,
  medias,
  onClose,
  setAddToPlaylist,
  setOpenFavoriteSnackbar,
  addToPlaylistRef,
}: IAddToPlaylistPopupProps) => {
  const dispatch = useDispatch();

  const { userId, userTeamIds } = useMemoSelector((state) => ({
    userId: getUserId(state),
    userTeamIds: getUserTeamIds(state),
  }));

  const [playlists, setPlaylists] = useState<IPlaylist[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);

  const handleTooltipClose = (
    e: React.MouseEvent<Document, MouseEvent> | React.ChangeEvent<Record<string, unknown>>
  ) => {
    if (!open) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const path = (e as any).path || ((e as any).composedPath && (e as any).composedPath()) || disableClosing;

    if (path?.find((item: Element) => item.id === EDIT_TOOLTIP_CONTAINER_ID)) return;

    onCancel && onCancel();
    setOpen(false);
  };

  const getPlaylistResults = useDebounce(async (value: string) => {
    const { playlists } =
      (await Api.getPlaylists({
        query: value,
        pagination: {
          skip: 0,
          take: 10,
          sort: {
            fieldName: 'updated_on',
            direction: 'desc',
          },
        },
        filter: {
          userId,
          teamIds: userTeamIds,
          filterType: 'created_by_my_team',
        },
      })) || {};

    playlists && setPlaylists(playlists);
  }, 100);

  const handleSearchChange = async (value: string) => {
    setSearchQuery(value);
    await getPlaylistResults(value);
  };

  const onClickAddToPlaylist = async (
    playlistId: string,
    title: string,
    playlistMediaIds: string[],
    skip?: boolean
  ) => {
    if (!medias.length) return;
    setPending(true);

    if (medias.length === 1 && playlistMediaIds.includes(medias[0].title)) {
      dispatch(
        setError({
          isOpen: true,
          title: 'Already Added',
          text: `This Version has already been added to ${title} playlist.`,
        })
      );

      setPending(false);
      return;
    }

    const existingSongs = medias.filter((item) => playlistMediaIds.includes(item.id));
    const notExistingSongs = medias.filter((item) => !playlistMediaIds.includes(item.id));

    if (existingSongs.length && !skip) {
      dispatch(
        setError({
          isOpen: true,
          title: 'Already Added',
          text: `The following song(s) already have been added to the playlist: \n ${existingSongs
            .map((item) => item.title)
            .join(', ')}${
            notExistingSongs.length
              ? `\n Songs wasn't added: ${notExistingSongs.map((item) => item.title).join(', ')}`
              : ''
          }`,
          btnText: 'Continue',
          onClick: () => onClickAddToPlaylist(playlistId, title, playlistMediaIds, true),
        })
      );

      setPending(false);
      return;
    }

    if (!notExistingSongs.length) {
      setPending(false);
      return;
    }

    onClose && onClose();

    try {
      await Api.addToPlaylist(
        notExistingSongs.map((item) => item.id),
        playlistId,
        { errorPopupConfig: DEFAULT_ERROR_CONFIG }
      );
    } catch (err) {
      setPending(false);
      // eslint-disable-next-line no-console
      console.debug(err);
      return;
    }

    setPending(false);
    setOpen(false);

    batch(() => {
      dispatch(setChangedPlaylistId(playlistId));
      dispatch(updatePlaylistHash(PlaylistHashActionTypes.playbar));
      dispatch(setMainNotification(`Song${notExistingSongs.length > 1 ? 's' : ''} added`));
    });

    setAddToPlaylist && setAddToPlaylist({ id: playlistId, title });
    setOpenFavoriteSnackbar && setOpenFavoriteSnackbar(true);
  };

  const handleTooltipOpen = () => {
    onOpen && onOpen();
    setOpen(true);
  };

  useEffect(() => {
    if (!open) {
      setPlaylists([]);
      return;
    }

    handleSearchChange('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useImperativeHandle(addToPlaylistRef, () => ({ setOpen }));

  const isHavePlaylists = !!searchQuery;

  return (
    <ClickAwayListener onClickAway={handleTooltipClose} touchEvent="onTouchEnd" disableReactTree>
      <Tooltip
        arrow
        classes={{
          popper: styles.addToPlaylistPopper,
          tooltip: styles.addToPlaylistTooltip,
          arrow: styles.addToPlaylistArrow,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <div
            id={EDIT_TOOLTIP_CONTAINER_ID}
            className={classNames(styles.addToPlaylistContainer, { [styles.disabled]: pending })}
          >
            <span className={styles.addToPlaylist}>Add To Playlist</span>
            <SearchQuery placeholder="Search playlists" onChange={handleSearchChange} value={searchQuery} />
            <div className={styles.recentPlaylistsContainer}>
              <span className={styles.recentPlaylistsTitle}>{isHavePlaylists ? 'Results' : 'Recent playlists'}</span>
              {playlists.map((item) => (
                <div
                  key={item.id}
                  className={classNames(globalStyles.textEllipsis, styles.recentPlaylists)}
                  onClick={() => onClickAddToPlaylist(item.id, item.title, item.mediaIds)}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
        }
      >
        {cloneElement(children, { ...(!addToPlaylistRef && { onClick: handleTooltipOpen }) })}
      </Tooltip>
    </ClickAwayListener>
  );
};

export default AddToPlaylistPopup;
