import { Control, Controller, Path } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import classNames from 'classnames';

import styles from './ToggleBtnGroup.module.scss';

interface ToggleBtnItemData {
  title: string;
  value: string | boolean;
}

export interface IToggleBtnGroupProps<T> {
  name?: Path<T>;
  control?: Control<T>;
  items: ToggleBtnItemData[];
  value?: string;
  onChangeToggle?: (value: string) => void;
  className?: string;
  size?: 'xs';
}

const ToggleBtnGroup = <T,>({
  items,
  control,
  name,
  value,
  onChangeToggle,
  className,
  size,
}: IToggleBtnGroupProps<T>) => {
  return control && name ? (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <ToggleButtonGroup
          color="primary"
          classes={{
            root: classNames(styles.toggleBtnGroup, className, { [styles.xs]: size === 'xs' }),
          }}
          value={value}
          exclusive
          onChange={(_, value) => {
            if (!items.find((el) => el.value === value)) return;
            onChange(value);
          }}
          aria-label="Platform"
        >
          {items.map(({ title, value }) => (
            <ToggleButton
              disableRipple
              key={`${value}`}
              value={value}
              classes={{
                root: styles.toggleBtn,
                selected: styles.selected,
              }}
            >
              {title}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    />
  ) : (
    <ToggleButtonGroup
      color="primary"
      classes={{
        root: classNames(styles.toggleBtnGroup, className, { [styles.xs]: size === 'xs' }),
      }}
      value={value}
      exclusive
      onChange={(_, value) => {
        if (!items.find((el) => el.value === value)) return;
        onChangeToggle && onChangeToggle(value);
      }}
      aria-label="Platform"
    >
      {items.map(({ title, value }) => (
        <ToggleButton
          disableRipple
          key={`${value}`}
          value={value}
          classes={{
            root: styles.toggleBtn,
            selected: styles.selected,
          }}
        >
          {title}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleBtnGroup;
