import React, { memo } from 'react';
import classNames from 'classnames';

import { IQuickSearchSongTitles } from 'components/Popups/DesktopSearch/data';

import { useMemoSelector } from 'hooks';
import { getSearchItemsNumState } from 'store';
import { fillArray } from 'utils';

import styles from './DesktopSearch.module.scss';

interface ITitlesWritersListProps {
  label: string;
  btnText: string;
  data: IQuickSearchSongTitles[] | string[];
  dataKey?: string;
  onClick?: (e: React.MouseEvent) => void;
  className: string;
}

const TitlesWritersList = ({
  label,
  btnText,
  data,
  onClick,
  dataKey,
  className,
}: ITitlesWritersListProps): JSX.Element => {
  const searchItemsNum = useMemoSelector(getSearchItemsNumState);

  return (
    <div className={classNames(styles.songTitlesWritersContainer, className)}>
      <div className={styles.songTitlesWriters}>{label}</div>
      <div className={styles.shortResultContainer}>
        {fillArray(data as IQuickSearchSongTitles[], searchItemsNum, '-').map((item, index) => (
          <p key={index}>{dataKey && data.length ? item[dataKey as keyof IQuickSearchSongTitles] || item : item}</p>
        ))}
      </div>
      <div className={styles.viewAll} onClick={onClick}>
        {btnText}
      </div>
    </div>
  );
};

export default memo(TitlesWritersList);
