import { ISelectOption } from './SelectCell';
import { TagsFormApi } from './TagsForm';
import { WritersFormApi } from './WritersForm';
import { ErrorType, ISongUploadItem } from 'types';

export interface ISongsUploadProps {
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
  onSave?: () => void;
}

export interface ISongsUploadRef {
  checkHasUnsavedChanges: () => boolean;
}

export type FormApi = TagsFormApi & WritersFormApi & { version: ISelectOption };

export type ItemErrors<Values> = {
  [K in keyof Values]?: ErrorType | undefined;
};

export enum SelectionType {
  Common = 'common',
  Tags = 'tag',
  Writers = 'writers',
  Versions = 'versions',
}

export interface IRowDataState {
  loaded?: boolean;
  procIds: string[];
  rowData: ISongUploadItem[];
}

export interface ISelection {
  formItem?: ISongUploadItem;
  items: ISongUploadItem[];
  type: SelectionType;
}
