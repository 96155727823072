import { Controller } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { FilterPopup } from 'components/Popups';
import { NewTextField as TextField } from 'components/UI';

export interface IContactsFilterItem {
  email?: string;
  name?: string;
}

export interface IContactsFilterPopup {
  filters: IContactsFilterItem;
  onClose: () => void;
  onSubmit: (values: IContactsFilterItem) => void;
  open: boolean;
}

const ContactsFilterPopup = ({ filters, onClose, onSubmit, open }: IContactsFilterPopup): JSX.Element => (
  <FilterPopup filters={filters} onClose={onClose} onSubmit={(v) => onSubmit(v as IContactsFilterItem)} open={open}>
    {({ control }) => (
      <div style={{ padding: '20px 0' }}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <TextField {...field} label="Name" labelPlacement="start" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => <TextField {...field} label="Email" labelPlacement="start" type="email" />}
            />
          </Grid>
        </Grid>
      </div>
    )}
  </FilterPopup>
);

export default ContactsFilterPopup;
