import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { sleepPromise } from 'components/UI/DataGrid/utils';
import Tooltip from 'components/UI/Tooltip';

import { ReactComponent as EditIcon } from 'assets/edit.svg';
import styles from './Tags.module.scss';

export interface ITagsProps {
  addText?: string;
  editText?: string;
  error?: boolean;
  onEdit?: () => void;
  tagClassName?: string;
  tags: string[] | null | undefined;
}

interface ITagsState {
  loaded?: boolean;
  hiddenTags: string[];
  visibleTags: string[];
}

export const calculateVisibleTagsCount = (container: React.RefObject<HTMLDivElement>, length: number): number => {
  const containerWidth = container.current?.clientWidth || 0;
  const items = container.current ? (Array.from(container.current.querySelectorAll('.tag-item')) as HTMLElement[]) : [];
  const systemItemsWidth = items.filter((i) => i.dataset['management']).reduce((w, i) => w + i.clientWidth, 0);
  const availableWidth = containerWidth - systemItemsWidth;

  let calculatedWidth = 0;
  let tagCount = 0;
  for (const item of items) {
    calculatedWidth += item.clientWidth;
    if (availableWidth > calculatedWidth || length === 1) {
      tagCount++;
    } else {
      return tagCount;
    }
  }

  return tagCount;
};

const Tag = ({ className, text }: { className?: string; text: string }) => (
  <div className={classNames(styles.item, 'tag-item')}>
    <div className={classNames(styles.tag, className)}>
      <div className={styles.tagText}>{text}</div>
    </div>
  </div>
);

const Tags = ({ addText, editText, error, onEdit, tagClassName, tags }: ITagsProps) => {
  const container = useRef<HTMLDivElement>(null);
  const [{ loaded, hiddenTags, visibleTags }, setState] = useState<ITagsState>({ hiddenTags: [], visibleTags: [] });

  useEffect(() => {
    (async () => {
      if (loaded) {
        setState((ps) => ({ ...ps, loaded: false }));
        await sleepPromise(1); // Let to render loading before calculation
      }
      const refreshTags = () => {
        if ((container.current?.clientWidth || 0) === 0) return setTimeout(refreshTags, 100);
        const visibleTagsCount = calculateVisibleTagsCount(container, tags?.length || 0);
        const visibleTags = tags?.slice(0, visibleTagsCount) || [];
        setState((ps) => ({ ...ps, hiddenTags: tags?.slice(visibleTagsCount) || [], loaded: true, visibleTags }));
      };
      refreshTags();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container.current?.clientWidth, tags]);

  const onEditClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onEdit && onEdit();
  };

  if ((!tags || tags.length === 0) && !onEdit) {
    return null;
  }

  return (
    <div className={styles.tagsContainer} ref={container}>
      <div className={classNames(styles.tags, !loaded && styles.loading)}>
        {(loaded ? visibleTags : tags || []).map((tag, i) => (
          <Tag className={tagClassName} key={i} text={tag} />
        ))}
        {tags && tags.length > 0 && (
          <>
            {tags.length > 1 && (
              <div
                className={classNames(styles.item, 'tag-item')}
                data-management="true"
                style={{ display: loaded && hiddenTags.length === 0 ? 'none' : undefined }}
              >
                <Tooltip text={hiddenTags.join(', ')}>
                  <div className={styles.counter}>{`+${loaded ? hiddenTags.length : tags.length}`}</div>
                </Tooltip>
              </div>
            )}
            {onEdit && (
              <div className={classNames(styles.item, 'tag-item')} data-management="true">
                <button className={classNames(styles.editButton, error && styles.error)} onClick={onEditClick}>
                  {editText || <EditIcon />}
                </button>
              </div>
            )}
          </>
        )}
        {(!tags || tags.length === 0) && onEdit && (
          <div className={classNames(styles.item, 'tag-item')} data-management="true">
            <button className={classNames(styles.addButton, error && styles.error)} onClick={onEditClick}>
              {addText || 'Add'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tags;
