import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { defaultAddToPlaylist } from 'components/MusicPlayer/data';
import { DoubleBtnGroup, Snackbar } from 'components/Reusable';

import { useMemoSelector } from 'hooks';
import { getFavoritePlaylistId, setCurrentPlaylistId } from 'store';

import styles from './MusicPlayer.module.scss';

import { IPlayerNotificationsProps } from '.';

const PlayerNotifications = ({
  openFavoriteSnackbar,
  setOpenFavoriteSnackbar,
  isFavorite,
  addToPlaylist,
  setAddToPlaylist,
}: IPlayerNotificationsProps) => {
  const dispatch = useDispatch();
  const favoritePlaylistId = useMemoSelector(getFavoritePlaylistId);

  const [openDuplicateSnackbar, setOpenDuplicateSnackbar] = useState<boolean>(false);

  const onClick = (playlistId: string) => {
    if (!playlistId) return;

    dispatch(setCurrentPlaylistId(playlistId));
  };

  return (
    <>
      <Snackbar
        containerClassName={styles.favoriteSnackbarContainer}
        contentClassName={styles.favoriteSnackbarContent}
        open={openFavoriteSnackbar}
        onClose={() => {
          setOpenFavoriteSnackbar(false);
          setAddToPlaylist(defaultAddToPlaylist);
        }}
        autoHideDuration={2000}
        message={
          <div className={styles.favoriteSnackbar}>
            {isFavorite || addToPlaylist.id ? 'Added to' : 'Removed from'}{' '}
            <button onClick={() => onClick(addToPlaylist.id || favoritePlaylistId)}>
              {addToPlaylist.id ? addToPlaylist.title : 'Favorites'}
            </button>
          </div>
        }
      />
      <Snackbar
        containerClassName={styles.duplicateSnackbarContainer}
        contentClassName={styles.duplicateSnackbarContent}
        open={openDuplicateSnackbar}
        onClose={() => setOpenDuplicateSnackbar(false)}
        autoHideDuration={2000}
        message={
          <>
            <h1>Duplicate</h1>
            <span>This song is already in the playlist</span>
            <DoubleBtnGroup
              name1="Add Anyway"
              name2="Don’t Add"
              spacing={20}
              onClick1={() => ({})}
              onClick2={() => {
                setOpenDuplicateSnackbar(false);
              }}
            />
          </>
        }
      />
    </>
  );
};

export default PlayerNotifications;
