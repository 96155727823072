import { useDispatch } from 'react-redux';

import { Tooltip } from 'components/UI';
import { RowNodeExt } from 'components/UI/DataGrid';
import { UserStatuses } from 'components/UI/StatusCard/data';

import { setAdminControls } from 'store';
import { ConfirmPopupTypes, IUserData } from 'store/reducers/general/types';

import { ReactComponent as ApproveIcon } from 'assets/approve.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as RejectIcon } from 'assets/reject.svg';
import { ReactComponent as TrashIcon } from 'assets/trash.svg';

interface IActionsBtnsGroupsProps {
  data: IUserData | undefined;
  node: RowNodeExt;
  className: string;
}

const ActionBtnsGroup = ({ data, className, node }: IActionsBtnsGroupsProps): JSX.Element => {
  const dispatch = useDispatch();

  const onClickAction = (type?: ConfirmPopupTypes) => {
    dispatch(
      setAdminControls({
        ...(type ? { openConfirmPopupType: type } : { isOpenEditPopup: true }),
        ...(data && {
          activeEditingUser: {
            data: { ...data },
            rowId: node.id?.replace('detail_', '') as string,
          },
        }),
      })
    );
  };

  return (
    <div className={className}>
      {data?.status === UserStatuses.PENDING ? (
        <>
          <Tooltip text="Approve User">
            <ApproveIcon onClick={() => onClickAction()} />
          </Tooltip>
          <Tooltip text="Deactivate User">
            <RejectIcon onClick={() => onClickAction(ConfirmPopupTypes.reject)} />
          </Tooltip>
        </>
      ) : (
        <Tooltip text="Edit User">
          <EditIcon onClick={() => onClickAction()} />
        </Tooltip>
      )}
      {data?.status === UserStatuses.ARCHIVED ? (
        <Tooltip text="Deactivate User">
          <RejectIcon onClick={() => onClickAction(ConfirmPopupTypes.reject)} />
        </Tooltip>
      ) : (
        <Tooltip text="Archive User">
          <TrashIcon onClick={() => onClickAction(ConfirmPopupTypes.archive)} />
        </Tooltip>
      )}
    </div>
  );
};

export default ActionBtnsGroup;
