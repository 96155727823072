import { GridApi, RowNode } from 'ag-grid-community';

import { getProcessingPercentage } from 'components/SongsUpload';

import { AssetStatus, IAsset } from 'services/Api';
import { getDataGridItems, isEmpty } from 'utils/helpers';

import { ErrorType, ISongMedias, ItemErrors, SongStatusTypes } from 'types';

export const getVersionValue = (data: ISongMedias | undefined): string | null => {
  if (!data?.version) return null;
  if (typeof data.version === 'string') return data.version;
  return data.version.id?.toString();
};

const isVersionUnique = (data: ISongMedias, items: ISongMedias[]): boolean => {
  const extension = data.type.toLowerCase();
  const relativeItems = items.filter((i) =>
    extension === 'wav' ? i.type.toLowerCase() === 'wav' : i.type.toLowerCase() !== 'wav'
  );
  return relativeItems.filter((i) => getVersionValue(i) === getVersionValue(data)).length === 0;
};

export const mapAssetToSongMedia = (asset: IAsset): ISongMedias => {
  const processing = getProcessingPercentage(asset.status);
  const title = asset.title?.split('.').slice(0, -1).join('.') || '';
  return {
    draft: true,
    duration: `${asset.duration || 0}`,
    error:
      asset.status && [AssetStatus.Error, AssetStatus.ProcessingError, AssetStatus.UploadError].includes(asset.status)
        ? 'Error'
        : null,
    id: asset.id,
    markers: null,
    modifiedBy: null,
    modifiedByName: null,
    modifiedOn: null,
    path: asset.fileName || '',
    playbackPath: asset.audioPlaybackPath || '',
    processing: typeof processing === 'number' ? processing : null,
    songTitle: title,
    title,
    type: asset.title?.split('.').pop()?.toUpperCase() || '',
    uploadedBy: null,
    uploadedByName: asset.uploadedBy || null,
    uploadedOn: asset.uploadedOn || null,
    version: null,
    waveform: asset.waveForm || null,
    writers: [],
    status: SongStatusTypes.ACTIVE,
  };
};

export const validateDraft = (node: RowNode, grid: GridApi): ItemErrors<ISongMedias> | undefined => {
  const requiredFields: (keyof ISongMedias)[] = ['version'];
  const versions = getDataGridItems<ISongMedias>(grid).filter((data) => !data.draft);

  const data = node.data as ISongMedias;
  const errors = requiredFields.reduce((err: ItemErrors<ISongMedias>, f) => {
    if (isEmpty(data && data[f], true)) {
      err[f] = ErrorType.Required;
    }
    if (!isVersionUnique(data, versions)) err[f] = ErrorType.NotUniqueVersion;
    return err;
  }, {});
  const errorFields = Object.keys(errors) as (keyof ISongMedias)[];
  node.setData({
    ...node.data,
    state: {
      ...(node.data.state || {}),
      errors: errorFields.length > 0 ? { ...(node.data.errors || {}), ...errors } : undefined,
    },
  });
  return errorFields.length > 0 ? errors : undefined;
};
