import classNames from 'classnames';

import { songStatuses } from 'components/Songs/data';
import { Tooltip } from 'components/UI';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './SongStatuses.module.scss';

import { ISongStatuses } from 'types';

const getStatusText = (songStatus: ISongStatuses, withArtist?: boolean): string => {
  const status = songStatuses.find((s) => s.addCode === songStatus.status)?.title ?? songStatus.status;
  return withArtist && songStatus.artist ? `${status} (${songStatus.artist})` : status;
};

const SongStatuses = ({ statuses }: { statuses: ISongStatuses[] }) => {
  return (
    <>
      <Tooltip text={getStatusText(statuses[0], true)}>
        <div className={classNames(styles.statusCard, globalStyles.textEllipsis)}>{getStatusText(statuses[0])}</div>
      </Tooltip>
      {statuses.length > 1 && (
        <Tooltip
          text={statuses
            .map((s) => getStatusText(s, true))
            .slice(1)
            .join(', ')}
        >
          <span className={styles.statusAdditionalCard}>+{statuses.length - 1}</span>
        </Tooltip>
      )}
    </>
  );
};

export default SongStatuses;
