import React from 'react';
import { RowNode } from 'ag-grid-community';
import classNames from 'classnames';

import { ReactComponent as PendingIcon } from 'assets/pending.svg';
import { ReactComponent as SaveIcon } from 'assets/save.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-2.svg';
import styles from './SongsUpload.module.scss';

import { ISongUploadItemExt } from 'types';

export interface ActionsCellProps {
  node: RowNode;
  onDelete: (node: RowNode) => void;
  onSave: (node: RowNode) => void;
}

const ActionsCell = ({ node, onDelete, onSave }: ActionsCellProps) => {
  const onSaveClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onSave(node);
  };
  const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onDelete(node);
  };

  const data = node.data as ISongUploadItemExt;

  return (
    <div className={styles.actionsCell}>
      {data.state?.saving ? (
        <PendingIcon className={styles.pendingIcon} />
      ) : (
        <>
          {!data.error && typeof data.processing === 'undefined' && (!data.groupId || data.groupId === data.id) && (
            <button className={styles.iconButton} onClick={onSaveClick}>
              <SaveIcon height="14" width="14" />
            </button>
          )}
          <button className={classNames(styles.iconButton, styles.deleteIconButton)} onClick={onDeleteClick}>
            <TrashIcon height="14" width="14" />
          </button>
        </>
      )}
    </div>
  );
};

export default ActionsCell;
