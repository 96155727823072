export enum ActionButtonsTypes {
  merge = 'merge',
  addToPlaylist = 'addToPlaylist',
  download = 'download',
  share = 'share',
  pitch = 'pitch',
  expand = 'expand',
  actions = 'actions',
  removeFromPlaylist = 'removeFromPlaylist',
  songDetails = 'songDetails',
  archive = 'archive',
  restore = 'restore',
  delete = 'delete',
  holdRequest = 'holdRequest',
  visibility = 'visibility',

  addStatus = 'addStatus',
  addNote = 'addNote',
}

export interface ISongMoreButton {
  id: number;
  title: string;
  type: ActionButtonsTypes;
  isForLabelUsers?: boolean;
  isForReadOnlyUsers?: boolean;
  hideInMobile?: boolean;
}

export const songMoreButtons: ISongMoreButton[] = [
  {
    id: 1,
    title: 'Request Hold',
    type: ActionButtonsTypes.holdRequest,
    isForLabelUsers: true,
    isForReadOnlyUsers: true,
    hideInMobile: true,
  },
  {
    id: 2,
    title: 'Remove from playlist',
    type: ActionButtonsTypes.removeFromPlaylist,
    isForLabelUsers: true,
    isForReadOnlyUsers: true,
  },
  {
    id: 3,
    title: 'Download',
    type: ActionButtonsTypes.download,
  },
  {
    id: 4,
    title: 'Pitch',
    type: ActionButtonsTypes.pitch,
    hideInMobile: true,
  },
  {
    id: 5,
    title: 'Share',
    type: ActionButtonsTypes.share,
    isForLabelUsers: true,
  },
  {
    id: 6,
    title: 'Add to Playlist',
    type: ActionButtonsTypes.addToPlaylist,
    isForLabelUsers: true,
    isForReadOnlyUsers: true,
  },
  {
    id: 7,
    title: 'Song Details',
    type: ActionButtonsTypes.songDetails,
    isForLabelUsers: true,
    isForReadOnlyUsers: true,
  },
  {
    id: 8,
    title: 'Archive',
    type: ActionButtonsTypes.archive,
  },
  {
    id: 9,
    title: 'Restore',
    type: ActionButtonsTypes.restore,
  },
  {
    id: 10,
    title: 'Delete',
    type: ActionButtonsTypes.delete,
  },
];
