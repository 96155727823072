import { useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Dialog, DoubleBtnGroup } from 'components/Reusable';
import { InputText } from 'components/UI';

import { useMemoSelector } from 'hooks';
import Api, { updateHoldRequestStatusTabValues } from 'services/Api';
import { getHoldRequestRejectPopupConfig, setHoldRequest, setMainNotification } from 'store';
import { DEFAULT_ERROR_CONFIG, DEFAULT_HOLD_REQUEST_CONFIG } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './HoldRequestRejectPopup.module.scss';

import { HoldStatuses } from 'types';

const HoldRequestRejectPopup = () => {
  const dispatch = useDispatch();
  const { isOpen, holdId, cb } = useMemoSelector(getHoldRequestRejectPopupConfig);

  const [message, setMessage] = useState<string>('');
  const [isPending, setIsPending] = useState<boolean>(false);

  const onClose = () => {
    setMessage('');
    dispatch(setHoldRequest({ reject: DEFAULT_HOLD_REQUEST_CONFIG.reject }));
  };

  const onSubmit = async () => {
    setIsPending(true);

    const res = await Api.holdReject(holdId, message, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    setIsPending(false);

    if (!res) return;

    batch(() => {
      dispatch(setMainNotification('Hold Denied'));
      dispatch(setHoldRequest({ action: { ...DEFAULT_HOLD_REQUEST_CONFIG.action } }));
      onClose();
    });
    cb ? await cb(HoldStatuses.REJECTED) : await updateHoldRequestStatusTabValues(true);
  };

  return (
    <Dialog
      className={styles.dialog}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: styles.paper,
        },
      }}
      closeFromBackdropClick
    >
      <div className={styles.dialogHeader}>
        <span>Deny Hold Request?</span>
      </div>
      <span className={classNames(globalStyles.f13h16SuisseREG_gray, styles.infoText)}>
        The song will be marked “Denied” and the user will be notified of the rejection and the reason why.
      </span>
      <InputText
        value={message}
        onChangeInput={(text) => setMessage(text)}
        name={'reason' as never}
        label="Reason:"
        labelPlacement="top"
        labelRootClass={styles.reasonLabel}
        labelFormClass={styles.reasonLabelForm}
        inputClass={styles.reasonInput}
      />
      <DoubleBtnGroup
        isRedDoneButton
        className={styles.doubleBtnGroup}
        name1="Cancel"
        spacing={30}
        name2="Deny"
        isPending={isPending}
        onClick1={onClose}
        onClick2={onSubmit}
      />
    </Dialog>
  );
};

export default HoldRequestRejectPopup;
