import { TrackInfo } from 'components/MusicPlayer';
import { PitchesModuleFilterDataType } from 'components/Pitches/data';
import { IConfirmPopupProps } from 'components/Popups/ConfirmPopup/ConfirmPopup';
import { SearchDataType, SearchItemsNums } from 'components/Popups/DesktopSearch/data';
import { DownloadStatuses } from 'components/Popups/DownloadPopup/data';
import { ISystemPitch } from 'components/Popups/SystemPitchForm';
import { ISongData, SongsModuleFilterDataType } from 'components/Songs/data';
import { UserStatuses } from 'components/UI/StatusCard/data';
import { IFavoritesData, IFilterByPlaylist, IPlaylistMedia, ISortByPlaylist } from 'containers/Sidebar/data';

import { IAsset } from 'services/Api';

import {
  AdminControlsFilterDataType,
  HoldRequestTabKeys,
  HoldRequestTabValuesType,
  HoldStatuses,
  IAddPopupConfigs,
  ICurrentTrack,
  ISong,
  StatusTabValuesType,
} from 'types/index';

export interface IDefaultTeam {
  id: string;
  name: string;
  isInternal: boolean;
  parent: IDefaultTeam | null;
}

export interface IUserData {
  id: string;
  email: string;
  status: UserStatuses;
  role: string;
  isTeamAdmin: boolean;
  defaultTeam: IDefaultTeam | null;
  defaultTerritory: null | string;
  createdOn: string;
  updatedBy: null | string;
  updatedOn: null | string;
  firstName: string;
  lastName: string;
  company?: string;
}

export interface IUser {
  createdOn: string;
  defaultTeam: null | IDefaultTeam;
  defaultTeamName: string | null;
  defaultTerritory: null | string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  role: string;
  isTeamAdmin: boolean;
  status: string;
  updatedBy: null | string;
  updatedOn: null | string;
}

export interface ITeam {
  id: string;
  name: string;
  isInternal: boolean;
  isViewing: boolean;
}

export interface IUserProfile {
  user: IUser;
  teams: ITeam[];
}

export enum ConfirmPopupTypes {
  archive = 'archive',
  reject = 'reject',
  resend = 'resend',
  restore = 'restore',
}

export interface IActiveEditingUser {
  data: IUserData;
  rowId: string;
}

export interface IAdminControls {
  filtersData: Partial<AdminControlsFilterDataType>;
  isOpenEditPopup: boolean;
  isOpenFiltersPopup: boolean;
  openConfirmPopupType: ConfirmPopupTypes | '';
  activeEditingUser: null | Partial<IActiveEditingUser>;
  statusTabValues: StatusTabValuesType;
}

export interface SongsModuleMyFilterType {
  filterType: string;
  userId: string;
  teamIds: string[];
}

export interface ISongsModule {
  filtersData: Partial<SongsModuleFilterDataType>;
  myFilter: Partial<SongsModuleMyFilterType>;
  isOpenFiltersPopup: boolean;
  localAssets: IAsset[];
  songsCount?: number;
}

export interface ISongDetailsDeletePopupConfig {
  isOpen: boolean;
  questionText: string;
  mainText: string;
  notificationText: string;
  id: string;
  type: string;
}

export interface IAddPopupConfigState extends IAddPopupConfigs {
  isOpen: boolean;
  isEdit?: boolean;
  editValues?: { [key: string]: unknown };
}

export interface ISongDetails {
  deletePopupConfig: ISongDetailsDeletePopupConfig;
  addPopupConfig: IAddPopupConfigState;
  reloadHash?: string;
  validationErrorPopupType?: 'required-field' | 'versions-not-unique';
  versionsLoading?: boolean;
}

export interface IPitchDetailsDeletePopupConfig {
  isOpen: boolean;
  id: string;
  rowId: string;
  title: string;
}

export interface IPitchDetails {
  deletePopupConfig: IPitchDetailsDeletePopupConfig;
}

export interface IPitchesModuleConfirmPopupConfig {
  type: string;
  questionText: string;
  id: string;
  rowId: string;
}

export interface IPitchesModuleExpirationConfig {
  isOpenExpirationPopup: boolean;
  expirationDate: string;
  id: string;
}

export enum PitchContentType {
  playlistPitch = 'playlistPitch',
  multipleSongPitch = 'multipleSongPitch',
}

export interface IPitchesModule {
  filtersData: Partial<PitchesModuleFilterDataType>;
  myFilter: Partial<SongsModuleMyFilterType>;
  isOpenFiltersPopup: boolean;
  confirmPopupConfig: IPitchesModuleConfirmPopupConfig;
  expirationConfig: IPitchesModuleExpirationConfig;
  systemPitchForm?: ISystemPitch;
  reloadHash?: string;
  pitchContentType?: PitchContentType;
}

export interface IError {
  title: string;
  text: string;
  isOpen: boolean;
  btnText?: string;
  onClick?: () => void;
  containerId?: string;
}

export interface IDownloadPopupConfig {
  open: boolean;
  title: string;
  statusText: string;
  status: DownloadStatuses;
  currentProcessText: string;
}

export interface IPlaylistFilter {
  sort: ISortByPlaylist;
  filterField: IFilterByPlaylist;
  query: string;
}

export interface IActiveTrack {
  songId: string;
  mediaId: string;
  index: number;
  isHaveMarkers?: boolean;
}

export enum ShareItemTypes {
  song = 'song',
  playlist = 'playlist',
}

export interface IShareConfig {
  type: '' | ShareItemTypes;
  songId: string;
  playlistId: string;
  isOpen: boolean;
}

export interface IHoldRequestFormConfig {
  isOpen: boolean;
  workId: string;
}
export interface IHoldRequestRejectConfig {
  isOpen: boolean;
  holdId: string;
  cb?: (status: HoldStatuses) => void;
}
export interface IHoldRequestActionConfig extends IHoldRequestRejectConfig {
  type: HoldRequestActions | null;
}

export interface IHoldRequest {
  form: IHoldRequestFormConfig;
  action: IHoldRequestActionConfig;
  reject: IHoldRequestRejectConfig;
  activeTab?: HoldRequestTabKeys | null;
  statusTabValues: HoldRequestTabValuesType;
  reloadHash?: string;
}

export enum HoldRequestActions {
  approve = 'approve',
  cancel = 'cancel',
}

export enum PlaylistHashActionTypes {
  songDetails = 'songDetails',
  playbar = 'playbar',
  playlist = 'playlist',
  featuredPlaylistTable = 'featuredPlaylistTable',
}

export interface INotificationsConfig {
  count: number;
}

export interface IEditPlaylistConfig {
  id: string;
  isFeatured?: boolean;
}

export type IRecentEditedSongUpdate = Partial<ISongData> & Partial<TrackInfo> & Partial<ISong>;

export interface IRecentEditedSongIds {
  mediaId?: string;
  songId?: string;
}

export interface IRecentEditedSong {
  ids: IRecentEditedSongIds;
  update: Omit<IRecentEditedSongUpdate, 'id' | 'mediaId' | 'songId'>;
}

export interface IRecentEditedSongs {
  songs: IRecentEditedSong[];
  usages: UsageKeys[];
}

export enum UsageKeys {
  songsTable = 'songsTable',
  playbar = 'playbar',
  songDetails = 'songDetails',
  playlist = 'playlist',
}

export enum PlayingStateTypes {
  playing = 'playing',
  paused = 'paused',
  error = 'error',
}

interface IGeneralState {
  selectedRowIndex: number;
  loading: boolean;
  isOpenSidebar: boolean;
  isOpenTopMenu: boolean;
  isOpenSearch: boolean;
  isOpenMobileMockup: boolean;
  isOpenShowHideColumns: boolean;
  isExpandedPlaybar: boolean;
  isMarkersVisible: boolean;
  searchData: SearchDataType;
  activeNavItemId: number;
  searchItemsNum: SearchItemsNums;
  userProfile: IUserProfile;
  adminControls: IAdminControls;
  songsModule: ISongsModule;
  pitchesModule: IPitchesModule;
  songDetails: ISongDetails;
  recentEditedSongs: IRecentEditedSongs;
  pitchDetails: IPitchDetails;
  playlistHash: {
    action: PlaylistHashActionTypes;
    hash: number;
  };
  featuredPlaylistHash: number;
  currentPlaylistId: string;
  favoritesList: IPlaylistMedia[];
  favoritesData: IFavoritesData;
  favoritesIdsObj: { [key: string]: boolean };
  recentPlayedList: IPlaylistMedia[];
  playlistFilter: IPlaylistFilter;
  changedPlaylistId: string;
  editPlaylist: IEditPlaylistConfig;
  currentTracks: ICurrentTrack[];
  activeTrack: IActiveTrack;
  playbarPlayingState: PlayingStateTypes;
  error: IError;
  downloadPopupConfig: IDownloadPopupConfig;
  holdRequest: IHoldRequest;
  shareConfig: IShareConfig;
  mainConfirmPopup: IConfirmPopupProps;
  mainNotification: string;
  notificationsConfig: INotificationsConfig;
}

export default IGeneralState;
