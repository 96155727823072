import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { ManualPitchForm, NotificationPopup } from 'components/Popups';
import { IconButton, PageHeader, Tab, Tabs } from 'components/Reusable';

import { useMemoSelector, useMount } from 'hooks';
import { getIsOpenSidebar, getUserId, getUserPermissions, getUserTeamIds, setPitchesModule } from 'store';

import { ReactComponent as PlusCircleIcon } from 'assets/plus-circle.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Pitches.module.scss';

import { PitchesTable } from './';
import { PitchesTableTypes } from './data';

const Pitches = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pitchesContainerRef = useRef<HTMLDivElement | null>(null);

  const [openCreateManualPitch, setOpenCreateManualPitch] = useState<boolean>(false);
  const [isOpenNotification, setIsOpenNotification] = useState(false);

  const { userId, userTeamIds, permissions, isOpenSidebar } = useMemoSelector((state) => ({
    userId: getUserId(state),
    userTeamIds: getUserTeamIds(state),
    permissions: getUserPermissions(state),
    isOpenSidebar: getIsOpenSidebar(state),
  }));

  const onClickTab = (tab: PitchesTableTypes) => {
    const myFilter = {
      filterType: tab,
      ...(PitchesTableTypes.my === tab && { userId }),
      ...(PitchesTableTypes.team === tab && { teamIds: userTeamIds }),
    };

    dispatch(setPitchesModule({ myFilter }));
  };

  useMount(() => {
    onClickTab((location.hash.toString().replace('#tab=', '') || PitchesTableTypes.my) as PitchesTableTypes);
  });

  return (
    <div
      className={classNames(styles.pitches, globalStyles.wrapper, { [styles.isOpenSidebar]: isOpenSidebar })}
      ref={pitchesContainerRef}
    >
      <PageHeader
        mediumMargin
        Component={
          permissions.pitchActions ? (
            <IconButton
              Icon={PlusCircleIcon}
              className={styles.manualPitchBtn}
              text="Manual Pitch"
              onClick={() => setOpenCreateManualPitch(true)}
            />
          ) : undefined
        }
      />
      <Tabs
        onChange={(tab) => onClickTab(tab as PitchesTableTypes)}
        className={styles.pitchTabs}
        contentClassName={styles.pitchTabsContent}
      >
        <Tab label="MY PITCHES" value={PitchesTableTypes.my}>
          <PitchesTable />
        </Tab>
        <Tab label="TEAM PITCHES" value={PitchesTableTypes.team}>
          <PitchesTable />
        </Tab>
      </Tabs>
      {openCreateManualPitch && (
        <ManualPitchForm
          onClose={() => setOpenCreateManualPitch(false)}
          onSubmit={() => {
            setOpenCreateManualPitch(false);
            dispatch(setPitchesModule({ reloadHash: new Date().toISOString() }));
            setIsOpenNotification(true);
          }}
        />
      )}
      <NotificationPopup isOpen={isOpenNotification} setIsOpen={setIsOpenNotification} text="Pitch Saved" />
    </div>
  );
};

export default Pitches;
