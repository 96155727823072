import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';

import {
  defaultPitchesModuleFilterData,
  PitchesModuleFilterDataType,
  PitchesModuleFilterKeys,
  PitchesModuleFilterTitles,
  pitchStatusesData,
  pitchTypesData,
} from 'components/Pitches/data';
import { DatePickerPopup } from 'components/Popups';
import { DoubleBtnGroup } from 'components/Reusable';
import { InputAutocomplete, InputDateRange, InputText } from 'components/UI';
import FiltersPopupWrapper from 'containers/FiltersPopupWrapper';

import { useMemoSelector } from 'hooks';
import {
  getIsOpenSidebar,
  getPitchesModuleFiltersData,
  getPitchesModuleIsOpenFiltersPopup,
  getUserTeamsForAutocomplete,
  setPitchesModule,
} from 'store';

import styles from './PitchesFiltersPopup.module.scss';

interface IFiltersPopup {
  parent?: MutableRefObject<HTMLDivElement | null>;
  customCancel?: () => void;
  customSubmit?: () => void;
}

const PitchesFiltersPopup = ({ customCancel, customSubmit, parent }: IFiltersPopup): JSX.Element => {
  const dispatch = useDispatch();

  const { control, handleSubmit, reset, setValue, watch } = useForm<PitchesModuleFilterDataType>({
    defaultValues: defaultPitchesModuleFilterData,
  });

  const { isOpenFiltersPopup, filtersData, teams, isOpenSidebar } = useMemoSelector((state) => ({
    isOpenFiltersPopup: getPitchesModuleIsOpenFiltersPopup(state),
    filtersData: getPitchesModuleFiltersData(state),
    teams: getUserTeamsForAutocomplete(state),
    isOpenSidebar: getIsOpenSidebar(state),
  }));

  const [isOpenDateRangePicker, setIsOpenDateRangePicker] = useState<boolean>(false);

  const onSubmit = (data: PitchesModuleFilterDataType) => {
    dispatch(setPitchesModule({ filtersData: data, isOpenFiltersPopup: false }));
    customSubmit && customSubmit();
  };

  const onCancel = (): void => {
    reset(filtersData);
    dispatch(setPitchesModule({ isOpenFiltersPopup: false }));
    onCloseDateRangePicker();
    customCancel && customCancel();
  };

  const onReset = () => {
    reset(defaultPitchesModuleFilterData);
    dispatch(setPitchesModule({ filtersData: defaultPitchesModuleFilterData }));
  };

  const onSubmitSingleDate = useCallback(
    (date?: string) => {
      setValue(PitchesModuleFilterKeys.sent, date || '');
      setIsOpenDateRangePicker(false);
    },
    [setValue]
  );

  const onCloseDateRangePicker = useCallback(() => {
    if (isOpenDateRangePicker) {
      setIsOpenDateRangePicker(false);
    }
  }, [isOpenDateRangePicker]);

  useEffect(() => {
    reset(filtersData);
  }, [filtersData, reset, isOpenFiltersPopup]);

  return (
    <FiltersPopupWrapper
      isOpen={isOpenFiltersPopup}
      onCancel={onCancel}
      parent={parent}
      component={() => (
        <form
          className={classNames(styles.filtersForm, {
            [styles.openSidebar]: isOpenSidebar,
          })}
          onSubmit={handleSubmit(onSubmit)}
          onReset={onReset}
        >
          <DatePickerPopup
            isSingleDayPicker
            containerClassName={styles.datePickerContainer}
            TransitionComponent={Fade}
            open={isOpenDateRangePicker}
            onClose={onCloseDateRangePicker}
            date={watch(PitchesModuleFilterKeys.sent) as string}
            onSubmitSingleDate={onSubmitSingleDate}
          />
          <div className={styles.filtersContainer}>
            <InputText
              control={control}
              name={PitchesModuleFilterKeys.title}
              label={PitchesModuleFilterTitles.title}
              placeholder="filter by pitch title"
            />
            <InputAutocomplete
              data={pitchStatusesData}
              control={control}
              name={PitchesModuleFilterKeys.status}
              label={PitchesModuleFilterTitles.status}
              labelPlacement="start"
              placeholder="filter by pitch status"
              autocompleteRootClass={styles.autocompleteRoot}
            />
            <InputAutocomplete
              data={pitchTypesData}
              control={control}
              name={PitchesModuleFilterKeys.pitchType}
              label={PitchesModuleFilterTitles.pitchType}
              labelPlacement="start"
              placeholder="filter by pitch type"
              autocompleteRootClass={styles.autocompleteRoot}
            />
            <InputText
              control={control}
              name={PitchesModuleFilterKeys.pitchedTo}
              label={PitchesModuleFilterTitles.pitchedTo}
              placeholder="filter by pitched to"
            />
            <InputText
              control={control}
              name={PitchesModuleFilterKeys.pitchedBy}
              label={PitchesModuleFilterTitles.pitchedBy}
              placeholder="filter by pitched by"
            />
            <InputDateRange
              control={control}
              name={PitchesModuleFilterKeys.sent}
              label={PitchesModuleFilterTitles.sent}
              placeholder="filter by sent"
              onClick={() => setIsOpenDateRangePicker(true)}
            />
            <InputAutocomplete
              data={teams}
              control={control}
              name={PitchesModuleFilterKeys.team}
              label={PitchesModuleFilterTitles.team}
              labelPlacement="start"
              placeholder="filter by team"
              autocompleteRootClass={styles.autocompleteRoot}
            />
          </div>
          <DoubleBtnGroup
            className={styles.doubleBtnGroupAdditional}
            name1="Reset"
            name2="Filter"
            type1="reset"
            type2="submit"
            spacing={13}
          />
        </form>
      )}
    />
  );
};

export default PitchesFiltersPopup;
