import { Tooltip } from 'components/UI';

import styles from './TooltipCellRenderer.module.scss';

export interface TooltipCellRendererProps {
  value?: unknown;
}

const TooltipCellRenderer = ({ value }: TooltipCellRendererProps): JSX.Element => {
  const text = typeof value === 'number' || typeof value === 'string' ? value.toString() : '';
  return text ? (
    <Tooltip text={text}>
      <span className={styles.textOverflow}>{text}</span>
    </Tooltip>
  ) : (
    <></>
  );
};

export default TooltipCellRenderer;
