import { forwardRef, useImperativeHandle, useState } from 'react';
import { Grid } from '@material-ui/core';

import { ISelectOption } from 'components/SongsUpload/SelectCell';
import { InputAutocomplete } from 'components/UI';

import styles from './SongsUpload.module.scss';

import { getVersionOptions } from './data';

const VersionsForm = forwardRef((_, ref) => {
  const [version, setVersion] = useState<ISelectOption>();
  useImperativeHandle(ref, () => ({ version }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <InputAutocomplete
          data={getVersionOptions()}
          labelPlacement="top"
          label="Versions"
          value={version}
          name={`version` as never}
          onChangeInput={(data) => setVersion(data)}
          autocompleteRootClass={styles.versionsAutocompleteRoot}
          formLabelClass={styles.versionsAutocompleteLabel}
          multiple={false}
        />
      </Grid>
    </Grid>
  );
});

export default VersionsForm;
