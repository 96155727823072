import { Dispatch, SetStateAction } from 'react';
import { GridApi, RowNode } from 'ag-grid-community';
import classNames from 'classnames';

import { CircularProgressSpinner, SongStatuses } from 'components/Reusable';
import { DataGridColDef, SortableProps } from 'components/UI/DataGrid';

import { getFormattedDate, getSongVersion } from 'utils';

import styles from './Songs.module.scss';

import { ActionsCell, PlayIconCell, PlayIconHeaderCell, SongTitleCell, WritersCell } from '.';
import { ISongStatuses, IWriterItem, SongStatusTypes, SongsWorkTypes } from 'types';

export enum SongsTableFilterTypes {
  myUploads = 'uploaded_by_me',
  teamUploads = 'uploaded_by_my_team',
  all = 'all',
  archived = 'archived',
}

export interface ISongData {
  id: string;
  workCode: string;
  workType: string;
  title: string;
  type: SongsWorkTypes;
  statuses: ISongStatuses[];
  writers: string[];
  wcm: null | number;
  teamId: null | string;
  team: null | string;
  mediaId: string | null;
  mediaPath: string | null;
  mediaPlaybackPath: string | null;
  version: string;
  uploadedBy: string;
  uploadedByName: string;
  creationDate: string;
  modifiedDate: string;
  modifiedBy: string;
  modifiedByName: string;
  key: string;
  error?: string;
  status?: SongStatusTypes | '';
  workStatus?: SongStatusTypes;
  labelView?: boolean;
}

export enum ColumnFields {
  selection = 'selection',
  songTitle = 'title',
  version = 'version',
  id = 'workCode',
  writers = 'writers',
  status = 'status',
  team = 'team',
  uploadedBy = 'uploadedByName',
  archivedBy = 'archivedByName',
  creationDate = 'creationDate',
  wcm = 'wcm',
  modifiedBy = 'modifiedByName',
  modifiedOn = 'modifiedDate',
  empty = 'empty',
  actions = 'actions',
  filters = 'filters',
  tags = 'tags',
  labelView = 'labelView',
}

export enum ColumnHeaderNames {
  empty = '',
  actions = 'Actions',
  songTitle = 'Song Title',
  version = 'Version',
  id = 'ID',
  writers = 'Writer(s)',
  status = 'Status',
  team = 'Team',
  uploadedBy = 'Uploaded By',
  archivedBy = 'Archived By',
  creationDate = 'Creation Date',
  wcm = 'WCM%',
  modifiedBy = 'Modified By',
  modifiedOn = 'Modified On',
  tags = 'Tags',
  labelView = 'Label View',
}

export enum SongsModuleFilterTitles {
  songTitle = 'Song Title',
  team = 'Team',
  workId = 'Work ID',
  wcm = 'WCM Control',
  type = 'Type',
  uploadedBy = 'Uploaded By',
  writers = 'Writer(s)',
  uploadedOn = 'Uploaded On',
  status = 'Status',
  modifiedBy = 'Modified By',
  creationDate = 'Creation Date',
  modifiedOn = 'Modified On',
}

export enum SongsModuleFilterKeys {
  songTitle = 'songTitle',
  team = 'team',
  workId = 'workId',
  wcm = 'wcm',
  type = 'type',
  uploadedBy = 'uploadedBy',
  writers = 'writers',
  uploadedOn = 'uploadedOn',
  status = 'status',
  modifiedBy = 'modifiedBy',
  creationDate = 'creationDate',
  modifiedOn = 'modifiedOn',
}

export interface ISongsModuleNestedData {
  id: number | string;
  title: string;
  code?: string;
  searchKey?: SongSyncedBooleans;
  addCode?: string;
}

export type SongsModuleFilterDataType = {
  [K in SongsModuleFilterKeys]:
    | null
    | string
    | boolean
    | ISongsModuleNestedData
    | ISongsModuleNestedData[]
    | IWriterItem[];
};

export const defaultSongsModuleFilterData: SongsModuleFilterDataType = {
  [SongsModuleFilterKeys.songTitle]: '',
  [SongsModuleFilterKeys.team]: [],
  [SongsModuleFilterKeys.workId]: '',
  [SongsModuleFilterKeys.wcm]: '',
  [SongsModuleFilterKeys.type]: null,
  [SongsModuleFilterKeys.uploadedBy]: '',
  [SongsModuleFilterKeys.writers]: null,
  [SongsModuleFilterKeys.uploadedOn]: '', // Date
  [SongsModuleFilterKeys.status]: [],
  [SongsModuleFilterKeys.modifiedBy]: '',
  [SongsModuleFilterKeys.creationDate]: '', // Date
  [SongsModuleFilterKeys.modifiedOn]: '', // Date
};

export const hiddenSongsColumnsDesktopSmall: ColumnFields[] = [
  ColumnFields.uploadedBy,
  ColumnFields.creationDate,
  ColumnFields.wcm,
  ColumnFields.modifiedOn,
  ColumnFields.modifiedBy,
];

export const hiddenSongsColumnsTablet: ColumnFields[] = [
  ...hiddenSongsColumnsDesktopSmall,
  ColumnFields.team,
  ColumnFields.id,
  ColumnFields.status,
];

export const hiddenSongsColumnsMobile: ColumnFields[] = [
  ...hiddenSongsColumnsTablet,
  ColumnFields.selection,
  ColumnFields.version,
  ColumnFields.writers,
];

export const getColumnsData = (
  filterType?: SongsTableFilterTypes,
  setReloadHash?: Dispatch<SetStateAction<string | undefined>>
): DataGridColDef<ISongData>[] => [
  {
    field: ColumnFields.selection,
    headerName: ColumnHeaderNames.empty,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    maxWidth: 50,
    suppressColumnsToolPanel: true,
  },
  {
    field: ColumnFields.empty,
    headerName: ColumnHeaderNames.empty,
    headerClass: styles.playIconHeaderCell,
    headerComponentFramework: ({ api }: { api: GridApi }) => <PlayIconHeaderCell api={api} />,
    maxWidth: 50,
    cellClass: styles.cellContainer,
    cellRendererFramework: ({ data, api }) => <PlayIconCell data={data} api={api} />,
    suppressColumnsToolPanel: true,
  },
  {
    field: ColumnFields.songTitle,
    headerName: ColumnHeaderNames.songTitle,
    headerClass: styles.headerCell,
    cellClass: styles.cellContainer,
    minWidth: 150,
    suppressSizeToFit: true,
    resizable: true,
    cellRendererFramework: ({ data, api }) => (
      <SongTitleCell
        status={data?.status}
        title={data?.title}
        mediaId={data?.mediaId}
        writers={data?.writers}
        version={data?.version}
        api={api}
      />
    ),
    suppressColumnsToolPanel: true,
    ...SortableProps,
  },
  ...(filterType !== SongsTableFilterTypes.archived
    ? [
        {
          field: ColumnFields.version,
          headerName: ColumnHeaderNames.version,
          headerClass: styles.headerCell,
          cellClass: styles.cellContainer,
          maxWidth: 94,
          valueGetter: ({ data }: { data?: ISongData }) => getSongVersion(data?.version),
        },
        {
          field: ColumnFields.id,
          headerName: ColumnHeaderNames.id,
          headerClass: styles.headerCell,
          valueGetter: ({ data, node }: { data?: ISongData; node: RowNode | null }) =>
            node?.level === 1 ? data?.workCode : data?.workCode?.slice(0, 2) || '',
          maxWidth: 74,
        },
      ]
    : []),
  {
    field: ColumnFields.writers,
    headerName: ColumnHeaderNames.writers,
    headerClass: styles.headerCell,
    cellClass: styles.cellContainer,
    resizable: true,
    suppressSizeToFit: true,
    cellRendererFramework: ({ data }) => <WritersCell writers={data?.writers} />,
    minWidth: 180,
  },
  {
    field: ColumnFields.status,
    headerName: ColumnHeaderNames.status,
    headerClass: styles.headerCell,
    cellClass: styles.statusCellContainer,
    minWidth: 234,
    cellRendererFramework: ({ data }) => (data?.statuses?.length ? <SongStatuses statuses={data.statuses} /> : ''),
  },
  {
    field: ColumnFields.team,
    headerName: ColumnHeaderNames.team,
    headerClass: styles.headerCell,
    minWidth: 120,
    ...SortableProps,
  },
  ...(filterType === SongsTableFilterTypes.archived
    ? [
        {
          field: ColumnFields.archivedBy,
          headerName: ColumnHeaderNames.archivedBy,
          headerClass: styles.headerCell,
          minWidth: 200,
        },
      ]
    : []),
  {
    field: ColumnFields.uploadedBy,
    headerName: ColumnHeaderNames.uploadedBy,
    headerClass: styles.headerCell,
    minWidth: 200,
    hide: true,
  },
  {
    field: ColumnFields.creationDate,
    headerName: ColumnHeaderNames.creationDate,
    headerClass: styles.headerCell,
    valueGetter: ({ data }) => getFormattedDate(data.creationDate || ''),
    minWidth: 166,
    hide: true,
    ...SortableProps,
  },
  {
    field: ColumnFields.wcm,
    headerName: ColumnHeaderNames.wcm,
    headerClass: styles.headerCell,
    hide: true,
    maxWidth: 140,
    minWidth: 140,
    cellRendererFramework: ({ data }) =>
      data?.wcm ? <CircularProgressSpinner value={Number(Number(data.wcm).toFixed(4))} showText={true} /> : '',
  },
  {
    field: ColumnFields.modifiedBy,
    headerName: ColumnHeaderNames.modifiedBy,
    headerClass: styles.headerCell,
    minWidth: 200,
    hide: true,
  },
  {
    field: ColumnFields.modifiedOn,
    headerName: ColumnHeaderNames.modifiedOn,
    headerClass: styles.headerCell,
    valueGetter: ({ data }) => getFormattedDate(data.modifiedDate || ''),
    minWidth: 130,
    hide: true,
  },
  {
    field: ColumnFields.actions,
    headerName: ColumnHeaderNames.actions,
    pinned: 'right',
    flex: 0.2,
    suppressColumnsToolPanel: true,
    cellClass: classNames('actionsCell', styles.cellContainer),
    cellRendererFramework: ({ node, data }) => <ActionsCell node={node} data={data} setReloadHash={setReloadHash} />,
  },
  {
    field: 'filters',
    hide: true,
    suppressColumnsToolPanel: true,
  },
];

export const songTypes: ISongsModuleNestedData[] = [
  { id: 1, title: 'Work', code: 'ww' },
  { id: 2, title: 'Preliminary Work', code: 'pw' },
];

export enum SongSyncedBooleans {
  onHold = 'onHold',
  offHold = 'offHold',
  cut = 'cut',
  unreleasedCut = 'unreleasedCut',
}

export const songStatuses: ISongsModuleNestedData[] = [
  { id: 1, title: 'Hold', code: 'hold', searchKey: SongSyncedBooleans.onHold, addCode: 'HOLD' },
  { id: 2, title: 'Off Hold', code: 'off', searchKey: SongSyncedBooleans.offHold, addCode: 'OFF' },
  { id: 3, title: 'Cut', code: 'cut', searchKey: SongSyncedBooleans.cut, addCode: 'CUT' },
  {
    id: 4,
    title: 'Unreleased Cut',
    code: 'unreleased_cut',
    searchKey: SongSyncedBooleans.unreleasedCut,
    addCode: 'UNRELEASED_CUT',
  },
];

export const getFilteredColumnsData = (
  filterType?: SongsTableFilterTypes,
  setReloadHash?: Dispatch<SetStateAction<string | undefined>>,
  allowedColumns?: ColumnFields[]
) => {
  return getColumnsData(filterType, setReloadHash).filter((item) =>
    allowedColumns ? allowedColumns.includes(item.field as ColumnFields) : true
  );
};
