import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';

import { Dialog, DoubleBtnGroup } from 'components/Reusable';
import { NewTextField as TextField } from 'components/UI';

import Api from 'services/Api';
import { DEFAULT_ERROR_CONFIG, isEmpty } from 'utils';

import styles from './WriterForm.module.scss';

import { IWriterItem } from 'types';

export interface IWriterFormProps {
  item?: IWriterItem;
  onClose?: () => void;
  onSubmit?: (item: IWriterItem) => void;
}

const WriterForm = ({ item, onClose, onSubmit }: IWriterFormProps) => {
  const methods = useForm<IWriterItem>({ defaultValues: item || {}, mode: 'all' });
  const submitForm = async (values: IWriterItem) => {
    const result = await Api.addWriter(
      { firstName: values?.firstName || '', lastName: values?.lastName || '' },
      { errorPopupConfig: DEFAULT_ERROR_CONFIG }
    );
    result && onSubmit && onSubmit({ ...item, id: result?.id, title: result?.name });
  };
  return (
    <Dialog classes={{ paper: styles.paper, root: styles.root }} onClose={onClose} open>
      <DialogTitle className={styles.title}>{item?.id ? 'Edit Writer' : 'Add New Writer'}</DialogTitle>
      <DialogContent className={styles.content}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              control={methods.control}
              name="firstName"
              render={({ field, formState }) => (
                <TextField {...field} disabled={formState.isSubmitting} highlightValid label="First Name" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={methods.control}
              name="lastName"
              render={({ field, fieldState: { error }, formState }) => (
                <TextField
                  {...field}
                  disabled={formState.isSubmitting}
                  error={error?.message}
                  highlightValid
                  label="Last Name"
                  required
                />
              )}
              rules={{ validate: (v) => (isEmpty(v, true) ? 'Required Field.' : undefined) }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <DoubleBtnGroup
          isPending={methods.formState.isSubmitting}
          name1="Cancel"
          name2={item?.id ? 'Save Changes' : 'Add Writer'}
          onClick1={onClose}
          onClick2={methods.handleSubmit(submitForm)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default WriterForm;
