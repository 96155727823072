// Downsamples or Upsamples peaks data
export const resample = (sample: number[], itemWidth: number, spaceWidth: number, canvasWidth: number): number[] => {
  let peaks = [...sample];
  const targetItemsNumber = (canvasWidth + spaceWidth) / (itemWidth + spaceWidth);
  const sampleRate = Math.ceil(targetItemsNumber / peaks.length);
  // When sampleRate is greater than 1 upsampling is needed before applying largestTriangleThreeBuckets
  if (sampleRate > 1) {
    peaks = peaks.reduce((res, peak) => {
      for (let i = 1; i <= sampleRate; i += 1) {
        res.push(peak);
      }
      return res;
    }, [] as number[]);
  }
  return largestTriangleThreeBuckets(peaks, Math.floor(targetItemsNumber));
};

// Responsive wave
// - https://stackoverflow.com/questions/35842427/resample-algorithm-for-linear-array-json
// - https://github.com/Alexufo/Responsive-waveform
// https://github.com/Alexufo/Responsive-waveform/blob/master/largestTriangleThreeBuckets.js
const largestTriangleThreeBuckets = (data: number[], threshold: number): number[] => {
  // data is linear array [90,87,97...]
  const dataLength = data.length;
  if (threshold >= dataLength || threshold === 0) {
    return data; // Nothing to do
  }
  const sampled: number[] = [];
  let sampledIndex = 0;

  // Bucket size. Leave room for start and end data points
  const every = (dataLength - 2) / (threshold - 2);

  let a = 0; // Initially a is the first point in the triangle
  let maxAreaPoint = 0;
  let maxArea = 0;
  let area = 0;
  let nextA = 0;

  sampled[sampledIndex++] = data[a]; // Always add the first point

  for (let i = 0; i < threshold - 2; i++) {
    // Calculate point average for next bucket (containing c)
    let avgX = 0;
    let avgY = 0;
    let avgRangeStart = Math.floor((i + 1) * every) + 1;
    let avgRangeEnd = Math.floor((i + 2) * every) + 1;
    avgRangeEnd = avgRangeEnd < dataLength ? avgRangeEnd : dataLength;

    const avg_range_length = avgRangeEnd - avgRangeStart;

    for (; avgRangeStart < avgRangeEnd; avgRangeStart++) {
      avgX += avgRangeStart;
      avgY += data[avgRangeStart] * 1; // * 1 enforces Number (value may be Date)
    }

    avgX /= avg_range_length;
    avgY /= avg_range_length;

    // Get the range for this bucket
    let rangeOffs = Math.floor((i + 0) * every) + 1;
    const rangeTo = Math.floor((i + 1) * every) + 1;

    // Point a
    const pointAX = a * 1; // enforce Number (value may be Date)
    const pointAY = data[a] * 1;

    maxArea = area = -1;

    for (; rangeOffs < rangeTo; rangeOffs++) {
      // Calculate triangle area over three buckets
      area = Math.abs((pointAX - avgX) * (data[rangeOffs] - pointAY) - (pointAX - rangeOffs) * (avgY - pointAY)) * 0.5;
      if (area > maxArea) {
        maxArea = area;
        maxAreaPoint = data[rangeOffs];
        nextA = rangeOffs; // Next a is this b
      }
    }

    sampled[sampledIndex++] = maxAreaPoint; // Pick this point from the bucket
    a = nextA; // This a is the next a (chosen b)
  }

  sampled[sampledIndex++] = data[dataLength - 1]; // Always add last
  return sampled;
};
