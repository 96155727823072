import { Tooltip } from 'components/UI';

import globalStyles from 'styles/modules/Global.module.scss';

interface IWritersCellProps {
  writers?: string[];
}

const WritersCell = ({ writers }: IWritersCellProps) => {
  return (
    <Tooltip text={writers?.join(' / ') || ''}>
      <span className={globalStyles.textEllipsis}>{writers?.join(' / ') || '-'}</span>
    </Tooltip>
  );
};

export default WritersCell;
