import { useEffect, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import History from 'history';

import { ConfirmDialogPopup } from 'components/Popups';
import { SongsSearchTypes } from 'components/Popups/DesktopSearch/data';
import { Tab, Tabs } from 'components/Reusable';
import SongsUpload, { ISongsUploadRef } from 'components/SongsUpload';

import { useMemoSelector, useMount } from 'hooks';
import {
  getSongsModuleMyFiltersData,
  getUserId,
  getUserPermissions,
  getUserTeamIds,
  setSearchData,
  setSongsModule,
} from 'store';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Songs.module.scss';

import { SongsDropzone, SongsHeader, SongsTable } from './';
import { SongsTableFilterTypes } from './data';

const Songs = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [draftExpanded, setDraftExpanded] = useState(false);
  const [locationToConfirm, setLocationToConfirm] = useState<History.Location | string | null>(null);
  const [reloadHash, setReloadHash] = useState<string | undefined>();
  const [tabToConfirm, setTabToConfirm] = useState<SongsTableFilterTypes | null>(null);

  const { userId, userTeamIds, myFilterData, permissions } = useMemoSelector((state) => ({
    userId: getUserId(state),
    userTeamIds: getUserTeamIds(state),
    myFilterData: getSongsModuleMyFiltersData(state),
    permissions: getUserPermissions(state),
  }));

  const isLocationConfirmedRef = useRef(false);
  const isTabConfirmedRef = useRef(false);
  const songsUploadRef = useRef<ISongsUploadRef>(null);

  const onClickTab = (tab: SongsTableFilterTypes): void => {
    const newTab = permissions.viewSongsTabs ? tab : SongsTableFilterTypes.all;
    if (!isTabConfirmedRef.current && songsUploadRef.current?.checkHasUnsavedChanges()) {
      setTabToConfirm(newTab);
      return;
    }
    isTabConfirmedRef.current = false;
    const myFilter = {
      filterType: newTab,
      ...(SongsTableFilterTypes.myUploads === newTab && { userId }),
      ...([SongsTableFilterTypes.teamUploads, SongsTableFilterTypes.all].includes(newTab) && { teamIds: userTeamIds }),
    };
    batch(() => {
      dispatch(setSongsModule({ myFilter }));
      dispatch(setSearchData({ searchType: SongsSearchTypes.songSearch }));
    });
  };

  const onLeaveCancel = (): void => {
    setLocationToConfirm(null);
    setTabToConfirm(null);
  };

  const onLeaveSubmit = (): void => {
    setLocationToConfirm(null);
    setTabToConfirm(null);
    // Allow to change location even only tab is confirmed, because the tab name should be updated in the URL as well.
    isLocationConfirmedRef.current = true;
    if (locationToConfirm) {
      history.push(locationToConfirm);
    }
    if (tabToConfirm) {
      isTabConfirmedRef.current = true;
      onClickTab(tabToConfirm);
    }
  };

  const promptMessageHandler = (location: History.Location): boolean => {
    if (!isLocationConfirmedRef.current && songsUploadRef.current?.checkHasUnsavedChanges()) {
      setLocationToConfirm(location);
      return false;
    }
    isLocationConfirmedRef.current = false;
    return true;
  };

  useMount(() => {
    onClickTab(
      permissions.viewSongsTabs
        ? ((location.hash.toString().replace('#tab=', '') || SongsTableFilterTypes.all) as SongsTableFilterTypes)
        : SongsTableFilterTypes.all
    );
  });

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent): void => {
      if (songsUploadRef.current?.checkHasUnsavedChanges()) {
        e.preventDefault();
      }
    };
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [songsUploadRef.current?.checkHasUnsavedChanges]);

  return (
    <div className={classNames(styles.songs, globalStyles.wrapper)}>
      <SongsHeader />
      {permissions.viewSongsTabs && (
        <Tabs
          className={styles.songTabs}
          contentClassName={styles.songTabsContent}
          onChange={(tab) => onClickTab(tab as SongsTableFilterTypes)}
          selectedTab={myFilterData.filterType}
        >
          <Tab label="ALL" value={SongsTableFilterTypes.all}>
            <SongsTable isSongsPage reloadHash={reloadHash} setReloadHash={setReloadHash} />
          </Tab>
          <Tab label="MY UPLOADS" value={SongsTableFilterTypes.myUploads}>
            <SongsDropzone onDrop={() => setDraftExpanded(true)} />
            <span className={styles.hideInMobile}>
              <h2 className={styles.heading}>Draft Uploads</h2>
              <SongsUpload
                expanded={draftExpanded}
                onExpand={setDraftExpanded}
                // Note: remove after latency issue fix from BE
                onSave={() => setTimeout(() => setReloadHash(new Date().toISOString()), 1000)}
                ref={songsUploadRef}
              />
            </span>
            <SongsTable isSongsPage reloadHash={reloadHash} setReloadHash={setReloadHash} title="Saved Uploads" />
            <ConfirmDialogPopup
              description="Changes that you made may not be saved."
              onCancel={onLeaveCancel}
              onSubmit={onLeaveSubmit}
              open={!!locationToConfirm || !!tabToConfirm}
              submitText="Leave"
              title="Are you sure you want to leave the page?"
            />
            <Prompt message={promptMessageHandler} when />
          </Tab>
          <Tab label="TEAM UPLOADS" value={SongsTableFilterTypes.teamUploads}>
            <SongsTable isSongsPage reloadHash={reloadHash} setReloadHash={setReloadHash} />
          </Tab>
          <Tab hideInMobile label="ARCHIVED" value={SongsTableFilterTypes.archived}>
            <SongsTable isSongsPage reloadHash={reloadHash} setReloadHash={setReloadHash} />
          </Tab>
        </Tabs>
      )}
      {permissions.viewAllSongs && <SongsTable isSongsPage reloadHash={reloadHash} setReloadHash={setReloadHash} />}
    </div>
  );
};

export default Songs;
