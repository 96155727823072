import classNames from 'classnames';

import styles from './ProgressBar.module.scss';

export interface IProgressBarProps extends React.PropsWithChildren<unknown> {
  className?: string;
  color?: string;
  curcle?: boolean;
  /**
   * In percent
   */
  height?: string;
  progress: number;
  /**
   * For curcle only
   */
  strokeWidth?: number;
  width?: string;
}

const curcleRender = ({ className, color, progress, strokeWidth }: IProgressBarProps) => {
  const strokeDasharray = 126.92;
  const strokeDashoffset = strokeDasharray - Math.round((progress * strokeDasharray) / 100);
  return (
    <svg className={classNames(styles.curcle, className)} style={{ color }} viewBox="22 22 44 44">
      <circle
        className={styles.curcleProgress}
        cx="44"
        cy="44"
        r="20.2"
        strokeWidth={strokeWidth || '4'}
        style={{ strokeDasharray, strokeDashoffset }}
      />
    </svg>
  );
};

const ProgressBar = (props: IProgressBarProps) => (
  <div
    className={classNames(styles.progressBar, props.className)}
    style={{
      height: props.height ? `${props.height}px` : undefined,
      width: props.width ? `${props.width}px` : undefined,
    }}
  >
    {props.curcle && curcleRender(props)}
    {!props.curcle && (
      <div
        className={classNames(styles.rect, props.className)}
        style={{ height: props.strokeWidth ? `${props.strokeWidth}px` : undefined }}
      >
        <div className={styles.rectProgress} style={{ backgroundColor: props.color, width: `${props.progress}%` }} />
      </div>
    )}
    {props.children && <div className={styles.content}>{props.children}</div>}
  </div>
);

export default ProgressBar;
