import DataGrid from './DataGrid';
export type {
  CellRendererProps,
  DataGridColDef,
  DataGridProps,
  FiltersModel,
  GetRowsHandler,
  GetRowsParams,
  RowNodeExt,
} from './IDataGrid';
export default DataGrid;

import arrowDownIcon from 'assets/arrow-down.svg';
import arrowUpIcon from 'assets/arrow-up.svg';
import sortIcon from 'assets/sort.svg';

export const SortableProps = {
  sortable: true,
  unSortIcon: true,
  icons: {
    sortAscending: `<img src=${arrowUpIcon} alt="sort-icon" />`,
    sortDescending: `<img src=${arrowDownIcon} alt="sort-icon" />`,
    sortUnSort: `<img src=${sortIcon} alt="sort-icon" />`,
  },
};

export const FilteringProps: { filter: 'customFilter'; suppressMenu: boolean } = {
  filter: 'customFilter',
  suppressMenu: true,
};

export const DefaultColDefConfigs = {
  flex: 1,
  minWidth: 100,
  suppressMovable: true,
};
