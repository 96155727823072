import { useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { Control, Controller, Path, RegisterOptions } from 'react-hook-form';
import { Chip, FormControlLabel, Popper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';

import { IAutocompleteClassnames } from 'components/Reusable/AutocompletesList/AutocompletesList';

import { capitalizeOnlyFirstChar } from 'utils';

import { ReactComponent as ArrowIcon } from 'assets/arrow.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import styles from './InputAutocomplete.module.scss';

export interface IInputAutocompleteProps<T, R> {
  data: T[];
  label?: string;
  placeholder?: string;
  name: Path<R>;
  control?: Control<R>;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  onChangeInput?: (data: T, name: Path<R>) => void;
  value?: NonNullable<T> | NonNullable<T>[] | null;
  disabled?: boolean;
  error?: boolean;
  multiple?: boolean;
  disableClearable?: boolean;
  required?: boolean;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  disablePortal?: boolean;
  onlyFirstCharCapitalized?: boolean;
  getOptionSelected?: (option: T, value: T) => boolean;
}

export interface IInputAutocompleteData {
  id: number | string;
  title: string;
}

const InputAutocomplete = <T extends IInputAutocompleteData, R>({
  label,
  data,
  name,
  placeholder,
  value,
  rules,
  control,
  labelPlacement,
  onChangeInput,
  formLabelClass,
  formRootClass,
  autocompleteRootClass,
  autocompleteInputRootClass,
  autocompleteInputRootReplaceClass,
  autocompleteInputClass,
  autocompleteEndAdornmentClass,
  autocompletePaperClass,
  autocompletePopperClass,
  autocompleteListboxClass,
  disabledClass,
  disabled,
  error,
  multiple = true,
  disableClearable = true,
  required,
  disablePortal = false,
  onlyFirstCharCapitalized = false,
  getOptionSelected,
}: IInputAutocompleteProps<T, R> & IAutocompleteClassnames): JSX.Element => {
  const classes = useMemo(
    () => ({
      endAdornment: autocompleteEndAdornmentClass || styles.endAdornment,
      inputRoot: autocompleteInputRootReplaceClass || classNames(styles.inputRoot, autocompleteInputRootClass),
      input: autocompleteInputClass || styles.input,
      listbox: classNames(styles.listbox, autocompleteListboxClass),
      popper: autocompletePopperClass || styles.popper,
      paper: autocompletePaperClass || styles.paper,
      root: classNames(autocompleteRootClass || styles.root, {
        [styles.error]: error,
        [styles.disabled]: !data.length || disabled,
      }),
      tag: styles.tag,
      clearIndicator: styles.clearIndicatorBtn,
    }),
    [
      autocompleteEndAdornmentClass,
      autocompleteInputClass,
      autocompleteInputRootClass,
      autocompleteInputRootReplaceClass,
      autocompleteListboxClass,
      autocompletePaperClass,
      autocompletePopperClass,
      autocompleteRootClass,
      data.length,
      disabled,
      error,
    ]
  );

  const renderTags = useCallback(
    (value: IInputAutocompleteData[], getTagProps) =>
      value.map((option: IInputAutocompleteData, index: number) => (
        <Chip deleteIcon={<DeleteIcon />} key={option.id} label={option.title} {...getTagProps({ index })} />
      )),
    []
  );

  const defaultGetOptionSelected = (option: T, value: T) => isEqual(option, value);

  return control ? (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          value={value}
          label={
            <>
              {label}
              {required && <span className={styles.required}>*</span>}
            </>
          }
          labelPlacement={labelPlacement}
          classes={{
            root: formRootClass || styles.formControl,
            label: formLabelClass || styles.formLabel,
            disabled: disabledClass,
          }}
          control={
            <Autocomplete
              getOptionSelected={getOptionSelected || defaultGetOptionSelected}
              onChange={(_, data) => onChange(data)}
              disableClearable={disableClearable}
              disabled={!data.length || disabled}
              popupIcon={<ArrowIcon />}
              PopperComponent={(props) => <Popper {...props} placement="bottom-end" disablePortal={disablePortal} />}
              multiple={multiple}
              options={data}
              disableCloseOnSelect={multiple}
              getOptionLabel={(option) =>
                option.title ? (onlyFirstCharCapitalized ? capitalizeOnlyFirstChar(option.title) : option.title) : ''
              }
              renderTags={renderTags}
              renderInput={(params) => (
                <TextField
                  placeholder={
                    (Array.isArray(value) && value.length) || (!Array.isArray(value) && value) ? '' : placeholder
                  }
                  {...params}
                  variant="standard"
                  autoComplete="off"
                />
              )}
              classes={classes}
            />
          }
        />
      )}
    />
  ) : (
    <FormControlLabel
      label={
        <>
          {label}
          {required && <span className={styles.required}>*</span>}
        </>
      }
      labelPlacement={labelPlacement}
      classes={{
        root: classNames(formRootClass, styles.formControl),
        label: formLabelClass || styles.formLabel,
        disabled: disabledClass,
      }}
      control={
        <Autocomplete
          value={value}
          onChange={(_, data) => onChangeInput && onChangeInput(data as T, name)}
          disableClearable={disableClearable}
          multiple={multiple}
          disabled={!data.length || disabled}
          popupIcon={<ArrowIcon />}
          PopperComponent={(props) => <Popper {...props} placement="bottom-end" disablePortal={disablePortal} />}
          options={data}
          getOptionSelected={getOptionSelected || defaultGetOptionSelected}
          getOptionLabel={(option) =>
            option.title ? (onlyFirstCharCapitalized ? capitalizeOnlyFirstChar(option.title) : option.title) : ''
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                (Array.isArray(value) && value.length) || (!Array.isArray(value) && value) ? '' : placeholder
              }
              variant="standard"
              autoComplete="off"
            />
          )}
          renderTags={renderTags}
          classes={classes}
        />
      }
    />
  );
};

export default InputAutocomplete;
