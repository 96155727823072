import { ReactComponent as CloseIcon } from 'assets/close.svg';
import styles from './DesktopSearch.module.scss';

interface ICloseBtnProps {
  onClick: () => void;
}

const CloseBtn = ({ onClick }: ICloseBtnProps): JSX.Element => {
  return (
    <button type="button" className={styles.closeBtn} onClick={onClick}>
      <CloseIcon />
    </button>
  );
};

export default CloseBtn;
