import classNames from 'classnames';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Pitches.module.scss';

const PitchViewsCell = ({ views }: { views?: number }) => {
  return <span className={classNames(styles.pitchesViewsCell, globalStyles.f11h13SuisseREG_gray)}>{views}</span>;
};

export default PitchViewsCell;
