import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PitchSongsColumnsFields, resultData } from 'components/PitchDetails/data';
import { PitchesStatusCell } from 'components/Pitches';
import { EditTooltip } from 'components/Reusable';
import { IGridApiNode } from 'components/SongDetails/data';
import { InputAutocomplete } from 'components/UI';

import Api from 'services/Api';
import { DEFAULT_ERROR_CONFIG } from 'utils';

import styles from './PitchDetails.module.scss';

interface IVersionsCellProps extends IGridApiNode {
  result?: string | null;
  mediaId?: string | null;
  isReadOnly?: boolean;
}

const ResultCell = ({ result, node, api, mediaId, isReadOnly }: IVersionsCellProps) => {
  const { id } = useParams<{ id: string }>();

  const defaultResult = useMemo(
    () => resultData.find((item) => item.title.toLowerCase() === result?.toLowerCase()),
    [result]
  );

  const [selectedResult, setSelectedResult] = useState(defaultResult);

  const onSubmitChangeResult = async () => {
    if (!selectedResult || !mediaId) return;

    const res = await Api.updatePitchManualResult(id, mediaId, selectedResult.title.toUpperCase(), {
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
    });

    if (!res) return;

    node?.setDataValue(PitchSongsColumnsFields.result, selectedResult?.title);

    setTimeout(() => {
      api.refreshCells({ force: true, columns: [PitchSongsColumnsFields.result] });
    }, 10);
  };

  return (
    <EditTooltip
      disableEditing={isReadOnly}
      Component={
        <InputAutocomplete
          multiple={false}
          data={resultData}
          name={'result' as never}
          disablePortal
          value={selectedResult}
          onChangeInput={setSelectedResult}
          formRootClass={styles.autocompleteLabelRoot}
        />
      }
      onCancel={() => setSelectedResult(defaultResult)}
      onSubmit={onSubmitChangeResult}
    >
      {defaultResult?.title ? (
        <PitchesStatusCell status={defaultResult?.title || ''} />
      ) : (
        <span style={{ width: 50, height: 50 }} />
      )}
    </EditTooltip>
  );
};

export default ResultCell;
