import classNames from 'classnames';

import { ReactComponent as PendingIcon } from 'assets/pending.svg';
import styles from './DoubleBtnGroup.module.scss';

interface IDoubleBtnGroupProps {
  name1?: string;
  name2?: string;
  className?: string;
  disabledClassName?: string;
  type1?: 'button' | 'submit' | 'reset';
  type2?: 'button' | 'submit' | 'reset';
  spacing?: number;
  onClick1?: () => void;
  onClick2?: () => void;
  isPending?: boolean;
  disabled?: boolean;
  pendingName2?: string;
  hideButton1?: boolean;
  hideButton2?: boolean;
  primary?: boolean;
  isRedDoneButton?: boolean;
  buttonSize?: 'xl';
}

const DoubleBtnGroup = ({
  className,
  disabledClassName = '',
  name1,
  name2,
  type1,
  type2,
  spacing = 0,
  onClick1,
  onClick2,
  disabled,
  isPending,
  pendingName2 = 'Saving...',
  hideButton1 = false,
  hideButton2 = false,
  buttonSize,
  primary,
  isRedDoneButton = false,
}: IDoubleBtnGroupProps): JSX.Element => {
  return (
    <div
      className={classNames(styles.doubleBtnGroup, className, {
        [styles.xl]: buttonSize === 'xl',
      })}
    >
      {!hideButton1 && (
        <button
          type={type1}
          className={classNames(styles.cancelBtn, {
            [styles.pending]: isPending,
          })}
          style={{ marginRight: `${spacing}px` }}
          onClick={onClick1}
        >
          {name1 || 'Cancel'}
        </button>
      )}
      {!hideButton2 && (
        <button
          type={type2}
          className={classNames(styles.confirmBtn, {
            [styles.primaryButton]: primary,
            [styles.pending]: isPending,
            [styles.disabled]: disabled,
            [disabledClassName]: disabled,
            [styles.redBtn]: isRedDoneButton,
          })}
          onClick={onClick2}
        >
          {isPending ? (
            <>
              <PendingIcon />
              {pendingName2}
            </>
          ) : (
            name2 || 'Save'
          )}
        </button>
      )}
    </div>
  );
};

export default DoubleBtnGroup;
