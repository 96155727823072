import React, { forwardRef, useState } from 'react';
import { FormControlLabel, FormControlLabelProps, Paper, PaperProps, TextField, Tooltip } from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';

import { useDidUpdate } from 'hooks';
import { isEmpty } from 'utils';

import { ReactComponent as ApproveIcon } from 'assets/approve-2.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/close-2.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import styles from './Autocomplete.module.scss';

import { IAutocompleteOption } from 'types';

export interface IAutocompleteProps {
  addNewButtonOnClick?: () => void;
  addNewButtonText?: string | JSX.Element;
  className?: string;
  defaultValue?: IAutocompleteOption | IAutocompleteOption[] | null;
  disableClearable?: boolean;
  disabled?: boolean;
  disableFilter?: boolean;
  error?: string;
  hideErrorIcon?: boolean;
  highlightValid?: boolean;
  Icon?: JSX.Element;
  label?: string | JSX.Element;
  labelClassName?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  multiple?: boolean;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChange?: (value: IAutocompleteOption | IAutocompleteOption[] | null) => void;
  onResolveSuggestions?: (text: string) => Promise<IAutocompleteOption[]>;
  options?: IAutocompleteOption[];
  placeholder?: string;
  renderOption?: (option: IAutocompleteOption) => JSX.Element;
  required?: boolean;
  resolveEmptyInput?: boolean;
  tagClassName?: string;
  value?: IAutocompleteOption | IAutocompleteOption[] | null;
  /**
   * Use with single mode only
   */
  valueIsArray?: boolean;
}

const useDebounce = <T,>(value: T, delay = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useDidUpdate(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};

const Autocomplete = (props: IAutocompleteProps, ref: React.Ref<unknown>) => {
  const [popup, setPopup] = useState<{
    inputValue: string;
    internalOpen?: boolean;
    open: boolean;
    options?: IAutocompleteOption[];
  }>({ inputValue: '', open: false });
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [value, setValue] = useState(props.value || props.defaultValue || (props.multiple ? [] : null));

  const disableClearable = typeof props.disableClearable === 'boolean' ? props.disableClearable : props.multiple;
  const empty = isEmpty(value);
  const hasRequiredOrErrorIcon = props.required || (props.error && !props.hideErrorIcon);
  const loading = popup.open && !props.options && !popup.options;
  const valid = props.highlightValid && !props.error && !empty;
  const debouncedInputValue = useDebounce(popup.inputValue);

  const onAddNewButtonOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.addNewButtonOnClick && props.addNewButtonOnClick();
  };

  const onChange = (
    _: React.ChangeEvent<Record<string, never>>,
    v: IAutocompleteOption | IAutocompleteOption[] | null
  ) => {
    setValue(v);
    const patchedValue = props.valueIsArray && !props.multiple ? (v ? [v as IAutocompleteOption] : []) : v;
    props.onChange && props.onChange(patchedValue);
  };

  useDidUpdate(() => setValue(props.value || (props.multiple ? [] : null)), [props.value]);
  useDidUpdate(() => {
    let mounted = true;
    const mountCheck = () => {
      mounted = false;
    };
    if (props.options || !popup.open || (!debouncedInputValue && !props.resolveEmptyInput)) return mountCheck;
    (async () => {
      try {
        const suggestions = props.onResolveSuggestions && (await props.onResolveSuggestions(debouncedInputValue));
        mounted && setPopup((prev) => ({ ...prev, options: suggestions || [] }));
      } catch (_) {
        mounted && setPopup((prev) => ({ ...prev, options: [] }));
      }
    })();
    return mountCheck;
  }, [debouncedInputValue, popup.open]);

  const PaperWrapper = (paperProps: PaperProps) => (
    <Paper {...paperProps} square>
      {paperProps.children}
      {props.addNewButtonText && !loading && (
        <>
          <hr className={styles.separator} />
          <button className={styles.addNewButton} onMouseDown={onAddNewButtonOnClick}>
            {props.addNewButtonText}
          </button>
        </>
      )}
    </Paper>
  );

  return (
    <FormControlLabel
      classes={{
        label: classNames(styles.label, props.labelClassName),
        labelPlacementStart: styles.labelPlacementStart,
        labelPlacementTop: styles.labelPlacementTop,
      }}
      className={props.className}
      control={
        <MuiAutocomplete
          ChipProps={{
            classes: { root: classNames(styles.chipRoot, props.tagClassName) },
            deleteIcon: <CloseIcon />,
          }}
          classes={{ paper: styles.paper }}
          className={classNames(styles.autocomplete, {
            [styles.hasRequiredOrErrorIcon]: hasRequiredOrErrorIcon,
            [styles.requiredAutocomplete]: props.required,
            [styles.clearableSelect]: !disableClearable,
            [styles.validBorder]: valid,
          })}
          disableClearable={disableClearable}
          disableCloseOnSelect={props.multiple}
          disabled={props.disabled}
          filterOptions={(options, s) =>
            props.disableFilter
              ? options
              : options.filter((o) => o.title.toLowerCase().indexOf(s.inputValue.toLowerCase()) > -1)
          }
          getOptionLabel={(option: IAutocompleteOption) => option.shortTitle || option.title || ''}
          getOptionSelected={(option: IAutocompleteOption, value: IAutocompleteOption) => option.id === value.id}
          inputValue={popup.inputValue}
          loading={loading}
          multiple={props.multiple}
          noOptionsText="Nothing found"
          onBlur={props.onBlur}
          onChange={onChange}
          onClose={() => setPopup((prev) => ({ ...prev, internalOpen: false, open: false, options: undefined }))}
          onInputChange={(_, v) =>
            setPopup((prev) => ({
              ...prev,
              inputValue: v,
              open: props.options ? prev.open : Boolean(prev.internalOpen && (v || props.resolveEmptyInput)),
              options: undefined,
            }))
          }
          onOpen={() =>
            setPopup((prev) => ({
              ...prev,
              internalOpen: true,
              open: props.options || popup.inputValue || props.resolveEmptyInput ? true : prev.open,
            }))
          }
          open={popup.open}
          options={props.options || popup.options || []}
          PaperComponent={PaperWrapper}
          popupIcon={props.Icon ? props.Icon : <ChevronDownIcon height="12" width="12" />}
          ref={ref}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <div className={styles.icons}>
                    {hasRequiredOrErrorIcon && !valid && (
                      <Tooltip
                        arrow
                        classes={{ arrow: styles.tooltipArrow, popper: styles.tooltipPopper, tooltip: styles.tooltip }}
                        onClose={() => setTooltipOpen(false)}
                        onOpen={() => setTooltipOpen(true)}
                        open={tooltipOpen && Boolean(props.error)}
                        placement="top-end"
                        title={props.error || ''}
                      >
                        {props.required ? (
                          <div
                            className={classNames(styles.icon, styles.requiredIcon, {
                              [styles.errorColor]: Boolean(props.error),
                            })}
                          />
                        ) : (
                          <WarningIcon className={classNames(styles.icon, styles.errorColor)} />
                        )}
                      </Tooltip>
                    )}
                    {valid && <ApproveIcon className={classNames(styles.icon, styles.validColor)} />}
                    {params.InputProps.endAdornment}
                  </div>
                ),
              }}
              error={Boolean(props.error)}
              name={props.name}
              placeholder={empty ? props.placeholder : undefined}
              variant="outlined"
            />
          )}
          renderOption={props.renderOption ? props.renderOption : (option) => option.title}
          value={value && Array.isArray(value) && !props.multiple ? value[0] || undefined : value || undefined}
        />
      }
      disabled={props.disabled}
      label={props.label}
      labelPlacement={props.labelPlacement || 'top'}
      value={value}
    />
  );
};

const AutocompleteWithRef = forwardRef(Autocomplete);

export default AutocompleteWithRef;
