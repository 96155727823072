import { IInputAutocompleteOption } from 'components/UI/InputAsyncAutocomplete';

import { UserStatusActions } from 'services/Api';
import { ConfirmPopupTypes } from 'store/reducers/general/types';

import { IAdminControlsNestedData } from 'types';

export interface IEditUserData {
  id: number;
  title: string;
  data?: IAdminControlsNestedData[];
  dataKey?: string;
  required?: boolean;
}

const editUserData: IEditUserData[] = [
  { id: 9, title: 'Email Address', dataKey: 'email' },
  { id: 10, title: 'First Name', dataKey: 'firstName' },
  { id: 11, title: 'Last Name', dataKey: 'lastName' },
  { id: 12, title: 'Territory', dataKey: 'defaultTerritory' },
  { id: 13, title: 'Company', dataKey: 'company' },
];

interface IConfirmPopupSetting {
  questionText: string;
  mainText: string;
  btnCancelText: string;
  btnDoneText: string;
  handleNotificationText: string;
  statusAction: UserStatusActions;
}

export type IConfirmPopupSettings = {
  [K in ConfirmPopupTypes]: IConfirmPopupSetting;
};

export interface IConfirmPopupState extends IConfirmPopupSetting {
  isOpen: boolean;
}

export const confirmPopupConfig: IConfirmPopupSettings = {
  [ConfirmPopupTypes.reject]: {
    questionText: 'Deny User?',
    mainText: 'They will no longer be able to log in',
    btnCancelText: 'Cancel',
    btnDoneText: 'Deny',
    handleNotificationText: 'User Denied',
    statusAction: UserStatusActions.reject,
  },
  [ConfirmPopupTypes.archive]: {
    questionText: 'Archive User?',
    mainText: 'They will no longer be able to log in',
    btnCancelText: 'Cancel',
    btnDoneText: 'Archive',
    handleNotificationText: 'User Archived',
    statusAction: UserStatusActions.archive,
  },
  [ConfirmPopupTypes.resend]: {
    questionText: 'Resend Setup Email?',
    mainText: 'This will send a new setup link to joey.whalen@warnerchappell.com email address',
    btnCancelText: 'Cancel',
    btnDoneText: 'Resend',
    handleNotificationText: 'Email Sent',
    statusAction: UserStatusActions.resend,
  },
  [ConfirmPopupTypes.restore]: {
    questionText: 'Restore User?',
    mainText: 'User will be able to access system',
    btnCancelText: 'Cancel',
    btnDoneText: 'Restore',
    handleNotificationText: 'User Restored',
    statusAction: UserStatusActions.restore,
  },
};

export interface IEditingData {
  role: string | null;
  team: string | null;
  teamAdmin: string | null;
  label?: IInputAutocompleteOption;
  subLabel?: IInputAutocompleteOption;
  company?: IInputAutocompleteOption;
}

export default editUserData;
