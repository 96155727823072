export enum UserStatuses {
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  APPROVED = 'APPROVED',
  // Note: okta status
  INACTIVE = 'INACTIVE',
}
