import { MouseEvent } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';

import styles from 'components/UI/SongMoreMenu/SongMoreMenu.module.scss';

import { useMemoSelector } from 'hooks';
import { getPlaylistFilterField, getUserId, getUserPermissions, getUserTeamIds } from 'store';

import { IPlaylistMoreButton, PlaylistActionButtonsTypes } from './data';

export interface IAnchorOrigin {
  horizontal: 'center' | 'left' | 'right' | number;
  vertical: 'bottom' | 'center' | 'top' | number;
}

interface IPlaylistMoreMenuProps {
  open: boolean;
  onClick: (event: MouseEvent<HTMLLIElement> | MouseEvent<HTMLAnchorElement>, type: PlaylistActionButtonsTypes) => void;
  onClose: () => void;
  anchorEl?: Element | null;
  anchorOrigin?: IAnchorOrigin;
  paperClassName?: string;
  isFavoriteCard?: boolean;
  createdBy?: string;
  teamId?: string;
}

const PlaylistMoreMenu = ({
  createdBy,
  teamId,
  open,
  anchorEl,
  anchorOrigin,
  onClose,
  onClick,
  paperClassName,
  isFavoriteCard,
}: IPlaylistMoreMenuProps) => {
  const { userId, teamIds, playlistFilterField, permissions } = useMemoSelector((state) => ({
    userId: getUserId(state),
    teamIds: getUserTeamIds(state),
    playlistFilterField: getPlaylistFilterField(state),
    permissions: getUserPermissions(state),
  }));
  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      classes={{ paper: classNames(styles.menuPaper, paperClassName), list: styles.menuList }}
    >
      {(permissions.playlistActions as IPlaylistMoreButton[])
        .filter((el: IPlaylistMoreButton) => {
          if (
            el.type === PlaylistActionButtonsTypes.archive &&
            (isFavoriteCard || createdBy !== userId || playlistFilterField.code === 'archived')
          ) {
            return false;
          } else if (el.type === PlaylistActionButtonsTypes.edit) {
            return (
              (isFavoriteCard || createdBy === userId || teamIds.includes(teamId || '')) &&
              playlistFilterField.code !== 'archived'
            );
          } else if (el.type === PlaylistActionButtonsTypes.delete) {
            return !isFavoriteCard && createdBy === userId && playlistFilterField.code === 'archived';
          } else if (el.type === PlaylistActionButtonsTypes.restore) {
            return !isFavoriteCard && createdBy === userId && playlistFilterField.code === 'archived';
          } else if ([PlaylistActionButtonsTypes.pitchPlaylist, PlaylistActionButtonsTypes.share].includes(el.type)) {
            return playlistFilterField.code !== 'archived';
          } else {
            return true;
          }
        })
        .map(({ type, title }: IPlaylistMoreButton) => (
          <MenuItem
            key={title}
            onClick={(e) => {
              onClick(e, type);
            }}
          >
            {title}
          </MenuItem>
        ))}
    </Menu>
  );
};

export default PlaylistMoreMenu;
