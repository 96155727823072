import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import styles from 'components/Popups/UploadPopup/UploadPopup.module.scss';
import Tooltip from 'components/UI/Tooltip';

import { useMemoSelector } from 'hooks';
import {
  dispatch as storeDispatch,
  getActiveTrackIsHaveMarkers,
  getDownloadPopupConfig,
  getIsExpandedPlaybar,
  getIsOpenSidebar,
  setDownloadPopupConfig,
} from 'store';
import { INITIAL_DOWNLOAD_POPUP_CONFIG } from 'utils';

import { ReactComponent as ApproveIcon } from 'assets/approve-2.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/close-2.svg';

import { DownloadStatuses } from './data';

export const preparingDownloadData = {
  open: true,
  title: 'Preparing Download',
  currentProcessText: 'In progress',
  status: DownloadStatuses.empty,
};

export const downloadingData = {
  ...preparingDownloadData,
  currentProcessText: 'Downloading',
  status: DownloadStatuses.done,
};

export const closeDownloadPopup = () => {
  storeDispatch(setDownloadPopupConfig(INITIAL_DOWNLOAD_POPUP_CONFIG));
};

const DownloadPopup = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const {
    isOpenSidebar,
    activeTrackIsHaveMarkers,
    isExpandedPlaybar,
    downloadPopupConfig: { open, title, statusText, currentProcessText, status },
  } = useMemoSelector((state) => ({
    isOpenSidebar: getIsOpenSidebar(state),
    activeTrackIsHaveMarkers: getActiveTrackIsHaveMarkers(state),
    isExpandedPlaybar: getIsExpandedPlaybar(state),
    downloadPopupConfig: getDownloadPopupConfig(state),
  }));

  const [expanded, setExpanded] = useState<boolean>(true);

  const onExpandClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const onCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setDownloadPopupConfig(INITIAL_DOWNLOAD_POPUP_CONFIG));
  };

  return open
    ? ReactDOM.createPortal(
        <div
          className={classNames(styles.popup, {
            [styles.sidebarMargin]: isOpenSidebar,
            [styles.popupWithMarkers]: activeTrackIsHaveMarkers,
            [styles.popupExpanded]: isExpandedPlaybar,
            [styles.popupWithMarkersAndExpanded]: activeTrackIsHaveMarkers && isExpandedPlaybar,
          })}
        >
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div>
              <button className={styles.headerButton} onClick={onExpandClick}>
                <ChevronDownIcon className={classNames(expanded && styles.rotate180)} height="18" width="18" />
              </button>
              <button className={styles.headerButton} onClick={onCloseClick}>
                <CloseIcon height="14" width="14" />
              </button>
            </div>
          </div>
          {expanded && (
            <div className={styles.details}>
              <div className={styles.items}>
                <div className={styles.itemCard}>
                  <div className={styles.itemTitleContainer}>
                    <Tooltip text={statusText}>
                      <div className={styles.itemTitle}>{statusText}</div>
                    </Tooltip>
                  </div>
                  <div className={styles.itemProgress}>{currentProcessText}</div>
                  <div className={styles.itemIconContainer}>
                    {status === DownloadStatuses.done && <ApproveIcon className={styles.successIcon} />}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>,
        document.body
      )
    : null;
};

export default DownloadPopup;
