import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { NotificationPopup } from 'components/Popups';
import MobileWrapper from 'containers/MobileWrapper';

import Api from 'services/Api';
import { DEFAULT_ERROR_CONFIG } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Contacts.module.scss';

import { ContactsForm, ContactsGrid, GroupDetailsHeader, GroupForm, IContact, IContactGroup } from '.';

const GroupDetails = (): JSX.Element => {
  const [contactsFormItem, setContactsFormItem] = useState<IContact>();
  const [details, setDetails] = useState<IContactGroup | null>(null);
  const [groupFormItem, setGroupFormItem] = useState<IContactGroup>();
  const [notification, setNotification] = useState<string>();
  const [reloadHash, setReloadHash] = useState<string>();
  const { id } = useParams<{ id: string }>();

  const onContactsFormSubmit = (values: IContact) => {
    setContactsFormItem(undefined);
    setNotification(contactsFormItem?.id ? 'Contact Updated' : 'Contact Created');
    groupFormItem
      ? setGroupFormItem((prev) => {
          const contacts = prev?.contacts?.slice() || [];
          values?.id && contacts.push({ id: values.id, title: values.name || '' });
          return { ...prev, contacts };
        })
      : setReloadHash(new Date().toISOString());
  };

  const onGroupFormSubmit = () => {
    setGroupFormItem(undefined);
    setReloadHash(new Date().toISOString());
  };

  const onTitleChange = async (title: string) => {
    const result = await Api.updateGroup({ id: details?.id, title }, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    if (result) {
      setNotification('Group Name Updated');
      setDetails(result);
    }
  };

  useEffect(() => {
    void Api.getGroupDetails(id, { errorPopupConfig: DEFAULT_ERROR_CONFIG }).then((details) =>
      setDetails(details || null)
    );
  }, [id]);

  return (
    <MobileWrapper>
      {details && (
        <div className={classNames(globalStyles.wrapper)}>
          <GroupDetailsHeader
            details={details}
            onAddContactsClick={() => setGroupFormItem(details)}
            onTitleChange={onTitleChange}
          />
          <h3 className={styles.secondaryTitle}>Contacts</h3>
          <ContactsGrid groupId={id} onEditContactClick={setContactsFormItem} reloadHash={reloadHash} />
          <NotificationPopup
            isOpen={Boolean(notification)}
            setIsOpen={() => setNotification(undefined)}
            text={notification || ''}
          />
          {contactsFormItem && (
            <ContactsForm
              item={contactsFormItem}
              onClose={() => setContactsFormItem(undefined)}
              onSubmit={onContactsFormSubmit}
            />
          )}
          {groupFormItem && (
            <GroupForm
              item={groupFormItem}
              onClose={() => setGroupFormItem(undefined)}
              onNewContactClick={() => setContactsFormItem({})}
              onSubmit={onGroupFormSubmit}
              readOnlyGroup
            />
          )}
        </div>
      )}
    </MobileWrapper>
  );
};

export default GroupDetails;
