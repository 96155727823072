import { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import moment from 'moment';

import { DatePickerPopup } from 'components/Popups';
import { ISongsModuleNestedData, songStatuses, SongSyncedBooleans } from 'components/Songs/data';
import { InputAutocomplete, InputDateRange, InputText, SwitchBtn } from 'components/UI';

import { useMemoSelector, useMount, useUnMount } from 'hooks';
import Api from 'services/Api';
import { getSongDetailsAddPopupConfig } from 'store';
import { dateFormat, DEFAULT_ERROR_CONFIG, getFormattedDate } from 'utils';

import styles from './SongDetails.module.scss';

import { IAddPopupProps } from 'types';

export enum AddStatusFields {
  artist = 'artist',
  status = 'status',
  subStatus = 'subStatus',
  producer = 'producer',
  label = 'label',
  internalContact = 'internalContact',
  externalContact = 'externalContact',
  date = 'date',
  released = 'released',
}

export interface ISongSubStatuses {
  id: number;
  code: string;
  title: string;
}

export const songSubStatuses: ISongSubStatuses[] = [
  { id: 1, title: 'Hold 1', code: 'HOLD_1' },
  { id: 2, title: 'Hold 2', code: 'HOLD_2' },
  { id: 3, title: 'Hold 3', code: 'HOLD_3' },
  { id: 4, title: 'Hold 4', code: 'HOLD_4' },
  { id: 5, title: 'Hold 5', code: 'HOLD_5' },
  { id: 6, title: 'Co-Writer Hold', code: 'HOLD_CO_WRITER' },
];

interface IAddStatusData {
  [AddStatusFields.artist]: string;
  [AddStatusFields.status]: ISongsModuleNestedData | null;
  [AddStatusFields.subStatus]: ISongSubStatuses | null;
  [AddStatusFields.producer]: string;
  [AddStatusFields.label]: string;
  [AddStatusFields.internalContact]: string;
  [AddStatusFields.externalContact]: string;
  [AddStatusFields.date]: string;
  [AddStatusFields.released]: boolean;
}

export const defaultAddStatusData: IAddStatusData = {
  [AddStatusFields.artist]: '',
  [AddStatusFields.status]: null,
  [AddStatusFields.subStatus]: null,
  [AddStatusFields.producer]: '',
  [AddStatusFields.label]: '',
  [AddStatusFields.internalContact]: '',
  [AddStatusFields.externalContact]: '',
  [AddStatusFields.date]: '',
  [AddStatusFields.released]: false,
};

const AddStatus = ({ addRef, setBtnDisabled }: IAddPopupProps) => {
  const { id } = useParams<{ id: string }>();

  const { editValues, isEdit } = useMemoSelector(getSongDetailsAddPopupConfig);

  const { control, watch, setValue, reset } = useForm<IAddStatusData>({ defaultValues: defaultAddStatusData });

  const [subStatuses, setSubStatuses] = useState<ISongSubStatuses[]>([]);
  const [openDateRangePicker, setOpenDateRangePicker] = useState<boolean>(false);

  const [artist, status, subStatus] = watch([
    AddStatusFields.artist,
    AddStatusFields.status,
    AddStatusFields.subStatus,
  ]);

  const isStatusHold = (status as ISongsModuleNestedData)?.searchKey === SongSyncedBooleans.onHold;
  const isStatusNotCut = (status as ISongsModuleNestedData)?.searchKey !== SongSyncedBooleans.cut;

  useMount(() => {
    if (!isEdit || !editValues) return;

    reset({
      [AddStatusFields.artist]: editValues.artist as string,
      [AddStatusFields.status]: songStatuses.find((item) => item.addCode === editValues.status) || null,
      [AddStatusFields.subStatus]:
        editValues.status === songStatuses.find((item) => item.searchKey === SongSyncedBooleans.onHold)?.addCode
          ? songSubStatuses.find((item) => item.code === editValues.subStatus)
          : null,
      [AddStatusFields.producer]: (editValues.producer as string) || '',
      [AddStatusFields.label]: (editValues.label as string) || '',
      [AddStatusFields.released]: !!editValues.released,
      [AddStatusFields.date]: getFormattedDate(editValues.statusDate as string),
      [AddStatusFields.internalContact]: (editValues?.contactInternal as string) || '',
      [AddStatusFields.externalContact]: (editValues?.contactExternal as string) || '',
    });
  });

  const onHandleSubmit = () => {
    const { status, artist, subStatus, producer, label, internalContact, externalContact, date, released } = watch();

    const addBody = {
      status: (status as unknown as ISongsModuleNestedData)?.addCode,
      artist,
      label,
      producer,
      released,
      date,
      subStatus: (subStatus as unknown as ISongSubStatuses)?.code,
      extContact: externalContact,
      contact: internalContact,
    };

    if (isEdit && editValues) {
      return Api.updateSongStatus(id, editValues.id as string, addBody, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    }

    return Api.addSongStatus(id, addBody, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
  };

  useImperativeHandle(addRef, () => ({ onHandleSubmit }));

  const onSubmitSingleDate = useCallback(
    (date?: string) => {
      setValue(AddStatusFields.date, date || '');
      setOpenDateRangePicker(false);
    },
    [setValue]
  );

  const onCloseDateRangePicker = useCallback(() => {
    if (openDateRangePicker) {
      setOpenDateRangePicker(false);
    }
  }, [openDateRangePicker]);

  useEffect(() => {
    if (isStatusNotCut) {
      setValue(AddStatusFields.producer, (editValues?.producer as string) || '');
      setValue(AddStatusFields.released, (editValues?.released as boolean) || false);
    }

    if (!isStatusHold) {
      setValue(AddStatusFields.subStatus, null);
      subStatuses.length && setSubStatuses([]);
      return;
    }

    setSubStatuses(songSubStatuses);
  }, [editValues?.producer, editValues?.released, isStatusHold, isStatusNotCut, setValue, subStatuses.length]);

  useEffect(() => {
    setBtnDisabled(!artist || !status || (isStatusHold && !subStatus));
  }, [artist, status, setBtnDisabled, isStatusHold, subStatus]);

  useUnMount(() => setBtnDisabled(false));

  return (
    <div className={styles.addStatus}>
      <DatePickerPopup
        isSingleDayPicker
        open={openDateRangePicker}
        TransitionComponent={Fade}
        onClose={onCloseDateRangePicker}
        date={watch(AddStatusFields.date)}
        onSubmitSingleDate={onSubmitSingleDate}
      />
      <div className={styles.addStatusContainer}>
        <InputText control={control} name={AddStatusFields.artist} label="Artist" labelPlacement="top" required />
        <InputAutocomplete
          control={control}
          name={AddStatusFields.status}
          data={songStatuses}
          multiple={false}
          label="Status"
          labelPlacement="top"
          required
        />
        {!!subStatuses.length && (
          <InputAutocomplete
            control={control}
            name={AddStatusFields.subStatus}
            data={subStatuses}
            multiple={false}
            required={isStatusHold}
            label="Sub-Status"
            labelPlacement="top"
          />
        )}
        {!isStatusNotCut && (
          <>
            <SwitchBtn
              control={control}
              name={AddStatusFields.released}
              formRootClassName={styles.addStatusSwitchBtn}
              label="Released"
              labelPlacement="top"
            />
            <InputText control={control} name={AddStatusFields.producer} label="Producer" labelPlacement="top" />
          </>
        )}

        <InputText control={control} name={AddStatusFields.label} label="Label" labelPlacement="top" />
        <InputText
          control={control}
          name={AddStatusFields.internalContact}
          label="Internal Contact"
          labelPlacement="top"
        />
        <InputText
          control={control}
          name={AddStatusFields.externalContact}
          label="External Contact"
          labelPlacement="top"
        />
        <InputDateRange
          control={control}
          name={AddStatusFields.date}
          label="Date"
          labelPlacement="top"
          onClick={() => setOpenDateRangePicker(true)}
          isValid={(value) => moment(value, dateFormat, true).isValid()}
        />
      </div>
    </div>
  );
};

export default AddStatus;
