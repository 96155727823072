import { forwardRef } from 'react';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';

import styles from './TextField.module.scss';

export interface ITextFieldProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  iconPlaceWidth?: number;
  iconRender?: string | JSX.Element;
  label?: string | JSX.Element;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  /**
   * Works with number type only
   */
  max?: number;
  maxLength?: number;
  /**
   * Works with number type only
   */
  min?: number;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (value: string | number | null) => void;
  placeholder?: string;
  required?: boolean;
  type?: string;
  value?: string | number | null;
}

const TextField = forwardRef(
  (
    {
      className,
      disabled,
      error,
      iconPlaceWidth,
      iconRender,
      label,
      labelPlacement = 'top',
      max,
      maxLength = 80,
      min,
      name,
      onBlur,
      onChange,
      placeholder,
      required,
      type,
      value,
    }: ITextFieldProps,
    ref: React.LegacyRef<HTMLInputElement>
  ) => {
    const onInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      let v: string | number | null = target.value;
      if (typeof maxLength === 'number' && v) v = v.substring(0, maxLength);
      if (type === 'number') {
        v = v ? parseFloat(v) : null;
        v = typeof v === 'number' && isNaN(v) ? null : v;
        if (typeof v === 'number') {
          if (typeof min === 'number' && v < min) v = min;
          if (typeof max === 'number' && v > max) v = max;
        }
      }
      onChange && onChange(v);
    };
    return (
      <FormControlLabel
        classes={{
          label: styles.label,
          labelPlacementTop: styles.labelPlacementTop,
        }}
        className={className}
        control={
          <div className={styles.textField}>
            <input
              className={classNames(styles.input, { [styles.textFieldWithIcon]: iconRender, [styles.error]: error })}
              disabled={disabled}
              name={name}
              onBlur={onBlur}
              onChange={onInputChange}
              onKeyDown={(e) => e.stopPropagation()} // Prevent: cursor locked in data grid
              placeholder={placeholder}
              ref={ref}
              style={{ paddingRight: iconPlaceWidth ? `${iconPlaceWidth}px` : undefined }}
              type={type || 'text'}
              value={typeof value === 'undefined' || value === null ? '' : value}
            />
            {iconRender && <div className={classNames(styles.textFieldIcon, error && styles.error)}>{iconRender}</div>}
          </div>
        }
        label={
          <>
            {label}
            {required && <span className={styles.required}>*</span>}
          </>
        }
        labelPlacement={labelPlacement}
      />
    );
  }
);

export default TextField;
