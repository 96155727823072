import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'components/Reusable';
import { Tooltip } from 'components/UI';
import { RowNodeExt } from 'components/UI/DataGrid';

import { useMemoSelector } from 'hooks';
import { getUserPermissions, setPitchesModule } from 'store';
import { defaultPitchesConfirmPopupConfig, defaultPitchesExpirationConfig } from 'utils';

import { ReactComponent as ArrowDownIcon } from 'assets/arrow.svg';
import { ReactComponent as ExpireIcon } from 'assets/expire.svg';
import { ReactComponent as MoreIcon } from 'assets/more.svg';
import { ReactComponent as RejectIcon } from 'assets/reject.svg';
import { ReactComponent as ResendIcon } from 'assets/resend.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-2.svg';
import styles from './Pitches.module.scss';

import { ConfirmPopupTypes, PitchStatuses, PitchTypes } from './data';

interface IActionsCellProps {
  type?: string;
  title?: string;
  expirationDate?: string;
  id?: string;
  status?: string;
  node: RowNodeExt;
}

const ActionsCell = ({ id, type, title, expirationDate, status, node }: IActionsCellProps) => {
  const dispatch = useDispatch();
  const permissions = useMemoSelector(getUserPermissions);

  const isManualPitch = type === PitchTypes.manual;
  const isDeactivatedLink = PitchStatuses.deactivated === status;
  const isExpiredLink = PitchStatuses.expired === status;

  const onClickExpand = (e: MouseEvent) => {
    e.stopPropagation();

    dispatch(
      setPitchesModule({
        expirationConfig: defaultPitchesExpirationConfig,
        confirmPopupConfig: defaultPitchesConfirmPopupConfig,
      })
    );

    node.setDetailsRow(!node.expanded, 70, true);
  };

  const onClickAction = (e: MouseEvent, type: ConfirmPopupTypes, questionText: string) => {
    e.stopPropagation();

    dispatch(setPitchesModule({ expirationConfig: defaultPitchesExpirationConfig }));

    id &&
      dispatch(
        setPitchesModule({
          confirmPopupConfig: { type, questionText, id, rowId: node.id?.replace('detail_', '') as string },
        })
      );
  };

  const onClickExpiration = (e: MouseEvent) => {
    e.stopPropagation();

    dispatch(setPitchesModule({ confirmPopupConfig: defaultPitchesConfirmPopupConfig }));

    id &&
      dispatch(
        setPitchesModule({
          expirationConfig: { isOpenExpirationPopup: true, expirationDate: (expirationDate || '') as string, id },
        })
      );
  };

  return !permissions.pitchActions ? null : (
    <div className={styles.actionsCellContainer}>
      {isManualPitch ? (
        <Tooltip text="Delete Pitch">
          <TrashIcon
            onClick={(e: MouseEvent<SVGSVGElement>) =>
              onClickAction(e, ConfirmPopupTypes.delete, `Delete “${title || ''}”?`)
            }
            className={styles.actionButton}
          />
        </Tooltip>
      ) : !isDeactivatedLink ? (
        <>
          <Tooltip text="Change Link Expiration">
            <ExpireIcon onClick={onClickExpiration} className={styles.actionButton} />
          </Tooltip>
          {!isExpiredLink && (
            <Tooltip text="Deactivate Link">
              <RejectIcon
                onClick={(e) =>
                  onClickAction(e, ConfirmPopupTypes.deactivate, `Deactivate Pitch Link “${title || ''}”?`)
                }
                className={styles.actionButton}
              />
            </Tooltip>
          )}
        </>
      ) : (
        <Tooltip text="Reactivate Link">
          <ResendIcon
            onClick={(e) => onClickAction(e, ConfirmPopupTypes.reactivate, `Reactivate pitch “${title || ''}”?`)}
            className={styles.actionButton}
          />
        </Tooltip>
      )}
      <ArrowDownIcon onClick={onClickExpand} className={styles.expandIcon} />
      <Button
        className={styles.moreMenu}
        Icon={<MoreIcon className={styles.moreIcon} />}
        menuItems={[
          ...(isManualPitch
            ? [
                {
                  onClick: (e: MouseEvent) => onClickAction(e, ConfirmPopupTypes.delete, `Delete “${title || ''}”?`),
                  text: 'Delete Pitch',
                },
              ]
            : !isDeactivatedLink
            ? [
                {
                  onClick: onClickExpiration,
                  text: 'Change Link Expiration',
                },
                ...(!isExpiredLink
                  ? [
                      {
                        onClick: (e: MouseEvent) =>
                          onClickAction(e, ConfirmPopupTypes.deactivate, `Deactivate Pitch Link “${title || ''}”?`),
                        text: 'Deactivate Link',
                      },
                    ]
                  : []),
              ]
            : [
                {
                  onClick: (e: MouseEvent) =>
                    onClickAction(e, ConfirmPopupTypes.reactivate, `Reactivate pitch “${title || ''}”?`),
                  text: 'Reactivate Link',
                },
              ]),
        ]}
      />
    </div>
  );
};

export default ActionsCell;
