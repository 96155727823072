import { Tooltip } from 'components/UI';

import globalStyles from 'styles/modules/Global.module.scss';

interface ILabelsCellProps {
  label?: string;
}

const LabelsCell = ({ label }: ILabelsCellProps) => {
  return (
    <Tooltip text={label || ''}>
      <span className={globalStyles.f13h16SuisseREG_grayDarker}>{label}</span>
    </Tooltip>
  );
};

export default LabelsCell;
