import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dialog } from '@material-ui/core';
import classNames from 'classnames';

import { IShareToContacts } from 'components/Popups/ShareSongPopup/data';
import { DoubleBtnGroup, ToggleBtnGroup } from 'components/Reusable';
import { InputAsyncAutocomplete, TextEditor } from 'components/UI';
import { IInputAutocompleteOption } from 'components/UI/InputAsyncAutocomplete';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import {
  getHoldRequestFormPopupConfig,
  getUserPermissions,
  setHoldRequest,
  setMainConfirmPopup,
  setMainNotification,
} from 'store';
import { DEFAULT_ERROR_CONFIG, DEFAULT_HOLD_REQUEST_CONFIG, DEFAULT_MAIN_CONFIRM_POPUP } from 'utils';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './HoldRequestFormPopup.module.scss';

interface IHoldValues {
  label: null | IInputAutocompleteOption;
  artist: null | IInputAutocompleteOption;
  isSomeoneElse: boolean;
  contact: null | IShareToContacts;
  message: string;
}

const defaultHoldValues: IHoldValues = {
  label: null,
  artist: null,
  isSomeoneElse: false,
  contact: null,
  message: '',
};

const toggleButtons = [
  { title: 'Myself', value: false },
  { title: 'Someone else', value: true },
];

const HoldRequestFormPopup = () => {
  const dispatch = useDispatch();

  const { control, watch, reset, handleSubmit, setValue } = useForm<IHoldValues>({
    mode: 'onChange',
    defaultValues: defaultHoldValues,
  });

  const {
    permissions,
    holdRequestFromPopupConfig: { isOpen, workId },
  } = useMemoSelector((state) => ({
    permissions: getUserPermissions(state),
    holdRequestFromPopupConfig: getHoldRequestFormPopupConfig(state),
  }));

  const handleCloseHoldRequestFormPopup = () => {
    reset(defaultHoldValues);
    dispatch(setHoldRequest({ form: DEFAULT_HOLD_REQUEST_CONFIG.form }));
  };

  const onSubmitHoldRequest = async (values: IHoldValues) => {
    const { artist, label, contact, message, isSomeoneElse } = values;

    const body = {
      artist: artist?.title || null,
      label: label?.title || null,
      contact: (isSomeoneElse && contact?.name) || null,
      message,
    };

    const res = await Api.workHoldRequest(workId, body, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    dispatch(setMainConfirmPopup(DEFAULT_MAIN_CONFIRM_POPUP));
    handleCloseHoldRequestFormPopup();

    if (!res) return;
    dispatch(setMainNotification('Hold Request Sent'));
  };

  const onClickSend = (values: IHoldValues) => {
    dispatch(
      setMainConfirmPopup({
        isOpen: true,
        disablePortal: true,
        questionText: 'Send hold request?',
        mainText: 'Your hold request will be sent to the appropriate WCM team member.',
        btnDoneText: 'Request Hold',
        onClickSubmit: () => onSubmitHoldRequest(values),
      })
    );
  };

  const isSomeoneElse = watch('isSomeoneElse');

  return (
    <Dialog
      className={styles.dialog}
      BackdropProps={{ className: styles.dialogBackdrop }}
      PaperProps={{ className: styles.dialogPaper }}
      open={isOpen}
      onClose={handleCloseHoldRequestFormPopup}
    >
      <div className={styles.dialogHeader}>
        <span>Hold Request</span>
        <CloseIcon onClick={handleCloseHoldRequestFormPopup} />
      </div>
      <form onSubmit={handleSubmit(onClickSend)}>
        <div className={classNames(styles.inputsContainer, styles.withMarginBottom)}>
          <Controller
            control={control}
            name="label"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputAsyncAutocomplete
                required
                error={!!error}
                label="Label"
                value={value}
                onChange={(_, value) => onChange(value)}
                onResolveSuggestions={(text) => Api.getWorkLabelsByQuery(text).catch(() => [])}
                addNotFoundedOptionText="label"
                addNewButtonOnClick={(inputValue) => {
                  setValue('label', { id: inputValue, title: inputValue });
                }}
                disableCloseOnSelect
              />
            )}
          />
          <Controller
            control={control}
            name="artist"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputAsyncAutocomplete
                required
                label="Artist"
                error={!!error}
                value={value}
                onChange={(_, value) => onChange(value)}
                onResolveSuggestions={(text) => Api.getWorkArtistsByQuery(text).catch(() => [])}
                addNotFoundedOptionText="artist"
                addNotFoundedOptionIndefiniteArticle="an"
                addNewButtonOnClick={(inputValue) => {
                  setValue('artist', { id: inputValue, title: inputValue });
                }}
                disableCloseOnSelect
              />
            )}
          />
        </div>
        {permissions.showHoldRequestContact && (
          <>
            <div className={styles.contactTitle}>Contact</div>
            <ToggleBtnGroup
              name="isSomeoneElse"
              control={control}
              items={toggleButtons}
              className={styles.toggleBtnFormRoot}
            />
          </>
        )}
        {isSomeoneElse && (
          <Controller
            control={control}
            name="contact"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputAsyncAutocomplete
                required
                label="Enter Contact"
                className={styles.withMarginBottom}
                error={!!error}
                value={value as unknown as IInputAutocompleteOption}
                onChange={(_, value) => onChange(value)}
                onResolveSuggestions={(text) => Api.getWorkContactsByQuery(text).catch(() => [])}
                renderOption={(option: IInputAutocompleteOption, state) => {
                  const optionsCasted = option as unknown as IShareToContacts;
                  const splittedName = optionsCasted.name?.split(' ');
                  const initials = splittedName?.slice(0, 2)?.reduce((total, item) => total + item[0], '') || '';

                  return (
                    <div
                      className={classNames(styles.optionContainer, {
                        [styles.selectedOption]: state.selected,
                      })}
                    >
                      <div
                        className={classNames(styles.optionInitials, globalStyles.f11h13SuisseSB_grayDarker, {
                          [styles.optionTeamInitials]: optionsCasted.team,
                        })}
                      >
                        {initials}
                      </div>
                      <div className={styles.titleAndTeamWrapper}>
                        <span className={globalStyles.f13h16SuisseREG_grayDarker}>{optionsCasted.name}</span>
                        {(optionsCasted.team || optionsCasted.teamName) && (
                          <span className={globalStyles.f11h13SuisseREG_grayDarker}>
                            ({optionsCasted.team ? 'Entire Team' : optionsCasted.teamName})
                          </span>
                        )}
                      </div>
                    </div>
                  );
                }}
                disableCloseOnSelect
              />
            )}
          />
        )}
        <div className={styles.holdRequestFormMessageTitle}>Message</div>
        <Controller
          name="message"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextEditor value={value} onChange={onChange} className={styles.holdRequestFormMessage} />
          )}
        />
        <DoubleBtnGroup
          type1="reset"
          type2="submit"
          name1="Cancel"
          name2="Send"
          className={styles.holdRequestButtons}
          onClick1={handleCloseHoldRequestFormPopup}
        />
      </form>
    </Dialog>
  );
};

export default HoldRequestFormPopup;
