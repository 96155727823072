import Tooltip from 'components/UI/Tooltip';

import Api from 'services/Api';

import { ReactComponent as ApproveIcon } from 'assets/approve-2.svg';
import { ReactComponent as CloseIcon } from 'assets/close-2.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import styles from './UploadPopup.module.scss';

export enum UploadStatus {
  Canceled = 'CANCELED',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Uploaded = 'UPLOADED',
  Waiting = 'WAITING',
  Canceling = 'CANCELING',
}

export interface IUploadItem {
  errorMessage?: string;
  file: File;
  id: string | number;
  /**
   * Percentage
   */
  progress: number;
  status: UploadStatus;
  title: string;
  uploadId?: string;
}

export interface IUploadItemCardProps {
  item: IUploadItem;
  onCancel: (item: IUploadItem, status: UploadStatus) => void;
  style?: React.CSSProperties;
}

const UploadItemCard = ({ item, onCancel, style }: IUploadItemCardProps): JSX.Element => {
  const onCancelButtonClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onCancel(item, UploadStatus.Canceling);
    await Api.bulkDeleteAssets([String(item.uploadId)]);
    onCancel(item, UploadStatus.Canceled);
  };

  const icons = {
    [UploadStatus.Canceled]: null,
    [UploadStatus.Error]: <WarningIcon className={styles.warningIcon} />,
    [UploadStatus.InProgress]: (
      <button className={styles.itemButton} onClick={onCancelButtonClick}>
        <CloseIcon />
      </button>
    ),
    [UploadStatus.Uploaded]: <ApproveIcon className={styles.successIcon} />,
    [UploadStatus.Waiting]: null,
    [UploadStatus.Canceling]: null,
  };

  const messages = {
    [UploadStatus.Canceled]: 'Upload Cancelled',
    [UploadStatus.Error]: item.errorMessage ?? '',
    [UploadStatus.InProgress]: `${item.progress}% Uploaded`,
    [UploadStatus.Uploaded]: '100% Uploaded',
    [UploadStatus.Waiting]: 'Waiting...',
    [UploadStatus.Canceling]: 'Canceling...',
  };

  return (
    <div className={styles.itemCard} style={style}>
      <div className={styles.itemTitleContainer}>
        <Tooltip text={item.title}>
          <div className={styles.itemTitle}>{item.title}</div>
        </Tooltip>
      </div>
      <div className={styles.itemProgress}>{messages[item.status]}</div>
      <div className={styles.itemIconContainer}>
        {item.progress === 100 && item.status === UploadStatus.InProgress ? null : icons[item.status]}
      </div>
    </div>
  );
};

export default UploadItemCard;
