import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { COPYRIGHT_YEAR, Paths } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Footer.module.scss';

interface IFooterProps {
  className?: string;
}

const Footer = ({ className }: IFooterProps) => {
  return (
    <div className={classNames(className, styles.footer)}>
      <Link to={Paths.termsOfUse} className={globalStyles.f11h13SuisseREG_white}>
        Terms of Service
      </Link>
      <Link to={Paths.privacyPolicy} className={globalStyles.f11h13SuisseREG_white}>
        Privacy
      </Link>
      <a
        href="https://www.wminewmedia.com/cookies-policy/?ot=363a2072-dc6c-4f93-8d3d-255778c71a20"
        target="_blank"
        rel="noreferrer"
        className={globalStyles.f11h13SuisseREG_white}
      >
        Cookies Policy
      </a>
      <a className={classNames('ot-sdk-show-settings', globalStyles.f11h13SuisseREG_white, styles.cookiesSettings)}>
        Cookies Settings
      </a>
      <span className={classNames(globalStyles.f11h13SuisseREG_white, styles.copyright)}>
        © {COPYRIGHT_YEAR} Warner Chappell Music, Inc.
      </span>
    </div>
  );
};

export default Footer;
