import { FunctionComponent, SVGProps } from 'react';
import React, { useState } from 'react';
import { Control, Controller, Path } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form';
import { FormControlLabel } from '@material-ui/core';
import classNames from 'classnames';

import { noop } from 'utils';

import { ReactComponent as Hide } from 'assets/loginIcons/close-white-eye.svg';
import { ReactComponent as Show } from 'assets/loginIcons/show-white-eye.svg';
import styles from './InputText.module.scss';

interface IInputTextProps<T> {
  name: Path<T>;
  control?: Control<T>;
  rules?: Omit<RegisterOptions<T>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  label?: string;
  value?: string;
  type?: string;
  placeholder?: string;
  inputClass?: string;
  maxLength?: number;
  onChangeInput?: (value: string, name: Path<T>) => void;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  labelRootClass?: string;
  labelFormClass?: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  inputIconClass?: string;
  onClickInput?: (value: string) => void;
  onBlurInput?: (value: string) => void;
  onEnterInput?: (value: string) => void;
  onClickIcon?: () => void;
  disableEditable?: boolean;
  required?: boolean;
  disabled?: boolean;
  max?: number;
  formControlClassName?: string;
}

const InputText = <T,>({
  control,
  rules,
  label,
  name,
  placeholder,
  inputClass,
  value,
  type = 'text',
  maxLength = 80,
  onChangeInput,
  labelPlacement = 'start',
  labelRootClass,
  labelFormClass,
  Icon,
  inputIconClass,
  disableEditable,
  onClickInput,
  onClickIcon,
  required,
  onBlurInput,
  onEnterInput,
  disabled,
  max = 100,
  formControlClassName,
}: IInputTextProps<T>): JSX.Element => {
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const toggleType = () => {
    setPasswordShown(!passwordShown);
  };
  return control && name ? (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, onBlur } }) => {
        return (
          <FormControlLabel
            label={
              <div className={styles.labelWrap}>
                {label}
                {required && <span className={styles.required}>*</span>}
                {type === 'password' && (
                  <div className={styles.toggle} onClick={toggleType}>
                    <div className={styles.eye}>
                      {passwordShown ? <Hide className={styles.hide} /> : <Show className={styles.show} />}
                    </div>
                    <span
                      className={classNames(styles.textField_showText, {
                        [styles.text_wcm_accent]: passwordShown,
                      })}
                    >
                      {!passwordShown ? 'Show' : 'Hide'}
                    </span>
                  </div>
                )}
              </div>
            }
            labelPlacement={labelPlacement}
            className={formControlClassName}
            control={
              <>
                <input
                  value={value}
                  autoComplete={type === 'password' ? 'new-password' : undefined}
                  disabled={disabled}
                  maxLength={maxLength}
                  className={classNames(inputClass, styles.inputText, {
                    [styles.inputDisableCaret]: disableEditable,
                    [styles.inputDisabled]: disabled,
                  })}
                  type={type !== 'password' ? type : passwordShown ? 'text' : 'password'}
                  placeholder={placeholder}
                  onChange={(e) => {
                    if (disableEditable) return noop();

                    // Note: validation for input numbers, if we need another values we can make this logic reusable,
                    //        but for now input number used only in one case that SongsModuleFiltersPopup
                    if (type === 'number') {
                      const inputValue = Number((e.target as HTMLInputElement).value);

                      if (!e.target.value && e.target.value !== '0') {
                        return onChange({ ...e, target: { ...e.target, value: '' } });
                      }
                      if (inputValue < 0) {
                        return onChange({ ...e, target: { ...e.target, value: '0' } });
                      }
                      if (inputValue > max) {
                        return onChange({ ...e, target: { ...e.target, value: `${max}` } });
                      }

                      return onChange({ ...e, target: { ...e.target, value: `${Number(inputValue.toFixed(2))}` } });
                    }
                    if (onChangeInput) {
                      onChangeInput?.(e.target.value, name);
                    }
                    onChange(e);
                  }}
                  onBlur={(e) => {
                    if (!e.target.value.trim()) return onChange({ ...e, target: { ...e.target, value: '' } });
                    if (onBlurInput) {
                      onBlurInput?.(e.target.value);
                    }
                    return disableEditable ? noop : onBlur();
                  }}
                  onClick={(e) => onClickInput && onClickInput((e.target as HTMLInputElement).value)}
                />
                {Icon && (
                  <Icon
                    className={classNames(inputIconClass, styles.inputIcon, {
                      [styles.active]: !!value,
                    })}
                    onClick={onClickIcon}
                  />
                )}
              </>
            }
            classes={{
              root: classNames(labelRootClass, styles.formControl),
              label: classNames(labelFormClass, styles.formLabel),
            }}
          />
        );
      }}
    />
  ) : (
    <FormControlLabel
      label={
        <>
          {label}
          {required && <span className={styles.required}>*</span>}
        </>
      }
      labelPlacement={labelPlacement}
      className={formControlClassName}
      control={
        <>
          <input
            value={value}
            disabled={disabled}
            maxLength={maxLength}
            className={classNames(inputClass, styles.inputText, {
              [styles.inputDisableCaret]: disableEditable,
              [styles.inputDisabled]: disabled,
            })}
            type={type}
            placeholder={placeholder}
            onBlur={(e) => onBlurInput && onBlurInput((e.target as HTMLInputElement).value)}
            onKeyDown={(e) => e.code === 'Enter' && onEnterInput && onEnterInput((e.target as HTMLInputElement).value)}
            onChange={(e) => {
              if (disableEditable || !onChangeInput) return noop();

              // Note: validation for input numbers, if we need another values we can make this logic reusable,
              //        but for now input number used only in one case that SongsModuleFiltersPopup
              if (type === 'number') {
                if (!e.target.value && e.target.value !== '0') {
                  return onChangeInput('', name);
                }

                const inputValue = Number((e.target as HTMLInputElement).value);

                if (inputValue < 0) {
                  return onChangeInput('0', name);
                }
                if (inputValue > max) {
                  return onChangeInput(`${max}`, name);
                }
                return onChangeInput(`${Number(inputValue.toFixed(2))}`, name);
              }

              onChangeInput(e.target.value, name);
            }}
            onClick={(e) => onClickInput && onClickInput((e.target as HTMLInputElement).value)}
          />
          {Icon && (
            <Icon
              className={classNames(inputIconClass, styles.inputIcon, {
                [styles.active]: !!value,
              })}
              onClick={onClickIcon}
            />
          )}
        </>
      }
      classes={{
        root: classNames(labelRootClass, styles.formControl),
        label: classNames(labelFormClass, styles.formLabel),
      }}
    />
  );
};

export default InputText;
