import { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { PitchTypes } from 'components/Pitches/data';
import { NotificationPopup } from 'components/Popups';
import { AddSongs } from 'components/Popups/ManualPitchForm';
import { MultiStepsFormDialog } from 'components/Reusable';
import { IPlaylistDetails, IPlaylistMedia } from 'containers/Sidebar/data';

import { useDidUpdate, useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getSystemPitchContentType, setPitchesModule } from 'store';
import { PitchContentType } from 'store/reducers/general/types';
import { DEFAULT_ERROR_CONFIG, sendAnalyticsEvent } from 'utils';

import styles from './SystemPitchForm.module.scss';

import { AddSongs as AddSongsSystemPitch, Confirm, CreatePitch } from '.';
import { ISong } from './SongRow';
import { AnalyticsEventTypes, IAutocompleteOption } from 'types';

export interface IContactAutocompleteOption extends IAutocompleteOption {
  email?: string;
  isGroup?: boolean;
  team?: string;
}

export interface ISystemPitch {
  contacts?: IContactAutocompleteOption[];
  downloadable?: boolean;
  expiryDate?: Date;
  message?: string;
  playlistId?: string;
  playlistName?: string;
  songs?: ISong[];
  title?: string;
}

export interface ISystemPitchFormProps {
  item: ISystemPitch;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const usePlaylistId = (item: ISystemPitch, methods: UseFormReturn<ISystemPitch, object>) => {
  useEffect(() => {
    let mounted = true;
    (async () => {
      const playlist: IPlaylistDetails | undefined = item.playlistId
        ? await Api.getPlaylistDetails({
            errorPopupConfig: DEFAULT_ERROR_CONFIG,
            pathId: item.playlistId,
          })
        : undefined;
      if (playlist && mounted) {
        methods.setValue('playlistName', playlist.title);
        methods.setValue(
          'songs',
          playlist.medias.map(
            (media: IPlaylistMedia): ISong => ({
              id: media.songId,
              mediaId: media.id,
              path: media.mediaPath,
              playbackPath: media.mediaPlaybackPath,
              title: media.title,
              version: media.version || undefined,
              writers: media.creators,
            })
          )
        );
      }
    })();
    return () => {
      mounted = false;
    };
  }, [item.playlistId, methods]);
};

const SystemPitchForm = ({ item }: ISystemPitchFormProps) => {
  const dispatch = useDispatch();
  const methods = useForm<ISystemPitch>({ defaultValues: item, mode: 'all' });
  const [notification, setNotification] = useState<string>();
  const [open, setOpen] = useState(true);

  const systemPitchContentType = useMemoSelector(getSystemPitchContentType);

  useDidUpdate(() => {
    methods.reset(item);
    setOpen(true);
  }, [item]);

  usePlaylistId(item, methods);

  const onSubmitClick = async (pitch: ISystemPitch) => {
    sendAnalyticsEvent(AnalyticsEventTypes.pitchSend);
    const result = await Api.addSystemPitch(pitch, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    if (result) {
      setOpen(false);
      setNotification('Pitch Sent');
      dispatch(setPitchesModule({ reloadHash: new Date().toISOString() }));
    }
  };

  const values = methods.getValues();

  return (
    <>
      <MultiStepsFormDialog
        contentMaxHeight="436px"
        isCurrentStepValid={methods.formState.isValid}
        isSubmitting={methods.formState.isSubmitting}
        onCancel={() => dispatch(setPitchesModule({ systemPitchForm: undefined }))}
        onStepChange={methods.trigger} // Revalidate form
        onSubmit={methods.handleSubmit(onSubmitClick)}
        open={open}
        playbarVisible
        steps={[
          {
            Component: (
              <div className={styles.systemPitchFormContent}>
                <CreatePitch methods={methods} />
              </div>
            ),
            title: 'Create Pitch',
          },
          {
            Component: (
              <div className={styles.systemPitchFormContent}>
                {systemPitchContentType === PitchContentType.multipleSongPitch ? (
                  <AddSongs
                    addedSongs={values?.songs?.map((el) => ({
                      id: el.id,
                      mediaId: el.mediaId,
                      path: el.path,
                      playbackPath: el.playbackPath,
                      result: undefined,
                      title: el.title,
                      version: el.version,
                      writers: el.writers || undefined,
                    }))}
                    methods={methods}
                    pitchType={PitchTypes.system}
                  />
                ) : (
                  <AddSongsSystemPitch methods={methods} />
                )}
              </div>
            ),
            subTitle: values.playlistName ? `Review songs from ${values.playlistName}` : undefined,
            title:
              values.playlistName || values.playlistId
                ? 'Pitch Playlist'
                : values.songs?.length === 1
                ? 'Pitch Song'
                : 'Pitch Songs',
          },
          {
            Component: (
              <div className={styles.systemPitchFormContent}>
                <Confirm methods={methods} />
              </div>
            ),
            title: 'Confirm Pitch',
          },
        ]}
        submitButtonText="Send"
      />
      <NotificationPopup
        isOpen={Boolean(notification)}
        setIsOpen={() => dispatch(setPitchesModule({ systemPitchForm: undefined }))}
        text={notification || ''}
      />
    </>
  );
};

export default SystemPitchForm;
