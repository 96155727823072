import { forwardRef, useImperativeHandle, useState } from 'react';
import { Popover, Tooltip } from '@material-ui/core';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';
import moment from 'moment';

import { Button } from 'components/Reusable';
import { ButtonVariant } from 'components/Reusable/Button';
import { DateRangePicker } from 'components/UI';

import { useDidUpdate } from 'hooks';
import { dateFormat, dateFormatWithSlash } from 'utils';
import { isEmpty } from 'utils';

import { ReactComponent as ApproveIcon } from 'assets/approve-2.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/close-2.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import styles from './DatePicker.module.scss';

export interface IDatePickerProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  highlightValid?: boolean;
  label?: string | JSX.Element;
  labelClassName?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (value: Date | null) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  showActionButtons?: boolean;
  required?: boolean;
  value: Date | null | undefined;
}

const dateToString = (value: Date | undefined | null): string => {
  return value ? moment(value).format(dateFormat) : '';
};

const DatePicker = forwardRef((props: IDatePickerProps, ref: React.Ref<IDatePickerProps>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(() => dateToString(props.value));
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const empty = isEmpty(props.value, true);
  const valid = props.highlightValid && !props.error && !empty;

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (anchorEl) return;
    setFocused(false);
    const date = inputValue ? moment(inputValue, dateFormat, true) : undefined;
    props.onChange && props.onChange(date?.isValid() ? date?.toDate() : null);
    props.onBlur && props.onBlur(e);
  };
  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    props.onFocus && props.onFocus(e);
  };

  useDidUpdate(() => setInputValue(dateToString(props.value)), [props.value]);
  useImperativeHandle(ref, () => ({ ...props }));

  return (
    <>
      <FormControlLabel
        classes={{
          label: classNames(styles.label, props.labelClassName),
          labelPlacementStart: styles.labelPlacementStart,
          labelPlacementTop: styles.labelPlacementTop,
        }}
        className={props.className}
        control={
          <div
            className={classNames(styles.control, {
              [styles.disabled]: props.disabled,
              [styles.errorBorder]: props.error,
              [styles.focused]: focused,
              [styles.validBorder]: valid,
            })}
          >
            <input
              autoComplete="off"
              className={styles.input}
              disabled={props.disabled}
              name={props.name}
              onBlur={onInputBlur}
              onChange={(e) => setInputValue(e.target.value)}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              onFocus={onInputFocus}
              placeholder={props.placeholder}
              type="text"
              value={inputValue}
            />
            <div className={styles.iconContainer}>
              {(props.error || props.required) && !valid && (
                <Tooltip
                  arrow
                  classes={{ arrow: styles.tooltipArrow, popper: styles.tooltipPopper, tooltip: styles.tooltip }}
                  onClose={() => setTooltipOpen(false)}
                  onOpen={() => setTooltipOpen(true)}
                  open={tooltipOpen && Boolean(props.error)}
                  placement="top-end"
                  title={props.error || ''}
                >
                  <div className={styles.tooltipIcon}>
                    {props.required ? (
                      <div className={classNames(styles.requiredIcon, { [styles.errorColor]: Boolean(props.error) })} />
                    ) : (
                      <WarningIcon className={classNames(styles.errorColor)} />
                    )}
                  </div>
                </Tooltip>
              )}
              {valid && <ApproveIcon className={classNames(styles.validColor)} />}
              <CalendarIcon />
            </div>
          </div>
        }
        label={props.label}
        labelPlacement={props.labelPlacement || 'top'}
      />
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          onClose={() => setAnchorEl(null)}
          open
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          {props.showActionButtons && (
            <div className={styles.toolbar}>
              <Button onClick={() => setAnchorEl(null)} variant={ButtonVariant.Text} data-testid="close-button">
                <CloseIcon />
              </Button>
            </div>
          )}
          <DateRangePicker
            date={moment(props.value).format(dateFormatWithSlash)}
            isSingleDayPicker
            onSubmitSingleDate={(date) => {
              setAnchorEl(null);
              props.onChange && props.onChange(date || null);
            }}
            onCancel={() => setAnchorEl(null)}
            showSingleDateActionButtons={props.showActionButtons}
          />
        </Popover>
      )}
    </>
  );
});
export default DatePicker;
