import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useMemoSelector, useWindowSize } from 'hooks';
import { getIsOpenMobileMockup, setIsOpenMobileMockup } from 'store';
import { getIsMobile, Paths, SCREEN_BREAKPOINTS } from 'utils';

interface IMobileWrapperProps {
  children: JSX.Element | null;
  isForTablet?: boolean;
}

const MobileWrapper = ({ children, isForTablet = false }: IMobileWrapperProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { width: windowWidth } = useWindowSize();

  const isOpenMobileMockup = useMemoSelector(getIsOpenMobileMockup);

  useEffect(() => {
    if (getIsMobile(windowWidth) && !isOpenMobileMockup) {
      dispatch(setIsOpenMobileMockup(true));
    }

    if (isForTablet && windowWidth >= SCREEN_BREAKPOINTS.desktopSmall) {
      history.replace(Paths.dashboard);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, dispatch]);

  return (
    <span style={{ display: getIsMobile(windowWidth) ? 'none' : 'contents' }}>
      {isForTablet && windowWidth >= SCREEN_BREAKPOINTS.desktopSmall ? null : children}
    </span>
  );
};

export default MobileWrapper;
