import { useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { ContactsForm, IContact } from 'components/Contacts';
import { Button, ContactOption } from 'components/Reusable';
import { NewAutocomplete as Autocomplete, NewDatePicker as DatePicker, NewTextField as TextField } from 'components/UI';

import Api from 'services/Api';
import { DEFAULT_ERROR_CONFIG, validateRequired } from 'utils';

import { ReactComponent as PlusCircleIcon } from 'assets/plus-circle.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import styles from './ManualPitchForm.module.scss';

import { IManualPitch } from '.';

const validateSentDate = (v: Date | null | undefined): string | undefined => {
  const requiredErr = validateRequired(v);
  if (requiredErr) return requiredErr;
  if (v && v.getTime() > new Date().getTime()) return 'Select Past Date.';
};

// eslint-disable-next-line @typescript-eslint/ban-types
const CreatePitch = ({ methods }: { methods: UseFormReturn<IManualPitch, object> }) => {
  const [contactsForm, setContactsForm] = useState<IContact>();
  const onContactsFormSubmit = (c: IContact) => {
    setContactsForm(undefined);
    methods.setValue('contact', { id: c.id || '', title: c.name || '' }, { shouldTouch: true, shouldValidate: true });
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            control={methods.control}
            name="title"
            render={({ field, fieldState: { error }, formState }) => (
              <TextField
                {...field}
                disabled={formState.isSubmitting}
                error={error?.message}
                highlightValid
                label="Title"
                required
              />
            )}
            rules={{ validate: validateRequired }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={methods.control}
            name="sentDate"
            render={({ field, fieldState: { error }, formState }) => (
              <DatePicker
                {...field}
                disabled={formState.isSubmitting}
                error={error?.message}
                highlightValid
                label="Sent Date"
                required
              />
            )}
            rules={{ validate: validateSentDate }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={methods.control}
            name="contact"
            render={({ field, fieldState: { error }, formState }) => (
              <Autocomplete
                {...field}
                className={styles.pitchedTo}
                disabled={formState.isSubmitting}
                error={error?.message}
                highlightValid
                Icon={<SearchIcon height="13" width="13" />}
                label="Pitched To"
                onResolveSuggestions={(text) =>
                  Api.getContactsAndTeamGroups(text, { errorPopupConfig: DEFAULT_ERROR_CONFIG })
                }
                renderOption={ContactOption}
                required
              />
            )}
            rules={{ validate: validateRequired }}
          />
          <Button Icon={<PlusCircleIcon />} onClick={() => setContactsForm({})} text="New Contact" />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={methods.control}
            name="artist"
            render={({ field, formState }) => <TextField {...field} disabled={formState.isSubmitting} label="Artist" />}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={methods.control}
            name="label"
            render={({ field, formState }) => <TextField {...field} disabled={formState.isSubmitting} label="Label" />}
          />
        </Grid>
      </Grid>
      {contactsForm && (
        <ContactsForm item={contactsForm} onClose={() => setContactsForm(undefined)} onSubmit={onContactsFormSubmit} />
      )}
    </>
  );
};

export default CreatePitch;
