import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

// OKTA OpenID Connect configuration
export const authOptions: OktaAuthOptions = {
  issuer: process.env.REACT_APP_OKTA_ISSUER, // + /oauth2/default
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID || 'dummy-id',
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  scopes: ['openid', 'offline_access', 'arrow_roles'],
  pkce: true,
  // disableHttpsCheck: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
};

const oktaAuth = new OktaAuth(authOptions);

export default oktaAuth;
