import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { EditTooltip } from 'components/Reusable';
import { IGridApiNode, VersionsColumnFields } from 'components/SongDetails/data';
import { getVersionOptions } from 'components/SongsUpload/data';
import { InputAutocomplete } from 'components/UI';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getUserPermissions, setError, setLoading, setRecentEditedSongs } from 'store';
import { DEFAULT_ERROR_CONFIG } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './SongDetails.module.scss';

interface IVersionsCellProps extends IGridApiNode {
  version?: string | null;
  mediaId?: string;
  workCode?: string;
}

const VersionsCell = ({ version, node, api, mediaId, workCode }: IVersionsCellProps) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const permissions = useMemoSelector(getUserPermissions);

  const versionsData = useMemo(() => getVersionOptions(), []);

  const defaultVersion = useMemo(
    () =>
      versionsData.find((item) =>
        [(item.id as string)?.toLowerCase(), (item.shortTitle as string)?.toLowerCase()].includes(
          version?.toLowerCase() || ''
        )
      ),
    [version, versionsData]
  );

  const [selectedVersion, setSelectedVersion] = useState(defaultVersion);

  const onSubmitChangeVersion = async () => {
    if (!selectedVersion?.id) return;

    dispatch(setLoading(true));

    const res = await Api.updateSongMediaVersion(
      { mediaId: mediaId || '', version: selectedVersion?.id as string },
      {
        pathId: id,
        handler: (details?: { errorCode?: string }) => {
          const isAlreadyExistError = details?.errorCode === '4704';

          dispatch(
            setError({
              title: isAlreadyExistError
                ? `${workCode || '(no title)'} has a version you selected already.`
                : DEFAULT_ERROR_CONFIG.title,
              text: isAlreadyExistError
                ? 'Please select a different version type and try again'
                : DEFAULT_ERROR_CONFIG.text,
              isOpen: true,
            })
          );
        },
      }
    );

    if (!res) {
      dispatch(setLoading(false));
      return;
    }

    node?.setDataValue(VersionsColumnFields.version, selectedVersion?.id);

    setTimeout(() => api.refreshCells({ force: true, columns: [VersionsColumnFields.version] }), 10);

    dispatch(
      setRecentEditedSongs({
        songs: [{ ids: { mediaId: mediaId || '', songId: id }, update: { version: selectedVersion?.title } }],
      })
    );
  };

  return (
    <EditTooltip
      Component={
        <InputAutocomplete
          multiple={false}
          data={versionsData}
          name={'version' as never}
          disablePortal
          value={selectedVersion}
          onChangeInput={setSelectedVersion}
          formRootClass={styles.autocompleteLabelRoot}
        />
      }
      disableEditing={!permissions.songDetailsActions}
      onCancel={() => setSelectedVersion(defaultVersion)}
      onSubmit={onSubmitChangeVersion}
    >
      <span className={globalStyles.f13h16SuisseREG_grayDarker}>{version || ''}</span>
    </EditTooltip>
  );
};

export default VersionsCell;
