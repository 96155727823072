import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
  adminControlsData,
  adminControlsFilterInputs,
  defaultAdminControlsFilterData,
  IAdminControlsData,
  UserRoles,
} from 'components/AdminControls/data';
import { AutocompletesList, DoubleBtnGroup } from 'components/Reusable';
import { InputText } from 'components/UI';
import { UserStatuses } from 'components/UI/StatusCard/data';
import FiltersPopupWrapper from 'containers/FiltersPopupWrapper';

import { useMemoSelector } from 'hooks';
import {
  getAdminControlsFiltersData,
  getAdminControlsIsOpenFiltersPopup,
  getIsOpenSidebar,
  getUserRole,
  getUserTeamsForAutocomplete,
  setAdminControls,
} from 'store';
import { ADMIN_USER_ROLES } from 'utils';

import styles from './AdminControlsFiltersPopup.module.scss';

import { AdminControlsFilterDataType, AdminControlsFilterKeys, IAdminControlsNestedData } from 'types';

interface IFiltersPopup {
  parent?: MutableRefObject<HTMLDivElement | null>;
  customCancel?: () => void;
  customSubmit?: () => void;
}

const AdminControlsFiltersPopup = ({ customCancel, customSubmit, parent }: IFiltersPopup): JSX.Element => {
  const dispatch = useDispatch();

  const { control, handleSubmit, reset, watch } = useForm<AdminControlsFilterDataType>({
    defaultValues: defaultAdminControlsFilterData,
  });

  const { isOpenFiltersPopup, filtersData, teams, isOpenSidebar, userRole } = useMemoSelector((state) => ({
    isOpenFiltersPopup: getAdminControlsIsOpenFiltersPopup(state),
    filtersData: getAdminControlsFiltersData(state),
    teams: getUserTeamsForAutocomplete(state),
    isOpenSidebar: getIsOpenSidebar(state),
    userRole: getUserRole(state),
  }));

  const [disabledFields, setDisabledFields] = useState<AdminControlsFilterKeys[]>([
    AdminControlsFilterKeys.role,
    AdminControlsFilterKeys.territory,
    AdminControlsFilterKeys.team,
    AdminControlsFilterKeys.isTeamAdmin,
  ]);

  const [statusField, roleField] = useWatch({
    control,
    name: [AdminControlsFilterKeys.status, AdminControlsFilterKeys.role],
  });

  const adminControlsConvertedData = useMemo(
    () =>
      adminControlsData.reduce((total, item) => {
        const teamItem = item.value === AdminControlsFilterKeys.team ? [{ ...item, data: teams }] : [];
        const isTeamAdminItem =
          item.value === AdminControlsFilterKeys.isTeamAdmin && ADMIN_USER_ROLES.includes(userRole) ? [item] : [];
        const restItems = [AdminControlsFilterKeys.team, AdminControlsFilterKeys.isTeamAdmin].includes(item.value)
          ? []
          : [item];

        return [...total, ...teamItem, ...isTeamAdminItem, ...restItems];
      }, [] as IAdminControlsData[]),
    [teams, userRole]
  );

  const onSubmit = (data: AdminControlsFilterDataType) => {
    dispatch(setAdminControls({ filtersData: data, isOpenFiltersPopup: false }));
    customSubmit && customSubmit();
  };

  const onCancel = (): void => {
    reset(filtersData);
    dispatch(setAdminControls({ isOpenFiltersPopup: false }));
    customCancel && customCancel();
  };

  const onReset = () => {
    reset(defaultAdminControlsFilterData);
    dispatch(setAdminControls({ filtersData: defaultAdminControlsFilterData }));
  };

  const clearTeamTerritoryIsTeamAdmin = useCallback(
    (withRole: boolean, withTeam = true): void => {
      const newBody = {
        ...(withRole ? { [AdminControlsFilterKeys.role]: null } : {}),
        ...(withTeam ? { [AdminControlsFilterKeys.team]: null } : {}),
        [AdminControlsFilterKeys.territory]: null,
        [AdminControlsFilterKeys.isTeamAdmin]: null,
      };
      reset({
        ...watch(),
        ...newBody,
      });
    },
    [reset, watch]
  );

  useEffect(() => {
    if (!isOpenFiltersPopup) return;

    reset(filtersData);
  }, [filtersData, isOpenFiltersPopup, reset]);

  useEffect(() => {
    const { role, team, territory, isTeamAdmin } = AdminControlsFilterKeys;

    const isStatusActive = (statusField as unknown as IAdminControlsNestedData)?.code === UserStatuses.ACTIVE;
    const isArrowReadOrLabelRole = [UserRoles.ARROW_READ, UserRoles.LABEL].includes(
      (roleField as unknown as IAdminControlsNestedData)?.title as UserRoles
    );
    const isPartnerOrSongWriterRole = [UserRoles.PARTNER, UserRoles.SONGWRITER].includes(
      (roleField as unknown as IAdminControlsNestedData)?.title as UserRoles
    );

    if (!isStatusActive && disabledFields.length !== 4) {
      // disable 4 fields - role, territory, team, is team admin
      clearTeamTerritoryIsTeamAdmin(true);
      setDisabledFields([role, territory, team, isTeamAdmin]);
    } else if (isStatusActive && isArrowReadOrLabelRole && disabledFields.length !== 3) {
      // disable 3 fields - territory, team, is team admin
      clearTeamTerritoryIsTeamAdmin(false);
      setDisabledFields([territory, team, isTeamAdmin]);
    } else if (isStatusActive && isPartnerOrSongWriterRole && disabledFields.length !== 2) {
      // disable 2 fields - territory,  is team admin
      clearTeamTerritoryIsTeamAdmin(false, false);
      setDisabledFields([territory, isTeamAdmin]);
    } else if (isStatusActive && !isArrowReadOrLabelRole && !isPartnerOrSongWriterRole && disabledFields.length !== 0) {
      // enable 4 fields - role, territory, team, is team admin
      setDisabledFields([]);
    }
  }, [statusField, roleField, disabledFields, setDisabledFields, clearTeamTerritoryIsTeamAdmin]);

  return (
    <FiltersPopupWrapper
      isOpen={isOpenFiltersPopup}
      onCancel={onCancel}
      parent={parent}
      component={() => (
        <form
          className={classNames(styles.filtersForm, {
            [styles.openSidebar]: isOpenSidebar,
          })}
          onSubmit={handleSubmit(onSubmit)}
          onReset={onReset}
        >
          <div className={styles.filtersContainer}>
            {adminControlsFilterInputs.map(({ title, label, placeholder, id }) => (
              <InputText key={id} name={title} label={label} placeholder={placeholder} control={control} />
            ))}
            <AutocompletesList
              control={control}
              data={adminControlsConvertedData}
              autocompleteRootClass={styles.autocompleteRoot}
              autocompletePopperClass={styles.autocompletePopper}
              autocompletePaperClass={styles.autocompletePaper}
              disabledFields={disabledFields}
              multiple={false}
              disableClearable={false}
            />
          </div>
          <DoubleBtnGroup
            className={styles.doubleBtnGroupAdditional}
            name1="Reset"
            name2="Filter"
            type1="reset"
            type2="submit"
            spacing={13}
          />
        </form>
      )}
    />
  );
};

export default AdminControlsFiltersPopup;
