import { Control, Controller, Path } from 'react-hook-form';
import { FormControlLabel, styled, Switch, SwitchProps } from '@material-ui/core';
import classNames from 'classnames';

import { Tooltip } from 'components/UI';

import styles from './SwitchBtn.module.scss';

interface ISwitchBtnProps<T> {
  name?: Path<T>;
  control?: Control<T>;
  label?: string | JSX.Element;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  labelClassName?: string;
  formRootClassName?: string;
  switchRootClassName?: string;
  onChangeSwitch?: (checked: boolean, name?: string) => void;
  checked?: boolean;
  disabled?: boolean;
  disabledTooltipText?: string;
}

type CustomSwitchPropsType = SwitchProps & {
  disabled?: boolean;
};

const SwitchBtn = <T,>({
  name,
  control,
  label,
  labelPlacement,
  labelClassName,
  formRootClassName,
  switchRootClassName,
  onChangeSwitch,
  checked,
  disabled,
  disabledTooltipText = '',
}: ISwitchBtnProps<T>) => {
  return control && name ? (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          disabled={disabled}
          classes={{
            root: classNames(styles.formRoot, formRootClassName || '', {
              [styles.cursorDefault]: !!disabledTooltipText,
            }),
            label: classNames(styles.label, labelClassName || ''),
          }}
          control={
            <Tooltip text={disabledTooltipText}>
              <div className={styles.switchWrapper}>
                <CustomSwitch
                  className={switchRootClassName}
                  onChange={(_, data) => {
                    onChange(data);
                    onChangeSwitch && onChangeSwitch(data, name);
                  }}
                  checked={!!value}
                  disabled={!!disabledTooltipText || disabled}
                />
              </div>
            </Tooltip>
          }
          label={label}
          labelPlacement={labelPlacement}
        />
      )}
    />
  ) : (
    <FormControlLabel
      classes={{
        root: classNames(styles.formRoot, formRootClassName || '', { [styles.cursorDefault]: !!disabledTooltipText }),
        label: classNames(styles.label, labelClassName || ''),
      }}
      control={
        <Tooltip text={disabledTooltipText}>
          <div className={styles.switchWrapper}>
            <CustomSwitch
              className={switchRootClassName}
              disabled={!!disabledTooltipText || disabled}
              checked={checked}
              onChange={(_, data) => onChangeSwitch && onChangeSwitch(data, name)}
            />
          </div>
        </Tooltip>
      }
      label={label}
      disabled={disabled}
      labelPlacement={labelPlacement}
    />
  );
};

const CustomSwitch = styled((props: CustomSwitchPropsType) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))({
  width: 42,
  height: 24,
  padding: 0,
  overflow: 'unset',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 5,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#d9a81b',
        opacity: 1,
        border: 0,
      },
      '& .MuiSwitch-thumb': {
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        opacity: 1,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#fff',
      opacity: 0.3,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      opacity: 0.3,
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '2px solid #fff',
    },
    '&.Mui-disabled': {
      color: '#fff',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    border: '2px solid #000',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16,
    border: '2px solid #000',
    backgroundColor: '#fff',
    transition: 'none',
    opacity: 1,
  },
});

export default SwitchBtn;
