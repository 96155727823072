import { Tooltip } from 'components/UI';

import styles from './SongDetails.module.scss';

interface IStatusCellProps {
  status?: string;
}

const StatusCell = ({ status }: IStatusCellProps) => {
  return (
    <Tooltip text={status || ''}>
      <span className={styles.statusCard}>{status || ''}</span>
    </Tooltip>
  );
};

export default StatusCell;
