import { useDispatch } from 'react-redux';

import { useMemoSelector } from 'hooks';
import { getUserPermissions, setSongDetails } from 'store';

import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/remove.svg';
import styles from './SongDetails.module.scss';

import { addPopupConfigs } from './addPopupConfigs';
import { IStatusData, RemoveActionTypes } from './data';
import { AddPopupTypes } from 'types';

interface IStatusActionsCellProps {
  data?: IStatusData;
}

const StatusActionsCell = ({ data }: IStatusActionsCellProps) => {
  const dispatch = useDispatch();

  const permissions = useMemoSelector(getUserPermissions);

  const onClickRemoveStatus = () => {
    dispatch(
      setSongDetails({
        deletePopupConfig: {
          isOpen: true,
          mainText: 'This action cannot be undone',
          questionText: `Delete "${data?.artist}" Status?`,
          notificationText: 'Status Deleted',
          id: data?.id || '',
          type: RemoveActionTypes.status,
        },
      })
    );
  };

  const onClickEditStatus = () => {
    dispatch(
      setSongDetails({
        addPopupConfig: {
          ...addPopupConfigs[AddPopupTypes.status],
          isOpen: true,
          isEdit: true,
          editValues: data,
        },
      })
    );
  };

  return !permissions.songDetailsActions ? null : (
    <div className={styles.actionsCellContainer}>
      <EditIcon onClick={onClickEditStatus} />
      <RemoveIcon onClick={onClickRemoveStatus} />
    </div>
  );
};

export default StatusActionsCell;
