import { NotificationPreferencesKeys } from 'components/NotificationPreferences/data';

export type NotificationPreferencesPopupDataType = {
  [K in NotificationPreferencesKeys]: boolean;
};

export const defaultNotificationPreferencesPopupData = {
  [NotificationPreferencesKeys.holdRequestsCreated]: false,
  [NotificationPreferencesKeys.songSharedWithMe]: false,
  [NotificationPreferencesKeys.pitchActivity]: true,
  [NotificationPreferencesKeys.playlistActivity]: false,
  [NotificationPreferencesKeys.holdStatus]: false,
  [NotificationPreferencesKeys.searchAlerts]: false,
};
