import { useCallback, useEffect, useState } from 'react';

import { SCREEN_BREAKPOINTS } from 'utils';

type TBreakpointTypes = 'isMobile' | 'isTablet' | 'isDesktopSmall' | 'isDesktopBig' | 'isLarge';

type TBreakpoint = {
  [key in TBreakpointTypes]: boolean;
};

const initBreakpoint = {
  isMobile: false,
  isTablet: false,
  isDesktopSmall: false,
  isDesktopBig: false,
  isLarge: false,
};

const useBreakpoints = (): TBreakpoint => {
  const getBreakpoint = useCallback((): TBreakpointTypes => {
    const windowWidth = window.innerWidth;

    if (windowWidth < SCREEN_BREAKPOINTS.mobile) {
      return 'isMobile';
    }
    if (windowWidth < SCREEN_BREAKPOINTS.tablet) {
      return 'isTablet';
    }
    if (windowWidth < SCREEN_BREAKPOINTS.desktopSmall) {
      return 'isDesktopSmall';
    }
    if (windowWidth < SCREEN_BREAKPOINTS.desktopBig) {
      return 'isDesktopBig';
    }

    return 'isLarge';
  }, []);

  const [breakpoint, setBreakpoint] = useState<TBreakpointTypes>(getBreakpoint());

  const resizeEvent = useCallback(() => {
    const currentBreakpoint = getBreakpoint();

    setBreakpoint(currentBreakpoint);
  }, [getBreakpoint]);

  useEffect(() => {
    window.addEventListener('resize', resizeEvent);

    return () => window.removeEventListener('resize', resizeEvent);
  }, [resizeEvent]);

  return { ...initBreakpoint, [breakpoint]: true };
};

export default useBreakpoints;
