import { RowNode } from 'ag-grid-community';

import { NewAutocomplete as Autocomplete } from 'components/UI';
import { IAutocompleteProps } from 'components/UI/NewAutocomplete';

import { IAutocompleteOption } from 'types';

export interface IAutocompleteCellRendererProps {
  disableClearable?: IAutocompleteProps['disableClearable'];
  disabled?: boolean;
  error?: string;
  field: string;
  getRelatedNodes?: (node: RowNode) => RowNode[];
  node: RowNode;
  onBlur?: IAutocompleteProps['onBlur'];
  onChange?: (v?: IAutocompleteOption | IAutocompleteOption[] | null) => void;
  options?: IAutocompleteProps['options'];
  placeholder?: string;
  value?: unknown;
}

const AutocompleteCellRenderer = ({
  disableClearable,
  disabled,
  error,
  field,
  getRelatedNodes,
  node,
  onBlur,
  onChange,
  options,
  placeholder,
  value,
}: IAutocompleteCellRendererProps) => {
  const onChangeHandler = (v: IAutocompleteOption | IAutocompleteOption[] | null) => {
    node.setDataValue(field, v);
    [...(getRelatedNodes ? getRelatedNodes(node) : [])].forEach((n) => n.setDataValue(field, v));
    onChange && onChange(v);
  };
  return (
    <Autocomplete
      disableClearable={disableClearable}
      disabled={disabled}
      error={error}
      hideErrorIcon
      onBlur={onBlur}
      onChange={onChangeHandler}
      options={options}
      placeholder={placeholder}
      value={value as IAutocompleteOption | IAutocompleteOption[] | null | undefined}
    />
  );
};

export default AutocompleteCellRenderer;
