import { DataGridColDef } from 'components/UI/DataGrid';

import { getUTCTimeToShowStr } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';

import { ActionsCell, PitchedByCell, PitchedToCell, PitchesStatusCell, PitchesTitleCell, PitchViewsCell } from './';
import { IPitchContact } from 'types';

export enum PitchTypes {
  manual = 'MANUAL',
  system = 'SYSTEM',
}

export enum PitchStatuses {
  active = 'ACTIVE',
  expired = 'EXPIRED',
  manual = 'MANUAL',
  deactivated = 'DEACTIVATED',
}

export interface IPitchStatus {
  id: number;
  type: PitchStatuses;
  title: string;
}

export const pitchStatusesData: IPitchStatus[] = [
  { id: 1, type: PitchStatuses.active, title: 'Active' },
  { id: 2, type: PitchStatuses.expired, title: 'Expired' },
  { id: 3, type: PitchStatuses.manual, title: 'Manual' },
  { id: 3, type: PitchStatuses.deactivated, title: 'Deactivated' },
];

export interface IFilterPitchType {
  id: number;
  type: PitchTypes;
  title: string;
}

export const pitchTypesData: IFilterPitchType[] = [
  { id: 1, type: PitchTypes.system, title: 'System' },
  { id: 2, type: PitchTypes.manual, title: 'Manual' },
];

export enum PitchesModuleFilterTitles {
  title = 'Title',
  status = 'Status',
  pitchType = 'Pitch Type',
  pitchedTo = 'Pitched To',
  pitchedBy = 'Pitched By',
  sent = 'Sent',
  team = 'Team',
}

export enum PitchesModuleFilterKeys {
  title = 'title',
  status = 'status',
  pitchType = 'pitchType',
  pitchedTo = 'pitchedTo',
  pitchedBy = 'pitchedBy',
  sent = 'sent',
  team = 'team',
}

export type PitchesModuleFilterDataType = {
  [K in PitchesModuleFilterKeys]: null | string | string[];
};

export const defaultPitchesModuleFilterData: PitchesModuleFilterDataType = {
  [PitchesModuleFilterKeys.title]: '',
  [PitchesModuleFilterKeys.status]: [],
  [PitchesModuleFilterKeys.pitchType]: [],
  [PitchesModuleFilterKeys.pitchedTo]: '',
  [PitchesModuleFilterKeys.pitchedBy]: '',
  [PitchesModuleFilterKeys.sent]: '', // Date
  [PitchesModuleFilterKeys.team]: [],
};

export enum PitchesColumnFields {
  id = 'id',
  title = 'title',
  status = 'status',
  pitchType = 'pitchType',
  pitchedTo = 'pitchedTo',
  pitchedBy = 'pitchedBy',
  sentDate = 'sentDate',
  expirationDate = 'expirationDate',
  team = 'team',
  views = 'views',
  actions = 'actions',
  type = 'type',
}

export const hiddenPitchesColumnsTablet: PitchesColumnFields[] = [
  PitchesColumnFields.status,
  PitchesColumnFields.pitchType,
  PitchesColumnFields.pitchedBy,
  PitchesColumnFields.expirationDate,
  PitchesColumnFields.team,
  PitchesColumnFields.views,
  PitchesColumnFields.type,
];

export const hiddenPitchesColumnsMobile: PitchesColumnFields[] = [
  ...hiddenPitchesColumnsTablet,
  PitchesColumnFields.pitchedTo,
  PitchesColumnFields.sentDate,
];

export type IPitchesData = {
  contacts: IPitchContact[];
  id: string;
  pitchedBy: null | string;
  sentDate: string;
  status: string;
  team: string;
  title: null | string;
  type: string;
  viewCount: number;
};

export enum PitchesColumnHeaderNames {
  title = 'Title',
  status = 'Status',
  pitchType = 'Pitch Type',
  pitchedTo = 'Pitched To',
  pitchedBy = 'Pitched By',
  sentDate = 'Sent Date',
  team = 'Team',
  views = 'Views',
  actions = 'Actions',
}

export const pitchesColumns: DataGridColDef<IPitchesData>[] = [
  {
    field: PitchesColumnFields.title,
    headerName: PitchesColumnHeaderNames.title,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellRendererFramework: ({ data }) => <PitchesTitleCell title={data?.title as string} />,
  },
  {
    field: PitchesColumnFields.status,
    headerName: PitchesColumnHeaderNames.status,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellRendererFramework: ({ data }) => <PitchesStatusCell status={data?.status as string} />,
    minWidth: 120,
  },
  {
    field: PitchesColumnFields.pitchType,
    headerName: PitchesColumnHeaderNames.pitchType,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    minWidth: 120,
    maxWidth: 120,
    valueGetter: ({ data }) => data?.type,
  },
  {
    field: PitchesColumnFields.pitchedTo,
    headerName: PitchesColumnHeaderNames.pitchedTo,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    minWidth: 120,
    cellRendererFramework: ({ data }) => (
      <PitchedToCell pitchedTo={(data?.contacts.length ? data?.contacts[0].contact : '') as string} />
    ),
  },
  {
    field: PitchesColumnFields.pitchedBy,
    headerName: PitchesColumnHeaderNames.pitchedBy,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    minWidth: 120,
    cellRendererFramework: ({ data }) => <PitchedByCell pitchedBy={data?.pitchedBy || ''} />,
  },
  {
    field: PitchesColumnFields.sentDate,
    headerName: PitchesColumnHeaderNames.sentDate,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    maxWidth: 130,
    minWidth: 120,
    valueGetter: ({ data }) => (data.sentDate ? getUTCTimeToShowStr(data.sentDate) : ''),
  },
  {
    field: PitchesColumnFields.team,
    headerName: PitchesColumnHeaderNames.team,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    minWidth: 120,
  },
  {
    field: PitchesColumnFields.views,
    headerName: PitchesColumnHeaderNames.views,
    headerClass: globalStyles.f13h16SuisseSB_grayDarker,
    cellClass: globalStyles.f13h16SuisseREG_grayDarker,
    cellRendererFramework: ({ data }) =>
      data?.type === PitchTypes.system ? <PitchViewsCell views={Number(data?.viewCount)} /> : '-',
    maxWidth: 90,
  },
  {
    field: PitchesColumnFields.actions,
    headerName: PitchesColumnHeaderNames.actions,
    suppressColumnsToolPanel: true,
    cellClass: 'actionsCell',
    pinned: 'right',
    width: 94,
    maxWidth: 94,
    cellRendererFramework: ({ data, node }) => (
      <ActionsCell node={node} id={data?.id} type={data?.type} title={data?.title || ''} status={data?.status} />
    ),
  },
  {
    field: 'filters',
    hide: true,
    suppressColumnsToolPanel: true,
  },
];

export enum PitchesTableTypes {
  my = 'created_by_me',
  team = 'created_by_my_team',
}

interface IConfirmPopupSetting {
  mainText: string;
  btnDoneText: string;
  handleNotificationText: string;
}

export type IConfirmPopupSettings = {
  [K in ConfirmPopupTypes]: IConfirmPopupSetting;
};

export enum ConfirmPopupTypes {
  deactivate = 'deactivate',
  reactivate = 'reactivate',
  delete = 'delete',
}

export const confirmPopupConfigs: IConfirmPopupSettings = {
  [ConfirmPopupTypes.deactivate]: {
    mainText: 'This action will make the pitch link no longer available.',
    btnDoneText: 'Deactivate Link',
    handleNotificationText: 'Link Deactivated',
  },
  [ConfirmPopupTypes.reactivate]: {
    mainText: 'This will email a new pitch link to the contacts.',
    btnDoneText: 'Reactivate Pitch',
    handleNotificationText: 'Pitch Reactivated',
  },
  [ConfirmPopupTypes.delete]: {
    mainText: 'This will permanently delete the manual pitch from the system, proceed?',
    btnDoneText: 'Delete Manual Pitch',
    handleNotificationText: 'Pitch Deleted',
  },
};
