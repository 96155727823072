import classNames from 'classnames';

import { ReactComponent as HeartIcon } from 'assets/heart.svg';
import styles from './MusicPlayer.module.scss';

import { IFavoriteContainerProps, LikeDislike } from './IMusicPlayer';

const FavoriteContainer = ({ favoriteConfig, toggleFavorite, disableIcon }: IFavoriteContainerProps) => {
  return (
    <div
      className={classNames(styles.heartContainer, {
        [styles.favorite]: favoriteConfig.isFavorite && !favoriteConfig.animation,
        [styles.like]: favoriteConfig.animation === LikeDislike.like,
        [styles.dislike]: favoriteConfig.animation === LikeDislike.dislike,
        [styles.disabledIcon]: disableIcon,
      })}
      onClick={toggleFavorite}
    >
      <HeartIcon className={styles.heartIcon} />
      <HeartIcon className={styles.heartBackground} />
    </div>
  );
};

export default FavoriteContainer;
