import React from 'react';
import { useHistory } from 'react-router-dom';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import classNames from 'classnames';

import { NotificationsList } from 'components/Reusable';

import { EDIT_TOOLTIP_CONTAINER_ID, Paths } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './NotificationsPopup.module.scss';

interface INotificationsPopupProps {
  children: JSX.Element;
  isOpen: boolean;
  handleClose: () => void;
}

const NotificationsPopup = ({ children, isOpen, handleClose }: INotificationsPopupProps) => {
  const history = useHistory();

  const handleTooltipClose = (
    e: React.MouseEvent<Document, MouseEvent> | React.ChangeEvent<Record<string, unknown>>
  ) => {
    if (!isOpen) return;

    const path =
      (e as unknown as { path: Element[] }).path ||
      ((e as unknown as Event).composedPath && (e as unknown as Event).composedPath());

    if (path?.find((item: Element) => item.id === EDIT_TOOLTIP_CONTAINER_ID)) return;

    handleClose();
  };

  const onClickSeeAll = () => {
    handleClose();
    history.push(Paths.notifications);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose} touchEvent={'onTouchEnd'} disableReactTree>
      <Tooltip
        classes={{
          popper: styles.notificationsPopper,
          tooltip: styles.notificationsTooltip,
        }}
        onClose={handleTooltipClose}
        open={isOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <div id={EDIT_TOOLTIP_CONTAINER_ID} className={styles.notificationsContainer}>
            <NotificationsList isInPopup handleClose={handleClose} />
            <button onClick={onClickSeeAll} className={classNames(styles.seeAllBtn, globalStyles.f13h16SuisseSB_black)}>
              See All
            </button>
          </div>
        }
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  );
};

export default NotificationsPopup;
