import { ColDef, ValueGetterFunc, ValueGetterParams } from 'ag-grid-community';
import classNames from 'classnames';

import { CellRendererProps, DataGridColDef } from 'components/UI/DataGrid';

import styles from './Songs.module.scss';

import { ISongData } from './data';

interface IExpandedRowProps {
  p: CellRendererProps<DataGridColDef<ISongData>>;
}

const ExpandedRow = ({ p }: IExpandedRowProps) => {
  const hiddenColumns = p.api.getColumnDefs().filter((col: ColDef) => col.hide);
  const pData = { ...p.data } as ISongData;

  return (
    <div className={styles.detailRowContainer}>
      <div className={styles.hiddenColItemsContainer}>
        {hiddenColumns.map((item) =>
          item.headerName ? (
            <div key={item.headerName} className={styles.hiddenColItemContainer}>
              <span className={styles.expandedHeaderNames}>{item.headerName}</span>
              <div className={'cellClass' in item ? classNames(item.cellClass) : ''}>
                {'cellRendererFramework' in item ? (
                  item?.cellRendererFramework(p)
                ) : 'valueGetter' in item && item?.valueGetter ? (
                  <span className={styles.defaultValueGetter}>
                    {(item.valueGetter as ValueGetterFunc)(p as unknown as ValueGetterParams)}
                  </span>
                ) : 'field' in item && item.field ? (
                  pData[item.field as keyof ISongData]
                ) : (
                  '-'
                )}
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default ExpandedRow;
