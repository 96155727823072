import { Dispatch, MouseEvent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getFavoritePlaylistId, getFavoritesIdsObj, setIsExpandedPlaybar } from 'store';
import { DEFAULT_ERROR_CONFIG } from 'utils';

import { ReactComponent as ArrowUpIcon } from 'assets/arrow.svg';
import styles from './MusicPlayer.module.scss';

import { IPlayControlsProps, IPlayerNotificationsProps, PlaybarHeaderTitle, PlayControls, PlayerActions } from '.';
import { IActionMarker, IAddToPlaylist, IFavoriteConfig, IPlaybarHeaderTitleProps, LikeDislike } from './IMusicPlayer';
import { ICurrentTrack } from 'types';

interface IPlaybarHeaderProps
  extends IPlayControlsProps,
    Omit<IPlayerNotificationsProps, 'isFavorite' | 'addToPlaylist'>,
    Omit<IPlaybarHeaderTitleProps, 'toggleFavorite'> {
  setFavoriteConfig: Dispatch<SetStateAction<IFavoriteConfig>>;
  setAddToPlaylist: Dispatch<SetStateAction<IAddToPlaylist>>;
  actionMarker: (props: IActionMarker) => void;
  isExpanded: boolean;
  updateTracks: (newTrack: ICurrentTrack) => Promise<void>;
  isExternal?: boolean;
  isDark?: boolean;
}

const PlaybarHeader = ({
  favoriteConfig,
  track,
  setFavoriteConfig,
  openFavoriteSnackbar,
  setOpenFavoriteSnackbar,
  aRef,
  setProgress,
  setDuration,
  setTime,
  setTrack,
  tracks,
  onTrackChange,
  onProgress,
  actionMarker,
  changeOpenPopup,
  checkIsPopup,
  popupContainerRef,
  isExpanded,
  setAddToPlaylist,
  updateTracks,
  isExternal,
  isDark,
}: IPlaybarHeaderProps) => {
  const dispatch = useDispatch();

  const { favoritesIdsObj, favoritePlaylistId } = useMemoSelector((state) => ({
    favoritesIdsObj: getFavoritesIdsObj(state),
    favoritePlaylistId: getFavoritePlaylistId(state),
  }));

  const [loading, setLoading] = useState<boolean>(false);

  const isFavorite = useMemo(() => favoritesIdsObj[track?.id || ''], [favoritesIdsObj, track?.id]);

  const toggleFavorite = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (loading) return;

    setLoading(true);

    const { isFavorite: isFavoriteState } = favoriteConfig;
    const { like, dislike } = LikeDislike;

    const response = isFavoriteState
      ? await Api.removeFromFavorite([track.id], favoritePlaylistId, { errorPopupConfig: DEFAULT_ERROR_CONFIG })
      : await Api.addToFavorite([track.id], favoritePlaylistId, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    setLoading(false);
    if (!response) return;

    setFavoriteConfig({
      isFavorite: !isFavoriteState,
      animation: isFavoriteState ? dislike : like,
    });

    !openFavoriteSnackbar && setOpenFavoriteSnackbar(true);
  };

  useEffect(() => {
    setFavoriteConfig({ isFavorite, animation: '' });
  }, [isFavorite, setFavoriteConfig]);

  return (
    <div className={classNames(styles.playBarHeader, { [styles.expanded]: isExpanded })}>
      <button onClick={() => dispatch(setIsExpandedPlaybar(true))} className={classNames(styles.expandActionBtn)}>
        <ArrowUpIcon />
      </button>
      <PlaybarHeaderTitle
        track={track}
        favoriteConfig={favoriteConfig}
        toggleFavorite={toggleFavorite}
        isDark={isDark}
      />
      <PlayControls
        updateTracks={updateTracks}
        track={track}
        aRef={aRef}
        setTrack={setTrack}
        setDuration={setDuration}
        setProgress={setProgress}
        setTime={setTime}
        tracks={tracks}
        onProgress={onProgress}
        onTrackChange={onTrackChange}
        actionMarker={actionMarker}
        changeOpenPopup={changeOpenPopup}
        checkIsPopup={checkIsPopup}
        popupContainerRef={popupContainerRef}
        favoriteConfig={favoriteConfig}
        toggleFavorite={toggleFavorite}
        isExternal={isExternal}
      />
      <PlayerActions
        isExternal={isExternal}
        aRef={aRef}
        checkIsPopup={checkIsPopup}
        changeOpenPopup={changeOpenPopup}
        popupContainerRef={popupContainerRef}
        track={track}
        setAddToPlaylist={setAddToPlaylist}
        setOpenFavoriteSnackbar={setOpenFavoriteSnackbar}
      />
    </div>
  );
};

export default PlaybarHeader;
