import { getFormattedDate } from 'utils';

export interface DateCellRendererProps {
  value?: unknown;
}

const DateCellRenderer = ({ value }: DateCellRendererProps): string =>
  value instanceof Date || typeof value === 'string' ? getFormattedDate(value) : '';

export default DateCellRenderer;
