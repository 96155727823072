import { useState } from 'react';
import { RowNode } from 'ag-grid-community';

import TextField from 'components/UI/TextField';

import { useDidUpdate } from 'hooks';
import { isEmpty } from 'utils';

import { ErrorType, ISongUploadItem, ISongUploadItemExt } from 'types';

export interface IInputCellProps {
  colKey: keyof ISongUploadItem;
  disabled?: boolean;
  error?: boolean;
  getRelatedNodes?: (node: RowNode) => RowNode[];
  iconPlaceWidth?: number;
  iconRender?: string | JSX.Element;
  /**
   * Works with number type only
   */
  max?: number;
  maxLength?: number;
  /**
   * Works with number type only
   */
  min?: number;
  node: RowNode;
  placeholder?: string;
  required?: boolean;
  type?: string;
  value?: string | number | null;
}

const getSafeValue = (value: string | number | null | undefined): string | number => {
  return isEmpty(value) ? '' : (value as string | number);
};

const InputCell = ({
  colKey,
  disabled,
  error,
  getRelatedNodes,
  iconPlaceWidth,
  iconRender,
  max,
  maxLength,
  min,
  node,
  placeholder,
  required,
  type,
  value,
}: IInputCellProps) => {
  const [inputValue, setInputValue] = useState(() => getSafeValue(value));
  useDidUpdate(() => setInputValue(getSafeValue(value)), [value]);

  const onBlur = () => {
    const data = node.data as ISongUploadItemExt;
    const errors = { ...(data.state?.errors || {}) };
    required && isEmpty(inputValue, true) ? (errors[colKey] = ErrorType.Required) : delete errors[colKey];
    node.setDataValue(colKey, inputValue);
    node.setDataValue('state', { ...(data.state || {}), errors });
    [...(getRelatedNodes ? getRelatedNodes(node) : [])].forEach((n) => n.setDataValue(colKey, inputValue));
  };

  return (
    <TextField
      disabled={disabled}
      error={Boolean(node.data.state?.errors && node.data.state.errors[colKey]) || error}
      iconPlaceWidth={iconPlaceWidth}
      iconRender={iconRender}
      max={max}
      maxLength={maxLength}
      min={min}
      onBlur={onBlur}
      onChange={(v) => setInputValue(getSafeValue(v))}
      placeholder={placeholder}
      type={type}
      value={inputValue}
    />
  );
};

export default InputCell;
