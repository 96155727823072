import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import HoldRequestStatusTabs from 'components/AdminControls/HoldRequestStatusTabs';
import { AdminControlsEditPopup, AdminControlsFiltersPopup, ConfirmPopup, NotificationPopup } from 'components/Popups';
import { confirmPopupConfig, IConfirmPopupState } from 'components/Popups/AdminControlsEditPopup/data';
import { HoldRequestTable, PageHeader, Tab, TableFilters, Tabs } from 'components/Reusable';
import { DataGridColDef } from 'components/UI/DataGrid';
import { autoSizeActionsColumn } from 'components/UI/DataGrid/utils';
import { UserStatuses } from 'components/UI/StatusCard/data';
import MobileWrapper from 'containers/MobileWrapper';

import { useDebounce, useMemoSelector, useUnMount, useWindowSize } from 'hooks';
import Api, { updateStatusTabValues, UserStatusActions } from 'services/Api';
import {
  getAdminControlsActiveEditingUserData,
  getAdminControlsActiveEditingUserRowId,
  getAdminControlsConfirmPopupType,
  getAdminControlsFiltersData,
  getAdminControlsIsOpenFiltersPopup,
  getIsOpenSidebar,
  getUserIsTeamAdmin,
  getUserRole,
  setAdminControls,
  setHoldRequest,
} from 'store';
import { HoldRequestActions, IUserData } from 'store/reducers/general/types';
import {
  ADMIN_PAGE_CONTAINER_ID,
  ADMIN_USER_ROLES,
  DEFAULT_ERROR_CONFIG,
  Paths,
  SCREEN_BREAKPOINTS,
  UserRoleCodes,
} from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './AdminControls.module.scss';

import { AdminControlsTable, FeaturedPlaylistTable, StatusTabs } from '.';
import {
  AdminControlsFilterTitles,
  ColumnFields,
  columns as columnsData,
  defaultAdminControlsFilterData,
  UserRoles,
} from './data';
import { AdminControlsFilterKeys, HoldRequestTypes, IAdminControlsNestedData } from 'types';

const AdminControls = (): JSX.Element => {
  const history = useHistory();
  const { search, hash } = useLocation();
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowSize();

  const {
    isOpenSidebar,
    confirmPopupType,
    activeEditingUserData,
    activeEditingUserRowId,
    filtersData,
    isOpenAdminControlsFiltersPopup,
    userRole,
    isTeamAdmin,
  } = useMemoSelector((state) => ({
    isOpenSidebar: getIsOpenSidebar(state),
    confirmPopupType: getAdminControlsConfirmPopupType(state),
    activeEditingUserData: getAdminControlsActiveEditingUserData(state),
    activeEditingUserRowId: getAdminControlsActiveEditingUserRowId(state),
    filtersData: getAdminControlsFiltersData(state),
    isOpenAdminControlsFiltersPopup: getAdminControlsIsOpenFiltersPopup(state),
    userRole: getUserRole(state),
    isTeamAdmin: getUserIsTeamAdmin(state),
  }));

  const [columns, setColumns] = useState<DataGridColDef<IUserData>[]>(columnsData);
  const [confirmPopupSetting, setConfirmPopupSetting] = useState<IConfirmPopupState>({
    isOpen: false,
    ...confirmPopupConfig.archive,
  });
  const [notificationSettings, setNotificationSettings] = useState({ isOpen: false, text: '' });

  const gridRef = useRef<GridApi | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const isOnlyTeamAdmin = useMemo(() => !ADMIN_USER_ROLES.includes(userRole) && isTeamAdmin, [isTeamAdmin, userRole]);
  const holdId = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get('holdId');
  }, [search]);

  const changeColumns = useDebounce(() => {
    const { desktopBig, desktopSmall, tablet } = SCREEN_BREAKPOINTS;
    const { team, role, isTeamAdmin, territory, filters } = ColumnFields;

    const isHideColumns =
      (windowWidth < desktopBig && windowWidth >= desktopSmall && isOpenSidebar) ||
      (windowWidth < desktopSmall && windowWidth >= tablet);

    setColumns((prevState) =>
      prevState.map((col) =>
        col.field === filters
          ? col
          : [role, team, territory, isTeamAdmin].includes(col.field as ColumnFields) && isHideColumns
          ? { ...col, hide: true }
          : { ...col, hide: false }
      )
    );

    setTimeout(() => autoSizeActionsColumn(gridRef.current), 100);
  }, 100);

  const handleClose = () => {
    dispatch(setAdminControls({ openConfirmPopupType: '' }));
    setConfirmPopupSetting({ ...confirmPopupSetting, isOpen: false });
  };

  const handleNotification = async (text: string, type?: UserStatusActions | '') => {
    let response;

    if (type) {
      response = await Api.changeUserStatus(
        { body: { id: activeEditingUserData?.id }, path: type },
        { errorPopupConfig: { ...DEFAULT_ERROR_CONFIG, containerId: ADMIN_PAGE_CONTAINER_ID } }
      );
    }

    const activeNode = gridRef.current?.getRowNode(activeEditingUserRowId || '');

    if (response) {
      if (!filtersData.status || filtersData.status === 'All') {
        activeNode?.setDataValue(ColumnFields.status, response.status);
      } else {
        activeNode?.deleteRow();

        if (activeNode?.expanded) {
          activeNode.expanded = false;
        }
      }

      setTimeout(() => {
        gridRef.current?.refreshCells({ force: true, columns: [ColumnFields.actions, ColumnFields.index] });
      }, 10);

      await updateStatusTabValues();
      setNotificationSettings({ isOpen: true, text });
      handleClose();
    }
  };

  const deleteTag = (type: AdminControlsFilterKeys): void => {
    const isActiveStatusSelected =
      type === AdminControlsFilterKeys.status &&
      (filtersData[type] as IAdminControlsNestedData)?.title === UserStatuses.ACTIVE;

    dispatch(
      setAdminControls({
        filtersData: {
          [type]: defaultAdminControlsFilterData[type],
          ...(isActiveStatusSelected && {
            [AdminControlsFilterKeys.territory]: null,
            [AdminControlsFilterKeys.role]: null,
            [AdminControlsFilterKeys.team]: null,
          }),
        },
      })
    );
  };

  useEffect(() => {
    setConfirmPopupSetting({
      ...(confirmPopupType ? confirmPopupConfig[confirmPopupType] : confirmPopupSetting),
      isOpen: !!confirmPopupType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPopupType]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => changeColumns(), [windowWidth, isOpenSidebar]);

  useEffect(() => {
    if (hash.toString().replace('#tab=', '') !== 'holdRequest' || !holdId) return;

    dispatch(
      setHoldRequest({
        action: { isOpen: true, holdId, type: HoldRequestActions.approve },
      })
    );
  }, [dispatch, hash, holdId]);

  useUnMount(() => dispatch(setAdminControls({ filtersData: defaultAdminControlsFilterData })));

  if ([UserRoleCodes.ArrowReadOnly, UserRoles.ARROW_READ].includes(userRole as unknown as UserRoles)) {
    history.replace(Paths.dashboard);
    return <></>;
  }

  return (
    <MobileWrapper>
      <div
        ref={parentRef}
        className={classNames(styles.adminControls, globalStyles.wrapper)}
        id={ADMIN_PAGE_CONTAINER_ID}
      >
        <PageHeader title={isOnlyTeamAdmin ? 'Team Admin' : undefined} />
        {isOnlyTeamAdmin ? (
          <Tabs className={styles.tabs} contentClassName={styles.tabContent}>
            <Tab label="Hold Request" value="holdRequest">
              <HoldRequestStatusTabs />
              <HoldRequestTable type={HoldRequestTypes.team} height="calc(100% - 90px - 25px - 20px - 14px)" />
            </Tab>
            <Tab label="Featured Playlist" value="featuredPlaylist">
              <FeaturedPlaylistTable />
            </Tab>
          </Tabs>
        ) : (
          <Tabs className={styles.tabs} contentClassName={styles.tabContent}>
            <Tab label="User Management" value="userManagement">
              <StatusTabs />
              <TableFilters
                data={filtersData}
                isOpenFiltersPopup={isOpenAdminControlsFiltersPopup}
                onDeleteTag={deleteTag}
                onClickFilterIcon={() => dispatch(setAdminControls({ isOpenFiltersPopup: true }))}
                dataTitles={AdminControlsFilterTitles}
                onlyFirstCharCapitalizedItems={[AdminControlsFilterKeys.status]}
              />
              <AdminControlsTable columns={columns} setColumns={setColumns} gridRef={gridRef} />
              <AdminControlsFiltersPopup parent={parentRef} />
              <AdminControlsEditPopup parent={parentRef} gridRef={gridRef} />
            </Tab>
            <Tab label="Hold Request" value="holdRequest">
              <HoldRequestStatusTabs />
              <HoldRequestTable type={HoldRequestTypes.team} height="calc(100% - 90px - 25px - 20px - 14px)" />
            </Tab>
            <Tab label="Featured Playlist" value="featuredPlaylist">
              <FeaturedPlaylistTable />
            </Tab>
          </Tabs>
        )}
        <ConfirmPopup
          isOpen={confirmPopupSetting.isOpen}
          setIsOpen={() => dispatch(setAdminControls({ openConfirmPopupType: '' }))}
          questionText={confirmPopupSetting.questionText}
          mainText={confirmPopupSetting.mainText}
          btnCancelText={confirmPopupSetting.btnCancelText}
          btnDoneText={confirmPopupSetting.btnDoneText}
          onClickSubmit={() =>
            handleNotification(confirmPopupSetting.handleNotificationText, confirmPopupSetting.statusAction)
          }
        />
        <NotificationPopup
          isOpen={notificationSettings.isOpen}
          setIsOpen={() => setNotificationSettings({ ...notificationSettings, isOpen: false })}
          text={notificationSettings.text}
        />
      </div>
    </MobileWrapper>
  );
};

export default AdminControls;
