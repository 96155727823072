import { DateCellRenderer } from 'components/Reusable';
import { CellRendererProps, DataGridColDef } from 'components/UI/DataGrid';

import styles from './Contacts.module.scss';

import { IContact } from '.';

const RowBlock = ({ label, text }: { label: string; text?: string | JSX.Element }) => (
  <div className={styles.rowBlock}>
    <label className={styles.label}>{label}</label>
    <div>{text || '—'}</div>
  </div>
);

const ExpandedRow = (props: CellRendererProps<DataGridColDef<IContact>>) => {
  const data = props.node.data as IContact;
  return (
    <div className={styles.expandedRow}>
      <RowBlock label="Organization" text={data.organizationName} />
      <RowBlock label="Team" text={data.team} />
      <RowBlock label="Created By" text={data.createdBy} />
      <RowBlock label="Created On" text={data.createdOn ? DateCellRenderer({ value: data.createdOn }) : undefined} />
    </div>
  );
};

export default ExpandedRow;
