import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useMemoSelector } from 'hooks';
import { getAdditionalRoutes, getRoutes } from 'store';
import { cookiesPolicyUrl, COPYRIGHT_YEAR, IRoute, oneTrustDomainId, Paths } from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Routes.module.scss';

import RoutesList from './RoutesList';

interface IRoutesProps {
  itemClassName: string;
  activeItemClassName: string;
  itemsContainerClassName?: string;
  hideClassName?: string;
  onClick?: (route: IRoute) => void;
  isMobileVersion?: boolean;
  hideInMobileClassName?: string;
}

const Routes = ({
  onClick,
  itemClassName,
  activeItemClassName,
  hideClassName,
  isMobileVersion,
  itemsContainerClassName,
  hideInMobileClassName,
}: IRoutesProps): JSX.Element | null => {
  const { routes, additionalRoutes } = useMemoSelector((state) => ({
    routes: getRoutes(state),
    additionalRoutes: getAdditionalRoutes(state),
  }));

  // Note: This is fixing the bug when the user second time opens the Dropdown and clicks Cookies Settings
  useEffect(() => {
    // @ts-ignore
    if (typeof window !== 'undefined' && typeof window?.Optanon?.initializeCookiePolicyHtml === 'function') {
      // @ts-ignore
      window.Optanon.initializeCookiePolicyHtml();
    }
  }, []);

  return (
    <>
      <div className={itemsContainerClassName}>
        <RoutesList
          itemClassName={itemClassName}
          activeItemClassName={activeItemClassName}
          hideClassName={hideClassName}
          routes={routes}
          onClick={onClick}
        />
      </div>
      {isMobileVersion && (
        <>
          <div className={itemsContainerClassName}>
            <RoutesList
              itemClassName={itemClassName}
              activeItemClassName={activeItemClassName}
              hideClassName={hideClassName}
              routes={additionalRoutes}
              onClick={onClick}
              hideInMobileClassName={hideInMobileClassName}
            />
          </div>
          {oneTrustDomainId && cookiesPolicyUrl && (
            <div className={itemsContainerClassName}>
              <Link to={Paths.termsOfUse} className={globalStyles.f11h13SuisseREG_black}>
                Terms of Service
              </Link>
              <Link to={Paths.privacyPolicy} className={globalStyles.f11h13SuisseREG_black}>
                Privacy
              </Link>
              <a
                href="https://www.wminewmedia.com/cookies-policy/?ot=363a2072-dc6c-4f93-8d3d-255778c71a20"
                target="_blank"
                rel="noreferrer"
                className={globalStyles.f11h13SuisseREG_black}
              >
                Cookies Policy
              </a>
              <a
                className={classNames(
                  'ot-sdk-show-settings',
                  globalStyles.f11h13SuisseREG_black,
                  styles.cookiesSettings
                )}
              >
                Cookies Settings
              </a>
              <span className={classNames(globalStyles.f9h11SuisseREG_black, styles.copyright)}>
                © {COPYRIGHT_YEAR} Warner Chappell Music, Inc.
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Routes;
