import { Tooltip } from 'components/UI';

import globalStyles from 'styles/modules/Global.module.scss';

interface IExternalContactCell {
  contactExternal?: string;
}

const ExternalContactCell = ({ contactExternal }: IExternalContactCell) => {
  return (
    <Tooltip text={contactExternal || ''}>
      <span className={globalStyles.f13h16SuisseREG_grayDarker}>{contactExternal}</span>
    </Tooltip>
  );
};

export default ExternalContactCell;
