import React from 'react';
import { Snackbar } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import { TransitionProps } from '@material-ui/core/transitions';

interface INotificationProps {
  open: boolean;
  onClose?: () => void;
  message: JSX.Element;
  containerClassName?: string;
  contentClassName?: string;
  autoHideDuration?: number;
  TransitionComponent?: React.ComponentType<TransitionProps & { children?: React.ReactElement<never, never> }>;
}

const SnackbarContainer = ({
  open,
  onClose,
  message,
  containerClassName,
  contentClassName,
  autoHideDuration,
  TransitionComponent,
}: INotificationProps): JSX.Element => {
  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      classes={{
        root: containerClassName,
      }}
      ContentProps={{
        className: contentClassName,
        style: {
          border: '1px solid #e5e5e5',
        },
      }}
      open={open}
      onClose={onClose}
      message={message}
      TransitionComponent={(TransitionComponent as never) || Grow}
      TransitionProps={{ appear: false }}
    />
  );
};

export default SnackbarContainer;
