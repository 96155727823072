import { useState } from 'react';
import { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { RowNodeExt } from 'components/UI/DataGrid';

import { useDidUpdate } from 'hooks';
import { isEmpty } from 'utils';

import { ReactComponent as ChevronDownIcon } from 'assets/arrow.svg';
import styles from './SongsUpload.module.scss';

import { ErrorType, ISongUploadItem, ISongUploadItemExt } from 'types';

export interface ISelectOption {
  id: string | number;
  shortTitle?: string;
  title: string;
}

export interface ISelectCellProps {
  colKey: keyof ISongUploadItem;
  disabled?: boolean;
  node: RowNodeExt;
  options: ISelectOption[];
  required?: boolean;
  value?: string | number | null;
}

const menuProps: Partial<MenuProps> = {
  anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
  classes: { paper: styles.selectPaper, list: styles.selectList },
  getContentAnchorEl: null,
};

const getSafeValue = (value: string | number | null | undefined): string | number => {
  return isEmpty(value) ? '' : (value as string | number);
};

const SelectCell = ({ colKey, disabled, node, options, required, value }: ISelectCellProps) => {
  const [v, setV] = useState(() => getSafeValue(value));
  useDidUpdate(() => setV(getSafeValue(value)), [value]);

  const data = node.data as ISongUploadItemExt;

  const onBlur = () => {
    const errors = { ...(data.state?.errors || {}) };
    required && isEmpty(v) ? (errors[colKey] = ErrorType.Required) : delete errors[colKey];
    node.setDataValue(colKey, v);
    node.setDataValue('state', { ...(data.state || {}), errors });
  };
  const renderValue = (selected: unknown) => {
    const option = options.find((c) => c.id === selected);
    return <>{option?.shortTitle || option?.title || option?.id || ''}</>;
  };

  return (
    <Select
      className={styles.selectCell}
      disabled={disabled}
      error={Boolean(data.state?.errors && data.state.errors[colKey])}
      IconComponent={ChevronDownIcon}
      MenuProps={menuProps}
      onBlur={onBlur}
      onChange={(e) => setV(e.target.value as string | number)}
      renderValue={renderValue}
      value={v}
      variant="outlined"
    >
      {options.map(({ id, title }) => (
        <MenuItem key={id} value={id}>
          {title}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectCell;
