import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ClickAwayListener } from '@material-ui/core';
import classNames from 'classnames';

import { cookiesPolicyUrl, COPYRIGHT_YEAR, oneTrustDomainId, Paths } from 'utils';

import { ReactComponent as ArrowIcon } from 'assets/arrow.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Dropdown.module.scss';

export interface IDropdownItems {
  id?: number | string;
  title?: string;
  name?: string;
  hide?: boolean;
  pageHeaderName?: string;
}

export interface IDropdownProps<T> {
  items?: T[];
  defaultSelectedItemId?: number;
  showSelected?: boolean;
  onClickItem?: (item: T) => void;
  activeItemId?: string | number;
  isVisible?: boolean;
  listContainerClassName?: string;
  selectItem?: boolean;
  showFooterLinks?: boolean;
  onClose?: () => void;
}

function Dropdown<T extends IDropdownItems>({
  items,
  defaultSelectedItemId,
  showSelected,
  onClickItem,
  activeItemId,
  isVisible,
  listContainerClassName,
  selectItem,
  showFooterLinks = false,
  onClose,
}: IDropdownProps<T>): JSX.Element | null {
  const [selectedItem, setSelectedItem] = useState(
    items ? items.find((elem) => elem.id === defaultSelectedItemId) || items[0] : ({} as IDropdownItems)
  );
  const [isVisibleItems, setIsVisibleItems] = useState(isVisible ?? false);

  const onCloseDropdown = () => {
    setIsVisibleItems(false);
    onClose && onClose();
  };

  const onClickDropdownItem = (item: T) => {
    setSelectedItem(item);
    onCloseDropdown();
    onClickItem && onClickItem(item);
  };

  // Note: This is fixing the bug when the user second time opens the Dropdown and clicks Cookies Settings
  useEffect(() => {
    if (!isVisibleItems) return;
    // @ts-ignore
    if (typeof window !== 'undefined' && typeof window?.Optanon?.initializeCookiePolicyHtml === 'function') {
      // @ts-ignore
      window.Optanon.initializeCookiePolicyHtml(true);
    }
  }, [isVisibleItems]);

  useEffect(() => {
    if (!activeItemId || !items) return;

    const activeItem = items.find((item) => item.id === activeItemId);

    if (!activeItem) return;

    setSelectedItem(activeItem);
  }, [activeItemId, items]);

  return !items?.length ? null : (
    <ClickAwayListener onClickAway={onCloseDropdown} touchEvent="onTouchEnd" disableReactTree>
      <div>
        {showSelected ? (
          <div className={styles.selectedItemContainer} onClick={() => setIsVisibleItems(!isVisibleItems)}>
            {selectedItem.title || selectedItem.name || selectedItem.pageHeaderName}
            <div className={styles.iconContainer}>
              <ArrowIcon />
            </div>
          </div>
        ) : null}
        {isVisibleItems ? (
          <ul
            className={classNames(styles.dropdownList, listContainerClassName, {
              [styles.withBorder]: showFooterLinks,
            })}
          >
            {items.map((item) =>
              (selectedItem && selectedItem.id === item.id && showSelected) || item.hide ? null : (
                <div
                  key={item.id}
                  className={classNames(styles.dropdownItem, {
                    [styles.activeDropdownItem]: selectItem && selectedItem?.id === item.id && !showSelected,
                  })}
                  onClick={() => onClickDropdownItem(item)}
                >
                  {item.title || item.name}
                </div>
              )
            )}
            {showFooterLinks && oneTrustDomainId && cookiesPolicyUrl && (
              <div className={styles.footerLinksContainer}>
                <Link to={Paths.termsOfUse} className={globalStyles.f11h13SuisseREG_black}>
                  Terms of Service
                </Link>
                <Link to={Paths.privacyPolicy} className={globalStyles.f11h13SuisseREG_black}>
                  Privacy
                </Link>
                <a
                  href="https://www.wminewmedia.com/cookies-policy/?ot=363a2072-dc6c-4f93-8d3d-255778c71a20"
                  target="_blank"
                  rel="noreferrer"
                  className={globalStyles.f11h13SuisseREG_black}
                >
                  Cookies Policy
                </a>
                <a
                  className={classNames(
                    'ot-sdk-show-settings',
                    globalStyles.f11h13SuisseREG_black,
                    styles.cookiesSettings
                  )}
                >
                  Cookies Settings
                </a>
                <span className={classNames(globalStyles.f9h11SuisseREG_black, styles.copyright)}>
                  © {COPYRIGHT_YEAR} Warner Chappell Music, Inc.
                </span>
              </div>
            )}
          </ul>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}

export default Dropdown;
