import { AddNotes, AddStatus, AddTags } from './';
import { AddPopupTypes, IAddPopupConfigs } from 'types';

type AddPopupConfigsType = {
  [K in AddPopupTypes]: IAddPopupConfigs;
};

export const addPopupConfigs: AddPopupConfigsType = {
  [AddPopupTypes.tags]: {
    component: AddTags,
    type: AddPopupTypes.tags,
  },
  [AddPopupTypes.notes]: {
    component: AddNotes,
    type: AddPopupTypes.notes,
  },
  [AddPopupTypes.status]: {
    component: AddStatus,
    type: AddPopupTypes.status,
  },
};
