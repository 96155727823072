import 'react-quill/dist/quill.snow.css';

import { useRef } from 'react';
import { forwardRef, KeyboardEvent } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useMount } from 'hooks';
import { setError } from 'store';
import { isValidURL } from 'utils';

import styles from './TextEditor.module.scss';

interface ITextEditorProps {
  value?: string;
  className?: string;
  onChange?: (content: string) => void;
  toolbar?: string[];
  formats?: string[];
  maxLength?: number;
}

const TextEditor = forwardRef(
  ({ onChange, toolbar, formats, className, value = '', maxLength }: ITextEditorProps, ref) => {
    const dispatch = useDispatch();
    const quillRef = useRef<ReactQuill>(null);

    const onHandleKeyDown = (event: KeyboardEvent) => {
      if (maxLength && (event.target as HTMLInputElement).innerText.length >= maxLength && event.key !== 'Backspace')
        event.preventDefault();
    };

    useMount(() => {
      const input = document.querySelector('input[data-link]') as HTMLInputElement;
      input.dataset.link = 'https://www.arrow.com';
      input.placeholder = 'https://www.arrow.com';

      if (!quillRef.current) return;
      // @ts-ignore
      quillRef.current.editor.theme.tooltip.save = function () {
        this.textbox.value = this.textbox.value.trim();

        if (this.textbox.value.indexOf('http') === -1) {
          this.textbox.value = 'https://' + this.textbox.value;
        }

        if (isValidURL(this.textbox.value) && this.textbox.value?.length < 250) {
          // Using Quill API to interact with the editor
          this.quill.blur(); // Example API method
          // Continue with your logic
        } else {
          dispatch(setError({ title: 'Invalid URL', text: '', isOpen: true }));
        }
      };
    });

    return (
      <div className="text-editor">
        <ReactQuill
          theme="snow"
          modules={{
            toolbar: toolbar ? [toolbar] : [['bold', 'italic', 'underline', 'link']],
          }}
          formats={formats || ['bold', 'italic', 'underline', 'link']}
          className={classNames(styles.textEditor, className)}
          onChange={(content) => onChange && onChange(content)}
          onKeyDown={onHandleKeyDown}
          ref={(element) => {
            // @ts-ignore
            quillRef.current = element;
            if (ref) {
              if (typeof ref === 'function') {
                ref(element);
              } else {
                ref.current = element;
              }
            }
          }}
          value={value}
          bounds=".text-editor"
        />
      </div>
    );
  }
);
export default TextEditor;
