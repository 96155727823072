import classNames from 'classnames';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Pitches.module.scss';

const PitchesStatusCell = ({ status }: { status?: string }) => {
  return <span className={classNames(styles.pitchesStatusCell, globalStyles.f11h13SuisseREG_gray)}>{status}</span>;
};

export default PitchesStatusCell;
