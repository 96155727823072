import { ColDef, ValueGetterFunc, ValueGetterParams } from 'ag-grid-community';

import { CellRendererProps, DataGridColDef } from 'components/UI/DataGrid';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Pitches.module.scss';

import { IPitchesData } from './data';

interface IExpandedRowProps {
  p: CellRendererProps<DataGridColDef<IPitchesData>>;
}

const ExpandedRow = ({ p }: IExpandedRowProps) => {
  const hiddenColumns = p.api.getColumnDefs().filter((col: ColDef) => col.hide);
  const pData = { ...p.data } as IPitchesData;

  return (
    <div className={styles.detailRowContainer}>
      <div className={styles.hiddenColItemsContainer}>
        {hiddenColumns.map((col: ColDef) =>
          col.headerName ? (
            <div key={col.headerName} className={styles.hiddenColItemContainer}>
              <div className={globalStyles.f11h13SuisseSB_gray}>{col.headerName}</div>
              <div className={globalStyles.f13h16SuisseREG_grayDarker}>
                {'cellRendererFramework' in col
                  ? col?.cellRendererFramework(p)
                  : 'valueGetter' in col && col?.valueGetter
                  ? (col.valueGetter as ValueGetterFunc)(p as unknown as ValueGetterParams)
                  : 'field' in col && col.field
                  ? pData[col.field as keyof IPitchesData]
                  : '-'}
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default ExpandedRow;
