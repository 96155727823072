import { GridApi, RowNode } from 'ag-grid-community';

// Extend Ag-Grid API methods
declare module 'ag-grid-community' {
  class RowNode {
    public deleteRow(): void;
  }

  class GridApi {
    public stateReducers: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setRowData: (value: React.SetStateAction<any[] | undefined>) => void;
      setTotal: (value: React.SetStateAction<number | undefined>) => void;
    };
  }
}

// Calling RowNode.prototype ends up with ReferenceError
// That's why it passed here this way
const extendGridFeatures = (node: typeof RowNode) => {
  // Extend Ag-Grid API methods
  node.prototype.deleteRow = function () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const api: GridApi = (this as any).beans?.gridApi;
    if (!api) {
      // eslint-disable-next-line no-console
      console.error(`Can't bind api in deletRow prototype`);
      return;
    }
    if (!api.stateReducers) {
      // eslint-disable-next-line no-console
      console.warn(`No stateReducers bound in on grid ready event`);
      return;
    }
    api.stateReducers.setRowData((data) => (data || []).filter((_, i) => i !== this.rowIndex));
    api.stateReducers.setTotal((total) => (total ? total - 1 : total));
  };
};

export default extendGridFeatures;
