import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { songGenresList } from 'components/Popups/DesktopSearch/data';

import { useMemoSelector } from 'hooks';
import { getUserPermissions, setSongDetails } from 'store';
import { capitalizeOnlyFirstChar, Paths } from 'utils';

import { ReactComponent as AiTagIcon } from 'assets/ai-tag.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/plus-circle.svg';
import styles from './SongDetails.module.scss';

import { addPopupConfigs } from './addPopupConfigs';
import { RemoveActionTypes } from './data';
import { AddPopupTypes, ISongTag, SongTagsTypes } from 'types';

interface ISongTagsProps {
  tags: ISongTag[];
}

const SongTags = ({ tags }: ISongTagsProps) => {
  const dispatch = useDispatch();

  const permissions = useMemoSelector(getUserPermissions);

  const onDeleteTag = (e: MouseEvent<SVGSVGElement>, text: string, id: string) => {
    e.preventDefault();

    dispatch(
      setSongDetails({
        deletePopupConfig: {
          isOpen: true,
          questionText: `Delete "${text}" Tag?`,
          mainText: 'You can add tags back later',
          notificationText: 'Tag Deleted',
          type: RemoveActionTypes.tag,
          id,
        },
      })
    );
  };

  return (
    <div className={styles.songDetailsTagsContainer}>
      <span className={styles.grayTitle}>Tags</span>
      <div className={styles.songDetailsTagsList}>
        {tags.map((item) => {
          const genre = songGenresList.find((el) => el.title === item.type);

          const query = genre
            ? `genre=${encodeURIComponent(genre.title)}&subGenre=${encodeURIComponent(item.tag)}`
            : `${item.type}=${encodeURIComponent(item.tag)}`;

          return (
            <a
              href={`${window.location.origin}${Paths.search}?${query}`}
              target="_blank"
              className={classNames(styles.songDetailsTag, {
                [styles.withoutDeleteIcon]: !permissions.songDetailsActions,
              })}
              key={item.id}
              rel="noreferrer"
            >
              {item?.isAiGenerated && permissions.showAiTagIcon && <AiTagIcon className={styles.aiTagIcon} />}
              {capitalizeOnlyFirstChar(item.type)}: {item.tag}
              {permissions.songDetailsActions && (
                <DeleteIcon
                  className={styles.deleteIcon}
                  onClick={(e) =>
                    onDeleteTag(
                      e,
                      `${SongTagsTypes[item.type as never] || SongTagsTypes.subGenre}: ${
                        item.tag.length > 50 ? `${item.tag.slice(0, 50)}...` : item.tag
                      }`,
                      item?.id || ''
                    )
                  }
                />
              )}
            </a>
          );
        })}
        {permissions.songDetailsActions && (
          <PlusIcon
            onClick={() =>
              dispatch(setSongDetails({ addPopupConfig: { ...addPopupConfigs[AddPopupTypes.tags], isOpen: true } }))
            }
          />
        )}
      </div>
    </div>
  );
};

export default SongTags;
