import classNames from 'classnames';

import { IMarker } from 'components/MusicPlayer/IMusicPlayer';

import { useWindowSize } from 'hooks';
import { getIsMobile, getReadableTime } from 'utils';

import { ReactComponent as CloseIcon } from 'assets/close-2.svg';
import { ReactComponent as ExpandIcon } from 'assets/expand.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './MusicPlayer.module.scss';
import { ReactComponent as PlayIcon } from './icons/play.svg';

interface IMarkersContainerMobileProps {
  markers: IMarker[];
  toggleViewMode: () => void;
  onMobileMarkerPlayBtnClick: (markerTime: number) => void;
  openMobileMarkersView?: boolean;
}

const MarkersContainerMobile = ({
  markers,
  openMobileMarkersView,
  toggleViewMode,
  onMobileMarkerPlayBtnClick,
}: IMarkersContainerMobileProps) => {
  const { width: windowWidth } = useWindowSize();

  return markers.length && getIsMobile(windowWidth) ? (
    <div className={classNames(styles.markersContainerMobile, { [styles.viewModeOn]: openMobileMarkersView })}>
      <div className={styles.markersHeaderMobile}>
        <div className={styles.titleContainerMobile}>
          <span className={globalStyles.f13h16SuisseSB_white}>Markers</span>
          <span className={classNames(styles.countMobile, globalStyles.f9h11SuisseREG_black)}>{markers.length}</span>
        </div>
        <button className={styles.viewBtn} onClick={toggleViewMode}>
          <span className={classNames(globalStyles.f11h13SuisseSB_white, styles.viewBtnText)}>
            {openMobileMarkersView ? 'Close' : 'View'}
          </span>
          {openMobileMarkersView ? <CloseIcon /> : <ExpandIcon />}
        </button>
      </div>
      <div className={styles.markersListMobile}>
        {markers.map(({ id, title, markerTime }) => (
          <div key={id} className={styles.markerItemMobile}>
            <PlayIcon className={styles.markerPlayIconMobile} onClick={() => onMobileMarkerPlayBtnClick(markerTime)} />
            <span className={classNames(globalStyles.f13h16SuisseREG_white, styles.markerTitleMobile)}>{title}</span>
            <span className={globalStyles.f13h16SuisseREG_white}>{getReadableTime(markerTime)}</span>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default MarkersContainerMobile;
