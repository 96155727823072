import { useRef } from 'react';

import useMount from './useMount';

const useIsComponentMounted = () => {
  const isMountedRef = useRef(false);

  useMount(() => {
    isMountedRef.current = true;
  });

  return isMountedRef.current;
};

export default useIsComponentMounted;
