import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import IGeneralState from 'store/reducers/general/types';

import generalSlice from './reducers/general';

export interface IReduxState {
  general: IGeneralState;
}

export const reducer = combineReducers({
  general: generalSlice.reducer,
});

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

export const {
  actions: {
    resetGeneral,
    setSelectedRowIndex,
    setLoading,
    setActiveNavItemId,
    setIsOpenSidebar,
    toggleIsOpenTopMenu,
    toggleIsOpenSearch,
    setIsOpenMobileMockup,
    setSearchData,
    setSearchItemsNum,
    setUserProfile,
    setAdminControls,
    setSongsModule,
    setPitchesModule,
    setIsExpandedPlaybar,
    setDownloadPopupConfig,
    setError,
    setIsOpenShowHideColumns,
    setSongDetails,
    setRecentEditedSongs,
    setIsMarkersVisible,
    setFavoritesList,
    setCurrentTracks,
    setActiveTrack,
    setPlaylistFilter,
    setChangedPlaylistId,
    updatePlaylistHash,
    updateFeaturedPlaylistHash,
    setCurrentPlaylistId,
    setFavoritesData,
    setPitchDetails,
    setShareConfig,
    setHoldRequest,
    setMainConfirmPopup,
    setMainNotification,
    setRecentPlayedList,
    setEditPlaylist,
    setNotificationsConfig,
    setPlaybarPlayingState,
  },
} = generalSlice;

export const { getState, dispatch } = store;
export * from './selectors';

export function getSelectors<T>(processor: (state: IReduxState) => T): T {
  return processor(getState());
}
export type RootState = ReturnType<typeof reducer>;
export type AppStore = typeof store;
export default store;
