import { useParams } from 'react-router-dom';
import { GridApi } from 'ag-grid-community';

import { PlayButton } from 'components/Reusable';

import { getDataGridItems } from 'utils';

import { ISongMedias } from 'types';

const PlayIconCell = ({ data, api }: { data?: ISongMedias; api: GridApi }) => {
  const { id } = useParams<{ id: string }>();

  return (
    <PlayButton
      extension={data?.playbackPath}
      mediaId={data?.id}
      processing={typeof data?.processing === 'number' ? data.processing : undefined}
      getTracks={() =>
        getDataGridItems<ISongMedias>(api).map((item) => ({
          mediaId: item.id,
          id: item.draft ? undefined : id,
          title: item.songTitle || '',
          waveForm: JSON.parse(item.waveform || '[]'),
          writers: item.writers?.map((item) => item.name || '') || [],
          path: item.path,
          playbackPath: item.playbackPath,
        }))
      }
    />
  );
};

export default PlayIconCell;
