import { MouseEvent } from 'react';
import { batch, useDispatch } from 'react-redux';
import { GridApi } from 'ag-grid-community';

import { Tooltip } from 'components/UI';

import { useMemoSelector } from 'hooks';
import { getActiveTrack, setActiveTrack, setCurrentTracks } from 'store';
import { getDataGridItems, immediatelyPlayAudio } from 'utils';

import { ReactComponent as PlayCircleIcon } from 'assets/play-circle.svg';
import { ReactComponent as WaveformIcon } from 'assets/waveform.svg';
import styles from './Songs.module.scss';

import { ISongData } from './data';
import { ICurrentTrack, IHoldRequestData } from 'types';

const PlayIconCell = ({ data, api }: { data?: ISongData | IHoldRequestData; api: GridApi }) => {
  const dispatch = useDispatch();

  const activeTrack = useMemoSelector(getActiveTrack);

  const onPlay = async (e: MouseEvent) => {
    e.stopPropagation();

    if (!data?.mediaId) return;

    const rows = (getDataGridItems(api) as ISongData[]).reduce((total, item) => {
      return [
        ...total,
        ...(item.mediaId
          ? [
              {
                mediaId: item.mediaId,
                id: (item as unknown as IHoldRequestData).songId || item.id || '',
                title: item.title || '',
                path: item.mediaPath || '',
                playbackPath: item.mediaPlaybackPath || '',
                writers: item?.writers,
                version: item.version || '',
              },
            ]
          : []),
      ];
    }, [] as ICurrentTrack[]);

    batch(() => {
      dispatch(setCurrentTracks(rows));
      dispatch(setActiveTrack({ index: rows.findIndex((el) => el.mediaId === data?.mediaId) }));
    });

    immediatelyPlayAudio();
  };

  return data?.mediaId ? (
    [activeTrack.mediaId, activeTrack.songId].includes(data.mediaId) ? (
      <WaveformIcon onClick={(e) => e.stopPropagation()} />
    ) : (
      <PlayCircleIcon onClick={onPlay} />
    )
  ) : (
    <Tooltip text="No Audio">
      <PlayCircleIcon className={styles.disablePlayButton} onClick={(e) => e.stopPropagation()} />
    </Tooltip>
  );
};

export default PlayIconCell;
