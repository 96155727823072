import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { MobileMockup } from 'components/Popups';
import { SwitchBtn, Tooltip } from 'components/UI';

import { useMemoSelector, useWindowSize } from 'hooks';
import Api, { downloadAudio } from 'services/Api';
import {
  getCurrentTracks,
  getIsOpenSidebar,
  getUserDefaultTeam,
  getUserPermissions,
  setError,
  setLoading,
  setMainNotification,
  setRecentEditedSongs,
  setShareConfig,
} from 'store';
import { ShareItemTypes } from 'store/reducers/general/types';
import {
  ARCHIVE_ERROR_CONFIG,
  checkIfSongIsInFeaturedPlaylist,
  DEFAULT_ERROR_CONFIG,
  getIsMobile,
  getSongVersion,
  Paths,
} from 'utils';

import { ReactComponent as InfoIcon } from 'assets/info.svg';
import styles from './MusicPlayer.module.scss';

import { PlayerMoreActions, playerMoreData } from './data';
import { IPlayerPopupsProps, PlayerPopups, TrackInfo } from './IMusicPlayer';
import { SongStatusTypes, SongsWorkTypes } from 'types';

interface IPlayerMorePopupProps extends IPlayerPopupsProps {
  changeOpenPopup: (type: PlayerPopups | '') => void;
  track: TrackInfo;
}

const PlayerMorePopup = ({ isShow, popupContainerRef, changeOpenPopup, track }: IPlayerMorePopupProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { width: windowWidth } = useWindowSize();

  const { isOpenSidebar, currentTracks, permissions, userDefaultTeam } = useMemoSelector((state) => ({
    isOpenSidebar: getIsOpenSidebar(state),
    currentTracks: getCurrentTracks(state),
    permissions: getUserPermissions(state),
    userDefaultTeam: getUserDefaultTeam(state),
  }));

  const onClickAction = async (type: PlayerMoreActions) => {
    changeOpenPopup('');

    const currentTrack = currentTracks.find((item) => item.mediaId === track.id);

    switch (type) {
      case PlayerMoreActions.download:
        currentTrack && (await downloadAudio(track.path, `${track.title} (${getSongVersion(track.version)})`));
        break;
      case PlayerMoreActions.share:
        dispatch(setShareConfig({ isOpen: true, songId: track.id, type: ShareItemTypes.song }));
        break;
      case PlayerMoreActions.songDetails:
        currentTrack && history.push(`${Paths.songs}/${currentTrack.id}`);
        break;
      case PlayerMoreActions.archive: {
        if (!currentTrack || !track.songId) return;

        if (userDefaultTeam?.id !== track?.teamId) {
          return dispatch(setError(ARCHIVE_ERROR_CONFIG));
        }

        dispatch(setLoading(true));

        const res = await Api.archiveSongs([track.songId], { errorPopupConfig: DEFAULT_ERROR_CONFIG });

        if (!res) {
          dispatch(setLoading(false));
          return;
        }

        batch(() => {
          dispatch(setMainNotification('Song Archived'));
          dispatch(
            setRecentEditedSongs({
              songs: [
                { ids: { mediaId: track.id, songId: track.songId }, update: { status: SongStatusTypes.ARCHIVED } },
              ],
            })
          );
          dispatch(setLoading(false));
        });
        break;
      }
      case PlayerMoreActions.songQueue:
      default:
        break;
    }
  };

  const onChangeLabelView = async (checked: boolean) => {
    if (!track.songId) return;

    dispatch(setLoading(true));

    const res = await Api.changeSongLabelView(track.songId, checked, {
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
    });

    if (!res) {
      dispatch(setLoading(false));
      return;
    }

    dispatch(
      setRecentEditedSongs({
        songs: [{ ids: { mediaId: track.id, songId: track.songId }, update: { labelView: checked } }],
      })
    );
    dispatch(setLoading(false));
  };

  return getIsMobile(windowWidth) ? (
    <MobileMockup />
  ) : (
    <>
      {isShow ? (
        <div
          className={classNames(styles.moreContainer, {
            [styles.moreContainerAdditional]: !isOpenSidebar,
          })}
          ref={popupContainerRef}
        >
          {permissions.playbarLabelView && (
            <SwitchBtn
              checked={track.labelView}
              label={
                <>
                  <p>Label view</p>
                  <Tooltip text="When activated, this song will be visible to non-WCM Label users.">
                    <InfoIcon />
                  </Tooltip>
                </>
              }
              labelPlacement="start"
              labelClassName={classNames(styles.moreItem, styles.label)}
              onChangeSwitch={(checked) =>
                checkIfSongIsInFeaturedPlaylist(!!track?.featured, checked, onChangeLabelView)
              }
            />
          )}
          {playerMoreData.map((item) => {
            if (item.type === PlayerMoreActions.download && !permissions.playbarDownload) {
              return null;
            }

            if (
              item.type === PlayerMoreActions.archive &&
              (track.type === SongsWorkTypes.work || !permissions.playbarArchive)
            ) {
              return null;
            }

            return (
              <div
                key={item.id}
                className={classNames(styles.moreItem, {
                  // Note: this is temporary
                  [styles.disabledItem]: item.type === PlayerMoreActions.songQueue,
                })}
                onClick={() => onClickAction(item.type)}
              >
                {item.title}
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default PlayerMorePopup;
