import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { RowNodeExt } from 'components/UI/DataGrid';

import { useMemoSelector } from 'hooks';
import { getIsOpenSidebar, setPitchDetails } from 'store';

import { ReactComponent as ArrowDownIcon } from 'assets/arrow.svg';
import { ReactComponent as RemoveIcon } from 'assets/remove.svg';
import styles from './PitchDetails.module.scss';

interface IActionsCellProps {
  node: RowNodeExt;
  id?: string | null;
  title?: string;
  isManualPitch: boolean;
}

const ActionsCell = ({ node, id, title, isManualPitch }: IActionsCellProps) => {
  const dispatch = useDispatch();

  const isOpenSidebar = useMemoSelector(getIsOpenSidebar);

  const onClickDeleteMedia = async () => {
    dispatch(
      setPitchDetails({
        deletePopupConfig: {
          isOpen: true,
          id: id || '',
          rowId: node.id?.replace('detail_', '') as string,
          title: title || '',
        },
      })
    );
  };

  return (
    <div className={styles.actionsCellContainer}>
      <ArrowDownIcon
        className={classNames(styles.expandIcon, { [styles.expandIconIsOpenSidebar]: isOpenSidebar })}
        onClick={() => node?.setDetailsRow && node.setDetailsRow(!node.expanded, 70, true)}
      />
      {isManualPitch && <RemoveIcon onClick={onClickDeleteMedia} />}
    </div>
  );
};

export default ActionsCell;
