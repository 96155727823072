import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { Tooltip } from 'components/UI';
import { RowNodeExt } from 'components/UI/DataGrid';

import { useMemoSelector } from 'hooks';
import { updateHoldRequestStatusTabValues } from 'services/Api';
import { getHoldRequestActiveTab, setHoldRequest } from 'store';
import { HoldRequestActions } from 'store/reducers/general/types';

import { ReactComponent as RejectIcon } from 'assets/reject.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './HoldRequestTable.module.scss';

import { HoldRequestTabKeys, HoldRequestTypes, HoldStatuses } from 'types';

export interface IActionsCellProps {
  holdId: string;
  status?: HoldStatuses;
  type: HoldRequestTypes;
  node: RowNodeExt;
  api: GridApi;
}

const ActionsCell = ({ holdId, type, status, node, api }: IActionsCellProps) => {
  const dispatch = useDispatch();
  const activeTab = useMemoSelector(getHoldRequestActiveTab);

  const refreshCells = () => {
    setTimeout(() => {
      api.refreshCells({ force: true });
    }, 10);
  };

  const updateNode = async (newStatus: HoldStatuses) => {
    await updateHoldRequestStatusTabValues();

    if (activeTab === HoldRequestTabKeys.AWAITING_APPROVAL) {
      node.deleteRow();
    } else {
      node.setDataValue('status', newStatus);
    }
    refreshCells();
  };

  const onHandleApproveCancel = (e: MouseEvent, type: HoldRequestActions) => {
    e.stopPropagation();
    dispatch(setHoldRequest({ action: { isOpen: true, holdId, type, cb: updateNode } }));
  };

  return (
    <div className={styles.actionsCellContainer}>
      {type === HoldRequestTypes.user ? (
        status === HoldStatuses.AWAITING_APPROVAL ? (
          <Tooltip text="Cancel Hold Request">
            <RejectIcon onClick={(e) => onHandleApproveCancel(e, HoldRequestActions.cancel)} />
          </Tooltip>
        ) : null
      ) : (
        <span
          onClick={(e) => onHandleApproveCancel(e, HoldRequestActions.approve)}
          className={classNames(globalStyles.f13h16SuisseREG_grayDarker, styles.viewRequest)}
        >
          View Request
        </span>
      )}
    </div>
  );
};

export default ActionsCell;
