import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { Controller, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import { songCategories } from 'components/Popups/DesktopSearch/data';
import { InputAsyncAutocomplete, NewAutocomplete as Autocomplete, NewTextField as TextField } from 'components/UI';
import { IInputAutocompleteOption } from 'components/UI/InputAsyncAutocomplete';

import { useDidUpdate } from 'hooks';
import Api from 'services/Api';

import { ISongUploadItem } from 'types';

export interface ITagsFormProps {
  item?: ISongUploadItem;
}

export type TagsFormApi = UseFormReturn<ISongUploadItem, Record<string, unknown>>;

const TagsForm = forwardRef(({ item }: ITagsFormProps, ref) => {
  const methods = useForm<ISongUploadItem>({ defaultValues: item || {} });
  const genre = useWatch({ control: methods.control, name: 'genre' });

  useDidUpdate(() => methods.reset(item), [item]);
  useDidUpdate(() => methods.setValue('subGenre', []), [genre]);
  useImperativeHandle(ref, () => ({ ...methods }));

  const genreOptions = useMemo(() => songCategories.find((c) => c.value === 'genre')?.data || [], []);
  const langOptions = useMemo(() => songCategories.find((c) => c.value === 'language')?.data || [], []);
  const moodOptions = useMemo(() => songCategories.find((c) => c.value === 'mood')?.data || [], []);
  const subGenreOptions = useMemo(
    () => genreOptions.find((g) => g.id === genre?.id)?.data || [],
    [genre, genreOptions]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Controller
          control={methods.control}
          name="genre"
          render={({ field }) => <Autocomplete {...field} label="Genre" options={genreOptions} />}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          control={methods.control}
          name="subGenre"
          render={({ field }) => (
            <Autocomplete
              {...field}
              disabled={!genre || !subGenreOptions.length}
              label="Sub-Genre"
              multiple
              options={subGenreOptions}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          control={methods.control}
          name="artists"
          render={({ field: { onChange, value } }) => (
            <InputAsyncAutocomplete
              multiple
              addCustomOption
              label="Artist"
              value={value as IInputAutocompleteOption[]}
              onChange={(_, value) => onChange(value)}
              onResolveSuggestions={(text) => Api.getArtists(text).catch(() => [])}
              disableClearable
              disableCloseOnSelect
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          control={methods.control}
          name="mood"
          render={({ field }) => <Autocomplete {...field} label="Mood" multiple options={moodOptions} />}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          control={methods.control}
          name="language"
          render={({ field }) => <Autocomplete {...field} label="Language" multiple options={langOptions} />}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          control={methods.control}
          name="bpm"
          render={({ field }) => <TextField {...field} label="BPM" max={220} type="number" />}
        />
      </Grid>
    </Grid>
  );
});

export default TagsForm;
