import styles from './ContactOption.module.scss';

import { IAutocompleteOption } from 'types';

export interface IContactOptionProps extends IAutocompleteOption {
  email?: string;
  team?: string;
}

const ContactOption = (props: IContactOptionProps) => (
  <div className={styles.contactOption}>
    <div>
      {props.title} <span className={styles.team}>({props.team || '-'})</span>
    </div>
    {props.email && <div className={styles.email}>{props.email}</div>}
  </div>
);

export default ContactOption;
