import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TabCard } from 'components/UI';

import { useMemoSelector, useMount } from 'hooks';
import Api from 'services/Api';
import { getAdminControlsFiltersData, getAdminControlsStatusTabValues, setAdminControls } from 'store';

import styles from './AdminControls.module.scss';

import { IAdminControlsData, STATUSES, tabsData } from './data';
import { StatusTabKeys } from 'types';

const StatusTabs = () => {
  const dispatch = useDispatch();

  const { filtersData, statusTabValues } = useMemoSelector((state) => ({
    filtersData: getAdminControlsFiltersData(state),
    statusTabValues: getAdminControlsStatusTabValues(state),
  }));

  const [activeTabId, setActiveTabId] = useState(1);

  const onTabClick = (id: number, status: string) => {
    const [defaultTab] = tabsData;

    setActiveTabId(id === activeTabId ? defaultTab.id : id);

    dispatch(
      setAdminControls({
        filtersData: {
          status:
            (filtersData.status as IAdminControlsData)?.code === status
              ? null
              : STATUSES.find((item) => item.code === status) || null,
          ...((status !== StatusTabKeys.ACTIVE || id === activeTabId) && {
            defaultTeam: null,
            defaultTerritory: null,
            role: null,
            isTeamAdmin: null,
          }),
        },
      })
    );
  };

  useEffect(() => {
    setActiveTabId(
      tabsData.find((item) => item.type === (filtersData.status as unknown as IAdminControlsData)?.code)?.id || 1
    );
  }, [filtersData.status]);

  useMount(() => {
    (async () => {
      const response = await Api.getStatusCount();
      response && dispatch(setAdminControls({ statusTabValues: response }));
    })();
  });

  return (
    <div className={styles.tabsContainer}>
      {tabsData.map(({ title, id, type, dataKey }, index) => (
        <TabCard
          isActive={activeTabId === id}
          key={index}
          title={title}
          value={statusTabValues[dataKey] as number}
          onClick={() => onTabClick(id, type)}
        />
      ))}
    </div>
  );
};

export default StatusTabs;
