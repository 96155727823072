import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { SongsSearchTypes } from 'components/Popups/DesktopSearch/data';
import { CircularProgressSpinner, EditTooltip, SongStatuses, WriterForm } from 'components/Reusable';
import { InputAsyncAutocomplete, InputText, SwitchBtn } from 'components/UI';
import { IInputAutocompleteOption } from 'components/UI/InputAsyncAutocomplete';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import {
  getUserPermissions,
  setError,
  setLoading,
  setMainConfirmPopup,
  setMainNotification,
  setRecentEditedSongs,
} from 'store';
import {
  checkIfSongIsInFeaturedPlaylist,
  DEFAULT_ERROR_CONFIG,
  getFormattedDate,
  Paths,
  SONG_TITLE_MAX_LENGTH,
} from 'utils';

import styles from './SongDetails.module.scss';

import { SongHeaderCols } from './data';
import { ISong, IWriterItem, SongsWorkTypes } from 'types';

const SongHeader = ({
  song,
  song: { title, writers, creationDate, createdByName, workCode, wcm, bpm, statuses, labelView, medias, type },
  updateSong,
}: {
  song: ISong;
  updateSong: (closeLoading?: boolean, newSong?: Partial<ISong>) => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const permissions = useMemoSelector(getUserPermissions);

  const [wcmInput, setWcmInput] = useState<number>(wcm || 0);
  const [bpmInput, setBpmInput] = useState<number>(bpm || 0);
  const [songTitle, setSongTitle] = useState<string>(title || '');
  const [isOpenAddNewWriter, setIsOpenAddNewWriter] = useState(false);
  const [writersState, setWritersState] = useState<IWriterItem[]>(writers);

  const disableEditable = type === SongsWorkTypes.work || !permissions.songDetailsActions;

  useEffect(() => {
    setWcmInput(wcm || 0);
    setBpmInput(bpm || 0);
    setSongTitle(title || '');
    setWritersState(writers);
  }, [bpm, wcm, title, writers]);

  const onUpdateSongDetails = async () => {
    dispatch(setLoading(true));

    if (!wcmInput) {
      setWcmInput(wcm || 0);
    }

    let isError = false;

    const songChangedData = {
      title: songTitle,
      bpm: bpmInput,
      wcm: (wcmInput || wcm) as number,
    };

    await Api.updateSongDetails(
      { ...songChangedData, ...(writersState.length && { writers: writersState.map((item) => `${item.id}`) }) },
      {
        pathId: id,
        handler: (_, status) => {
          if (status === 409) {
            isError = true;

            dispatch(
              setMainConfirmPopup({
                isOpen: true,
                questionText: 'New song details are available',
                mainText: 'Changes has been made to this song, please refresh to see the latest updates.\n',
                btnDoneText: 'Refresh',
                onClickSubmit: updateSong,
              })
            );
          } else {
            dispatch(setError(DEFAULT_ERROR_CONFIG));
          }
        },
      }
    );

    if (!isError) {
      dispatch(
        setRecentEditedSongs({
          songs: song.medias.map((media) => ({
            ids: { mediaId: media.id, songId: song.id },
            update: {
              ...songChangedData,
              writers: writersState as string[],
            },
          })),
        })
      );
    }
  };

  const onNewWriterAdded = ({ title, id }: IWriterItem) => {
    id && title && setWritersState([...writersState, { name: title, id }]);
    setIsOpenAddNewWriter(false);
  };

  const onChangeLabelView = async (checked: boolean) => {
    dispatch(setLoading(true));

    const res = await Api.changeSongLabelView(id, checked, {
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
    });

    if (!res) {
      dispatch(setLoading(false));
      return;
    }

    dispatch(
      setRecentEditedSongs({
        songs: song.medias.map((media) => ({
          ids: { mediaId: media.id, songId: song.id },
          update: {
            labelView: checked,
          },
        })),
      })
    );

    dispatch(setMainNotification('Label View Updated'));
  };

  return (
    <div className={styles.songHeader}>
      <EditTooltip
        Component={
          <InputText
            value={songTitle}
            onChangeInput={(value) => setSongTitle(value)}
            labelRootClass={styles.inputTextLabelRoot}
            name={'songTitle' as never}
            maxLength={SONG_TITLE_MAX_LENGTH}
          />
        }
        onCancel={() => setSongTitle(title || '')}
        disableEditing={disableEditable}
        onSubmit={onUpdateSongDetails}
      >
        <span className={styles.songTitle}>{title}</span>
      </EditTooltip>
      <EditTooltip
        disableEditing={disableEditable}
        disableClosing={isOpenAddNewWriter}
        onSubmit={onUpdateSongDetails}
        className={styles.writersContainer}
        Component={
          <>
            <InputAsyncAutocomplete
              value={writersState.map((item) => ({ id: item.id || '', title: item.name || '' }))}
              onChange={(_, value) => {
                setWritersState(
                  (value as IInputAutocompleteOption[])?.map((item) => ({ id: item.id, name: item.title }))
                );
              }}
              addNewButtonOnClick={() => setIsOpenAddNewWriter(true)}
              addNewButtonText="Add New Writer"
              className={styles.writersInput}
              multiple
              onResolveSuggestions={(text) => Api.getWriters(text).catch(() => [])}
              disablePortal
              disableClearable
              disableCloseOnSelect
            />
            {isOpenAddNewWriter && (
              <WriterForm onClose={() => setIsOpenAddNewWriter(false)} onSubmit={onNewWriterAdded} />
            )}
          </>
        }
        onCancel={() => setWritersState(writers)}
      >
        <>
          {writers?.length ? (
            writers.map((item, index, arr) => (
              <Fragment key={item.id}>
                <a
                  href={`${window.location.origin}${Paths.search}?searchQuery=${item.name}&searchType=${SongsSearchTypes.viewAllSongsWriters}`}
                  target="_blank"
                  className={styles.writers}
                  rel="noreferrer"
                >
                  {item.name}
                </a>
                {index < arr.length - 1 && <span className={styles.writersSeparator}> / </span>}
              </Fragment>
            ))
          ) : (
            <span className={styles.writers}>-</span>
          )}
        </>
      </EditTooltip>
      <div className={styles.songHeaderCols}>
        {permissions.viewSongDetailsHeader ? (
          <>
            <div>
              <span className={classNames(styles.grayTitle, styles.mb4)}>{SongHeaderCols.status}</span>
              {statuses.length ? <SongStatuses statuses={statuses} /> : '-'}
            </div>
            <div>
              <span className={classNames(styles.grayTitle, styles.mb4)}>{SongHeaderCols.createdOn}</span>
              <span className={styles.infoText}>{getFormattedDate(creationDate || '', '-')}</span>
            </div>
            <div>
              <span className={classNames(styles.grayTitle, styles.mb4)}>{SongHeaderCols.uploadedBy}</span>
              <span className={styles.infoText}>{createdByName}</span>
            </div>
            <div>
              <span className={classNames(styles.grayTitle, styles.mb4)}>{SongHeaderCols.workId}</span>
              <span className={styles.infoText}>{workCode}</span>
            </div>
            <div>
              <span className={classNames(styles.grayTitle, styles.mb4)}>{SongHeaderCols.wcm}</span>
              <EditTooltip
                Component={
                  <InputText
                    value={`${wcmInput}`}
                    onChangeInput={(value) => setWcmInput(Number(value))}
                    type="number"
                    labelRootClass={styles.inputTextLabelRoot}
                    name={'wcm' as never}
                  />
                }
                disableEditing={disableEditable}
                onCancel={() => setWcmInput(wcm || 0)}
                onSubmit={onUpdateSongDetails}
              >
                <CircularProgressSpinner value={wcm} showText={true} showNullAsDash={true} />
              </EditTooltip>
            </div>
            <div>
              <span className={classNames(styles.grayTitle, styles.mb4)}>{SongHeaderCols.bpm}</span>
              <EditTooltip
                Component={
                  <InputText
                    value={`${bpmInput}`}
                    onChangeInput={(value) => setBpmInput(Number(value))}
                    type="number"
                    labelRootClass={styles.inputTextLabelRoot}
                    name={'bpm' as never}
                    max={220}
                  />
                }
                disableEditing={disableEditable || !medias.length}
                onCancel={() => setBpmInput(bpm || 0)}
                onSubmit={onUpdateSongDetails}
              >
                <span className={styles.infoText}>{typeof bpm === 'number' ? bpm : '-'}</span>
              </EditTooltip>
            </div>
            <div className={styles.labelViewCol}>
              <span className={styles.grayTitle}>{SongHeaderCols.labelView}</span>
              {!permissions.songDetailsActions ? (
                <span className={styles.infoText}>{labelView ? 'Yes' : 'No'}</span>
              ) : (
                <SwitchBtn
                  checked={labelView}
                  label={labelView ? 'Yes' : 'No'}
                  labelPlacement="end"
                  labelClassName={styles.labelViewText}
                  switchRootClassName={styles.switchBtnRoot}
                  onChangeSwitch={(checked) =>
                    checkIfSongIsInFeaturedPlaylist(song?.featured, checked, onChangeLabelView)
                  }
                  disabledTooltipText={
                    !song?.medias?.length ? 'Please upload audio to allow Label Users to see this demo' : ''
                  }
                />
              )}
            </div>
          </>
        ) : null}
        {permissions.viewSongDetailsStatus && (
          <div>
            <span className={classNames(styles.grayTitle, styles.mb4)}>{SongHeaderCols.status}</span>
            {statuses.length ? <SongStatuses statuses={statuses} /> : null}
          </div>
        )}
        {permissions.viewSongDetailsBpm && (
          <div>
            <span className={classNames(styles.grayTitle, styles.mb4)}>{SongHeaderCols.bpm}</span>
            <EditTooltip
              Component={
                <InputText
                  value={`${bpmInput}`}
                  onChangeInput={(value) => setBpmInput(Number(value))}
                  type="number"
                  labelRootClass={styles.inputTextLabelRoot}
                  name={'bpm' as never}
                  max={220}
                />
              }
              disableEditing={disableEditable || !medias.length}
              onCancel={() => setBpmInput(bpm || 0)}
              onSubmit={onUpdateSongDetails}
            >
              <span className={styles.infoText}>{typeof bpm === 'number' ? bpm : '-'}</span>
            </EditTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default SongHeader;
