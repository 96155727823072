import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import classNames from 'classnames';

import { Logo } from 'components/Reusable';
import { UserStatuses } from 'components/UI/StatusCard/data';

import { useMemoSelector } from 'hooks';
import { dispatch, getUserInfo, setUserProfile } from 'store';
import { DEFAULT_USER, Paths } from 'utils';

import { ReactComponent as AccessLogoIcon } from 'assets/access-logo.svg';
import styles from './RequestAccessPage.module.scss';

const RequestAccessPage = (): JSX.Element => {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  const { email, status } = useMemoSelector(getUserInfo);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (email || status) {
      setLoading(false);
      return;
    }

    setLoading(true);

    (async () => {
      const session = await oktaAuth.session.get();

      if ([UserStatuses.ACTIVE, UserStatuses.INACTIVE].includes(session?.status as UserStatuses)) {
        await oktaAuth.signOut();
        history.replace(Paths.empty);
        return;
      }

      dispatch(
        setUserProfile({ user: { ...DEFAULT_USER.user, email: (session as unknown as { login: string }).login } })
      );

      setLoading(false);
    })();
  }, [email, history, oktaAuth, oktaAuth.session, status]);

  return loading ? (
    <></>
  ) : (
    <div className={styles.requestAccessContainer}>
      <Logo />
      <h1 className={styles.titleText}>
        {status === UserStatuses.PENDING ? 'Your access is pending' : 'You need access'}
      </h1>
      <span className={styles.infoText}>
        {status !== UserStatuses.PENDING ? (
          <>
            You can request access to arrow by <br className={styles.notPendingBreak} />
            <a href={Paths.support}>contacting us</a>
          </>
        ) : (
          <>
            Your access request has been submitted. We’ll send you <br className={styles.pendingBreak} /> an email once
            your account has been approved.
          </>
        )}
      </span>
      {status === UserStatuses.PENDING && (
        <span className={classNames(styles.infoText, styles.assistance)}>
          Need assistance? <a href={Paths.support}>Contact us</a>
        </span>
      )}
      {email && (
        <>
          <span className={classNames(styles.infoText, styles.signedInAs)}>Signed in as:</span>
          <div className={styles.tag}>
            <span>{email}</span>
          </div>
        </>
      )}
      <button onClick={() => oktaAuth.signOut()} className={styles.logoutBtn}>
        Logout
      </button>
      <AccessLogoIcon className={styles.accessLogo} />
    </div>
  );
};

export default RequestAccessPage;
