import ReactDOM from 'react-dom';

interface IWrapperComponentProps {
  children: JSX.Element;
  disablePortal?: boolean;
}

const Wrapper = ({ children }: IWrapperComponentProps) => {
  return ReactDOM.createPortal(children, document.body);
};

const WrapperComponent = ({ disablePortal, children }: IWrapperComponentProps) => {
  return disablePortal ? <Wrapper>{children}</Wrapper> : <>{children}</>;
};

export default WrapperComponent;
