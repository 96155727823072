import React from 'react';
import { GridApi } from 'ag-grid-community';

import { useMemoSelector } from 'hooks';
import { getActiveTrack } from 'store';

import { ReactComponent as PlayCircleIcon } from 'assets/play-circle.svg';
import { ReactComponent as WaveformIcon } from 'assets/waveform.svg';

import { IPitchMedia, playSong } from './data';

interface IPlayIconCellProps {
  api: GridApi;
  data?: IPitchMedia;
}

const PlayIconCell = ({ api, data }: IPlayIconCellProps) => {
  const activeTrack = useMemoSelector(getActiveTrack);

  return activeTrack.mediaId === data?.id ? (
    <WaveformIcon style={{ pointerEvents: 'none' }} />
  ) : (
    <PlayCircleIcon onClick={() => playSong(api, data)} />
  );
};

export default PlayIconCell;
