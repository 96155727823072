import { Tooltip as MaterialTooltip, Zoom } from '@material-ui/core';
import classNames from 'classnames';

import styles from './Tooltip.module.scss';

interface ITooltipProps {
  text?: string;
  children: JSX.Element;
  textAlign?: 'left' | 'right';
  isDark?: boolean;
  isError?: boolean;
}

const Tooltip = ({ text, children, textAlign, isDark, isError }: ITooltipProps) => {
  return text ? (
    <MaterialTooltip
      arrow
      classes={{
        popper: styles.popperTooltip,
        tooltip: classNames(styles.tooltip, styles[`textAlign${textAlign || ''}` as never], {
          [styles.dark]: isDark,
          [styles.error]: isError,
        }),
        arrow: styles.tooltipArrow,
      }}
      title={text}
      TransitionComponent={Zoom}
      placement="top"
    >
      {children}
    </MaterialTooltip>
  ) : (
    children
  );
};

export default Tooltip;
