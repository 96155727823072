import { IAddToPlaylist } from 'components/MusicPlayer/IMusicPlayer';

export const markerData = ['Intro', 'Verse', 'Pre-Chorus', 'Chorus', 'Post-Chorus', 'Bridge', 'Solo', 'Outro'];

export const recentPlaylists = [
  'New Songs 03.22.21',
  'KPOP',
  'Dude Vibes',
  'Alex Ryberg Pitch Songs',
  'Duet Power Ballads',
  'Songs for Atlantic UK',
  'New Songs 09.01.20',
];

export enum PlayerMoreActions {
  download = 'download',
  share = 'share',
  songQueue = 'songQueue',
  songDetails = 'songDetails',
  archive = 'archive',
}

interface IPlayerMoreData {
  id: number;
  title: string;
  type: PlayerMoreActions;
}

export const playerMoreData: IPlayerMoreData[] = [
  {
    id: 1,
    title: 'Download',
    type: PlayerMoreActions.download,
  },
  {
    id: 2,
    title: 'Share',
    type: PlayerMoreActions.share,
  },
  {
    id: 3,
    title: 'Archive',
    type: PlayerMoreActions.archive,
  },
  {
    id: 4,
    title: 'View Song Queue',
    type: PlayerMoreActions.songQueue,
  },
  {
    id: 5,
    title: 'Song Details',
    type: PlayerMoreActions.songDetails,
  },
];

export const defaultAddToPlaylist: IAddToPlaylist = {
  id: '',
  title: '',
};
