import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TabCard } from 'components/UI';

import { useMemoSelector, useMount } from 'hooks';
import { updateHoldRequestStatusTabValues } from 'services/Api';
import { getHoldRequestStatusTabValues, setHoldRequest } from 'store';

import styles from './AdminControls.module.scss';

import { holdRequestTabsData } from './data';
import { HoldRequestTabKeys } from 'types';

const HoldRequestStatusTabs = () => {
  const dispatch = useDispatch();

  const tabValues = useMemoSelector(getHoldRequestStatusTabValues);

  const [activeTabId, setActiveTabId] = useState(1);

  const onTabClick = (id: number) => {
    const [defaultTab] = holdRequestTabsData;

    setActiveTabId(id === activeTabId ? defaultTab.id : id);
  };

  useMount(() => {
    (async () => await updateHoldRequestStatusTabValues())();
  });

  useEffect(() => {
    const activeTabDataKey = holdRequestTabsData.find((item) => item.id === activeTabId)?.dataKey;

    if (!activeTabDataKey) return;

    dispatch(
      setHoldRequest({ activeTab: activeTabDataKey === HoldRequestTabKeys.AllStatuses ? null : activeTabDataKey })
    );
  }, [activeTabId, dispatch]);

  return (
    <div className={styles.tabsContainer}>
      {holdRequestTabsData.map(({ title, id, dataKey }, index) => (
        <TabCard
          isActive={activeTabId === id}
          key={index}
          title={title}
          value={tabValues[dataKey] as number}
          onClick={() => onTabClick(id)}
        />
      ))}
    </div>
  );
};

export default HoldRequestStatusTabs;
