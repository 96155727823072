import { GridApi } from 'ag-grid-community';

export interface PrivateExposure {
  gridBody: HTMLDivElement;
  bodyViewport: HTMLDivElement;
}

export const gridPrivateInvocation = <T = void>(
  api: GridApi | undefined | null = null,
  safeCallback: (e: PrivateExposure) => T
): T | null => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const a = api as any;
  if (!a) return null;

  const gridBodyCon = a.gridBodyCon;
  const e: PrivateExposure = {
    gridBody: gridBodyCon?.eGridBody as HTMLDivElement,
    bodyViewport: gridBodyCon?.eBodyViewport as HTMLDivElement,
  };

  try {
    return safeCallback(e);
  } catch (ex) {
    /* Ignore any errors */
    return null;
  }
};
