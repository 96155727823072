import classNames from 'classnames';

import { Tooltip } from 'components/UI';

import globalStyles from 'styles/modules/Global.module.scss';

const PitchedToCell = ({ pitchedTo }: { pitchedTo?: string }) => {
  return (
    <Tooltip text={pitchedTo || ''}>
      <span className={classNames(globalStyles.textEllipsis, globalStyles.f13h16SuisseREG_grayDarker)}>
        {pitchedTo || '-'}
      </span>
    </Tooltip>
  );
};

export default PitchedToCell;
