import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

import { ReactComponent as CloudIcon } from 'assets/cloud.svg';
import styles from './SongDropzone.module.scss';

interface ISongDropzone {
  onDrop?: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
}

const SongDropzone = ({ onDrop }: ISongDropzone) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      audio: ['.mp3', 'audio/wav', '.m4a', '.aiff', '.flac'],
    },
  });

  return (
    <div {...getRootProps()} className={styles.songsDropzone}>
      <input {...getInputProps()} />
      <div className={styles.songsDropzoneContent}>
        {isDragActive ? (
          <span className={styles.songsDropText}>Drop the files here ...</span>
        ) : (
          <>
            <CloudIcon />
            <span className={styles.songsDropText}>
              Drag and drop mp3, flac, aiff, wav, or m4a audio files to upload
            </span>
            <span className={styles.songsBrowse}>Browse Files</span>
          </>
        )}
      </div>
    </div>
  );
};

export default SongDropzone;
