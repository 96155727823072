import classNames from 'classnames';

import styles from './StatusCard.module.scss';

import { UserStatuses } from './data';
import { HoldStatuses } from 'types';

interface IStatusCardProps {
  value: UserStatuses | string | HoldStatuses;
  className?: string;
}

const StatusCard = ({ value, className }: IStatusCardProps) => {
  return (
    <div className={classNames(className, styles.statusContainer, styles[(value as string)?.toLowerCase() as never])}>
      {value}
    </div>
  );
};

export default StatusCard;
