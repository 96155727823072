import { useMemo } from 'react';
import { GridApi } from 'ag-grid-community';

import { getDataGridItems } from 'utils';

interface IUsePlayIconHeaderConfigProps<T> {
  api: GridApi;
  key?: keyof T; // 'mediaId' | 'id'
}

const usePlayIconHeaderConfig = <T>({ api, key = 'mediaId' as keyof T }: IUsePlayIconHeaderConfigProps<T>) => {
  const { dataGridItems, isPlayDisabled } = useMemo(() => {
    const dataGridItems = getDataGridItems(api) as T[];
    const isPlayDisabled = dataGridItems.every((el) => !el[key as keyof T]);

    return { dataGridItems, isPlayDisabled };
  }, [api, key]);

  return { dataGridItems, isPlayDisabled };
};

export default usePlayIconHeaderConfig;
