import { MutableRefObject, RefObject } from 'react';
import classNames from 'classnames';

import { TrackInfo } from 'components/MusicPlayer/IMusicPlayer';
import { formatDuration } from 'components/MusicPlayer/utils';
import WaveForm from 'components/WaveForm';

import styles from './MusicPlayer.module.scss';

interface IWaveBarProps {
  track: TrackInfo;
  waveRef: RefObject<HTMLDivElement | undefined>;
  progress: number;
  duration: number;
  aRef: MutableRefObject<HTMLAudioElement | undefined>;
  time: number;
  isExpanded: boolean;
  peeks: number[];
}

const WaveBar = ({ track, waveRef, progress, duration, aRef, time, isExpanded, peeks }: IWaveBarProps) => {
  const onSeek = (rewindTo: number) => {
    if (!track) return;
    // Audio rewind side effect
    const audio = aRef.current;

    if (audio) {
      audio.currentTime = (rewindTo * audio.duration) / 100;
    }
  };

  return (
    <div className={classNames(styles.playBarWaves, { [styles.expanded]: isExpanded })}>
      <span className={classNames(styles.timeInfo, styles.timeInfoStart)}>{formatDuration(time)}</span>
      <WaveForm
        ref={waveRef}
        className={styles.waveForm}
        barWidth={1}
        spaceWidth={1}
        baseFillStyle={'#929292'}
        caretFillStyle={'#d9a81b'}
        loadingFillStyle={'#B2B2B2'}
        progress={progress} // Progress state
        peaks={peeks} // Peaks sample
        onSeek={track && onSeek}
      />
      <span className={classNames(styles.timeInfo, styles.timeInfoEnd)}>
        {duration ? formatDuration(duration) : '-:--'}
      </span>
    </div>
  );
};

export default WaveBar;
